import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../../lib/components/Loader';
import { ChapterEntity } from '../../../../../../lib/model/Book';
import { PageLayout } from '../../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../../lib/utils/request';
import { extract_num_id_from_id } from '../../../utils/extract_num_id_from_id';
import { ChaptersBreadcrumb } from '../components/ChaptersBreadcrumb';
import { ChaptersDataTable } from '../components/ChaptersDataTable';
import { ChapterDto } from '../dto/ChapterDto';

export const ChapterListPage: FC = () => {
	const [remove_counter, set_remove_counter] = useState(0);

	const { id: book_id, edition_id } = useParams() as { id: string; edition_id: string };
	const book_num_id = extract_num_id_from_id(book_id);
	const edition_num_id = extract_num_id_from_id(edition_id);
	const BASE_URL = `${process.env.REACT_APP_BOOKS_API}/books/${book_num_id}/editions/${edition_num_id}/chapters`;

	return (
		<PageLayout
			{...{
				title: 'Capítulos',
				browser_back: true,
				breadcrumb: () => (
					<ChaptersBreadcrumb {...{ book_id, in_editions: true, edition_id, in_chapters: true }} />
				),
			}}
		>
			<Loader
				{...{
					reload_when_changes: [remove_counter],
					request: () => request().get<ChapterDto[]>(BASE_URL),
					renderChildren: (chapters: ChapterEntity[]) => {
						const row_data_from_entity: any[] = chapters.map((chapter) => {
							const authors_name = chapter.authors.map((author) => author.title) || [];

							return {
								...chapter,
								authors: authors_name,
							};
						});

						return (
							<ChaptersDataTable
								{...{
									chapters: row_data_from_entity,
									on_remove_success: () => set_remove_counter(remove_counter + 1),
								}}
							/>
						);
					},
				}}
			/>
		</PageLayout>
	);
};
