import React, { useState } from 'react';
import { ImageResolution } from './model/ImageResolution';
import { UploaderImage } from './UploaderImage';

interface IProps {
	resolution: ImageResolution;
	upload_api_endpoint: string;
	src_img: string;
}

export const ImageComponent: React.FC<IProps> = ({ resolution, upload_api_endpoint, src_img }) => {
	const [show_img, set_show_img] = useState(true);

	return (
		<div className=' flex-col items-center justify-center text-center '>
			<div className='block  '>
				{show_img && (
					<img
						{...{
							src: `${src_img}?d=${Date.now()}`,
							style: {
								maxHeight: 300,
							},
							alt: `${src_img}`,
						}}
					/>
				)}
			</div>

			<div className='mt-3'>
				<UploaderImage
					{...{
						upload_api_endpoint,
						resolution,
						on_success: () => {
							set_show_img(false);
							setTimeout(() => {
								try {
									set_show_img(true);
								} catch (error) {}
							}, 500);
						},
					}}
				/>
			</div>
		</div>
	);
};
