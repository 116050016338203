import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	periodicId?: string;
	editionId?: string;
	in_items?: boolean;
	itemId?: string;
	new_item?: boolean;
}

export const ItemsBreadcrumb: FC<IProps> = ({ periodicId, editionId, new_item, itemId, in_items }) => {
	return (
		<Breadcrumb>
			<Breadcrumb.Item key='periodics'>
				<Link to={'/conteudos/revistas'}>Revistas</Link>
			</Breadcrumb.Item>

			<Breadcrumb.Item key='periodic_title'>
				<Link to={`/conteudos/revistas/${periodicId}`}>{periodicId}</Link>
			</Breadcrumb.Item>

			<Breadcrumb.Item key='editionId'>
				<Link to={`/conteudos/revistas/${periodicId}/edicoes`}>Edições</Link>
			</Breadcrumb.Item>

			<Breadcrumb.Item key='editionId'>
				<Link to={`/conteudos/revistas/${periodicId}/edicoes/${editionId}`}>{editionId}</Link>
			</Breadcrumb.Item>

			{in_items && (
				<Breadcrumb.Item key='itemId'>
					<Link to={`/conteudos/revistas/${periodicId}/edicoes/${editionId}/itens`}>Itens</Link>
				</Breadcrumb.Item>
			)}

			{new_item && <Breadcrumb.Item key='new_item'>Novo</Breadcrumb.Item>}

			{itemId && (
				<Breadcrumb.Item key='editionId'>
					<Link to={`/conteudos/revistas/${periodicId}/edition/${editionId}/items/${itemId}`}>
						{itemId}
					</Link>
				</Breadcrumb.Item>
			)}
		</Breadcrumb>
	);
};
