import { Tag } from './Tag';

export type InformativeEntity = {
	num_id: string;
	publish_date: string;
	pdf?: string;
	html?: string;
	type?: string;
	is_published: boolean;
};

export enum SignedUrlTypes {
	READ = 'read',
	WRITE = 'write',
}
