import { Button } from 'antd';
import React, { CSSProperties, FC } from 'react';

interface IProps {
	onClick: () => void;
	style?: CSSProperties;
}

export const CancelButton: FC<IProps> = ({ onClick, style }) => (
	<Button {...{ type: 'primary', danger: true, onClick, style }}>Cancelar</Button>
);
