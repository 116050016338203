import { failed, Result, success } from '../../../model/Result';

type SessionInfo = {
	created_at: number;
};

export function useSessionStore() {
	const KEY = 'frmadminses';

	return {
		store: (created_at: number) => {
			if (!window.localStorage) {
				return;
			}

			const ses_info: SessionInfo = { created_at };

			localStorage.setItem(KEY, JSON.stringify(ses_info));
		},
		get: (): Result<SessionInfo> => {
			if (!window.localStorage) {
				return failed('localstorage nao suportado.');
			}

			const key_content = localStorage.getItem(KEY);

			if (!key_content) {
				return failed('nao encontrou informacoes da sessao no localstorage.');
			}

			const ses_info: SessionInfo = JSON.parse(key_content);

			return success(ses_info);
		},
		clear: () => {
			if (!window.localStorage) {
				return;
			}

			localStorage.removeItem(KEY);
		},
	};
}
