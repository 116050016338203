import { FC, useState } from 'react';
import { Loader } from '../../../../../lib/components/Loader';
import { PermissionDto } from '../../../../../lib/dtos/PermissionDto';
import { PermissionGroup } from '../../../../../lib/model/PermissionGroup';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../lib/utils/request';
import { PermissionGroupInsertModal } from '../components/PermissionGroupInsertModal';
import { PermissionGroupDatatable } from '../components/PermissionGroupDatatable';

const BASE_URL = `${process.env.REACT_APP_PERMISSIONS_GROUPS_API}/permissions`;

export const PermissionGroupListPage: FC = () => {
	const [remove_counter, set_remove_counter] = useState(0);
	const [insert_counter, set_insert_counter] = useState(0);
	const [visible_form_insert, set_visible_form_insert] = useState(false);

	return (
		<PageLayout title='Grupos de Permissão'>
			<Loader
				{...{
					reload_when_changes: [insert_counter, remove_counter],
					request: () => request().get<PermissionDto[]>(BASE_URL),
					renderChildren: (permission_groups: PermissionGroup[]) => (
						<PermissionGroupDatatable
							{...{
								permission_groups,
								on_remove_success: () => set_remove_counter(remove_counter + 1),
								visible_insert_modal: () => set_visible_form_insert(true),
							}}
						/>
					),
				}}
			/>
			<PermissionGroupInsertModal
				{...{
					visible: visible_form_insert,
					close: () => set_visible_form_insert(false),
					insert_success: () => set_insert_counter(insert_counter + 1),
				}}
			/>
		</PageLayout>
	);
};
