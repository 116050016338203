import { Button, Input, Spin } from 'antd';
import React from 'react';
import { ButtonsContainer } from '../../../../../lib/components/Buttons/ButtonsContainer';
import { FormContainer } from '../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../lib/components/Form/FormField';
import { InsertPriceVersionDto } from '../../dtos/InsertPriceVersionDto';
import { usePriceVersionForm } from './usePriceVersionForm';

interface IFormProps {
	loading: boolean;
	submit: (dto: InsertPriceVersionDto) => void;
	version_date: number;
}

export const Form: React.FC<IFormProps> = ({ loading, submit, version_date }) => {
	const { change_field_value, form_state, show_errors } = usePriceVersionForm();

	function validate_and_submit() {
		show_errors();

		if (!form_state.valid) {
			return;
		}

		const dto: InsertPriceVersionDto = { ...form_state.value, version_date };

		submit(dto);
	}

	return (
		<FormContainer {...{ loading }}>
			<FormField
				{...{
					label: 'Comentários',
					name: 'comments',
					form_state,
				}}
			>
				<Input
					{...{
						value: form_state.value.comments,
						onChange: (event) => change_field_value('comments', event.target.value),
					}}
				/>
			</FormField>

			<ButtonsContainer class_name='flex justify-end mt-4'>
				{loading ? (
					<Spin />
				) : (
					<Button
						type={'primary'}
						onClick={validate_and_submit}
						style={{ paddingLeft: 30, paddingRight: 30 }}
					>
						Enviar
					</Button>
				)}
			</ButtonsContainer>
		</FormContainer>
	);
};
