import { format, parseISO } from 'date-fns';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../../../../../lib/components/Buttons/AddButton';
import { RemoveButton } from '../../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../../lib/components/DataTable/ColumnFactory';
import { DataTable } from '../../../../../lib/components/DataTable/DataTable';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { ClippingFromElasticDto } from '../../dtos/ClippingFromElasticDto';
import { useClippingsApi } from '../hooks/useClippingsApi';

type IProps = {
	clippings: ClippingFromElasticDto[];
	on_remove_success: () => void;
};

export const ClippingsDataTable: FC<IProps> = ({ clippings, on_remove_success }) => {
	const { remove } = useClippingsApi();

	const navigate = useNavigate();

	useEffect(() => {
		if (!!remove.result) {
			on_remove_success();
		}
	}, [remove.result]);

	useRequestFeedback(remove.result, remove.error);

	return (
		<DataTable
			{...{
				buttons: [<AddButton onClick={() => navigate('novo')} />],
				columns: [
					ColumnFactory.Link(
						{
							field: 'publish_date',
							headerName: 'Data de Publicacao',
							resizable: false,
							rowDrag: false,
							width: 160,
						},
						(props: { data: { id: string } }) => props.data.id
					),
					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							cellStyle: CellStyle.Centered,
							width: 90,
						},
						renderer: (props: { data: { id: string } }) => (
							<RemoveButton
								{...{
									on_confirm: () => remove.do_remove(props.data.id),
									title: `Deseja remover o informativo ${props.data.id}?`,
								}}
							/>
						),
					},
				],
				rowData: clippings.map((clipping) => ({
					id: clipping.Id,
					publish_date: format(parseISO(clipping.PublishDate), 'dd/MM/yyyy'),
				})),
			}}
		/>
	);
};
