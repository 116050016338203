import Avatar from 'antd/lib/avatar/avatar';
import { formatRelative } from 'date-fns';
import { pt } from 'date-fns/locale';
import { FC } from 'react';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../lib/components/DataTable/ColumnFactory';
import { SearchableDataTable } from '../../../../lib/components/SearchableDataTable/SearchableDataTable';
import { UserProfile } from '../../../../lib/model/UserProfile';

export const UserProfilesDataTable: FC = () => (
	<SearchableDataTable
		{...{
			date_fields: ['Última atividade'],
			sortable_fields: ['Nome', 'Email'],
			searchable_defaults: {
				sort_field: 'date1',
				sort_dir: 'desc',
			},
			row_data_from_entity: (user: UserProfile) => ({
				...user,
				cpf: user.cpf === '00000000000' ? '' : user.cpf,
				phone: user.phone.number !== 99999999 ? `${user.phone.areaCode} ${user.phone.number}` : '',
				last_activity: formatRelative(user.last_activity, Date.now(), { locale: pt }),
			}),

			columns: [
				{
					col_def: {
						headerName: '',
						field: 'picture',
						width: 120,
						cellStyle: CellStyle.Centered,
					},
					renderer: (props: { data: { picture: string } }) => (
						<Avatar {...{ src: props.data.picture }} />
					),
				},

				ColumnFactory.Link(
					{
						field: 'name',
						headerName: 'Nome',
						resizable: true,
						rowDrag: false,
						width: 250,
					},
					(props: { data: { email: string } }) => props.data.email
				),
				{
					field: 'email',
					headerName: 'Email',
					resizable: true,
					rowDrag: false,
					width: 300,
				},
				{
					field: 'cpf',
					headerName: 'CPF',
					resizable: true,
					rowDrag: false,
					width: 150,
				},
				{
					field: 'phone',
					headerName: 'Telefone',
					resizable: true,
					rowDrag: false,
					width: 150,
				},
				{
					field: 'last_activity',
					headerName: 'Última atividade',
					resizable: true,
					rowDrag: false,
					width: 230,
				},
			],
		}}
	/>
);
