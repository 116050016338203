import { Checkbox, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FC, useEffect } from 'react';
import { FormButtons } from '../../../../../lib/components/Form/FormButtons';
import { FormContainer } from '../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../lib/components/Form/FormField';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { Highlight } from '../../../../../lib/model/Highlight';
import { UpdateHighlightDto } from '../../dtos/UpdateHighlightDto';
import { useHighlightsApi } from '../../hooks/useHighlightApi';
import { useHighlightForm } from './useHighlightForm';

interface IProps {
	highlight: Highlight;
}

export const HighlightForm: FC<IProps> = ({ highlight }) => {
	const { form_state, show_errors, change_field_value, fill_with_data } = useHighlightForm();
	const { update } = useHighlightsApi();

	useEffect(() => {
		fill_with_data(highlight);
	}, [highlight]);

	function submit() {
		show_errors();
		if (!form_state.valid) {
			return;
		}

		const dto: UpdateHighlightDto = {
			id: form_state.value.id,
			name: form_state.value.name,
			text: form_state.value.text,
			promotion: form_state.value.promotion,
		};

		update.do_update(dto);
	}

	useRequestFeedback(update.result, update.error);

	return (
		<FormContainer>
			<FormField {...{ label: 'Nome', name: 'name', form_state }}>
				<Input
					value={form_state.value.name}
					onChange={(evt) => change_field_value('name', evt.target.value)}
					className='w-1/2'
				/>
			</FormField>

			<FormField {...{ label: 'Texto', name: 'description', form_state, required: false }}>
				<TextArea
					{...{
						rows: 4,

						value: form_state.value.text,
						onChange: (event) => change_field_value('text', event.target.value),
						className: 'w-1/2',
					}}
				/>
			</FormField>

			<FormField
				{...{
					label: 'Promoção?',
					name: 'promotion',
					form_state,
					required: false,
					render_children_inside_label: false,
					class_name: 'flex',
				}}
			>
				<Checkbox
					checked={form_state.value.promotion}
					onChange={(evt) => change_field_value('promotion', evt.target.checked)}
					className='ml-1'
				/>
			</FormField>

			<FormButtons {...{ go_back_on_cancel: true, on_update_button_click: submit }} />
		</FormContainer>
	);
};
