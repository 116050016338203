import { Button, Form, Modal } from 'antd';
import { FC } from 'react';
import { UserLimit } from './useDownloadsconfigForm';
import { UserLimitsFormItems } from './UserLimitsFormItems';

interface IAddUserLimitModal {
	visible: boolean;
	close: () => void;
	set_user_limits: (user_limits: UserLimit[]) => void;
}

export const AddUserLimitModal: FC<IAddUserLimitModal> = ({ visible, close, set_user_limits }) => {
	function values_from_form(elements: UserLimit[]) {
		const convert_to_obj = Object.getOwnPropertyDescriptors(elements);
		const user_limits: UserLimit[] = convert_to_obj.user_limits.value.map(
			(user_limit: { email: string; limit: number }) => {
				return {
					email: user_limit.email,
					limit: user_limit.limit,
				};
			}
		);

		set_user_limits(user_limits);
	}

	return (
		<Modal
			{...{
				title: 'Adicionar Limites de Download por Usuario',
				centered: true,
				visible,
				width: 800,
				closable: true,
				destroyOnClose: true,
				onCancel: close,
				footer: null,
			}}
		>
			<Form onFinish={values_from_form}>
				<Form.List name='user_limits' initialValue={[{ email: '', limit: 0 }]}>
					{(fields, { add, remove }) => (
						<>
							{fields.map(({ key, name: index }) => (
								<UserLimitsFormItems {...{ add, index, key, remove, fields_length: fields.length }} />
							))}
						</>
					)}
				</Form.List>

				<div className='w-full flex flex-row justify-end'>
					<Button type='primary' htmlType='submit' onClick={close}>
						Adicionar
					</Button>
				</div>
			</Form>
		</Modal>
	);
};
