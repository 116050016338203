export const form_validators = {
	required_text_field: (field_value: string) =>
		field_value.trim().length === 0 ? 'Campo obrigatório.' : '',

	required_boolean_field: (field_value: boolean) => (field_value === false ? false : true),

	required_number_field: (field_value: number) => (field_value === 0 ? 'Campo obrigatório.' : ''),

	email_field: (field_value: string) => {
		// eslint-disable-next-line
		field_value.trim().length === 0 ? 'Campo obrigatório.' : '';

		const verify_email = /^[_a-z0-9\-.]+@[a-z]+\.[a-z]+(\.[a-z]+)*$/i;

		const validate_email = verify_email.test(field_value);

		if (validate_email) {
			return '';
		} else {
			return 'E-mail Incorreto';
		}
	},

	value_string: (field_value: string) => {
		if (field_value) {
			// eslint-disable-next-line
			typeof field_value.trim() === 'string' ? '' : 'Nome inválido';
		} else {
			return '';
		}
	},
	required_list_field: (field_value: any[]) =>
		field_value.length === 0 ? 'Lista de conteudos obrigatória.' : '',
};
