import { List as AntList } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { ReactNode } from 'react';
import { ListRemoveButton } from './ListRemoveButton';

interface IProps {
	data: any[];
	header?: ReactNode;
	on_remove_click?: (item: any) => void;
	icon?: ReactNode;
	render_title?: (item: any) => ReactNode;
}

export const List: React.FC<IProps> = ({
	data,
	on_remove_click,
	icon,
	render_title = (i) => <span className='block'>{i}</span>,
	header,
}) => {
	return (
		<AntList
			{...{
				itemLayout: 'horizontal',
				size: 'small',
				header: (
					<Title level={5} className='uppercase'>
						{header}
					</Title>
				),
				dataSource: data,
				renderItem: (item) => {
					const actions = [];

					if (on_remove_click) {
						actions.push(
							<ListRemoveButton
								{...{
									on_click: () => on_remove_click(item),
								}}
							/>
						);
					}

					return (
						<AntList.Item actions={actions}>
							<AntList.Item.Meta
								{...{
									avatar: icon ? icon : null,
									title: render_title(item),
								}}
							/>
						</AntList.Item>
					);
				},
			}}
		/>
	);
};
