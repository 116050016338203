import { ColDef, ColGroupDef } from 'ag-grid-community';
import { ReactNode } from 'react';
import { ColWithRendererDef } from './Types';

type R = {
	frameworkComponents: {
		[key: string]: (props: any) => ReactNode;
	};
	columnDefs: (ColDef | ColGroupDef)[];
};

export function columns_reducer(acc: R, c: any): R {
	if (!c?.renderer) {
		return {
			frameworkComponents: acc.frameworkComponents,
			columnDefs: [...acc.columnDefs, c],
		};
	}

	const col: ColWithRendererDef = c;
	const renderer_name = col.col_def.field + '';

	return {
		frameworkComponents: {
			...acc.frameworkComponents,
			[renderer_name]: col.renderer,
		},
		columnDefs: [
			...acc.columnDefs,
			{
				cellRenderer: renderer_name,
				...col.col_def,
			},
		],
	};
}
