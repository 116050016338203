import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../../lib/components/Loader';
import { ItemEntity } from '../../../../../../lib/model/Periodic';
import { PageLayout } from '../../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../../lib/utils/request';
import { extract_num_id_from_id } from '../../../utils/extract_num_id_from_id';
import { ItemsBreadcrumb } from '../components/ItemsBreadcrumb';
import { ItemForm } from '../components/form/ItemForm';
import { ItemDto } from '../dto/ItemDto';

export const UpdateItemPage: FC = () => {
	const { id, editionId, itemId } = useParams() as {
		id: string;
		editionId: string;
		itemId: string;
	};

	const periodicNumId = String(extract_num_id_from_id(id)).toLocaleUpperCase();
	const editionNumId = String(extract_num_id_from_id(editionId)).toLocaleUpperCase();
	const itemNumId = extract_num_id_from_id(itemId);

	const BASE_URL = `${process.env.REACT_APP_PERIODIC_API}/periodics/${periodicNumId}/editions/${editionNumId}/items/${itemNumId}`;

	return (
		<PageLayout
			{...{
				title: 'Atualizar Item',
				browser_back: true,
				breadcrumb: () => <ItemsBreadcrumb {...{ periodicId: id, editionId, in_items: true, itemId }} />,
			}}
		>
			<Loader
				{...{
					request: () => request().get<ItemDto>(BASE_URL),
					renderChildren: (item: ItemEntity) => <ItemForm {...{ item }} />,
				}}
			/>
		</PageLayout>
	);
};
