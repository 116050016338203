import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { ClippingsListPage } from './Clippings/pages/ClippingsListPage';
import { InsertClippingPage } from './Clippings/pages/InsertClippingPage';
import { UpdateClippingPage } from './Clippings/pages/UpdateClippingPage';
import { ClippingSourceListPage } from './ClippingSource/pages/ClippingSourceListPage';

export const PlatformClippingRoutes: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.PlatformAdmin, Role.PlatformClipping] }}>
		<Routes>
			<Route
				{...{
					path: ':source_id/informativos/novo',
					element: <InsertClippingPage />,
				}}
			/>

			<Route
				{...{
					path: ':source_id/informativos/:clipping_id',
					element: <UpdateClippingPage />,
				}}
			/>

			<Route
				{...{
					path: ':source_id/informativos',
					element: <ClippingsListPage />,
				}}
			/>

			<Route
				{...{
					path: '/',
					element: <ClippingSourceListPage />,
				}}
			/>
		</Routes>
	</ProtectedContent>
);
