import { Select } from 'antd';
import { FC } from 'react';
import { EditionFrequency } from '../../../../../../lib/model/Periodic';

const Option = Select;

interface IProps {
	value: EditionFrequency;
	on_change: (field_name: any, value: EditionFrequency) => void;
}

export const SelectEditionFrequency: FC<IProps> = ({ value, on_change }) => (
	<Select
		placeholder='Selecione a Frequência'
		onChange={(editionFrequency) => on_change('frequency', editionFrequency)}
		defaultValue={EditionFrequency.Mensal}
		style={{ width: '50%' }}
		value={value}
	>
		<Option value={EditionFrequency.Mensal} key={EditionFrequency.Mensal}>
			{EditionFrequency.Mensal}
		</Option>
		<Option value={EditionFrequency.Bimestral} key={EditionFrequency.Bimestral}>
			{EditionFrequency.Bimestral}
		</Option>
		<Option value={EditionFrequency.Trimestral} key={EditionFrequency.Trimestral}>
			{EditionFrequency.Trimestral}
		</Option>
		<Option value={EditionFrequency.Quadrimestral} key={EditionFrequency.Quadrimestral}>
			{EditionFrequency.Quadrimestral}
		</Option>
		<Option value={EditionFrequency.Semestral} key={EditionFrequency.Semestral}>
			{EditionFrequency.Semestral}
		</Option>
		<Option value={EditionFrequency.Anual} key={EditionFrequency.Anual}>
			{EditionFrequency.Anual}
		</Option>
		<Option value={EditionFrequency.Irregular} key={EditionFrequency.Irregular}>
			{EditionFrequency.Irregular}
		</Option>
	</Select>
);
