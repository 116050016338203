import { useParams } from 'react-router-dom';
import { useRequest } from '../../../../../lib/hooks';
import { request } from '../../../../../lib/utils/request';
import { ContractDto } from '../dtos/ContractDto';
import { InsertContractDto } from '../dtos/InsertContractDto';
import { UpdateContractDto } from '../dtos/UpdateContractDto';

const BASE_URL = process.env.REACT_APP_CLIENT_CONTRACT_MANAGEMENT_API;

export function useContractManagementApi() {
	const { id: client_id, c_number } = useParams() as { id: string; c_number: string };

	const create = useCreateContract(client_id);
	const update = useUpdateContract(client_id, c_number);
	const remove = useRemoveContract();

	return {
		create,
		update,
		remove,
	};
}

function useCreateContract(client_id: string) {
	const [result, loading, error, create] = useRequest<ContractDto>((dto: InsertContractDto) =>
		request().post(`${BASE_URL}/clients/${client_id}/contracts`, dto)
	);

	return {
		do_create: (dto: InsertContractDto) => create(dto),
		result,
		error,
		loading,
	};
}

function useUpdateContract(client_id: string, c_number: string) {
	const [updated, loading, error, update] = useRequest<ContractDto>((dto: UpdateContractDto) =>
		request().put(`${BASE_URL}/clients/${client_id}/contracts/${c_number}`, dto)
	);

	return {
		do_update: (dto: UpdateContractDto) => update(dto),
		updated,
		loading,
		error,
	};
}

function useRemoveContract() {
	const [removed, loading, error, remove] = useRequest<string>(
		(client_id: string, contract_number: number) =>
			request().delete(`${BASE_URL}/clients/${client_id}/contracts/${contract_number}`)
	);

	return {
		do_remove: (client_id: string, contract_number: number) => remove(client_id, contract_number),
		removed,
		error,
		loading,
	};
}
