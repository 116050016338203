import { FC } from 'react';
import { AddButton } from '../../../../../lib/components/Buttons/AddButton';
import { RemoveButton } from '../../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../../lib/components/DataTable/CellStyle';
import { SearchableDataTable } from '../../../../../lib/components/SearchableDataTable/SearchableDataTable';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { SearchTagDto } from '../dto/SearchTagDto';
import { useSearchTagsApi } from '../hooks/SearchTagsApi';

interface IProps {
	on_remove_success: () => void;
	visible_insert_modal: () => void;
	title_of_the_item_to_be_removed: string;
}

export const SearchTagsDatatable: FC<IProps> = ({
	on_remove_success,
	visible_insert_modal,
	title_of_the_item_to_be_removed,
}) => {
	const { remove } = useSearchTagsApi();

	if (remove.result) {
		on_remove_success();
	}

	useRequestFeedback(remove.result, remove.error);

	return (
		<SearchableDataTable
			{...{
				row_height: () => 50,
				sortable_fields: ['Titulo'],
				buttons_on_top: [<AddButton onClick={() => visible_insert_modal()} />],
				columns: [
					{
						field: 'title',
						headerName: 'Titulo',
						resizable: true,
						width: 250,
					},

					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							cellStyle: CellStyle.Centered,
							width: 100,
						},
						renderer: (props: { data: SearchTagDto }) => (
							<RemoveButton
								{...{
									on_confirm: () => remove.do_remove(props.data.id),
									title: `Deseja remover a ${title_of_the_item_to_be_removed} ${props.data.title}?`,
								}}
							/>
						),
					},
				],
			}}
		/>
	);
};
