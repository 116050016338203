import { CloseCircleFilled } from '@ant-design/icons';
import { Input } from 'antd';
import { FC, useRef, useState } from 'react';
import { AddButton } from './Buttons/AddButton';
import { CloseIcon } from './icons/CloseIcon';

interface IProps {
	on_added: (value: string) => void;
	validate: (value: string) => boolean;
	format_value?: (value: string) => string;
}

export const AddInput: FC<IProps> = ({ on_added, validate, format_value = (v) => v }) => {
	const [value, set_value] = useState('');
	const [invalid, set_invalid] = useState(false);
	const input_ref = useRef<any>();

	function submit(keep_focused: boolean) {
		if (!validate(value)) {
			return set_invalid(true);
		}

		on_added(value);
		set_value('');

		if (keep_focused && input_ref.current) {
			input_ref.current.focus();
		}
	}

	return (
		<div className='px-3'>
			<div className='flex items-center'>
				<Input
					{...{
						ref: input_ref,
						value,
						onChange: (event) => {
							set_invalid(false);
							const v = format_value(event.target.value);
							set_value(v);
						},
						className: invalid ? 'border-red-500' : '',
						onPressEnter: () => submit(true),
						onBlur: () => {
							if (!value) {
								return;
							}

							submit(false);
						},
					}}
				/>

				<span className='ml-3' />

				<AddButton {...{ onClick: () => submit(true) }} />
			</div>

			{invalid && (
				<div className='text-red-500 flex items-center py-1'>
					<CloseIcon /> <span className='ml-2 text-xs'>Dado inválido.</span>
				</div>
			)}
		</div>
	);
};
