import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchQueryDto } from '../model/SearchQueryDto';
import { useQuery } from './useQuery';
import { useSearchApi } from './useSearchApi';

export function useSearch<T>(default_query: SearchQueryDto, version_date: number | 'current') {
	const location = useLocation();
	const [pathname] = useState(location.pathname);
	const query = useQuery(default_query);
	const { result, loading, error, search } = useSearchApi<T>();

	const dto: SearchQueryDto = {
		groups: query.groups.join(','),
		tags: query.tags.join(','),
		sort_by: query.sort_by,
		page: query.page,
		text: query.text,
	};

	useEffect(() => {
		if (location.pathname !== pathname) {
			return;
		}
		query.clear();
	}, [location.pathname]);

	useEffect(() => {
		if (location.pathname !== pathname || loading) {
			return;
		}

		search(dto, version_date);
	}, [location.pathname, dto.text, dto.page, dto.sort_by, dto.groups, dto.tags]);

	return {
		result,
		error,
		loading,
		query,
		reload: () => search(dto, version_date),
	};
}
