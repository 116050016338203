import { FC } from 'react';
import { ColumnFactory } from '../../../../lib/components/DataTable/ColumnFactory';
import { DataTable } from '../../../../lib/components/DataTable/DataTable';
import { InformativeTypeEntity } from '../../../../lib/model/InformativeType';

interface IProps {
	informative_types: InformativeTypeEntity[];
	on_remove_success: () => void;
}

export const InformativesTypeDataTable: FC<IProps> = ({ informative_types, on_remove_success }) => {
	return (
		<DataTable
			{...{
				rowData: informative_types,
				buttons: [],
				columns: [
					ColumnFactory.Link(
						{ field: 'label', headerName: 'Título', resizable: true, width: 200 },
						(props: { data: { type: string } }) => `${props.data.type}/list`
					),
				],
			}}
		/>
	);
};
