import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../../../../../lib/shell/PageLayout/PageLayout';
import { ChaptersBreadcrumb } from '../components/ChaptersBreadcrumb';
import { ChapterForm } from '../components/form/ChapterForm';

export const InsertChapterPage: FC = () => {
	const { id: book_id, edition_id } = useParams() as { id: string; edition_id: string };

	return (
		<PageLayout
			{...{
				title: 'Inserir Capítulo',
				browser_back: true,
				breadcrumb: () => (
					<ChaptersBreadcrumb {...{ book_id, edition_id, in_chapters: true, new_chapter: true }} />
				),
			}}
		>
			<ChapterForm />
		</PageLayout>
	);
};
