import { FC, useState } from 'react';
import { Loader } from '../../../../lib/components/Loader';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { BannerInsertModal } from '../components/BannerInsertModal';
import { BannersDataTable } from '../components/BannersDataTable';
import { BannerDto } from '../dtos/BannerDto';

export const BannersListPage: FC = () => {
	const [visible_insert_form, set_visible_insert_form] = useState(false);
	const [remove_count, set_remove_count] = useState(0);

	return (
		<PageLayout {...{ title: 'Banners' }}>
			<Loader
				{...{
					reload_when_changes: [remove_count],
					request: () => request().get<BannerDto[]>(`${process.env.REACT_APP_BANNERS_API}`),
					renderChildren: (banners: BannerDto[]) => (
						<>
							<BannersDataTable
								{...{
									banners,
									visible_insert_form: () => set_visible_insert_form(true),
									on_remove_success: () => set_remove_count(remove_count + 1),
								}}
							/>
							<BannerInsertModal
								{...{
									visible: visible_insert_form,
									close: () => set_visible_insert_form(false),
								}}
							/>
						</>
					),
				}}
			/>
		</PageLayout>
	);
};
