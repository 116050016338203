import React from 'react';
import { ImageComponent } from './ImageComponent';
import { ImageResolution } from './model/ImageResolution';

interface IProps {
	upload_api_endpoint: string;
	src_img: string;
}

export const UploadMultipleResolutionImages: React.FC<IProps> = ({ upload_api_endpoint, src_img }) => {
	const resolution_text = {
		[ImageResolution.Desktop]: 'Desktop (1880x646)',
		[ImageResolution.TABLE_LANDSCAPE]: 'Tablet paisagem (2134x917)',
		[ImageResolution.TABLET_PORTRAIT]: 'Tablet retrato (1601x917)',
		[ImageResolution.MOBILE]: 'Mobile (667x782)',
	};
	return (
		<div className='flex flex-wrap h-full'>
			{[
				ImageResolution.Desktop,
				ImageResolution.MOBILE,
				ImageResolution.TABLE_LANDSCAPE,
				ImageResolution.TABLET_PORTRAIT,
			].map((resolution) => (
				<div
					key={resolution}
					style={{
						height: 400,
					}}
					className='w-1/2 flex flex-col justify-center items-center  px-12'
				>
					<p className='font-semibold text-xl'>{resolution_text[resolution]}</p>

					<ImageComponent
						{...{
							resolution,
							upload_api_endpoint,
							src_img: `${src_img}/${resolution}.jpg`,
						}}
					/>
				</div>
			))}
		</div>
	);
};
