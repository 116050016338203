import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	banner_name: string;
}

export const BannersBreadcrumb: FC<IProps> = ({ banner_name }) => (
	<Breadcrumb>
		<Breadcrumb.Item key='banners'>
			<Link to={'/loja/banners'}>Banners</Link>
		</Breadcrumb.Item>
		<Breadcrumb.Item key='banner'>{banner_name}</Breadcrumb.Item>
	</Breadcrumb>
);
