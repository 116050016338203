import { useParams } from 'react-router-dom';
import { useRequest } from '../../../../lib/hooks';
import { request } from '../../../../lib/utils/request';
import { InformativeDto } from '../dto/InformativeDto';
import { InformativeInsertDto } from '../dto/InformativeInsertDto';
import { InformativeUpdateDto } from '../dto/InformativeUpdateDto';
import { UpdateInformativeContentTextDto } from '../dto/UpdateInformativeContentTextDto';
import { InformativeContent } from '../dto/InformativeContent';

export function useInformativesApi() {
	const { num_id, type, publish_date } = useParams() as {
		num_id: string;
		type: string;
		publish_date: string;
	};

	const BASE_URL = `${process.env.REACT_APP_INFORMATIVE_API}/informatives`;
	const insert = useCreateInformatives(BASE_URL);
	const remove = useRemoveInformatives(BASE_URL);
	const update = useUpdateInformatives(BASE_URL, num_id);
	const list_types = useListTypesInformatives(BASE_URL);
	const get_list = useListInformatives(BASE_URL, type);
	const get_informative = useGetInformatives(BASE_URL, type, num_id);
	const get_informative_by_date = useGetInformativesByDate(BASE_URL, type, num_id, publish_date);
	const get_last_informative_id = useGetLastInformativeID(BASE_URL);
	const read_pdf = useReadPDF(BASE_URL, num_id);
	const update_text = useUpdateInformativeText(BASE_URL, num_id);
	const publish = usePublishInformative(BASE_URL, num_id);

	return {
		insert,
		remove,
		update,
		list_types,
		get_list,
		get_informative,
		get_last_informative_id,
		get_informative_by_date,
		read_pdf,
		update_text,
		publish,
	};
}

function useCreateInformatives(BASE_URL: string) {
	const [result, loading, error, do_create] = useRequest<InformativeDto>((dto: InformativeInsertDto) =>
		request().post(BASE_URL, dto)
	);

	return {
		create: (dto: InformativeInsertDto) => do_create(dto),
		result,
		error,
		loading,
	};
}

function useUpdateInformatives(BASE_URL: string, num_id: string) {
	const [result, loading, error, update] = useRequest<InformativeDto>((dto: InformativeUpdateDto) =>
		request().put(`${BASE_URL}/${num_id}`, dto)
	);

	return {
		do_update: (dto: InformativeUpdateDto) => update(dto),
		result,
		error,
		loading,
	};
}

function useGetInformatives(BASE_URL: string, type: string, num_id: string) {
	const [result, loading, error, do_get] = useRequest<any>(
		() =>
			new Promise((resolve, reject) => {
				request()
					.get(`${BASE_URL}/:type/list/${num_id}`)
					.then((res) => setTimeout(() => resolve(res as any), 1500))
					.catch(reject);
			})
	);

	return {
		get: () => do_get(),
		result,
		error,
		loading,
	};
}

function useGetInformativesByDate(BASE_URL: string, type: string, publish_date: string, num_id: string) {
	const [result, loading, error, do_get] = useRequest<any>(
		(publish_date: string) =>
			new Promise((resolve, reject) => {
				request()
					.get(`${BASE_URL}/jacoby/list/specific/${publish_date}`)
					.then((res) => setTimeout(() => resolve(res as any), 1500))
					.catch(reject);
			})
	);

	return {
		get: (publish_date: string) => do_get(publish_date),
		result,
		error,
		loading,
	};
}

function useRemoveInformatives(BASE_URL: string) {
	const [result, loading, error, remove] = useRequest<string>((num_id: string) =>
		request().delete(`${BASE_URL}/${num_id}`)
	);

	return {
		do_remove: (num_id: string) => remove(num_id),
		result,
		loading,
		error,
	};
}

function useListTypesInformatives(BASE_URL: string) {
	const [result, loading, error, do_get] = useRequest<any>(
		() =>
			new Promise((resolve, reject) => {
				request()
					.get(`${BASE_URL}`)
					.then((res) => setTimeout(() => resolve(res as any), 1500))
					.catch(reject);
			})
	);

	return {
		get: () => do_get(),
		result,
		error,
		loading,
	};
}

function useListInformatives(BASE_URL: string, type: string) {
	const [result, loading, error, do_get] = useRequest<any>(
		() =>
			new Promise((resolve, reject) => {
				request()
					.get(`${BASE_URL}/:type/list`)
					.then((res) => setTimeout(() => resolve(res as any), 1500))
					.catch(reject);
			})
	);

	return {
		get: () => do_get(),
		result,
		error,
		loading,
	};
}

function useGetLastInformativeID(BASE_URL: string) {
	const [result, loading, error, do_get] = useRequest<{ num_id: string }>(
		() =>
			new Promise((resolve, reject) => {
				request()
					.get(`${BASE_URL}/last-id`)
					.then((res) => setTimeout(() => resolve(res as { num_id: string }), 1500))
					.catch(reject);
			})
	);

	return {
		get: () => do_get(),
		result,
		error,
		loading,
	};
}

function useReadPDF(BASE_URL: string, num_id: string) {
	const [result, loading, error, do_get] = useRequest<any>(
		() =>
			new Promise((resolve, reject) => {
				request()
					.get(`${BASE_URL}/${num_id}/pdf-content-text`)
					.then((res) => setTimeout(() => resolve(res as any), 1500))
					.catch(reject);
			})
	);

	return {
		get: () => do_get(),
		result,
		error,
		loading,
	};
}

function useUpdateInformativeText(BASE_URL: string, num_id: string) {
	const [result, loading, error, update] = useRequest<string>((dto: UpdateInformativeContentTextDto[]) =>
		request().put(`${BASE_URL}/${num_id}/update-text`, dto)
	);

	return {
		do_update: (dto: UpdateInformativeContentTextDto[]) => update(dto),
		result,
		loading,
		error,
	};
}

function usePublishInformative(BASE_URL: string, num_id: string) {
	const [result, loading, error, index] = useRequest<string>(() =>
		request().put(`${BASE_URL}/${num_id}/item-content`, {})
	);

	return {
		do_index: (num_id: string) => index(num_id),
		result,
		loading,
		error,
	};
}
