import { FC } from 'react';
import { AddButton } from '../../../../../lib/components/Buttons/AddButton';
import { RemoveButton } from '../../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../../lib/components/DataTable/CellStyle';
import { DataTable } from '../../../../../lib/components/DataTable/DataTable';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { Tag } from '../../../../../lib/model/Tag';
import { useTagsManagementApi } from '../../hooks/ContractTagsApi';

interface IProps {
	video_series: Tag[];
	on_remove_success: () => void;
	visible_insert_modal: () => void;
}

export const VideoSeriesDatatable: FC<IProps> = ({
	video_series,
	on_remove_success,
	visible_insert_modal,
}) => {
	const { remove } = useTagsManagementApi('book');

	if (remove.result) {
		on_remove_success();
	}

	useRequestFeedback(remove.result, remove.error);

	return (
		<DataTable
			{...{
				rowData: video_series,
				buttons: [<AddButton onClick={() => visible_insert_modal()} />],
				columns: [
					{
						field: 'title',
						headerName: 'Titulo',
						resizable: true,
						rowDrag: false,
						width: 500,
					},

					// {
					// 	col_def: {
					// 		field: 'buttons',
					// 		headerName: '',
					// 		pinned: 'right',
					// 		cellStyle: CellStyle.Centered,
					// 		width: 100,
					// 	},
					// 	renderer: (props: { data: Tag }) => (
					// 		<RemoveButton
					// 			{...{
					// 				on_confirm: () => remove.do_remove(props.data.id),
					// 				title: `Deseja remover a ${props.data.title} de vídeo?`,
					// 			}}
					// 		/>
					// 	),
					// },
				],
			}}
		/>
	);
};
