import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { SectionListPage } from './pages/SectionListPage';
import { UpdateSectionListPage } from './pages/UpdateSectionListPage';

export const SectionsRoutes: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.StoreAdmin, Role.StoreSections] }}>
		<Routes>
			<Route path=':id' element={<UpdateSectionListPage />} />
			<Route path='/' element={<SectionListPage />} />
		</Routes>
	</ProtectedContent>
);
