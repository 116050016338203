import { useForm } from '../../../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../../../lib/hooks/useForm/validators';
import { ItemTypes } from '../../../../../../../lib/model/Periodic';
import { Tag } from '../../../../../../../lib/model/Tag';

type FormValues = {
	numId: string;
	title: string;
	itemType: ItemTypes;
	keywords: Tag[];
	authors: Tag[];
	themes: Tag[];
};

export function useItemForm() {
	const form = useForm<FormValues>(
		{
			numId: '',
			title: '',
			itemType: ItemTypes.Other,
			keywords: [],
			authors: [],
			themes: [],
		},
		function validate_form(state) {
			return {
				numId: form_validators.required_text_field(state.value.numId),
				title: form_validators.required_text_field(state.value.title),
				itemType: '',
				keywords: form_validators.required_list_field(state.value.keywords),
				authors: '',
				themes: '',
			};
		}
	);

	return form;
}
