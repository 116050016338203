import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FC } from 'react';
import { AddButton } from '../../../../../../lib/components/Buttons/AddButton';
import { RemoveButton } from '../../../../../../lib/components/Buttons/RemoveButton';
import { remove_duplicate_emails } from './remove_duplicate_emails';
import { UserLimit } from './useDownloadsconfigForm';

interface IProps {
	user_limits: UserLimit[];
	set_user_limits: (user_limits: UserLimit[]) => void;
	set_visible: () => void;
}

export const UserLimitsDataTable: FC<IProps> = ({ user_limits, set_user_limits, set_visible }) => {
	const user_limits_without_duplicates = remove_duplicate_emails<UserLimit>(user_limits);

	const user_limits_with_index = user_limits_without_duplicates.map((user_limit, index) => ({
		email: user_limit.email,
		limit: user_limit.limit,
		index,
	}));

	const columns: ColumnsType<UserLimit> = [
		{
			title: 'E-mail',
			dataIndex: 'email',
			key: 'email',
		},
		{
			title: 'Limite',
			dataIndex: 'limit',
			key: 'limit',
			align: 'center',
		},
		{
			title: '',
			dataIndex: '',
			key: 'button',
			align: 'center',
			render: (value: { email: string; index: number }) => (
				<RemoveButton
					{...{
						title: `Deseja remover o limite de download de ${value.email}?`,
						on_confirm: () => {
							const new_user_limits = user_limits_with_index.filter((user_limit, index) => {
								if (index !== value.index) {
									return user_limit;
								}
								return;
							});
							set_user_limits(new_user_limits);
						},
					}}
				/>
			),
		},
	];

	return (
		<div className='border border-gray-200 p-2 bg-gray-100 rounded-sm'>
			<AddButton
				{...{ onClick: set_visible, style: { display: 'flex', float: 'right', marginBottom: '.6rem' } }}
			/>

			<Table columns={columns} dataSource={user_limits_with_index} size='small' pagination={false} />
		</div>
	);
};
