export const CellStyle = {
	Centered: {
		display: 'flex ',
		'justify-content': 'center',
		'align-items': 'center ',
	},

	Left: {
		display: 'flex ',
		'justify-content': 'left',
		'align-items': 'center ',
	},
};
