import React from 'react';
import { SearchableQueryDto } from './model/SearchableQueryDto';

interface ISearchableContext {
	result: {
		total: number;
		entities: any[];
	};
	loading: boolean;
	error: any;
	reload: () => void;
	query: {
		change: (search_query: SearchableQueryDto) => any;
		text: string;
		change_text: (text: string) => any;
		tags: string[];
		change_tags: (tags: string) => any;
		sort: string;
		sort_dir: string;
		change_sort_dir: (
			field: 'date1' | 'date2' | 'sort1' | 'sort2' | 'sort3' | '',
			dir: 'asc' | 'desc'
		) => any;
		limit: number;
		change_limit: (limit: number) => any;
		page: number;
		change_page: (page_number: number) => any;
		default: boolean;
		clear: () => void;
	};
}

export const SearchableContext = React.createContext({} as ISearchableContext);
