import { FC, useEffect } from 'react';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { PublishButton } from '../../../../lib/components/Buttons/PublishButton';
import { RemoveButton } from '../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../lib/components/DataTable/ColumnFactory';
import { DataTable } from '../../../../lib/components/DataTable/DataTable';
import { useRequestFeedback } from '../../../../lib/hooks';
import { HighlightList } from '../../../../lib/model/Highlight';
import { useHighlightsApi } from '../hooks/useHighlightApi';

interface IProps {
	set_visible_modal: (value: boolean) => void;
	highlights: HighlightList;
	on_remove: () => void;
}

export const HighlightsDataTable: FC<IProps> = ({ highlights, set_visible_modal, on_remove }) => {
	const { remove, publish } = useHighlightsApi();

	useEffect(() => {
		if (remove.result) {
			on_remove();
		}
	}, [remove.result]);

	useRequestFeedback(remove.result, remove.error);
	useRequestFeedback(publish.result, publish.error);

	return (
		<DataTable
			{...{
				row_height: () => 45,
				buttons: [
					<AddButton {...{ onClick: () => set_visible_modal(true) }} />,
					<PublishButton
						{...{
							onClick: publish.do_publish,
							text_confirm: 'Deseja publicar esses destaques?',
							loading: publish.loading,
						}}
					/>,
				],
				columns: [
					ColumnFactory.Link(
						{
							field: 'name',
							headerName: 'Nome',
							resizable: true,
							width: 400,
						},
						(props: { data: { id: string } }) => props.data.id
					),
					{
						field: 'promotion',
						headerName: 'Promocao',
						resizable: true,
						width: 100,
					},
					{
						col_def: {
							field: 'buttons',
							headerName: '',
							width: 80,
							cellStyle: CellStyle.Centered,
							pinned: 'right',
						},
						renderer: (props: { data: { id: string } }) => (
							<RemoveButton
								{...{
									on_confirm: () => remove.do_remove(props.data.id),
									title: 'Deseja remover esse grupo?',
								}}
							/>
						),
					},
				],

				rowData: row_data_from_entity(highlights),
			}}
		/>
	);
};

const row_data_from_entity = (data: HighlightList): any[] => {
	const data_display = data.map((highlight) => ({
		id: highlight.id,
		name: highlight.name,
		promotion: highlight.promotion ? 'Sim' : 'Nao',
	}));

	return data_display;
};
