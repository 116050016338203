import { useForm } from '../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../lib/hooks/useForm/validators';

type FormValues = {
	id: string;
	name: string;
	text: string;
	promotion: boolean;
};

export function useHighlightForm() {
	const form = useForm<FormValues>(
		{
			id: '',
			name: '',
			text: '',
			promotion: false,
		},
		function validate_form(state) {
			return {
				id: '',
				name: form_validators.required_text_field(state.value.name || ''),
				text: '',
				promotion: '',
			};
		}
	);

	return form;
}
