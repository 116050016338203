import { useParams } from 'react-router-dom';
import { useRequest } from '../../../../../../lib/hooks';
import { request } from '../../../../../../lib/utils/request';
import { extract_num_id_from_id } from '../../../utils/extract_num_id_from_id';
import { ChapterDto } from '../dto/ChapterDto';
import { InsertChapterDto } from '../dto/InsertChapterDto';
import { UpdateChapterDto } from '../dto/UpdateChapterDto';
import { UpdateChapterContentTextDto } from '../dto/UpdateChapterContentTextDto';

export function useChaptersApi() {
	const { id, edition_id, chapter_id } = useParams() as {
		id: string;
		edition_id: string;
		chapter_id: string;
	};

	const book_num_id = extract_num_id_from_id(id);
	const edition_num_id = extract_num_id_from_id(edition_id);
	const chapter_num_id = extract_num_id_from_id(chapter_id);

	const BASE_URL = `${process.env.REACT_APP_BOOKS_API}/books/${book_num_id}/editions/${edition_num_id}/chapters`;

	const insert = useCreateChapter(BASE_URL);
	const remove = useRemoveChapter(BASE_URL);
	const update = useUpdateChapter(BASE_URL, chapter_num_id);
	const update_text = useUpdateChapterText(BASE_URL, chapter_num_id);
	const read_pdf = useReadPDF(BASE_URL, chapter_num_id);
	const get_last_id = useGetLastChapterID(BASE_URL);

	return {
		insert,
		remove,
		update,
		update_text,
		read_pdf,
		get_last_id,
	};
}

function useCreateChapter(BASE_URL: string) {
	const [result, loading, error, do_create] = useRequest<ChapterDto>((dto: InsertChapterDto) =>
		request().post(BASE_URL, dto)
	);

	return {
		create: (dto: InsertChapterDto) => do_create(dto),
		result,
		error,
		loading,
	};
}

function useUpdateChapter(BASE_URL: string, chapter_num_id: string) {
	const [result, loading, error, update] = useRequest<ChapterDto>((dto: UpdateChapterDto) =>
		request().put(`${BASE_URL}/${chapter_num_id}`, dto)
	);

	return {
		do_update: (dto: UpdateChapterDto) => update(dto),
		result,
		error,
		loading,
	};
}

function useRemoveChapter(BASE_URL: string) {
	const [result, loading, error, remove] = useRequest<string>((num_id: string) =>
		request().delete(`${BASE_URL}/${num_id}`)
	);

	return {
		do_remove: (num_id: string) => remove(num_id),
		result,
		loading,
		error,
	};
}

function useUpdateChapterText(BASE_URL: string, chapter_num_id: string) {
	const [result, loading, error, update] = useRequest<string>((dto: UpdateChapterContentTextDto[]) =>
		request().put(`${BASE_URL}/${chapter_num_id}/update-text`, dto)
	);

	return {
		do_update: (dto: UpdateChapterContentTextDto[]) => update(dto),
		result,
		loading,
		error,
	};
}

function useGetLastChapterID(BASE_URL: string) {
	const [result, loading, error, do_get] = useRequest<{ num_id: string }>(
		() =>
			new Promise((resolve, reject) => {
				request()
					.get(`${BASE_URL}/last-id`)
					.then((res) => setTimeout(() => resolve(res as { num_id: string }), 1500))
					.catch(reject);
			})
	);

	return {
		get: () => do_get(),
		result,
		error,
		loading,
	};
}

function useReadPDF(BASE_URL: string, chapter_num_id: string) {
	const [result, loading, error, do_get] = useRequest<any>(
		() =>
			new Promise((resolve, reject) => {
				request()
					.get(`${BASE_URL}/${chapter_num_id}/pdf-content-text`)
					.then((res) => setTimeout(() => resolve(res as any), 1500))
					.catch(reject);
			})
	);

	return {
		get: () => do_get(),
		result,
		error,
		loading,
	};
}
