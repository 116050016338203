import { useParams } from 'react-router-dom';
import { useRequest } from '../../../../lib/hooks';
import { request } from '../../../../lib/utils/request';
import { PeriodicDto } from '../dto/PeriodicDto';
import { InsertPeriodicDto } from '../dto/InsertPeriodicDto';
import { UpdatePeriodicDto } from '../dto/UpdatePeriodicDto';
import { extract_num_id_from_id } from '../utils/extract_num_id_from_id';

const BASE_URL = `${process.env.REACT_APP_PERIODIC_API}/periodics`;

export function usePeriodicsApi() {
	const { id } = useParams() as { id: string };

	const numId = extract_num_id_from_id(id);

	const insert = useCreatePeriodic();
	const remove = useRemovePeriodic();
	const update = useUpdatePeriodic(numId);
	const get_last_id = useGetLastPeriodicID();

	return {
		insert,
		remove,
		update,
		get_last_id,
	};
}

function useCreatePeriodic() {
	const [result, loading, error, do_create] = useRequest<PeriodicDto>(
		(dto: InsertPeriodicDto) =>
			new Promise((resolve, reject) => {
				request()
					.post(BASE_URL, dto)
					.then((res) => setTimeout(() => resolve(res as PeriodicDto), 1500))
					.catch(reject);
			})
	);

	return {
		create: (dto: InsertPeriodicDto) => do_create(dto),
		result,
		error,
		loading,
	};
}

function useUpdatePeriodic(numId: string) {
	const [result, loading, error, update] = useRequest<PeriodicDto>(
		(dto: UpdatePeriodicDto) =>
			new Promise((resolve, reject) => {
				request()
					.put(`${BASE_URL}/${numId}`, dto)
					.then((res) => setTimeout(() => resolve(res as PeriodicDto), 1500))
					.catch(reject);
			})
	);

	return {
		do_update: (dto: UpdatePeriodicDto) => update(dto),
		result,
		error,
		loading,
	};
}

function useRemovePeriodic() {
	const [result, loading, error, remove] = useRequest<string>(
		(numId: string) =>
			new Promise((resolve, reject) => {
				request()
					.delete(`${BASE_URL}/${numId}`)
					.then((res) => setTimeout(() => resolve(res as string), 1500))
					.catch(reject);
			})
	);

	return {
		do_remove: (numId: string) => remove(numId),
		result,
		loading,
		error,
	};
}

function useGetLastPeriodicID() {
	const [result, loading, error, do_get] = useRequest<{ numId: string }>(
		() =>
			new Promise((resolve, reject) => {
				request()
					.get(`${BASE_URL}/last-id`)
					.then((res) => setTimeout(() => resolve(res as { numId: string }), 1500))
					.catch(reject);
			})
	);

	return {
		get: () => do_get(),
		result,
		error,
		loading,
	};
}
