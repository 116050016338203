import { CheckCircleTwoTone } from '@ant-design/icons';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { FC } from 'react';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../lib/components/DataTable/ColumnFactory';
import { SearchableDataTable } from '../../../../lib/components/SearchableDataTable/SearchableDataTable';
import { ContractDto } from '../dtos/ContractDto';

export const ContractsDatatable: FC = () => {
	const BASE_URL = `/plataforma/clientes`;

	return (
		<SearchableDataTable
			{...{
				sortable_fields: ['Nome do Cliente'],
				date_fields: ['Início', 'Término'],
				searchable_defaults: {
					sort_field: 'sort1',
					sort_dir: 'desc',
				},

				columns: [
					{
						renderer: (props: { data: { active: boolean } }) =>
							props.data.active && (
								<CheckCircleTwoTone
									{...{
										twoToneColor: '#52c41a',
									}}
								/>
							),

						col_def: {
							field: 'active',
							headerName: 'Ativo',
							width: 70,
							cellStyle: CellStyle.Centered,
						},
					},
					ColumnFactory.Link(
						{
							field: 'client_id',
							headerName: 'ID do Cliente',
							resizable: true,
							rowDrag: false,
							width: 170,
						},
						(props: { data: { client_id: string } }) => `${BASE_URL}/${props.data.client_id}`
					),
					ColumnFactory.Link(
						{
							field: 'c_number',
							headerName: 'Número do Contrato',
							resizable: true,
							rowDrag: false,
							width: 200,
						},
						(props: { data: { client_id: string; c_number: number } }) =>
							`${BASE_URL}/${props.data.client_id}/contratos/${props.data.c_number}`
					),

					{
						field: 'client_name',
						headerName: 'Nome do Cliente',
						resizable: true,
						rowDrag: false,
						width: 200,
					},
					{
						field: 'started_at',
						headerName: 'Data de Início',
						resizable: true,
						rowDrag: false,
						width: 140,
					},
					{
						field: 'end_at',
						headerName: 'Data de Término',
						resizable: true,
						rowDrag: false,
						width: 140,
					},
				],
				row_data_from_entity: (entity: ContractDto) => ({
					...entity,
					started_at: format(entity.started_at, 'dd/MM/yyyy', { locale: pt }),
					end_at: format(entity.end_at, 'dd/MM/yyyy', { locale: pt }),
					active: entity.end_at > Date.now(),
				}),
			}}
		/>
	);
};
