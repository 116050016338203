import { ReactNode } from 'react';
import { AreaInterestIcon } from '../../components/icons/AreaInterestIcon';
import { AuthorsIcon } from '../../components/icons/AuthorsIcon';
import { BookSerieIcon } from '../../components/icons/BookSerieIcon';
import { BooksIcon } from '../../components/icons/BooksIcon';
import { EventsIcon } from '../../components/icons/EventsIcon';
import { KeywordsIcon } from '../../components/icons/KeywordsIcon';
import { PeriodicsIcon } from '../../components/icons/PeriodicsIcon';
import { VideosIcon } from '../../components/icons/VideosIcon';
import { Role } from '../../features/auth/model/Role';
import { InformativesIcon } from '../../components/icons/InformativesIcon';

export type MenuItemType = {
	name: string;
	icon?: ReactNode;
	href?: string;
	items?: MenuItemType[];
	roles?: Role[];
	key: string;
};

//* PERMITIR ITENS BASEADOS NAS PERMISSÕES DO USUÁRIO
export const MENU: MenuItemType[] = [
	{
		name: 'Plataforma',
		key: 'plat',
		items: [
			{
				name: 'Clientes',
				key: 'clients',
				href: '/plataforma/clientes',
				roles: [Role.Admin, Role.PlatformAdmin],
			},
			{
				name: 'Contratos',
				key: 'contracts',
				href: '/plataforma/contratos',
				roles: [Role.Admin, Role.PlatformAdmin],
			},
			/*{
				name: 'Informativos',
				key: 'clippings',
				href: '/plataforma/fontes-de-informativos',
				roles: [Role.Admin, Role.PlatformAdmin, Role.PlatformClipping],
			},*/
		],
		roles: [
			Role.Admin,
			Role.PlatformAdmin,
			Role.PlatformClipping,
			Role.PlatformClients,
			Role.PlatformContracts,
			Role.PlatformClients,
		],
	},
	{
		name: 'Loja',
		key: 'store',
		items: [
			{
				name: 'Banners',
				key: 'banners',
				href: '/loja/banners',
				roles: [Role.Admin, Role.StoreAdmin, Role.StoreBanners],
			},
			{
				name: 'Seções',
				key: 'sections',
				href: '/loja/secoes',
				roles: [Role.Admin, Role.StoreAdmin, Role.StoreSections],
			},
			{
				name: 'Preços',
				key: 'prices',
				href: '/loja/precos',
				roles: [Role.Admin, Role.StoreAdmin, Role.StorePricing],
			},
			{
				name: 'Destaques',
				key: 'highlights',
				href: '/loja/destaques',
				roles: [Role.Admin, Role.StoreAdmin],
			},
			{
				name: 'Usuários',
				key: 'users',
				href: '/loja/usuarios',
				roles: [Role.Admin, Role.StoreAdmin, Role.StoreUsers],
			},
			{
				name: 'Liberação de Conteúdo',
				key: 'content_assignment',
				href: '/loja/liberacao-de-conteudo/direito-autoral',
				roles: [Role.Admin, Role.StoreContentAssignment, Role.StoreAdmin],
			},
		],
		roles: [
			Role.Admin,
			Role.StorePricing,
			Role.StoreAdmin,
			Role.StoreBanners,
			Role.StoreContentAssignment,
			Role.StoreUsers,
		],
	},
	{
		name: 'Conteúdos',
		key: 'contents',
		items: [
			{
				name: 'Livros',
				key: 'books',
				href: '/conteudos/livros',
				icon: <BooksIcon />,
				roles: [Role.Admin],
			},
			{
				name: 'Revistas',
				key: 'periodics',
				href: '/conteudos/revistas',
				icon: <PeriodicsIcon />,
				roles: [Role.Admin],
			},
			{
				name: 'Eventos',
				key: 'events',
				href: '/conteudos/eventos',
				icon: <EventsIcon />,
				roles: [Role.Admin],
			},
			{
				name: 'Vídeos',
				key: 'videos',
				href: '/conteudos/videos',
				icon: <VideosIcon />,
				roles: [Role.Admin],
			},
			{
				name: 'Informativos',
				key: 'informativos',
				href: '/conteudos/informativos',
				icon: <InformativesIcon />,
				roles: [Role.Admin],
			},
			// {
			// 	name: 'Autores',
			// 	key: 'authors',
			// 	href: '/conteudos/autores',
			// 	icon: <AuthorsIcon />,
			// 	roles: [Role.Admin],
			// },
			{
				name: 'Tags',
				key: 'tags',
				icon: null,
				items: [
					{
						name: 'Grupos de Permissão',
						key: 'permission_groups',
						href: '/conteudos/tags/grupos-de-permissao',
						icon: <BookSerieIcon />,
						roles: [Role.Admin],
					},
					{
						name: 'Série de Livro',
						key: 'book_series',
						href: '/conteudos/tags/serie-de-livros',
						icon: <BookSerieIcon />,
						roles: [Role.Admin],
					},
					{
						name: 'Série de Vídeo',
						key: 'video_series',
						href: '/conteudos/tags/serie-de-videos',
						icon: <BookSerieIcon />,
						roles: [Role.Admin],
					},
					{
						name: 'Área de Interesse',
						key: 'area_interest',
						href: '/conteudos/tags/areas-de-interesse',
						icon: <AreaInterestIcon />,
						roles: [Role.Admin],
					},
					{
						name: 'Autor',
						key: 'authors_tag',
						href: '/conteudos/tags/author-tag',
						icon: <AuthorsIcon />,
						roles: [Role.Admin],
					},
					{
						name: 'Evento',
						key: 'event_tag',
						href: '/conteudos/tags/event-tag',
						icon: <KeywordsIcon />,
						roles: [Role.Admin],
					},
					{
						name: 'Palavras Chave',
						key: 'keywords',
						href: '/conteudos/tags/palavras-chave',
						icon: <KeywordsIcon />,
						roles: [Role.Admin],
					},
					{
						name: 'Tema',
						key: 'theme_tag',
						href: '/conteudos/tags/theme-tag',
						icon: <KeywordsIcon />,
						roles: [Role.Admin],
					},
				],
				roles: [Role.Admin],
			},
		],
		roles: [Role.Admin, Role.Contents, Role.ContentGroup, Role.ContentTags],
	},
];
