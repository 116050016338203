import { DeleteOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
interface IProps {
	on_click: () => void;
}

export const ListRemoveButton: React.FC<IProps> = ({ on_click }) => {
	return (
		<Button
			{...{
				onClick: on_click,
				className:
					' text-red-500 hover:bg-red-500 hover:text-white hover:border-0 border-0 flex  items-center justify-center',

				icon: <DeleteOutlined />,
			}}
		/>
	);
};
