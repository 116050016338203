import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { AreaInterestListPage } from './search_tags/area_interest/AreaInterestListPage';
import { AuthorTagListPage } from './search_tags/author/AuthorTagListPage';
import { PermissionGroupListPage } from './permission_groups/page/PermissionGroupListPage';
import { BookSerieListPage } from './book_series/page/BookSerieListPage';
import { KeywordListPage } from './search_tags/keywords/KeywordListPage';
import { ThemeTagListPage } from './search_tags/theme/ThemeTagListPage';
import { VideoSerieListPage } from './video_series/page/VideoSerieListPage';
import { EventTagListPage } from './search_tags/event/EventTagListPage';

export const TagsRoutes: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.Contents, Role.ContentTags] }}>
		<Routes>
			<Route path='grupos-de-permissao' element={<PermissionGroupListPage />} />
			<Route path='serie-de-livros' element={<BookSerieListPage />} />
			<Route path='serie-de-videos' element={<VideoSerieListPage />} />
			<Route path='areas-de-interesse' element={<AreaInterestListPage />} />
			<Route path='author-tag' element={<AuthorTagListPage />} />
			<Route path='palavras-chave' element={<KeywordListPage />} />
			<Route path='theme-tag' element={<ThemeTagListPage />} />
			<Route path='event-tag' element={<EventTagListPage />} />
		</Routes>
	</ProtectedContent>
);
