import { Pagination as AntPagination } from 'antd';
import Text from 'antd/lib/typography/Text';
import { FC } from 'react';

interface IProps {
	total: number;
	loading: boolean;
	page: number;
	change_page: (page: number) => any;
}

export const Pagination: FC<IProps> = ({ total, change_page, page, loading }) => {
	return (
		<div className='flex justify-between items-center'>
			<Text style={{ fontWeight: 'bold' }}>{total} resultados encontrados.</Text>

			<div className='flex items-center'>
				<AntPagination
					{...{
						total,
						showQuickJumper: false,
						showLessItems: true,
						showSizeChanger: false,
						onChange: change_page,
						current: page,
						pageSize: 20,
						showTitle: false,
						disabled: loading,
						hideOnSinglePage: true,
					}}
				/>
			</div>
		</div>
	);
};
