import { Select } from 'antd';
import { FC } from 'react';
import { ChapterTypes } from '../../../../../../lib/model/Book';

const Option = Select;

interface IProps {
	value: ChapterTypes;
	on_change: (field_name: any, value: ChapterTypes) => void;
}

export const SelectChapterType: FC<IProps> = ({ value, on_change }) => (
	<Select
		placeholder='Selecione o tipo'
		onChange={(chapter_type) => on_change('chapter_type', chapter_type)}
		defaultValue={ChapterTypes.CHAPTER}
		style={{ width: '50%' }}
		value={value}
	>
		<Option value={ChapterTypes.PRE_TEXTUAL} key={ChapterTypes.PRE_TEXTUAL}>
			Pré-Textual
		</Option>
		<Option value={ChapterTypes.CHAPTER} key={ChapterTypes.CHAPTER}>
			Capítulo
		</Option>
		<Option value={ChapterTypes.POS_TEXTUAL} key={ChapterTypes.POS_TEXTUAL}>
			Pós-Textual
		</Option>
	</Select>
);
