import { useRequest } from '../../../../lib/hooks';
import { request } from '../../../../lib/utils/request';
import { PriceVersionDto } from '../dtos/PriceVersionDto';
import { SetAsCurrentVersionDto } from '../dtos/SetAsCurrentVersionDto';

export function usePriceAdminApi() {
	const [result, loading, error, set_as_current] = useRequest<PriceVersionDto>(
		(dto: SetAsCurrentVersionDto) =>
			request().put(`${process.env.REACT_APP_PRICING_ADMIN_API}/current`, dto)
	);

	return {
		result,
		loading,
		error,
		set_as_current_version(version_date: number) {
			const dto: SetAsCurrentVersionDto = {
				version_date: +version_date,
			};
			set_as_current(dto);
		},
		get_version: (version_date: number): Promise<PriceVersionDto> => {
			return request().get(`${process.env.REACT_APP_PRICING_ADMIN_API}/pricing-version/${version_date}`);
		},
	};
}
