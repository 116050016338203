import { useForm } from '../../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../../lib/hooks/useForm/validators';
import { EditionFrequency } from '../../../../../../lib/model/Periodic';

type FormValues = {
	numId: string;
	number: number;
	title: string;
	frequency: EditionFrequency;
	startMonth: string;
	endMonth: string;
	startYear: string;
	currentEdition: boolean;
};

export function useEditionForm() {
	const form = useForm<FormValues>(
		{
			numId: '',
			number: 0,
			title: '',
			frequency: '' as EditionFrequency,
			startMonth: '',
			endMonth: '',
			startYear: '',
			currentEdition: false,
		},
		function validate_form(state) {
			return {
				numId: form_validators.required_text_field(state.value.numId),
				number: form_validators.required_number_field(state.value.number || 0),
				title: '',
				frequency: form_validators.required_text_field(state.value.frequency || ''),
				startMonth: form_validators.required_text_field(state.value.startMonth || ''),
				endMonth: '',
				startYear: '',
				currentEdition: '',
			};
		}
	);

	return form;
}
