import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { InformativeBreadcrumb } from '../components/InformativeBreadcrumb';
import { InformativeForm } from '../components/form/InformativeForm';

export const InsertInformativePage: FC = () => {
	const { num_id: num_id } = useParams() as { num_id: string };

	return (
		<PageLayout
			{...{
				title: 'Inserir Informativo',
				browser_back: true,
				breadcrumb: () => <InformativeBreadcrumb {...{ num_id, in_chapters: true, new_chapter: true }} />,
			}}
		>
			<InformativeForm />
		</PageLayout>
	);
};
