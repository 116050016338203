import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { AddContractPage } from './client_contracts/page/AddContractPage';
import { UpdateContractPage } from './client_contracts/page/UpdateContractPage';
import { AddClientConfigPage } from './pages/AddClientConfigPage';
import { ClientsListPage } from './pages/ClientsListPage';
import { UpdateClientConfigPage } from './pages/UpdateClientConfigPage';

export const PlatformClientsRoutes: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.PlatformAdmin, Role.PlatformClients] }}>
		<Routes>
			<Route {...{ path: ':id/contratos/novo', element: <AddContractPage /> }} />
			<Route {...{ path: ':id/contratos/:c_number', element: <UpdateContractPage /> }} />
			<Route {...{ path: 'novo', element: <AddClientConfigPage /> }} />
			<Route {...{ path: ':id', element: <UpdateClientConfigPage /> }} />
			<Route {...{ path: '/', element: <ClientsListPage /> }} />
		</Routes>
	</ProtectedContent>
);
