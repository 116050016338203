import { WifiOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { List } from '../../../../../../lib/components/List/List';

interface IProps {
	ranges: string[];
	on_removed: (d: string) => void;
}

export const IpRangeList: FC<IProps> = ({ ranges, on_removed }) => {
	return (
		<List
			{...{
				data: ranges,
				header: 'Range de ips',
				on_remove_click: on_removed,
				icon: <WifiOutlined className='text-blue-500 flex items-center justify-center py-1' />,
			}}
		/>
	);
};
