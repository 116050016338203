import { FC } from 'react';
import { AddButton } from '../../../../../lib/components/Buttons/AddButton';
import { DataTable } from '../../../../../lib/components/DataTable/DataTable';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { PermissionGroup, ruleOptions } from '../../../../../lib/model/PermissionGroup';
import { usePermissionsApi } from '../../hooks/PermissionGroupsApi';
import { RemoveButton } from '../../../../../lib/components/Buttons/RemoveButton';

interface IProps {
	permission_groups: PermissionGroup[];
	on_remove_success: () => void;
	visible_insert_modal: () => void;
}

export const PermissionGroupDatatable: FC<IProps> = ({
	permission_groups,
	on_remove_success,
	visible_insert_modal,
}) => {
	const { remove } = usePermissionsApi();

	if (remove.result) {
		on_remove_success();
	}

	useRequestFeedback(remove.result, remove.error);

	return (
		<DataTable
			{...{
				rowData: permission_groups,
				buttons: [<AddButton onClick={() => visible_insert_modal()} />],
				columns: [
					{
						field: 'title',
						headerName: 'Título',
						resizable: true,
						rowDrag: false,
						width: 500,
					},
					{
						col_def: {
							field: 'ruleType',
							headerName: 'Regra de Permissionamento',
							resizable: true,
							rowDrag: false,
							width: 500,
						},
						renderer: (props) => {
							return (
								<span>{ruleOptions?.find((r) => r.key === props?.data?.ruleType)?.title || '-'}</span>
							);
						},
					},
					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							width: 100,
						},
						renderer: (props: { data: { id: string; title: string } }) => (
							<RemoveButton
								{...{
									on_confirm: () => remove.do_remove(props.data.id),
									title: `Deseja remover a regra de permissionamento "${props.data.title}"?`,
								}}
							/>
						),
					},
				],
			}}
		/>
	);
};
