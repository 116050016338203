import { useParams } from 'react-router-dom';
import { useRequest } from '../../../../lib/hooks';
import { request } from '../../../../lib/utils/request';
import { VideoDto } from '../dto/VideoDto';
import { InsertVideoDto } from '../dto/InsertVideoDto';
import { UpdateVideoDto } from '../dto/UpdateVideoDto';
import { extract_num_id_from_id } from '../utils/extract_num_id_from_id';

const BASE_URL = `${process.env.REACT_APP_VIDEO_API}/videos`;

export function useVideosApi() {
	const { id } = useParams() as { id: string };

	const num_id = extract_num_id_from_id(id);

	const insert = useCreateVideo();
	const remove = useRemoveVideo();
	const update = useUpdateVideo(num_id);

	return {
		insert,
		remove,
		update,
	};
}

function useCreateVideo() {
	const [result, loading, error, do_create] = useRequest<VideoDto>(
		(dto: InsertVideoDto) =>
			new Promise((resolve, reject) => {
				request()
					.post(BASE_URL, dto)
					.then((res) => setTimeout(() => resolve(res as VideoDto), 1500))
					.catch(reject);
			})
	);

	return {
		create: (dto: InsertVideoDto) => do_create(dto),
		result,
		error,
		loading,
	};
}

function useUpdateVideo(num_id: string) {
	const [result, loading, error, update] = useRequest<VideoDto>(
		(dto: UpdateVideoDto) =>
			new Promise((resolve, reject) => {
				request()
					.put(`${BASE_URL}/${num_id}`, dto)
					.then((res) => setTimeout(() => resolve(res as VideoDto), 1500))
					.catch(reject);
			})
	);

	return {
		do_update: (dto: UpdateVideoDto) => update(dto),
		result,
		error,
		loading,
	};
}

function useRemoveVideo() {
	const [result, loading, error, remove] = useRequest<string>(
		(num_id: string) =>
			new Promise((resolve, reject) => {
				request()
					.delete(`${BASE_URL}/${num_id}`)
					.then((res) => setTimeout(() => resolve(res as string), 1500))
					.catch(reject);
			})
	);

	return {
		do_remove: (num_id: string) => remove(num_id),
		result,
		loading,
		error,
	};
}
