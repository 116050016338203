import { Modal, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { AddButton } from '../../../../../lib/components/Buttons/AddButton';
import { InputItem } from '../../../../../lib/components/InputItem';
import { InsertPermissionDto } from '../../../../../lib/dtos/PermissionDto';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { usePermissionsApi } from '../../hooks/PermissionGroupsApi';
import { ruleOptions } from '../../../../../lib/model/PermissionGroup';

interface IProps {
	visible: boolean;
	close: () => void;
	insert_success: () => void;
}

export const PermissionGroupInsertModal: FC<IProps> = ({ visible, close, insert_success }) => {
	// const [id, set_id] = useState('');
	const [title, set_title] = useState('');
	const [ruleType, set_ruleType] = useState('');

	const { insert } = usePermissionsApi();

	useEffect(() => {
		if (!!insert.result) {
			insert_success();
			close();
		}
	}, [insert.result]);

	const submit = () => {
		const dto: InsertPermissionDto = { title, ruleType };
		if (title) insert.create(dto);
	};

	useRequestFeedback(insert.result, insert.error);

	return (
		<Modal
			{...{
				title: 'Criar Grupo de Permissão',
				visible,
				closable: true,
				onCancel: close,
				destroyOnClose: true,
				centered: true,
				footer: [<AddButton {...{ onClick: submit, disabled: !title }} />],
			}}
		>
			<InputItem
				{...{
					label: 'Título',
					input_item_name: title,
					on_changed: set_title,
				}}
			/>
			<div className='mt-2'>
				<label className='mr-4 font-semibold'>
					<span className='text-red-500'> * </span>
					Regra de Permissionamento:
				</label>
				<div className='w-5/6'>
					<Select placeholder='Selecione' onChange={(v) => set_ruleType(v)} style={{ width: '100%' }}>
						{ruleOptions.map((o) => (
							<Select.Option value={o.key} key={o.key}>
								{o.title}
							</Select.Option>
						))}
					</Select>
				</div>
			</div>
		</Modal>
	);
};
