import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	book_id?: string;
}

export const BooksBreadcrumb: FC<IProps> = ({ book_id }) => (
	<Breadcrumb>
		<Breadcrumb.Item key='books'>
			<Link to={'/conteudos/livros'}>Livros</Link>
		</Breadcrumb.Item>

		{book_id ? (
			<Breadcrumb.Item key='book_title'>
				<Link to={`/conteudos/livros/${book_id}`}>{book_id}</Link>
			</Breadcrumb.Item>
		) : (
			<Breadcrumb.Item key='new_book'>novo</Breadcrumb.Item>
		)}
	</Breadcrumb>
);
