import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { ButtonsContainer } from '../../../../lib/components/Buttons/ButtonsContainer';
import { LinkButton } from '../../../../lib/components/Buttons/LinkButton';
import { RemoveButton } from '../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../lib/components/DataTable/ColumnFactory';
import { SearchableDataTable } from '../../../../lib/components/SearchableDataTable/SearchableDataTable';
import { useRequestFeedback } from '../../../../lib/hooks';
import { PeriodicEntity } from '../../../../lib/model/Periodic';
import { usePeriodicsApi } from '../hooks/usePeriodicsApi';

export const PeriodicsDataTable: FC = () => {
	const navigate = useNavigate();
	const { remove } = usePeriodicsApi();

	useRequestFeedback(remove.result, remove.error);

	return (
		<SearchableDataTable
			{...{
				row_data_from_entity: (periodic: PeriodicEntity) => ({
					...periodic,
				}),
				sortable_fields: ['Título'],
				searchable_defaults: { sort_field: 'sort1', sort_dir: 'asc' },
				buttons_on_top: [<AddButton onClick={() => navigate('novo')} />],
				columns: [
					ColumnFactory.Link(
						{
							field: 'title',
							headerName: 'Título',
							resizable: true,
							width: 350,
						},
						(props: { data: { numId: string } }) => props.data.numId
					),
					{
						field: 'description',
						headerName: 'Descrição',
						resizable: true,
						width: 300,
					},
					{
						field: 'cdu',
						headerName: 'CDU',
						resizable: true,
						width: 150,
					},
					{
						field: 'cdd',
						headerName: 'CDD',
						resizable: true,
						width: 150,
					},
					{
						field: 'issn',
						headerName: 'ISSN',
						resizable: true,
						width: 150,
					},
					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							cellStyle: CellStyle.Centered,
							width: 180,
						},
						renderer: (props: { data: PeriodicEntity }) => (
							<ButtonsContainer class_name='flex center'>
								<LinkButton
									{...{
										name: 'Edições',
										type: 'ghost',
										route: `${props.data.numId}/edicoes`,
									}}
								/>
								<RemoveButton
									{...{
										on_confirm: () => remove.do_remove(String(props.data.numId)),
										title: `Deseja remover a revista ${props.data.title}?`,
									}}
								/>
							</ButtonsContainer>
						),
					},
				],
			}}
		/>
	);
};
