import { FC, useState } from 'react';
import { Loader } from '../../../../lib/components/Loader';
import { HighlightList } from '../../../../lib/model/Highlight';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { HighlightInsertModal } from '../components/HighlightInsertModal';
import { HighlightsDataTable } from '../components/HighlightsDataTable';
import { HighlightsListDto } from '../dtos/HighlightListDto';

export const HighlightsListPage: FC = () => {
	const [visible_form_insert, set_visible_form_insert] = useState(false);
	const [count_remove, set_count_remove] = useState(0);

	return (
		<PageLayout {...{ title: 'Destaques', key: 'highlights' }}>
			<Loader
				{...{
					reload_when_changes: [count_remove],
					request: () => request().get<HighlightsListDto>(`${process.env.REACT_APP_HIGHLIGHTS_API}`),
					renderChildren: (highlights: HighlightList) => (
						<>
							<HighlightsDataTable
								{...{
									highlights,
									set_visible_modal: set_visible_form_insert,
									on_remove: () => set_count_remove(count_remove + 1),
								}}
							/>

							<HighlightInsertModal
								{...{
									visible: visible_form_insert,
									close: () => set_visible_form_insert(false),
								}}
							/>
						</>
					),
				}}
			/>
		</PageLayout>
	);
};
