import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AddButton } from '../../../../../../lib/components/Buttons/AddButton';
import { ButtonsContainer } from '../../../../../../lib/components/Buttons/ButtonsContainer';
import { RemoveButton } from '../../../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../../../lib/components/DataTable/ColumnFactory';
import { DataTable } from '../../../../../../lib/components/DataTable/DataTable';
import { useRequestFeedback } from '../../../../../../lib/hooks';
import { ChapterEntity } from '../../../../../../lib/model/Book';
import { extract_num_id_from_id } from '../../../utils/extract_num_id_from_id';
import { useChaptersApi } from '../hooks/useChaptersApi';

interface IProps {
	chapters: ChapterEntity[];
	on_remove_success: () => void;
}

export const ChaptersDataTable: FC<IProps> = ({ chapters, on_remove_success }) => {
	const { id, edition_id } = useParams() as {
		id: string;
		edition_id: string;
		chapter_id: string;
	};

	const book_num_id = extract_num_id_from_id(id);
	const edition_num_id = extract_num_id_from_id(edition_id);

	const BASE_URL = `${process.env.REACT_APP_BOOKS_API}/books/${book_num_id}/editions/${edition_num_id}/chapters`;

	const navigate = useNavigate();
	const { remove } = useChaptersApi();

	if (remove.result) {
		on_remove_success();
	}

	useRequestFeedback(remove.result, remove.error);

	return (
		<DataTable
			{...{
				rowData: chapters,
				buttons: [<AddButton {...{ onClick: () => navigate('novo') }} />],
				columns: [
					ColumnFactory.Link(
						{ field: 'title', headerName: 'Titulo', resizable: true, width: 200 },
						(props: { data: { id: string } }) => props.data.id
					),
					{
						field: 'chapter_type',
						headerName: 'Tipo',
						resizable: false,
						width: 100,
					},
					{
						field: 'language',
						headerName: 'Idioma',
						resizable: false,
						width: 100,
					},
					{
						field: 'authors',
						headerName: 'Autor(es)',
						resizable: true,
						width: 240,
					},
					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							cellStyle: CellStyle.Centered,
							width: 180,
						},
						renderer: (props: { data: { num_id: string; title: string } }) => (
							<ButtonsContainer>
								<RemoveButton
									{...{
										style: { marginLeft: '.5rem' },
										on_confirm: () => remove.do_remove(props.data.num_id),
										title: `Deseja remover o livro ${props.data.title}?`,
									}}
								/>
							</ButtonsContainer>
						),
					},
				],
			}}
		/>
	);
};
