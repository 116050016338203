import  { FC } from 'react';

export type Quality = 'low' | 'med' | 'hi' | 'original';

const { REACT_APP_PUBLIC_BUCKET_URL } = process.env;

interface IProps {
	src: string;
	quality: Quality;
	img_props?: any;
}

export const PublicImage: FC<IProps> = ({ src, img_props = {}, quality }: IProps) => {
	const ext = get_img_extension(src);
	const retina_version: any = {
		low: 'med',
		med: 'hi',
		hi: 'original',
	};

	const webp_srcs = [
		get_public_img_src(src.replace(`.${ext}`, '.webp'), quality),
		`${get_public_img_src(src.replace(`.${ext}`, '.webp'), retina_version[quality])} 2x`,
	];

	const img_srcs = [
		get_public_img_src(src, quality),
		`${get_public_img_src(src, retina_version[quality])} 2x`,
	];

	const srcset = [...webp_srcs, ...img_srcs].join(', ');

	return <img srcSet={srcset} src={get_public_img_src(src, 'original')} {...img_props} />;
};

const get_public_img_src = (src: string, quality: Quality) => {
	const ext = get_img_extension(src);

	if (quality === 'original') {
		return `${REACT_APP_PUBLIC_BUCKET_URL}/${src}`;
	}

	return `${REACT_APP_PUBLIC_BUCKET_URL}/${src.replace(`.${ext}`, '')}_${quality}.${ext}`;
};

const get_img_extension = (src: string) => src.split('.').pop();
