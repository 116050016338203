import React, { FC, ReactNode } from 'react';

interface IProps {
	children: ReactNode;
	class_name?: string;
}

export const ButtonsContainer: FC<IProps> = ({ children, class_name = '' }) => (
	<div className={`${class_name}`}>{children}</div>
);
