import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { BookRoutes } from './books/routes';
import { TagsRoutes } from './tags/routes';
import { VideosRoutes } from './videos/routes';
import { PeriodicsRoutes } from './periodics/routes';
import { InformativesRoutes } from './informatives/routes';

export const ContentsRoutes: FC = () => (
	<Routes>
		<Route path='livros/*' element={<BookRoutes />} />
		<Route path='tags/*' element={<TagsRoutes />} />
		<Route path='videos/*' element={<VideosRoutes />} />
		<Route path='revistas/*' element={<PeriodicsRoutes />} />
		<Route path='informativos/*' element={<InformativesRoutes />} />
	</Routes>
);
