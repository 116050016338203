import React, { FC } from 'react';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { ButtonsContainer } from '../../../../lib/components/Buttons/ButtonsContainer';
import { PublishButton } from '../../../../lib/components/Buttons/PublishButton';
import { RemoveButton } from '../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../lib/components/DataTable/ColumnFactory';
import { DataTable } from '../../../../lib/components/DataTable/DataTable';
import { useRequestFeedback } from '../../../../lib/hooks';
import { Section } from '../../../../lib/model/Section';
import { useSectionApi } from '../hooks/useSectionPage';

interface IProps {
	sections: Section[];
	visible_insert_modal: () => void;
	on_remove_success: () => void;
}

export const SectionsDataTable: FC<IProps> = ({ sections, visible_insert_modal, on_remove_success }) => {
	const { remove, order_changed, publish } = useSectionApi();

	if (remove.result) {
		on_remove_success();
	}

	useRequestFeedback(remove.result, remove.error);
	useRequestFeedback(order_changed.result, order_changed.error);
	useRequestFeedback(publish.result, publish.error);

	return (
		<DataTable
			{...{
				buttons: [
					<ButtonsContainer class_name={'flex flex-row'}>
						<AddButton {...{ onClick: visible_insert_modal }} />
						<PublishButton
							{...{
								onClick: publish.do_publish,
								text_confirm: 'Deseja publicar estas Seções?',
								loading: publish.loading,
								style: { marginLeft: 15 },
							}}
						/>
					</ButtonsContainer>,
				],
				row_drag: true,
				columns: [
					ColumnFactory.Link(
						{
							field: 'title',
							headerName: 'Nome da Sessão',
							resizable: true,
							rowDrag: true,
							width: 350,
						},
						(props: { data: { id: string } }) => props.data.id
					),

					{
						field: 'display',
						headerName: 'Display',
						resizable: true,
						width: 150,
					},
					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							cellStyle: CellStyle.Centered,
							width: 120,
						},
						renderer: (props: { data: { id: string } }) => (
							<RemoveButton
								{...{
									on_confirm: () => remove.do_remove(props.data.id),
									title: 'Deseja remover esta sessão?',
								}}
							/>
						),
					},
				],
				rowData: sections,
				on_row_drag: order_changed.do_order_change,
			}}
		/>
	);
};
