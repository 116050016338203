import React from 'react';

export const VideosIcon = (props: any) => (
	<svg viewBox='0 0 512 512' className='w-3 h-3 fill-current' {...props}>
		<path
			d='M462.002,92.002h-42.001V50c0-27.57-22.43-50-50-50h-320c-27.57,0-50,22.43-50,50v320c0,27.57,22.43,50,50,50h42.001
			v42.002c0,27.57,22.43,50,50,50h320c27.57,0,50-22.43,50-50V142.001C512.003,114.431,489.573,92.002,462.002,92.002z M50.001,400
			c-16.542,0-30-13.457-30-30V50c0-16.542,13.458-30,30-30h320c16.542,0,30,13.458,30,30v320c0,16.542-13.458,30-30,30H50.001z
			 M492.002,462.002c0,16.542-13.458,30-30,30h-320c-16.542,0-30-13.458-30-30V420h257.999c27.57,0,50-22.43,50-50V112.002h42.001
			c16.542,0,30,13.458,30,30V462.002z'
		/>

		<path
			d='M142.25,457.002h-0.27c-5.523,0-10,4.477-10,10s4.477,10,10,10h0.27c5.523,0,10-4.477,10-10
			S147.774,457.002,142.25,457.002z'
		/>

		<path
			d='M462.025,457.002H170.98c-5.523,0-10,4.477-10,10s4.477,10,10,10h291.044c5.522,0,10-4.477,10-10
			S467.547,457.002,462.025,457.002z'
		/>

		<path d='M110.035,35h-0.27c-5.523,0-10,4.477-10,10s4.477,10,10,10h0.27c5.523,0,10-4.477,10-10S115.559,35,110.035,35z' />

		<path
			d='M81.036,35H50.001c-5.523,0-10,4.477-10,10s4.477,10,10,10h31.034c5.523,0,10-4.477,10-10
			C91.036,39.477,86.559,35,81.036,35z'
		/>

		<path
			d='M293.485,201.129c-2.377-8.799-8.037-16.146-15.922-20.676l-48.918-28.242c-0.491-0.319-0.986-0.625-1.484-0.911
			l-48.792-28.172c-5.589-3.685-12.086-5.631-18.813-5.631c-18.871,0-34.224,15.352-34.224,34.224v116.611
			c0,0.407,0.025,0.809,0.072,1.203c0.197,5.58,1.772,11.06,4.586,15.92c6.09,10.516,17.428,17.048,29.59,17.048
			c5.986,0,11.9-1.592,17.089-4.598l50.492-29.153c0.286-0.166,0.561-0.343,0.825-0.532l49.219-28.414
			c5.373-3.001,9.84-7.417,12.923-12.779C294.669,219.125,295.861,209.927,293.485,201.129z M272.79,217.061
			c-1.284,2.233-3.141,4.067-5.369,5.304c-0.049,0.027-0.099,0.055-0.148,0.083L217.14,251.39c-0.287,0.165-0.562,0.343-0.826,0.532
			l-49.656,28.67c-2.159,1.25-4.607,1.912-7.078,1.912c-5.05,0-9.757-2.709-12.283-7.071c-1.253-2.164-1.914-4.616-1.912-7.091
			c0-0.34-0.017-0.679-0.052-1.015V151.721h0.001c0-7.843,6.381-14.224,14.224-14.224c2.851,0,5.595,0.836,7.936,2.417
			c0.195,0.131,0.394,0.256,0.597,0.374l49.091,28.344c0.246,0.142,0.482,0.292,0.716,0.447c0.166,0.109,0.335,0.214,0.507,0.314
			l49.177,28.391c3.27,1.879,5.612,4.919,6.596,8.561C275.161,209.987,274.668,213.793,272.79,217.061z'
		/>
	</svg>
);
