import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../lib/components/Loader';
import { Contract } from '../../../../../lib/model/Contract';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../lib/utils/request';
import { useClientConfigApi } from '../../hooks/useClientConfigApi';
import { ContractDto } from '../../../contracts/dtos/ContractDto';
import { ContractForm } from '../components/ContractForm/ContractForm';
import { ContractsBreadcrumb } from '../components/ContractsBreadcrumb';

const BASE_URL = process.env.REACT_APP_CLIENT_CONTRACT_MANAGEMENT_API;

export const UpdateContractPage: FC = () => {
	const { id: client_id, c_number } = useParams<{ id: string; c_number: string }>();
	const { client } = useClientConfigApi(client_id);

	return (
		<PageLayout
			{...{
				title: 'Atualizar Contrato',
				browser_back: true,
				breadcrumb: () => <ContractsBreadcrumb {...{ client_id, contract_number: c_number }} />,
			}}
		>
			<Loader
				{...{
					request: () =>
						request().get<ContractDto>(`${BASE_URL}/clients/${client_id}/contracts/${c_number}`),
					renderChildren: (contract: Contract) => <ContractForm contract={contract} client={client} />,
				}}
			/>
		</PageLayout>
	);
};
