import { Button } from 'antd';
import { CSSProperties, FC } from 'react';

interface IProps {
	onClick: () => void;
	disabled?: boolean;
	style?: CSSProperties;
}

export const CloseButton: FC<IProps> = ({ onClick, disabled = false, style }) => (
	<Button {...{ type: 'primary', onClick, disabled, style }}>
		<div className='flex items-center'>Fechar</div>
	</Button>
);
