import { Input } from 'antd';
import { FC } from 'react';
import { FormField } from '../../../../../lib/components/Form/FormField';
import { FormState } from '../../../../../lib/hooks/useForm/types';
import { ClientConfigFormValues } from '../../hooks/useClientConfigForm';

interface IProps {
	form_state: FormState<ClientConfigFormValues>;
	on_change: (max_users: number, max_simultaneous_users: number) => void;
}

export const RestrictedForm: FC<IProps> = ({ form_state, on_change }) => {
	const { max_users, max_simultaneous_users } = form_state.value;

	return (
		<>
			<FormField
				{...{
					label: 'Máximo de usuários',
					name: 'max_users',
					form_state,
					required: true,
					children: (
						<Input
							{...{
								type: 'number',
								value: max_users,
								onChange: (event) => {
									on_change(+event.target.value, max_simultaneous_users);
								},
							}}
						/>
					),
				}}
			/>
			<FormField
				{...{
					label: 'Máximo de usuários simultaneos',
					name: 'max_simultaneous_users',
					form_state,
					required: true,
					children: (
						<Input
							{...{
								type: 'number',
								value: max_simultaneous_users,
								onChange: (event) => {
									on_change(max_users, +event.target.value);
								},
							}}
						/>
					),
				}}
			/>
		</>
	);
};
