import { ColDef } from 'ag-grid-community';
import { NavLink } from 'react-router-dom';
import { ColWithRendererDef } from './Types';

export const ColumnFactory = {
	Link: (col_def: ColDef, create_link: (props: { data: any }) => string): ColWithRendererDef => {
		return {
			col_def,
			renderer: (props: { data: any }) => {
				const field_value = props.data[String(col_def.field)];
				const to = create_link(props);

				return (
					<NavLink
						{...{
							to,
							className: 'text-blue-600 hover:underline',
						}}
					>
						{field_value}
					</NavLink>
				);
			},
		};
	},
};
