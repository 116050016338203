import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { useClientConfigApi } from '../../hooks/useClientConfigApi';
import { ContractForm } from '../components/ContractForm/ContractForm';
import { ContractsBreadcrumb } from '../components/ContractsBreadcrumb';

export const AddContractPage: FC = () => {
	const { id: client_id } = useParams();
	const { client } = useClientConfigApi(client_id);

	return (
		<PageLayout
			{...{
				title: `Novo Contrato - ${client?.name}`,
				breadcrumb: () => <ContractsBreadcrumb {...{ client_id }} />,
				browser_back: true,
			}}
		>
			<ContractForm {...{ client }} />
		</PageLayout>
	);
};
