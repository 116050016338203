import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { ButtonsContainer } from '../../../../lib/components/Buttons/ButtonsContainer';
import { RemoveButton } from '../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../lib/components/DataTable/ColumnFactory';
import { DataTable } from '../../../../lib/components/DataTable/DataTable';
import { useRequestFeedback } from '../../../../lib/hooks';
import { useInformativesApi } from '../hooks/useInformativesApi';
import { InformativeEntity } from '../../../../lib/model/Informative';
import { DateInputFormMonthYear } from '../../../../lib/components/DateInputForMonthYear';
import moment, { Moment } from 'moment';

interface IProps {
	informatives: InformativeEntity[];
	dateChanged: (date: Moment) => void;
	on_remove_success: () => void;
}

export const InformativesDataTable: FC<IProps> = ({ informatives, on_remove_success, dateChanged }) => {
	const [month, setMonth] = useState(moment());
	const { type } = useParams() as {
		type?: string;
	};

	const BASE_URL = `${process.env.REACT_APP_INFORMATIVE_API}/${type}/list`;

	const navigate = useNavigate();
	const { remove } = useInformativesApi();
	useRequestFeedback(remove.result, remove.error);

	const data = informatives
		.map((r) => {
			const d = new Date(r.publish_date);
			const publish_date = String(
				`${String(d.getDate() + 1).padStart(2, '0')}/${String(d.getMonth() + 1).padStart(
					2,
					'0'
				)}/${d.getFullYear()}`
			);
			return { ...r, date: publish_date };
		})
		.sort((a, b) => moment(a.date, 'DD-MM-YYYY').diff(moment(b.date, 'DD-MM-YYYY')));

	return (
		<DataTable
			{...{
				rowData: data,
				element_on_top: (
					<div className='flex flex-col'>
						<div className='max-w-lg'>
							<DateInputFormMonthYear
								date={month}
								on_changed={(date: Moment) => {
									setMonth(date);
									dateChanged(date);
								}}
							/>
						</div>
					</div>
				),
				buttons: [<AddButton {...{ onClick: () => navigate('novo') }} />],
				columns: [
					ColumnFactory.Link(
						{ field: 'date', headerName: 'Data de publicação', resizable: true, width: 200 },
						(props: { data: { num_id: string } }) => props.data.num_id
					),
					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							cellStyle: CellStyle.Centered,
							width: 180,
						},
						renderer: (props: { data: { num_id: string; title: string } }) => (
							<ButtonsContainer>
								<RemoveButton
									{...{
										style: { marginLeft: '.5rem' },
										on_confirm: () => remove.do_remove(props.data.num_id),
										title: `Deseja remover o informativo ${props.data.num_id}?`,
									}}
								/>
							</ButtonsContainer>
						),
					},
				],
			}}
		/>
	);
};
