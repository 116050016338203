import { FC } from 'react';
import { TagTypeWithColor, tag_types } from './tag_type';

interface IProps {
	on_select_tag_type: (tag: TagTypeWithColor) => void;
}

export const SelectTagType: FC<IProps> = ({ on_select_tag_type }) => {
	return (
		<>
			<h2 className='text-sm pb-1'>Selecione o Tipo de Conteúdo</h2>
			<ul className='flex flex-row mb-1'>
				{tag_types.map((tag_type) => (
					<li
						className={`my-1 mx-0.5 py-1 px-2 rounded text-white cursor-pointer ${tag_type.color} hover:bg-opacity-70`}
						onClick={() => on_select_tag_type(tag_type)}
					>
						{tag_type.title}
					</li>
				))}
			</ul>
		</>
	);
};
