import React from 'react';

export const EventsIcon = (props: any) => (
	<svg viewBox='0 0 512 512' className='w-3 h-3 fill-current' {...props}>
		<path
			id='XMLID_168_'
			d='m502.177 93.892-240.971-88.987c-3.36-1.241-7.053-1.241-10.413 0l-240.97 88.987c-5.947 2.729-9.823 7.879-9.823 14.099v385.005c0 8.3 6.729 15.029 15.029 15.029h59.709c8.3 0 15.029-6.729 15.029-15.029 0-10.822-.638-49.153-8.855-88.02-3.911-18.504-8.998-34.344-15.119-47.08-2.479-5.159-5.155-9.854-8.018-14.08 18.897-1.431 36.685-7.298 52.985-17.517 16.094-10.089 30.406-24.184 42.537-41.891 19.193-28.014 28.491-58.077 31.461-80.334h142.48c2.971 22.257 12.268 52.319 31.461 80.334 12.132 17.708 26.443 31.802 42.537 41.891 16.301 10.219 34.088 16.086 52.985 17.517-2.862 4.226-5.538 8.921-8.018 14.08-6.121 12.736-11.208 28.576-15.119 47.08-8.216 38.867-8.855 77.198-8.855 88.02 0 8.3 6.729 15.029 15.029 15.029h59.709c8.3 0 15.029-6.729 15.029-15.029v-385.005c.004-8.366-6.593-12.763-9.819-14.099zm-246.177-58.867 156.887 57.937h-313.774zm-196.612 442.942h-29.329v-121.174c8.749 10.786 16.184 29.51 21.446 54.4 5.36 25.356 7.239 50.558 7.883 66.774zm-29.329-159.064v-114.829h63.391c-3.83 73.72-35.937 101.301-63.391 114.829zm98.442-51.483c-7.601 11.094-18.224 23.29-32.248 32.439 16.451-24.839 25.478-56.644 27.283-95.784h30.823c-3.142 18.448-11.508 42.399-25.858 63.345zm254.998 0c-14.349-20.945-22.715-44.897-25.858-63.345h30.823c1.805 39.141 10.832 70.946 27.283 95.784-14.024-9.149-24.648-21.345-32.248-32.439zm98.442 210.547h-29.329c.643-16.216 2.523-41.418 7.883-66.774 5.261-24.891 12.697-43.615 21.446-54.4zm0-159.064c-27.454-13.528-59.562-41.11-63.391-114.829h63.391zm0-144.887h-451.882v-50.995h451.883v50.995z'
		/>
	</svg>
);
