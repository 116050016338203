import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { InputItem } from '../../../../lib/components/InputItem';
import { InsertContentListDto } from '../dtos/InsertContentListDto';
import { useContentAssignmentApi } from '../hooks/useContentListApi';

interface IProps {
	visible: boolean;
	close: () => void;
}

export const ContentListInsertModal: React.FC<IProps> = ({ visible, close }) => {
	const navigate = useNavigate();
	const [name, set_list_name] = useState('');

	const { insert } = useContentAssignmentApi();

	useEffect(() => {
		if (insert.result) {
			navigate(insert.result.id);
		}
	}, [insert.result]);

	function submit() {
		const dto: InsertContentListDto = { name };

		if (name) {
			return insert.do_insert(dto);
		}

		return;
	}

	return (
		<Modal
			{...{
				title: 'Criar Lista',
				visible,
				centered: true,
				width: 500,
				closable: true,
				onCancel: close,
				destroyOnClose: true,
				children: <InputItem {...{ label: 'Nome', input_item_name: name, on_changed: set_list_name }} />,
				footer: [<AddButton {...{ onClick: submit, disabled: !name }} />],
			}}
		/>
	);
};
