import { FC } from 'react';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { ButtonsContainer } from '../../../../lib/components/Buttons/ButtonsContainer';
import { PublishButton } from '../../../../lib/components/Buttons/PublishButton';
import { RemoveButton } from '../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../lib/components/DataTable/ColumnFactory';
import { DataTable } from '../../../../lib/components/DataTable/DataTable';
import { useRequestFeedback } from '../../../../lib/hooks';
import { Banner } from '../../../../lib/model/Banner';
import { useBannersApi } from '../hooks/useBannersApi';

interface IProps {
	banners: Banner[];
	visible_insert_form: () => void;
	on_remove_success: () => void;
}

export const BannersDataTable: FC<IProps> = ({ banners, visible_insert_form, on_remove_success }) => {
	const { remove, publish, update_list } = useBannersApi();

	if (remove.result) {
		on_remove_success();
	}

	useRequestFeedback(publish.result, publish.error);
	useRequestFeedback(remove.result, remove.error);

	return (
		<DataTable
			{...{
				row_height: () => 150,
				buttons: [
					<ButtonsContainer class_name={'flex flex-row'}>
						<AddButton {...{ onClick: visible_insert_form }} />

						<PublishButton
							{...{
								onClick: publish.do_publish,
								text_confirm: 'Deseja publicar estes Banners?',
								loading: publish.loading,
								style: { marginLeft: 15 },
							}}
						/>
					</ButtonsContainer>,
				],
				columns: [
					{
						col_def: {
							field: 'id',
							headerName: '',
						},
						renderer: (props: { data: { id: string; name: string } }) => (
							<img
								{...{
									style: {
										width: 120,
										height: 150,
										padding: 10,
									},
									src: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/store/banners/${props.data.id}/mobile.jpg`,
									alt: `${props.data.name}`,
								}}
							/>
						),
					},
					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							cellStyle: CellStyle.Centered,
							width: 80,
						},
						renderer: (props: { data: { id: string } }) => (
							<RemoveButton
								{...{
									on_confirm: () => remove.remove(props.data.id),
									title: 'Deseja remover esse banner?',
								}}
							/>
						),
					},
					ColumnFactory.Link(
						{
							field: 'name',
							headerName: 'Nome',
							width: 500,
							rowDrag: true,
						},
						(props: { data: { id: string } }) => props.data.id
					),
				],
				rowData: banners,
				on_row_drag: update_list.do_update,
			}}
		/>
	);
};
