import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	source_id?: string;
}

export const ClippingBreadcrumb: FC<IProps> = ({ source_id }) => (
	<Breadcrumb>
		<Breadcrumb.Item key='home'>
			<Link to={'/plataforma/fontes-de-informativos'}>Fontes de Informativo</Link>
		</Breadcrumb.Item>
		{source_id && (
			<Breadcrumb.Item key={source_id}>
				<Link to={`/plataforma/fontes-de-informativos/${source_id}/informativos`}>{source_id}</Link>
			</Breadcrumb.Item>
		)}
	</Breadcrumb>
);
