import { useParams } from 'react-router-dom';
import { ContentDto } from '../../../../lib/features/content_group/hooks/useContentGroup';
import { useRequest } from '../../../../lib/hooks';
import { request } from '../../../../lib/utils/request';
import { AssociatedsDto } from '../dtos/AssociatedsDto';
import { ContentListDto } from '../dtos/ContentListDto';
import { InsertContentListDto } from '../dtos/InsertContentListDto';
import { UpdateContentListNameDto } from '../dtos/UpdateContentListNameDto';

const BASE_URL = process.env.REACT_APP_CONTENT_ASSIGNMENT_API;

export function useContentAssignmentApi() {
	const { list_id } = useParams() as { list_id: string };

	const remove = useRemoveContentList();
	const insert = useInsertContentList();
	const update_content_list = useUpdateContentList(list_id);
	const update_list_name = useRenameContentList(list_id);
	const insert_associateds_list = useInsertAssociateds(list_id);
	const remove_associated = useRemoveAssociated(list_id);

	return {
		insert,
		update_content_list,
		update_list_name,
		remove,
		insert_associateds_list,
		remove_associated,
	};
}

function useInsertContentList() {
	const [result, loading, error, create] = useRequest<ContentListDto>((dto: InsertContentListDto) =>
		request().post(`${BASE_URL}`, dto)
	);

	return {
		do_insert: (dto: InsertContentListDto) => create(dto),
		result,
		error,
		loading,
	};
}

function useRenameContentList(list_id: string) {
	const [result, loading, error, update] = useRequest<ContentListDto>((dto: UpdateContentListNameDto) =>
		request().put(`${BASE_URL}/${list_id}`, dto)
	);

	return {
		do_update: (dto: UpdateContentListNameDto) => update(dto),
		result,
		loading,
		error,
	};
}

function useUpdateContentList(list_id: string) {
	const [contents_updated, loading, error, update] = useRequest<ContentListDto>((dto: ContentDto[]) =>
		request().put(`${BASE_URL}/${list_id}/contents`, dto)
	);

	return {
		do_update_contents: (dto: ContentDto[]) => update(dto),
		contents_updated,
		loading,
		error,
	};
}

function useInsertAssociateds(list_id: string) {
	const [result, loading, error, update] = useRequest<AssociatedsDto>((dto: AssociatedsDto) =>
		request().post(`${BASE_URL}/${list_id}/associateds`, dto)
	);

	return {
		do_insert_associateds: (dto: AssociatedsDto) => update(dto),
		result,
		loading,
		error,
	};
}

function useRemoveContentList() {
	const [result, loading, error, remove] = useRequest<string>((list_id: string) => {
		return new Promise((resolve, reject) => {
			request()
				.delete(`${BASE_URL}/${list_id}`)
				.then((res) => setTimeout(() => resolve(res as string), 1500))
				.catch(reject);
		});
	});

	return {
		do_remove: (list_id: string) => remove(list_id),
		result,
		loading,
		error,
	};
}

function useRemoveAssociated(list_id: string) {
	const [result, loading, error, remove] = useRequest<string>((email: string) =>
		request().delete(`${BASE_URL}/${list_id}/${email}`)
	);

	return {
		do_remove_associated: (email: string) => remove(email),
		result,
		loading,
		error,
	};
}
