import { LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	text: string;
	route: string;
	onClick: () => void;
	disabled?: boolean;
}

export const ActionButton: FC<IProps> = ({ text, route, onClick, disabled = false }) => (
	<Link to={route} className='absolute right-5'>
		<Button {...{ type: 'dashed', onClick, disabled }}>
			<div className='flex items-center'>
				<LinkOutlined style={{ marginRight: '0.3rem' }} /> {text}
			</div>
		</Button>
	</Link>
);
