import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { PlatformClientsRoutes } from './clients/routes';
import { PlatformClippingRoutes } from './clipping/routes';
import { ContractsListPage } from './contracts/pages/ContractsListPage';

export const PlatformRoutes: FC = () => (
	<Routes>
		<Route path='fontes-de-informativos/*' element={<PlatformClippingRoutes />} />
		<Route path='clientes/*' element={<PlatformClientsRoutes />} />
		<Route path='contratos' element={<ContractsListPage />} />
	</Routes>
);
