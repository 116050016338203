import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { FC, useEffect, useState } from 'react';
import { UpdateButton } from '../../../../../../lib/components/Buttons/UpdateButton';
import { Label } from '../../../../../../lib/components/Form/Label';
import { useClientConfigApi } from '../../../hooks/useClientConfigApi';

interface IProps {
	client_id: string;
}

export const SsoTokenKeyInput: FC<IProps> = ({ client_id }) => {
	const { token_key } = useClientConfigApi(client_id);
	const [key, set_key] = useState('');
	const [key_already_loaded, set_key_already_loaded] = useState(false);

	useEffect(() => {
		if (token_key.get_req.result !== undefined) {
			set_key(String(token_key.get_req.result));
			set_key_already_loaded(true);
		}
	}, [token_key.get_req.result]);

	function on_submit(key: string) {
		token_key.update(key);
	}

	return (
		<>
			<Label
				{...{
					label_text: 'Chave do Token',
					show_required: false,
				}}
			>
				{key_already_loaded ? (
					<KeyInput
						{...{
							on_change: set_key,
							value: key,
							on_submit,
						}}
					/>
				) : (
					<ShowKeyButton
						{...{
							loading: token_key.get_req.loading,
							on_click: token_key.get_req.get,
						}}
					/>
				)}
			</Label>
		</>
	);
};

const ShowKeyButton: FC<{
	loading: boolean;
	on_click: () => void;
}> = ({ on_click, loading }) => (
	<Button
		className='block'
		{...{
			onClick: on_click,
			disabled: loading,
		}}
	>
		<div className='flex items-center'>
			{loading ? <LoadingOutlined /> : <EyeOutlined />}

			<div className='ml-2'>Mostrar chave</div>
		</div>
	</Button>
);

const KeyInput: FC<{
	value: string;
	on_change: (key: string) => void;
	on_submit: (key: string) => void;
}> = ({ on_change, value, on_submit }) => {
	const key_min_size = 32;

	const [show_error, set_show_error] = useState(false);

	return (
		<div className=' flex '>
			<div className='  w-96'>
				<Input
					{...{
						value,

						className: show_error ? 'border-red-500' : '',
						onChange: (evt) => {
							on_change(evt.target.value.trim());
							set_show_error(false);
						},
						placeholder: '',
					}}
				/>
				{show_error && (
					<span className='text-sm text-red-500 mt-1'>
						Chave deve ter no mínimo {key_min_size} caracteres.
					</span>
				)}
			</div>

			<div className='ml-3'>
				<UpdateButton
					{...{
						onClick: () => {
							if (value.length < key_min_size) {
								return set_show_error(true);
							}

							on_submit(value);
						},
					}}
				/>
			</div>
		</div>
	);
};
