import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { UserProfilePage } from './pages/UserProfilePage';
import { UserProfileListPage } from './pages/UserProfilesListPage';

export const UserProfilesRoutes: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.StoreAdmin, Role.StoreUsers] }}>
		<Routes>
			<Route path=':email' element={<UserProfilePage />} />
			<Route path='/' element={<UserProfileListPage />} />
		</Routes>
	</ProtectedContent>
);
