import { useForm } from '../../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../../lib/hooks/useForm/validators';
import { Tag } from '../../../../../../lib/model/Tag';

type FormValues = {
	num_id: string;
	title: string;
	isbn: string;
	description: string;
	number: number;
	pages: number;
	subtitle: string;
	coordinators: Tag[];
	authors: Tag[];
	publish_date: number;
};

export function useEditionForm() {
	const form = useForm<FormValues>(
		{
			num_id: '',
			title: '',
			isbn: '',
			description: '',
			number: 1,
			pages: 0,
			subtitle: '',
			coordinators: [],
			authors: [],
			publish_date: Date.now(),
		},
		function validate_form(state) {
			return {
				num_id: form_validators.required_text_field(state.value.num_id),
				title: form_validators.required_text_field(state.value.title || ''),
				isbn: form_validators.required_text_field(state.value.isbn || ''),
				description: form_validators.required_text_field(state.value.description || ''),
				number: form_validators.required_number_field(state.value.number),
				pages: form_validators.required_number_field(state.value.pages),
				subtitle: '',
				coordinators: '',
				authors: '',
				publish_date: '',
			};
		}
	);

	return form;
}
