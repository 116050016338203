import { useParams } from 'react-router-dom';
import { create_new_content_group } from '../../../../lib/features/content_group/hooks/useContentGroup';
import { useRequest } from '../../../../lib/hooks';
import { request } from '../../../../lib/utils/request';
import { SectionDto } from '../dtos/SectionDto';
import { SectionInsertDto } from '../dtos/SectionInsertDto';
import { SectionUpdateDto } from '../dtos/SectionUpdateDto';

const BASE_URL = `${process.env.REACT_APP_SECTIONS_API}`;

export function useSectionApi() {
	const { id } = useParams() as { id: string };

	const remove = useRemoveSection();
	const insert = useCreateSection();
	const order_changed = useOrderChangedSections();
	const update = useUpdateSection(id);
	const publish = usePublishSections();

	return {
		publish,
		order_changed,
		insert,
		remove,
		update,
	};
}

function useCreateSection() {
	const [result, loading, error, create] = useRequest<SectionDto>((dto: SectionInsertDto) =>
		create_new_content_group().then((content_group) =>
			request().post(BASE_URL, { ...dto, content_group_id: content_group.id })
		)
	);

	return {
		do_insert: (dto: SectionInsertDto) => create(dto),
		result,
		error,
		loading,
	};
}

function useUpdateSection(section_id: string) {
	const [result, loading, error, update] = useRequest<SectionDto>((dto: SectionUpdateDto) =>
		request().put(`${BASE_URL}/${section_id}`, dto)
	);

	return {
		do_update: (dto: SectionUpdateDto) => update(dto),
		result,
		error,
		loading,
	};
}

function useRemoveSection() {
	const [result, loading, error, remove] = useRequest<string>((section_id: string) =>
		request().delete(`${BASE_URL}/${section_id}`)
	);

	return {
		do_remove: (section_id: string) => remove(section_id),
		result,
		loading,
		error,
	};
}

function useOrderChangedSections() {
	const [result, loading, error, reorder] = useRequest<SectionDto[]>((dto: SectionDto[]) =>
		request().put(BASE_URL, dto)
	);
	return {
		do_order_change: (dto: SectionDto[]) => reorder(dto),
		result,
		loading,
		error,
	};
}

function usePublishSections() {
	const [result, loading, error, do_publish] = useRequest<void>(() => request().post(`${BASE_URL}/publish`));
	return {
		do_publish,
		result,
		loading,
		error,
	};
}
