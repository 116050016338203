import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../lib/components/Loader';
import { BookEntity } from '../../../../lib/model/Book';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { BooksBreadcrumb } from '../components/BooksBreadcrumb';
import { BookForm } from '../components/form/BookForm';
import { BookDto } from '../dto/BookDto';
import { extract_num_id_from_id } from '../utils/extract_num_id_from_id';

export const UpdateBookPage: FC = () => {
	const { id: book_id } = useParams() as { id: string };
	const book_num_id = extract_num_id_from_id(book_id);

	const BASE_URL = `${process.env.REACT_APP_BOOKS_API}/books/${book_num_id}`;

	return (
		<PageLayout
			{...{
				title: 'Atualizar Livro',
				browser_back: true,
				breadcrumb: () => <BooksBreadcrumb {...{ book_id }} />,
			}}
		>
			<Loader
				{...{
					request: () => request().get<BookDto>(BASE_URL),
					renderChildren: (book: BookEntity) => <BookForm {...{ book }} />,
				}}
			/>
		</PageLayout>
	);
};
