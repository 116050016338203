import { FC } from 'react';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { VideosBreadcrumb } from '../components/VideosBreadcrumb';
import { VideoForm } from '../components/form/VideoForm';

export const InsertVideoPage: FC = () => (
	<PageLayout {...{ title: 'Inserir Vídeo', browser_back: true, breadcrumb: () => <VideosBreadcrumb /> }}>
		<VideoForm />
	</PageLayout>
);
