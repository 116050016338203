import { Tabs } from 'antd';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormButtons } from '../../../../lib/components/Form/FormButtons';
import { ContentGroupDataTable } from '../../../../lib/features/content_group/ContentGroupDataTable';
import { Section } from '../../../../lib/model/Section';
import { useSectionApi } from '../hooks/useSectionPage';
import { SectionForm } from './Section_Form/SectionForm';

const { TabPane } = Tabs;

interface IProps {
	section: Section;
}

export const UpdateSection: FC<IProps> = ({ section }) => {
	const navigate = useNavigate();

	const [section_state, set_section_state] = useState({
		title: section.title,
		display: section.display,
	});

	const { update } = useSectionApi();

	function update_section(dto: Section) {
		update.do_update(dto);
	}

	if (update.result) {
		navigate(-1);
	}

	return (
		<Tabs type='card'>
			<TabPane tab='Seção' key={section.id}>
				<SectionForm {...{ initial_values: { ...section_state }, on_change: set_section_state }} />
				<FormButtons
					{...{
						go_back_on_cancel: true,
						on_update_button_click: () =>
							update_section({
								id: section.id,
								title: section_state.title,
								display: section_state.display,
								content_group_id: section.content_group_id,
							}),
					}}
				/>
			</TabPane>

			<TabPane tab='Conteúdos' key='imgs'>
				<ContentGroupDataTable {...{ id: section.content_group_id }} />
			</TabPane>
		</Tabs>
	);
};
