import React from 'react';
import { Tag } from '../../model/Tag';
import { CloseIcon } from '../icons/CloseIcon';
interface IProps {
	tag: Tag;
	on_remove_tag?: (id: string) => any;
}

export const TagComponent: React.FC<IProps> = ({ tag, on_remove_tag = () => null }) => {
	return (
		<span className='flex items-center justify-center '>
			{tag.title}
			<button
				className='ml-2'
				{...{
					onClick: (evt) => {
						evt.preventDefault();
						on_remove_tag(tag.id);
					},
				}}
			>
				<CloseIcon />
			</button>
		</span>
	);
};
