import { FC, useEffect, useState } from 'react';
import { SearchableProvider } from '../../../../../lib/components/SearchableDataTable/SearchableProvider';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { SearchTagInsertModal } from '../components/SearchTagInsertModal';
import { SearchTagsDatatable } from '../components/SearchTagsDatatable';

export const KeywordListPage: FC = () => {
	const [is_removed, set_is_removed] = useState(false);
	const [visible_form_insert, set_visible_form_insert] = useState(false);
	const [result_success, set_result_success] = useState(false);

	useEffect(() => {
		set_is_removed(false);
		set_result_success(false);
	}, [is_removed, result_success]);

	return (
		<PageLayout
			{...{
				title: 'Palavras Chave',
			}}
		>
			<SearchableProvider
				{...{
					key: `tag-list-${is_removed}-${result_success}`,
					entity_name: 'admin-search-tags-entity',
					default_query: {
						limit: 20,
						page: 1,
						sort: 'sort1',
						sort_dir: 'desc',
						tags: 'kwrd',
						text: '',
					},
				}}
			>
				<SearchTagsDatatable
					{...{
						title_of_the_item_to_be_removed: 'palavra-chave',
						on_remove_success: () => set_is_removed(true),
						visible_insert_modal: () => set_visible_form_insert(true),
					}}
				/>
			</SearchableProvider>
			<SearchTagInsertModal
				{...{
					tag_name_in_portuguese: 'Palavra-Chave',
					prefix: 'kwrd',
					visible: visible_form_insert,
					close: () => set_visible_form_insert(false),
					insert_success: () => set_result_success(true),
				}}
			/>
		</PageLayout>
	);
};
