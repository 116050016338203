import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../../lib/components/Loader';
import { ChapterEntity } from '../../../../../../lib/model/Book';
import { PageLayout } from '../../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../../lib/utils/request';
import { extract_num_id_from_id } from '../../../utils/extract_num_id_from_id';
import { ChaptersBreadcrumb } from '../components/ChaptersBreadcrumb';
import { ChapterForm } from '../components/form/ChapterForm';
import { ChapterDto } from '../dto/ChapterDto';

export const UpdateChapterPage: FC = () => {
	const { id, edition_id, chapter_id } = useParams() as {
		id: string;
		edition_id: string;
		chapter_id: string;
	};

	const book_num_id = extract_num_id_from_id(id);
	const edition_num_id = extract_num_id_from_id(edition_id);
	const chapter_num_id = extract_num_id_from_id(chapter_id);

	const BASE_URL = `${process.env.REACT_APP_BOOKS_API}/books/${book_num_id}/editions/${edition_num_id}/chapters/${chapter_num_id}`;

	const [change_form, set_change_form] = useState(0);

	return (
		<PageLayout
			{...{
				title: 'Atualizar Capítulo',
				browser_back: true,
				breadcrumb: () => (
					<ChaptersBreadcrumb {...{ book_id: id, edition_id, in_chapters: true, chapter_id }} />
				),
			}}
		>
			<Loader
				{...{
					reload_when_changes: [change_form],
					request: () => request().get<ChapterDto>(BASE_URL),
					renderChildren: (chapter: ChapterEntity) => (
						<ChapterForm {...{ chapter, on_change_form: () => set_change_form(change_form + 1) }} />
					),
				}}
			/>
		</PageLayout>
	);
};
