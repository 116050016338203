import { useForm } from '../../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../../lib/hooks/useForm/validators';
import { Tag } from '../../../../../../lib/model/Tag';

type FormValues = {
	c_number: string;
	started_at: number;
	end_at: number;
	comments: string;
	tags: Tag[];
};

export function useContractForm() {
	const form = useForm<FormValues>(
		{
			c_number: '',
			comments: '',
			end_at: Date.now(),
			started_at: Date.now(),
			tags: [],
		},
		function validate_form(state) {
			return {
				c_number: form_validators.required_text_field(state.value.c_number),
				comments: '',
				started_at: '',
				end_at: '',
				tags: form_validators.required_list_field(state.value.tags),
			};
		}
	);

	return form;
}
