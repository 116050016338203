import { Modal } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { InputItem } from '../../../../lib/components/InputItem';
import { InsertHighlightDto } from '../dtos/InsertHighlightDto';
import { useHighlightsApi } from '../hooks/useHighlightApi';

interface IProps {
	visible: boolean;
	close: () => void;
}

export const HighlightInsertModal: FC<IProps> = ({ visible, close }) => {
	const navigate = useNavigate();
	const { insert } = useHighlightsApi();

	const [input_spotlight_name, set_input_spotlight_name] = useState('');

	function submit(name: string) {
		const dto: InsertHighlightDto = { name };

		if (name.length !== 0) {
			return insert.create(dto);
		}
		return;
	}

	useEffect(() => {
		if (insert.result) {
			navigate(insert.result.id);
		}
	}, [insert.result]);

	return (
		<Modal
			{...{
				title: 'Adicionar Destaque',
				visible,
				centered: true,
				width: 500,
				closable: true,
				onCancel: close,
				footer: [
					<AddButton
						{...{ disabled: !input_spotlight_name, onClick: () => submit(input_spotlight_name) }}
					/>,
				],
			}}
		>
			<InputItem
				{...{
					label: 'Nome',
					input_item_name: input_spotlight_name,
					on_changed: set_input_spotlight_name,
				}}
			/>
		</Modal>
	);
};
