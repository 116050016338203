import { FC, useState } from 'react';
import { Loader } from '../../../../../lib/components/Loader';
import { TagDto } from '../../../../../lib/dtos/TagDto';
import { Tag } from '../../../../../lib/model/Tag';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../lib/utils/request';
import { TagInsertModal } from '../../components/TagInsertModal';
import { VideoSeriesDatatable } from '../components/VideoSeriesDatatable';

const BASE_URL = `${process.env.REACT_APP_CLIENT_CONTRACT_MANAGEMENT_API}/tags/video`;

export const VideoSerieListPage: FC = () => {
	const [remove_counter, set_remove_counter] = useState(0);
	const [insert_counter, set_insert_counter] = useState(0);
	const [visible_form_insert, set_visible_form_insert] = useState(false);

	return (
		<PageLayout title='Séries de Vídeos'>
			<Loader
				{...{
					reload_when_changes: [insert_counter, remove_counter],
					request: () => request().get<TagDto[]>(BASE_URL),
					renderChildren: (video_series: Tag[]) => (
						<VideoSeriesDatatable
							{...{
								video_series,
								on_remove_success: () => set_remove_counter(remove_counter + 1),
								visible_insert_modal: () => set_visible_form_insert(true),
							}}
						/>
					),
				}}
			/>
			<TagInsertModal
				{...{
					type: 'video',
					visible: visible_form_insert,
					close: () => set_visible_form_insert(false),
					insert_success: () => set_insert_counter(insert_counter + 1),
				}}
			/>
		</PageLayout>
	);
};
