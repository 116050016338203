import { Input } from 'antd';
import { FC, useEffect } from 'react';
import { FormContainer } from '../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../lib/components/Form/FormField';
import { SelectSectionDiplay } from '../SelectSectionDisplay';
import { SectionFormArgs, useSectionForm } from './useSectionForm';

interface ISectionFormProps {
	initial_values: SectionFormArgs;
	on_change: (args: SectionFormArgs) => void;
}

export const SectionForm: FC<ISectionFormProps> = ({ initial_values, on_change }) => {
	const { change_field_value, form_state, show_errors, fill_with_data } = useSectionForm();

	useEffect(() => {
		fill_with_data(initial_values);
	}, []);

	useEffect(() => {
		show_errors();

		if (form_state.valid) {
			return on_change({
				title: form_state.value.title,
				display: form_state.value.display,
			});
		}
	}, [form_state.value.title, form_state.value.display, form_state.valid]);

	return (
		<FormContainer>
			<FormField
				{...{
					label: 'Título',
					name: 'title',
					form_state,
					class_name: 'w-1/2',
				}}
			>
				<Input
					{...{
						value: form_state.value.title,
						onChange: (event) => change_field_value('title', event.target.value),
						style: { marginBottom: '1rem' },
					}}
				/>
			</FormField>

			<FormField
				{...{
					label: 'Forma de exibição',
					name: 'display',
					form_state,
					class_name: 'w-1/2',
				}}
			>
				<SelectSectionDiplay
					{...{
						display: form_state.value.display,
						on_change: (display) => change_field_value('display', display),
					}}
				/>
			</FormField>
		</FormContainer>
	);
};
