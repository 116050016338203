import { FC, useState } from 'react';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { InsertPriceVersionSteps } from '../components/InsertPriceVersionSteps';
import { PricesDataTable } from '../components/PricesDataTable';

export const PricesFilesListPage: FC = () => {
	const [is_inserting_new_version, set_is_inserting_new_version] = useState<boolean>(false);
	const [inserted_list, set_inserted_list] = useState(false);

	return (
		<PageLayout {...{ title: 'Preços' }}>
			<PricesDataTable
				{...{ visible_insert_modal: () => set_is_inserting_new_version(true), inserted_list }}
			/>

			{is_inserting_new_version && (
				<InsertPriceVersionSteps
					{...{
						on_success: () => set_inserted_list(true),
						on_cancel: () => set_is_inserting_new_version(false),
					}}
				/>
			)}
		</PageLayout>
	);
};
