import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	periodic_id?: string;
}

export const PeriodicsBreadcrumb: FC<IProps> = ({ periodic_id }) => (
	<Breadcrumb>
		<Breadcrumb.Item key='periodics'>
			<Link to={'/conteudos/revistas'}>Revistas</Link>
		</Breadcrumb.Item>

		{periodic_id ? (
			<Breadcrumb.Item key='periodic_title'>
				<Link to={`/conteudos/revistas/${periodic_id}`}>{periodic_id}</Link>
			</Breadcrumb.Item>
		) : (
			<Breadcrumb.Item key='new_periodic'>novo</Breadcrumb.Item>
		)}
	</Breadcrumb>
);
