import { formatRelative } from 'date-fns';
import { pt } from 'date-fns/locale';
import { FC } from 'react';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { ButtonsContainer } from '../../../../lib/components/Buttons/ButtonsContainer';
import { DownloadXlsButton } from '../../../../lib/components/Buttons/DownloadXlsButton';
import { LinkButton } from '../../../../lib/components/Buttons/LinkButton';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { DataTable } from '../../../../lib/components/DataTable/DataTable';
import { OkIcon } from '../../../../lib/components/icons/Ok';
import { Loader } from '../../../../lib/components/Loader';
import { request } from '../../../../lib/utils/request';
import { PriceVersionDto } from '../dtos/PriceVersionDto';

const BASE_URL = `${process.env.REACT_APP_PRICING_ADMIN_API}`;

interface IProps {
	visible_insert_modal: () => void;
	inserted_list: boolean;
}

export const PricesDataTable: FC<IProps> = ({ visible_insert_modal, inserted_list }) => (
	<Loader
		{...{
			reload_when_changes: [inserted_list],
			request: () => request().get<PriceVersionDto[]>(BASE_URL),
			renderChildren: (price_versions: PriceVersionDto[]) => (
				<DataTable
					{...{
						rowData: row_data_from_entity(price_versions),

						columns: [
							{
								col_def: {
									field: 'is_current',
									headerName: 'Versão Atual',
									width: 120,
									cellStyle: CellStyle.Centered,
								},
								renderer: (props: { data: { is_current: boolean } }) => (
									<>{props.data.is_current && <OkIcon />}</>
								),
							},
							{ field: 'send_date', headerName: 'Data de envio', width: 250, resizable: true },
							{ field: 'comments', headerName: 'Comentários', width: 300, resizable: true },
							{
								col_def: {
									field: 'buttons',
									headerName: '',
									pinned: 'right',
									cellStyle: CellStyle.Centered,
									width: 250,
								},
								renderer: (props: { data: { date: number } }) => {
									const version_date = props.data.date;
									const DOWNLOAD_BASE_URL = `${process.env.REACT_APP_PRICING_ADMIN_API}/list/download/${version_date}`;

									return (
										<ButtonsContainer class_name='flex flex-row w-full items-center'>
											<DownloadXlsButton
												{...{
													url: DOWNLOAD_BASE_URL,
													style: { marginRight: '1rem' },
												}}
											/>

											<LinkButton
												{...{
													key: 'preview',
													name: 'Visualizar',
													route: `${version_date}/visualizar`,
												}}
											/>
										</ButtonsContainer>
									);
								},
							},
						],

						buttons: [<AddButton {...{ onClick: visible_insert_modal }} />],
					}}
				/>
			),
		}}
	/>
);

function row_data_from_entity(price_versions: PriceVersionDto[]) {
	return price_versions.map((price_version) => {
		const yet = formatRelative(price_version.date, Date.now(), {
			locale: pt,
		});

		return {
			...price_version,
			send_date: yet,
		};
	});
}
