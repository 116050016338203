import { useForm } from '../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../lib/hooks/useForm/validators';
import { PublishType } from '../../../../../lib/model/Periodic';
import { Tag } from '../../../../../lib/model/Tag';

export type FormValues = {
	numId: string;
	publishType: PublishType;
	acronym: string;
	title: string;
	description: string;
	cdu: string;
	cdd: string;
	issn: string;
	issnImpresso?: string;
	qualisSeal: string;
	areasInterest: Tag[];
	keywords: Tag[];
	authors: Tag[];
	themes: Tag[];
};

export function usePeriodicForm() {
	const form = useForm<FormValues>(
		{
			numId: '',
			publishType: '' as PublishType,
			acronym: '',
			title: '',
			description: '',
			cdu: '',
			cdd: '',
			issn: '',
			issnImpresso: '',
			qualisSeal: '',
			areasInterest: [],
			keywords: [],
			authors: [],
			themes: [],
		},
		function validate_form(state) {
			return {
				numId: form_validators.required_text_field(state.value.numId),
				publishType: form_validators.required_text_field(state.value.publishType),
				acronym: form_validators.required_text_field(state.value.acronym),
				title: form_validators.required_text_field(state.value.title),
				description: form_validators.required_text_field(state.value.description),
				cdu: form_validators.required_text_field(state.value.cdu),
				cdd: form_validators.required_text_field(state.value.cdd),
				issn: '',
				issnImpresso: '',
				qualisSeal: '',
				areasInterest: form_validators.required_list_field(state.value.areasInterest),
				keywords: form_validators.required_list_field(state.value.keywords),
				authors: form_validators.required_list_field(state.value.authors),
				themes: '',
			};
		}
	);

	return form;
}
