import { PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import axios from 'axios';
import { FC, ReactNode, useState } from 'react';
import { useRequest, useRequestFeedback } from '../../../../../lib/hooks';
import { request } from '../../../../../lib/utils/request';
import '../../../../../lib/components/Uploader/style.scss';

interface IProps {
	accept: string;
	children?: ReactNode;
	upload_api_endpoint: string;
	upload_success: (file: string) => void;
}

export const VideoUploader: FC<IProps> = ({
	upload_api_endpoint,
	accept,
	upload_success,
	children
}) => {
	const [uploading, set_uploading] = useState(false);
	const [filename, set_filename] = useState('');
	const [signed_url, , error_signed, get_signed_url, set_signed_url] = useRequest(() =>
		request().get<string>(upload_api_endpoint)
	);

	useRequestFeedback('', error_signed);

	return (
		<div className='flex flex-col'>
			<Upload
				{...{
					method: 'PUT',
					accept,
					multiple: false,
					showUploadList: true,
					name: 'url',
					action: signed_url,
					beforeUpload() {
						set_uploading(true);
						return get_signed_url();
					},
					customRequest: ({ file, onSuccess, onError, action }: any) => {		
						const config = { headers: { 'content-type': file.type }};
						set_filename(action.key);
						axios.put(action.url, file, config)
							 .then((res) => onSuccess(res.data, file))
							 .catch((err: Error) => onError(err));
					},
					onChange(info: any) {
						if (info.file.status === 'done') {
							set_uploading(false);
							set_signed_url('');
							message.success('Upload executado com sucesso.');
							upload_success(filename);
						} else if (info.file.status === 'error') {
							set_uploading(false);
							message.error(`${info.file.name} erro no upload.`);
						}
					},
				}}
			>
				{children || (
					<>
						{uploading && (<p className='bg-red-200 p-2 w-48'>
							Upload em progresso...
						</p>)}
						<div className='flex flex-col bg-gray-100 hover:bg-gray-200 border border-dashed border-gray-300 w-48 h-40 items-center'>
							<span className='flex flex-row mt-12 items-center pb-4'>
								<PlusOutlined style={{ color: 'green', fontSize: 17 }} />
							</span>
							Clique ou Arraste
						</div>
					</>
				)}
			</Upload>
		</div>
	);
};
