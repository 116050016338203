import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';
import { ChapterPdfUploader } from './ChapterPdfUploader/ChapterPdfUploader';

interface IProps {
	on_success: () => void;
	upload_api_endpoint: string;
}

export const UploaderPDF: FC<IProps> = ({ on_success, upload_api_endpoint }) => (
	<ChapterPdfUploader
		{...{
			accept: '.pdf',
			upload_api_endpoint,
			upload_success: () => setTimeout(on_success, 1000),
			class_name: 'items-start mb-2',
		}}
	>
		<Button {...{ type: 'primary', title: 'Fazer upload', icon: <UploadOutlined /> }}>Fazer upload</Button>
	</ChapterPdfUploader>
);
