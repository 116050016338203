import { Button, message } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../../../../../lib/components/Buttons/AddButton';
import { ButtonsContainer } from '../../../../../lib/components/Buttons/ButtonsContainer';
import { CellStyle } from '../../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../../lib/components/DataTable/ColumnFactory';
import { DataTable } from '../../../../../lib/components/DataTable/DataTable';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { EditionEntity, EditionFrequency } from '../../../../../lib/model/Periodic';
import { useEditionsApi } from '../hooks/useEditionsApi';
import { PublishButton } from '../../../../../lib/components/Buttons/PublishButton';
import { CheckCircleOutlined, CloseCircleOutlined, EyeOutlined } from '@ant-design/icons';

interface IProps {
	editions: EditionEntity[];
	on_remove_success: () => void;
}

export const EditionsDataTable: FC<IProps> = ({ editions, on_remove_success }) => {
	const navigate = useNavigate();
	const { remove, publish: publish_edition } = useEditionsApi();
	const orderEditions = editions?.length
		? [...editions].sort((a, b) =>
				(a?.number || 0) > (b?.number || 0) ? -1 : (a?.number || 0) < (b?.number || 0) ? 1 : 0
		  )
		: [];
	if (remove.result) {
		on_remove_success();
	}

	useRequestFeedback(remove.result, remove.error);

	async function publish(edition_num_id: string) {
		message.loading('Publicando...', 0);
		await publish_edition.do_index(edition_num_id);
		message.success('Edição publicada com sucesso.');
		window.location.reload();
	}

	return (
		<DataTable
			{...{
				row_height: () => 150,
				rowData: orderEditions,
				buttons: [<AddButton onClick={() => navigate('novo')} />],
				columns: [
					ColumnFactory.Link(
						{
							field: 'title',
							headerName: 'Título',
							resizable: true,
							width: 200,
						},
						(props: { data: { numId: string } }) => props.data.numId
					),
					{
						field: 'number',
						headerName: 'Número Sequencial',
						resizable: false,
						width: 200,
					},
					{
						col_def: {
							field: 'frequency',
							headerName: 'Frequência',
							resizable: false,
							width: 150,
						},
						renderer: (props: { data: { frequency: EditionFrequency } }) =>
							props?.data?.frequency || '',
					},
					{
						col_def: {
							field: 'isPublished',
							headerName: 'Publicado?',
							resizable: true,
							width: 180,
						},
						renderer: (props: { data: { isPublished?: boolean } }) => (
							<>
								{props.data.isPublished ? (
									<CheckCircleOutlined style={{ color: 'green' }} />
								) : (
									<CloseCircleOutlined style={{ color: 'red' }} />
								)}
							</>
						),
					},
					{
						col_def: {
							field: 'url',
							headerName: 'Link na Plataforma',
							resizable: true,
							width: 180,
						},
						renderer: (props: {
							data: { periodicNumId: string; numId: string; isPublished?: boolean };
						}) => (
							<>
								{props.data.isPublished ? (
									<a
										href={`${process.env.REACT_APP_PLATFORM_DOMAIN}/v2/revista/${props.data.periodicNumId}/${props.data.numId}`}
										target='_blank'
									>
										<Button
											{...{
												type: 'primary',
												title: 'Vizualizar na Plataforma',
												style: { color: '#fff' },
											}}
										>
											<div className='flex items-center'>
												<EyeOutlined />
											</div>
										</Button>
									</a>
								) : (
									<></>
								)}
							</>
						),
					},
					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							cellStyle: CellStyle.Centered,
							width: 220,
						},
						renderer: (props: { data: { id: string; title: string; numId: string } }) => (
							<ButtonsContainer class_name='flex center'>
								<Button {...{ type: 'ghost', onClick: () => navigate(`${props.data.numId}/itens`) }}>
									Itens
								</Button>
								<PublishButton
									{...{
										onClick: () => {
											publish(props.data.numId);
										},
										text_confirm:
											'Deseja publicar esta edição na plataforma? Obs: Todos os itens da edição devem estar devidamente cadastrados.',
										loading: !!publish_edition?.loading,
										style: { marginLeft: 15 },
									}}
								/>
							</ButtonsContainer>
						),
					},
				],
			}}
		/>
	);
};
