import { InsertTagDto, TagDto } from '../../../../lib/dtos/TagDto';
import { useRequest } from '../../../../lib/hooks';
import { request } from '../../../../lib/utils/request';

const BASE_URL = `${process.env.REACT_APP_CLIENT_CONTRACT_MANAGEMENT_API}/tags`;

export type ContractTagsType = 'book' | 'video';

export function useTagsManagementApi(type: ContractTagsType) {
	const insert = useCreateTag(type);
	const remove = useRemoveTag(type);

	return {
		insert,
		remove,
	};
}

function useCreateTag(type: ContractTagsType) {
	const [result, loading, error, do_create] = useRequest<TagDto>((dto: InsertTagDto) =>
		request().post(getBaseUrlFor(type), dto)
	);

	return {
		create: (dto: InsertTagDto) => do_create(dto),
		result,
		error,
		loading,
	};
}

function useRemoveTag(type: ContractTagsType) {
	const [result, loading, error, remove] = useRequest<string>((id: string) =>
		request().delete(`${getBaseUrlFor(type)}/${id}`)
	);

	return {
		do_remove: (id: string) => remove(id),
		result,
		loading,
		error,
	};
}

function getBaseUrlFor(type: ContractTagsType) {
	return `${BASE_URL}/${type}`;
}
