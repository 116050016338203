import { Modal, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { InputItem } from '../../../../lib/components/InputItem';
import { InsertTagDto } from '../../../../lib/dtos/TagDto';
import { useRequestFeedback } from '../../../../lib/hooks';
import { ContractTagsType, useTagsManagementApi } from '../hooks/ContractTagsApi';
import { request } from '../../../../lib/utils/request';
import { PermissionDto } from '../../../../lib/dtos/PermissionDto';
import { UpdateButton } from '../../../../lib/components/Buttons/UpdateButton';

interface IProps {
	type: ContractTagsType;
	visible: boolean;
	current_update_id?: any;
	close: () => void;
	insert_success: () => void;
}

const PERMISSIONS_BASE_URL = `${process.env.REACT_APP_PERMISSIONS_GROUPS_API}/permissions`;

export const TagInsertModal: FC<IProps> = ({ type, visible, current_update_id, close, insert_success }) => {
	const [id, set_id] = useState(current_update_id?.id || '');
	const [title, set_title] = useState(current_update_id?.title || '');
	const [permission_options, set_permission_options] = useState<any[]>([]);
	const [permission_group, set_permission_group] = useState<any>(current_update_id?.permission_id || '');

	const { insert } = useTagsManagementApi(type);

	useEffect(() => {
		if (!visible) return;
		loadPermissions();
	}, [visible]);

	const loadPermissions = async () => {
		const r = await request().get<PermissionDto[]>(PERMISSIONS_BASE_URL);
		set_permission_options(r);
	};

	useEffect(() => {
		if (!!insert.result) {
			insert_success();
			close();
		}
	}, [insert.result]);

	useEffect(() => {
		set_id(current_update_id?.id || '');
		set_title(current_update_id?.title || '');
		set_permission_group(current_update_id?.permission_id || '');
	}, [current_update_id]);

	const submit = () => {
		const dto: InsertTagDto = { title: title };
		if (permission_group) {
			dto.permission_id = permission_group;
		}
		if (title) insert.create(dto);
	};

	useRequestFeedback(insert.result, insert.error);

	return (
		<Modal
			{...{
				title: 'Criar Série',
				visible,
				closable: true,
				onCancel: close,
				destroyOnClose: true,
				centered: true,
				footer: [
					current_update_id?.id ? (
						<UpdateButton {...{ onClick: submit, disabled: !title || !permission_group }} />
					) : (
						<AddButton {...{ onClick: submit, disabled: !title || !permission_group }} />
					),
				],
			}}
		>
			<InputItem
				{...{
					label: 'Título',
					input_item_name: title,
					on_changed: set_title,
					disabled: !!current_update_id?.title,
				}}
			/>
			<div className='mt-2'>
				<label className='mr-4 font-semibold'>
					<span className='text-red-500'> * </span>
					Grupo de Permissão:
				</label>
				<div className='w-5/6'>
					<Select
						placeholder='Selecione'
						onChange={(v) => set_permission_group(v)}
						style={{ width: '100%' }}
						value={permission_group}
					>
						<Select.Option value=''>{''}</Select.Option>
						{permission_options.map((o) => (
							<Select.Option value={String(o.id)} key={`group-${o.id}`}>
								{o.title}
							</Select.Option>
						))}
					</Select>
				</div>
			</div>
		</Modal>
	);
};
