import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../lib/components/Loader';
import { VideoEntity } from '../../../../lib/model/Video';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { VideosBreadcrumb } from '../components/VideosBreadcrumb';
import { VideoForm } from '../components/form/VideoForm';
import { VideoDto } from '../dto/VideoDto';
import { extract_num_id_from_id } from '../utils/extract_num_id_from_id';

export const UpdateVideoPage: FC = () => {
	const { id: video_id } = useParams() as { id: string };
	const video_num_id = extract_num_id_from_id(video_id);

	const BASE_URL = `${process.env.REACT_APP_VIDEO_API}/videos/${video_num_id}`;

	return (
		<PageLayout
			{...{
				title: 'Atualizar Vídeo',
				browser_back: true,
				breadcrumb: () => <VideosBreadcrumb {...{ video_id }} />,
			}}
		>
			<Loader
				{...{
					request: () => request().get<VideoDto>(BASE_URL),
					renderChildren: (video: VideoEntity) => <VideoForm {...{ video }} />,
				}}
			/>
		</PageLayout>
	);
};
