import { ColumnFactory } from '../../../../../lib/components/DataTable/ColumnFactory';
import { DataTable } from '../../../../../lib/components/DataTable/DataTable';
import { ClippingSourceDto } from '../../dtos/ClippingSourceDtos';

type IProps = {
	clipping_sources: ClippingSourceDto[];
};

export const ClippingSourceDataTable: React.FC<IProps> = ({ clipping_sources }) => (
	<DataTable
		{...{
			rowData: clipping_sources.filter((c) => c.id !== 'abradt'),
			columns: [
				ColumnFactory.Link(
					{
						field: 'name',
						headerName: 'Nome',
						resizable: false,
						rowDrag: false,
						width: 300,
					},
					(props: { data: { id: string } }) => `${props.data.id}/informativos`
				),

				{
					field: 'periodicity',
					headerName: 'Periodicidade',
					resizable: false,
					rowDrag: false,
					width: 150,
				},
			],
		}}
	/>
);
