import { FC, useLayoutEffect, useRef } from 'react';

interface IProps {
	initial_value: string;
	on_change: (html: string) => void;
}

export const InputHtmlContent: FC<IProps> = ({ initial_value, on_change }) => {
	const html_content = useRef<HTMLDivElement>(null);

	const on_change_listener = useRef(() => {
		on_change(html_content.current?.innerHTML || '');
	});

	useLayoutEffect(() => {
		if (html_content.current) {
			html_content.current.innerHTML = initial_value;

			html_content.current.addEventListener('input', on_change_listener.current);
		}

		return () => {
			if (html_content.current) {
				html_content.current.removeEventListener('input', on_change_listener.current);
			}
		};
	}, [initial_value]);

	return (
		<div
			{...{
				className: 'html-text border rounded-sm p-1',
				ref: html_content,
				contentEditable: true,
				suppressContentEditableWarning: true,
				placeholder: 'Insira o Texto HTML.',
			}}
		/>
	);
};
