import { Action } from '../../model/Action';
import { ChangeValuePayload } from './form_actions';
import { FormState, ValidateForm } from './types';

export const create_form_reducer = <T>(validate_fn: ValidateForm<T>) => {
	return (state: FormState<T>, { type, payload }: Action<any>): FormState<T> => {
		const handlers: any = {
			initialize: (value: T): FormState<T> => {
				return {
					...state,
					value,
					dirty: false,
					dirty_fields: [],
					show_errors: false,
				};
			},
			change_value: ({ field_name, field_value }: ChangeValuePayload<T>): FormState<T> => {
				return {
					...state,
					dirty_fields: Array.from(new Set([...state.dirty_fields, String(field_name)])),
					value: {
						...state.value,
						[field_name]: field_value,
					},
					dirty: true,
				};
			},
			show_errors: (): FormState<T> => ({
				...state,
				show_errors: true,
			}),
		};

		const next_state = handlers?.[type]?.(payload) || state;
		const errors = validate_fn(next_state);

		return {
			...next_state,
			errors,
			//@ts-ignore
			valid: !Object.keys(errors).some((key: any) => !!errors[key]),
		};
	};
};
