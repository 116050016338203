import { Alert } from 'antd';
import { FC } from 'react';
import { Loader } from '../../../../lib/components/Loader';
import { TagDto } from '../../../../lib/dtos/TagDto';
import { Tag } from '../../../../lib/model/Tag';
import { request } from '../../../../lib/utils/request';
import { SelectedTags } from '../../clients/client_contracts/components/Tags/SelectedTags';

const API_URL = process.env.REACT_APP_CLIENT_CONTRACT_MANAGEMENT_API;

interface IProps {
	client_id: string;
}

export const ContractedContents: FC<IProps> = ({ client_id }) => {
	const PRODUCTS_URL = `${API_URL}/clients/${client_id}/tags`;

	return (
		<Loader
			{...{
				request: () => request().get<TagDto[]>(PRODUCTS_URL),
				renderChildren: (contracted_contents: Tag[]) => (
					<div className='w-3/4'>
						{contracted_contents.length !== 0 ? (
							<SelectedTags {...{ tags: contracted_contents }} />
						) : (
							<Alert message='Nenhum conteudo contratado.' type='info' showIcon />
						)}
					</div>
				),
			}}
		/>
	);
};
