import { Select } from 'antd';
import { FC } from 'react';
import { FormField } from '../../../../lib/components/Form/FormField';
import { Loader } from '../../../../lib/components/Loader';
import { TagDto } from '../../../../lib/dtos/TagDto';
import { FormState } from '../../../../lib/hooks/useForm/types';
import { Tag } from '../../../../lib/model/Tag';
import { request } from '../../../../lib/utils/request';

const VIDEO_SERIES_BASE_URL = `${process.env.REACT_APP_CLIENT_CONTRACT_MANAGEMENT_API}/tags/video`;

const Option = Select;

interface IProps {
	on_change: (serie: string) => void;
	video_serie_state: Tag;
	serie_from_video?: Tag;
	form_state: FormState<any>;
	name: string;
	label?: string;
	required?: boolean;
	class_name?: string;
}

export const VideoSerieSelect: FC<IProps> = ({
	on_change,
	video_serie_state,
	serie_from_video,
	form_state,
	name,
	class_name,
	label,
	required,
}) => {
	return (
		<FormField {...{ label, name, form_state, class_name, required }}>
			<Loader
				{...{
					request: () => request().get<TagDto[]>(VIDEO_SERIES_BASE_URL),
					renderChildren: (video_series: Tag[]) => (
						<Select
							placeholder='Selecione a serie'
							onChange={on_change}
							defaultValue={
								serie_from_video && JSON.stringify(video_serie_state.title).replace(/"/g, '')
							}
							style={{ width: '100%' }}
						>
							{video_series.map((serie) => (
								<Option value={JSON.stringify(serie)} key={serie.id}>
									{serie.title}
								</Option>
							))}
						</Select>
					),
				}}
			/>
		</FormField>
	);
};
