import { ReactNode } from 'react';
import { FormState } from '../../hooks/useForm/types';
import { Label } from './Label';

interface IProps {
	name: string;
	label?: string;
	required?: boolean;
	form_state: FormState<any>;
	children: ReactNode;
	render_children_inside_label?: boolean;
	class_name?: string;
}

export const FormField: React.FC<IProps> = ({
	form_state,
	name,
	class_name = 'flex flex-col block mb-3',
	children,
	required = true,
	label,
	render_children_inside_label = true,
}) => {
	const error_message = form_state.errors[name];
	const has_error = (form_state.dirty_fields.includes(name) || form_state.show_errors) && error_message;

	const cls_error = has_error ? ' border-2 border-red-500' : '';

	const input_el = <div className={cls_error}>{children}</div>;

	return (
		<div className={class_name}>
			<Label
				{...{
					htmlFor: name,
					label_text: String(label),
					show_required: required,
					children: render_children_inside_label && input_el,
				}}
			/>

			{!render_children_inside_label && input_el}
			{has_error && <span className='text-sm text-red-500 mt-1'>{error_message}</span>}
		</div>
	);
};
