import { useQueryParams } from '../../../hooks';
import { useChangeQueryParams } from '../../../hooks/useChangeQueryParams';
import { SearchableQueryDto } from '../model/SearchableQueryDto';

export function useQuery(default_query: SearchableQueryDto) {
	const { change } = useChangeQueryParams<Partial<SearchableQueryDto>>();

	const params = useQueryParams() as Partial<SearchableQueryDto>;

	return {
		change: (search_query: SearchableQueryDto) => change(search_query),
		text: (params.text || '').trim(),
		change_text: (text: string) => change({ text }),
		tags: (params.tags || '').split(','),
		change_tags: (tags: string) => change({ tags }),
		sort: params.sort || 'date1',
		sort_dir: params.sort_dir || 'desc',
		change_sort_dir: (field: 'date1' | 'date2' | 'sort1' | 'sort2' | 'sort3' | '', dir: 'asc' | 'desc') =>
			change({ sort: field, sort_dir: dir }),
		page: +(params.page || 1),
		change_page: (page: number) => change({ page }),
		limit: +(params.limit || '20'),
		change_limit: (limit: number) => change({ limit }),
		clear: () => change(default_query),
		default:
			params.text === default_query.text &&
			params.sort === default_query.sort &&
			+(params.page || 1) === default_query.page &&
			params.tags === default_query.tags &&
			params.sort_dir === default_query.sort_dir &&
			params.limit === default_query.limit,
	};
}
