export type TagTypeWithColor = {
	value: string;
	title: string;
	color: string;
};

export const tag_types: TagTypeWithColor[] = [
	{ title: 'Livros', value: 'book', color: 'bg-green-700' },
	{ title: 'Vídeos', value: 'video', color: 'bg-blue-600' },
	{ title: 'Revistas', value: 'periodic', color: 'bg-gray-500' },
	{ title: 'Informativos', value: 'news', color: 'bg-yellow-500' },
	{ title: 'Códigos', value: 'code', color: 'bg-indigo-500' },
];
