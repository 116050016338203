import { useRequest } from '../../../hooks/useRequest';
import { SearchResultDto } from '../../../model/SearchDto';
import { request } from '../../../utils/request';
import { SearchDto } from '../model/SearchDto';

const search_url = `${process.env.REACT_APP_ENTITY_SEARCH_API}`;

export function useSearchableApi<T>(entity_name: string) {
	const [result, loading, error, search_request] = useRequest<SearchResultDto<T>>((dto: SearchDto) => {
		return request().get(`${search_url}/${entity_name}`, dto);
	});

	return {
		search: (dto: SearchDto) => search_request(dto),
		result,
		error,
		loading,
	};
}
