import { Role } from '../model/Role';
import { useForumAuth } from './useForumAuth';

export function useHasRole() {
	const { session } = useForumAuth();

	return (role: Role) => {
		if (session.roles.includes(Role.Admin)) {
			return true;
		}

		return session.roles.includes(role);
	};
}
