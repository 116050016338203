import { Button, Modal } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { CancelButton } from '../../../../lib/components/Buttons/CancelButton';
import { BookEditionSelect } from '../../../../lib/features/book_edition/components/BookEditionSelect';
import { useRequestFeedback } from '../../../../lib/hooks';
import { Content } from '../../../../lib/model/Content';
import { useContentAssignmentApi } from '../hooks/useContentListApi';

interface IProps {
	visible: boolean;
	close: () => void;
	contents: Content[] | [];
	on_success: (value: Content[]) => void;
}

export const ContentInsertModal: FC<IProps> = ({ close, visible, contents, on_success }) => {
	const [editions, set_edition_selecteds] = useState<Content[]>(contents);

	const { update_content_list } = useContentAssignmentApi();

	useRequestFeedback(update_content_list.contents_updated, update_content_list.error);

	useEffect(() => {
		if (update_content_list.contents_updated) {
			on_success(editions);
			close();
		}
	}, [update_content_list.contents_updated]);

	useEffect(() => {
		set_edition_selecteds(contents);
	}, [contents]);

	return (
		<Modal
			{...{
				title: 'Editar Conteúdo',
				visible,
				centered: true,
				width: 1070,
				closable: false,
				children: <BookEditionSelect {...{ value: editions, on_change: set_edition_selecteds }} />,
				footer: [
					<CancelButton {...{ onClick: close, className: 'mr-2' }} />,
					<Button type={'primary'} onClick={() => update_content_list.do_update_contents(editions)}>
						Confirmar
					</Button>,
				],
			}}
		/>
	);
};
