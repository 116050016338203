import React, { ReactNode } from 'react';

interface IProps {
	children: ReactNode;
	top?: boolean;
}
export const Toolbar: React.FC<IProps> = ({ children, top = true }) => {
	const cls = top ? 'border-t' : 'border-b';
	return (
		<div
			className={`flex w-full items-end justify-between py-3 px-2 border-l border-r ${cls}`}
			style={{
				background: '#f8f8f8',
				borderColor: '#babfc7',
			}}
		>
			{children}
		</div>
	);
};
