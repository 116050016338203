import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../lib/components/Loader';
import { EditionEntity } from '../../../../../lib/model/Periodic';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../lib/utils/request';
import { extract_num_id_from_id } from '../../utils/extract_num_id_from_id';
import { EditionsBreadcrumb } from '../components/EditionsBreadcrumb';
import { EditionForm } from '../components/form/EditionForm';
import { EditionDto } from '../dto/EditionDto';

export const UpdateEditionPage: FC = () => {
	const { id: periodic_id, edition_id } = useParams() as { id: string; edition_id: string };

	const periodicNumId = String(extract_num_id_from_id(periodic_id)).toUpperCase();
	const editionNumId = String(extract_num_id_from_id(edition_id)).toUpperCase();

	const BASE_URL = `${process.env.REACT_APP_PERIODIC_API}/periodics/${periodicNumId}/editions/${editionNumId}`;

	return (
		<PageLayout
			{...{
				title: 'Atualizar Edição',
				browser_back: true,
				breadcrumb: () => <EditionsBreadcrumb {...{ periodic_id, in_editions: true, edition_id }} />,
			}}
		>
			<Loader
				{...{
					request: () => request().get<EditionDto>(BASE_URL),
					renderChildren: (edition: EditionEntity) => <EditionForm {...{ edition }} />,
				}}
			/>
		</PageLayout>
	);
};
