import { FC } from 'react';
import { Tag } from '../../../../../lib/model/Tag';
import { TagTypeWithColor } from './Tags/tag_type';

type TagWithColor = {
	id: string;
	title: string;
	color: string;
};

interface IProps {
	items: Tag[];
	insert_tag_with_color: (item: TagWithColor) => void;
	selected_tags: Tag[];
	tag_type: TagTypeWithColor;
	get_tag_type_title: (tag: Tag) => string;
}

export const ListTags: FC<IProps> = ({
	insert_tag_with_color,
	items,
	selected_tags,
	tag_type,
	get_tag_type_title,
}) => {
	return (
		<ul className='flex flex-row rounded border border-gray-100 p-4 bg-gray-50 flex-wrap max-w-lg'>
			{items
				.filter((tag) => {
					if (!selected_tags.some((t) => t.id === tag.id)) {
						return tag;
					}

					return null;
				})
				.map((tag) => {
					return (
						<li
							className={`h-7 mx-0.5 py-1 px-2 mb-3 rounded text-white cursor-pointer ${tag_type.color} hover:bg-opacity-70`}
							onClick={() => insert_tag_with_color({ ...tag, color: tag_type.color })}
						>
							{get_tag_type_title(tag)}
						</li>
					);
				})}
		</ul>
	);
};
