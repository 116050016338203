import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AddButton } from '../../../../../../lib/components/Buttons/AddButton';
import { ButtonsContainer } from '../../../../../../lib/components/Buttons/ButtonsContainer';
import { RemoveButton } from '../../../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../../../lib/components/DataTable/ColumnFactory';
import { DataTable } from '../../../../../../lib/components/DataTable/DataTable';
import { useRequestFeedback } from '../../../../../../lib/hooks';
import { ItemEntity, ItemTypes } from '../../../../../../lib/model/Periodic';
import { extract_num_id_from_id } from '../../../utils/extract_num_id_from_id';
import { useItemsApi } from '../hooks/useItemsApi';
import { OrderButton } from '../../../../../../lib/components/Buttons/OrderButton';
import { Button, Card, Modal, message } from 'antd';
import OrderItems from './OrderItems';
import { CancelButton } from '../../../../../../lib/components/Buttons/CancelButton';

interface IProps {
	items: ItemEntity[];
	on_remove_success: () => void;
	on_order_success: () => void;
}

export const ItemsDataTable: FC<IProps> = ({ items, on_remove_success, on_order_success }) => {
	const { id, editionId } = useParams() as {
		id: string;
		editionId: string;
		itemId: string;
	};
	const [showOrderModal, setShowOrderModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [orderedItems, setOrderedItems] = useState<any[]>(items);
	const reverseItems = items?.length
		? [...items].sort((a, b) =>
				(a?.order || 0) < (b.order || 0) ? 1 : (a.order || 0) > (b.order || 0) ? -1 : 0
		  )
		: [];

	const periodicNumId = String(extract_num_id_from_id(id)).toLocaleUpperCase();
	const editionNumId = String(extract_num_id_from_id(editionId)).toUpperCase();

	const BASE_URL = `${process.env.REACT_APP_PERIODIC_API}/periodics/${periodicNumId}/editions/${editionNumId}/items`;

	const navigate = useNavigate();
	const { remove, update_order } = useItemsApi();

	if (remove.result) {
		on_remove_success();
	}

	useRequestFeedback(remove.result, remove.error);

	const submit = async () => {
		setLoading(true);
		const newItems = JSON.parse(JSON.stringify(orderedItems));
		const reversed = newItems.reverse();
		for (let i = 0; i <= reversed?.length; i++) {
			if (newItems?.[i]) reversed[i].order = i + 1;
		}
		for (const item of reversed) {
			const numId = item?.numId || '';
			const dto = { ...item };
			dto.numId = undefined;
			await update_order.do_update(numId, dto);
		}
		message.success('Ordenação salva com sucesso.');
		setLoading(false);
		setShowOrderModal(false);
		on_order_success();
	};

	return (
		<>
			<DataTable
				{...{
					rowData: reverseItems,
					buttons: [
						<OrderButton {...{ onClick: () => setShowOrderModal(true) }} />,
						<AddButton {...{ onClick: () => navigate('novo') }} />,
					],
					columns: [
						ColumnFactory.Link(
							{ field: 'title', headerName: 'Título', resizable: true, width: 300 },
							(props: { data: { numId: string } }) => props.data.numId
						),
						{
							col_def: {
								field: 'itemType',
								headerName: 'Tipo',
								resizable: true,
								width: 200,
							},
							renderer: (props: { data: { itemType: ItemTypes } }) => {
								switch (props?.data?.itemType) {
									case ItemTypes.Doctrine:
										return <span>Doutrina</span>;
									case ItemTypes.Jurisprudence:
										return <span>Jurisprudência</span>;
									case ItemTypes.Jurisprudential_Trends:
										return <span>Tendências Jurisprudenciais</span>;
									default:
										return <span>Outro</span>;
								}
							},
						},
						{
							col_def: {
								field: 'buttons',
								headerName: '',
								pinned: 'right',
								cellStyle: CellStyle.Centered,
								width: 180,
							},
							renderer: (props: { data: { numId: string; title: string } }) => (
								<ButtonsContainer>
									{/* <DownloadFromSignedUrlButton
									{...{ api_url: `${BASE_URL}/${props.data.num_id}/download` }}
								/> */}

									<RemoveButton
										{...{
											style: { marginLeft: '.5rem' },
											on_confirm: () => remove.do_remove(props.data.numId),
											title: `Deseja remover o item ${props.data.title}?`,
										}}
									/>
								</ButtonsContainer>
							),
						},
					],
				}}
			/>
			<Modal
				{...{
					title: 'Ordenação de Itens',
					visible: showOrderModal,
					centered: true,
					width: 1100,
					closable: true,
					destroyOnClose: true,
					onCancel: () => setShowOrderModal(false),
					footer: [
						<CancelButton
							{...{ onClick: () => setShowOrderModal(false), className: 'mr-2', loading }}
						/>,
						<Button type={'primary'} onClick={submit} loading={loading}>
							Salvar
						</Button>,
					],
				}}
			>
				<Card style={{ background: '#fff', marginTop: 1 }} className='frm-fieldset '>
					<OrderItems items={items} onChange={(values: any[]) => setOrderedItems(values)} />
				</Card>
			</Modal>
		</>
	);
};
