import NodeCache from 'node-cache';
import { failed, Result, success } from '../model/Result';

const five_minutes_in_ms = 300;

export function MemoryCache<T>(ttl: number = five_minutes_in_ms) {
	const cache = new NodeCache();

	return {
		get: (key: string): Result<T> => {
			const r = cache.get<T>(key);

			return !r ? failed('não encontrou.') : success(r);
		},
		set: (key: string, value: T) => cache.set<T>(key, value, ttl),
	};
}
