import { ContentPrice, EMPTY_PRICE } from '../../model/ContentPrice';
import { content_price_cache } from './ContentPriceCache';

const bucket_name = process.env.REACT_APP_PUBLIC_BUCKET_URL;

export async function get_content_price(
	content_id: string,
	version_date: number | 'current'
): Promise<ContentPrice> {
	const key = `${bucket_name}/store/prices/book/${version_date}/${content_id}.json`;

	const [, price_from_cache] = content_price_cache.get(key);

	if (price_from_cache) {
		return price_from_cache;
	}

	const r = await fetch(key);

	if (r.status !== 200) {
		content_price_cache.set(key, EMPTY_PRICE);
		return EMPTY_PRICE;
	}

	const price = await r.json();

	content_price_cache.set(key, price);

	return price;
}
