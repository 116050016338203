import { Tag } from '../../model/Tag';

interface IProps {
	tags: Tag[];
	on_tag_selected: (tag: Tag) => any;
}

export const TagList: React.FC<IProps> = ({ tags, on_tag_selected }) => {
	return (
		<ul className='mt-2 h-auto max-h-40 overflow-y-auto'>
			{!tags ? (
				<li>Resultado vazio.</li>
			) : (
				tags.map((tag: Tag) => (
					<li
						key={`list-item-${tag.id} `}
						onClick={(evt) => {
							evt.preventDefault();
							on_tag_selected(tag);
						}}
						className={' border-b cursor-pointer w-full  '}
					>
						<button className={' p-2 focus:bg-blue-100 outline-0 w-full  block text-justify'}>
							{tag.title}
						</button>
					</li>
				))
			)}
		</ul>
	);
};
