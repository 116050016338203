import { ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
interface IProps {
	on_click: () => any;
}

export const ReloadButton: React.FC<IProps> = ({ on_click }) => {
	return (
		<Button
			{...{
				onClick: on_click,
				icon: <ReloadOutlined />,
				style: { display: 'flex', alignItems: 'center' },
			}}
		>
			Atualizar
		</Button>
	);
};
