import { FC, useState } from 'react';
import { FormButtons } from '../../../../lib/components/Form/FormButtons';
import { InputItem } from '../../../../lib/components/InputItem';
import { useRequestFeedback } from '../../../../lib/hooks';
import { UpdateContentListNameDto } from '../dtos/UpdateContentListNameDto';
import { useContentAssignmentApi } from '../hooks/useContentListApi';

interface IProps {
	list_name: string;
}

export const UpdateListName: FC<IProps> = ({ list_name }) => {
	const [name, set_list_name] = useState(list_name);

	const { update_list_name } = useContentAssignmentApi();

	function submit() {
		const dto: UpdateContentListNameDto = { name };

		if (name) {
			return update_list_name.do_update(dto);
		}
		return;
	}

	useRequestFeedback(update_list_name.result, update_list_name.error);

	return (
		<>
			<InputItem
				{...{
					class_name: 'w-1/2 flex flex-row items-center',
					label: 'Nome',
					input_item_name: name,
					on_changed: set_list_name,
				}}
			/>

			<FormButtons {...{ go_back_on_cancel: true, on_update_button_click: submit }} />
		</>
	);
};
