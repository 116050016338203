import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { EditionsBreadcrumb } from '../components/EditionsBreadcrumb';
import { EditionForm } from '../components/form/EditionForm';

export const InsertEditionPage: FC = () => {
	const { id } = useParams();

	return (
		<PageLayout
			{...{
				title: 'Inserir Edição',
				browser_back: true,
				breadcrumb: () => <EditionsBreadcrumb periodic_id={id} in_editions new_edition />,
			}}
		>
			<EditionForm />
		</PageLayout>
	);
};
