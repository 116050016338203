import { FC } from 'react';
import { Tag } from '../../../../../../lib/model/Tag';
import { verify_tag_type } from './verify_tag_type';

interface IProps {
	tags: Tag[];
	periodics?: any[];
}

export const SelectedTags: FC<IProps> = ({ tags, periodics }) => {
	const tags_with_color = verify_tag_type(tags, periodics);
	return (
		<ul className='flex flex-row flex-wrap'>
			{tags_with_color.map((tag) => {
				return <li className={`mx-0.5 py-1 px-2 mb-2 rounded text-white ${tag.color}`}>{tag.name}</li>;
			})}
		</ul>
	);
};
