import { FC } from 'react';
import { FormButtons } from '../../../../../lib/components/Form/FormButtons';
import { PeriodicEntity } from '../../../../../lib/model/Periodic';

interface IProps {
	periodic?: PeriodicEntity;
	submit: () => void;
	disabled?: boolean;
}

export const PeriodicFormButtons: FC<IProps> = ({ periodic, submit, disabled }) => {
	return (
		<FormButtons
			{...{
				disabled,
				go_back_on_cancel: true,
				on_add_button_click: periodic ? undefined : () => submit(),
				on_update_button_click: periodic ? () => submit() : undefined,
				on_action_button_click: periodic ? () => {} : undefined,
				action_button_text: periodic ? 'Edições' : undefined,
				action_button_route: periodic ? `/conteudos/revistas/${periodic?.numId}/edicoes` : undefined,
			}}
		/>
	);
};
