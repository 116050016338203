export type ContentPrice = {
	id: string;
	description: string;
	tags: [];
	price: Price;
};

type Price = {
	digital: { price: number; discount_percent: number; final_price: number };
	paper: { price: number; discount_percent: number; final_price: number };
};

export const EMPTY_PRICE: ContentPrice = {
	id: '',
	description: '',
	price: {
		digital: {
			discount_percent: 0,
			final_price: 0,
			price: 0,
		},
		paper: {
			discount_percent: 0,
			final_price: 0,
			price: 0,
		},
	},
	tags: [],
};
