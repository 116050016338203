import React from 'react';
import { Content } from '../../../../model/Content';
import { BookItem } from './BookItem';
import { Book } from './types';

interface IProps {
	value: Content[];
	books: Book[];
	on_change: (selecteds: Content[]) => void;
}

export const EditionSelectionList: React.FC<IProps> = ({ books, value, on_change }) => {
	return (
		<ul className=' bg-white border overflow-auto w-2/3' style={{ height: 350 }}>
			{(books || []).filter((book)=> book.editions.length > 0).map((book) => (
				
				<BookItem
					{...{
						book,
						key: book.id,
						selecteds: value,
						on_change,
					}}
				/>
			))}
		</ul>
	);
};
