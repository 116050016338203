import { useRequest } from '../../../../../lib/hooks';
import { request } from '../../../../../lib/utils/request';
import { InsertSearchTagDto, SearchTagDto } from '../dto/SearchTagDto';

const BASE_URL = `${process.env.REACT_APP_SEARCH_TAGS_API}/search-tags`;

export function useSearchTagsApi() {
	const insert = useCreateTag();
	const remove = useRemoveTag();

	return {
		insert,
		remove,
	};
}

function useCreateTag() {
	const [result, loading, error, do_create] = useRequest<SearchTagDto>(
		(dto: InsertSearchTagDto) =>
			new Promise((resolve, reject) => {
				request()
					.post(`${BASE_URL}`, dto)
					.then((res: any) => setTimeout(() => resolve(res), 1500))
					.catch(reject);
			})
	);

	return {
		create: (dto: InsertSearchTagDto) => do_create(dto),
		result,
		error,
		loading,
	};
}

function useRemoveTag() {
	const [result, loading, error, remove] = useRequest<string>(
		(id: string) =>
			new Promise((resolve, reject) => {
				request()
					.delete(`${BASE_URL}/${id}`)
					.then((res: any) => setTimeout(() => resolve(res), 1500))
					.catch(reject);
			})
	);

	return {
		do_remove: (id: string) => remove(id),
		result,
		loading,
		error,
	};
}
