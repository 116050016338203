import { Select } from 'antd';
import { FC } from 'react';

const Option = Select;

interface IProps {
	value: string;
	field: string;
	on_change: (field_name: any, value: string) => void;
}

export const SelectMonth: FC<IProps> = ({ value, field, on_change }) => (
	<Select
		placeholder='Selecione o Mês'
		onChange={(editionFrequency) => on_change(field, editionFrequency)}
		defaultValue=''
		style={{ width: '50%' }}
		value={value}
	>
		<Option value='01'>Janeiro</Option>
		<Option value='02'>Fevereiro</Option>
		<Option value='03'>Março</Option>
		<Option value='04'>Abril</Option>
		<Option value='05'>Maio</Option>
		<Option value='06'>Junho</Option>
		<Option value='07'>Julho</Option>
		<Option value='08'>Agosto</Option>
		<Option value='09'>Setembro</Option>
		<Option value='10'>Outubro</Option>
		<Option value='11'>Novembro</Option>
		<Option value='12'>Dezembro</Option>
	</Select>
);
