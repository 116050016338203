import { Tabs } from 'antd';
import { format, parseISO } from 'date-fns';
import { pt } from 'date-fns/locale';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../lib/components/Loader';
import { UserProfile } from '../../../../lib/model/UserProfile';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { PageSection } from '../../../../lib/shell/PageSection';
import { request } from '../../../../lib/utils/request';
import { UserAddressData } from '../components/UserAddressData';
import { UsersContentsDataTable } from '../components/UserContentsDataTable';
import { UserPersonalData } from '../components/UserPersonalData';
import { UserProfileBreadcrumb } from '../components/UserProfileBreadcrumb';
import { UserProfileDto } from '../dtos/UserProfileDto';

const { TabPane } = Tabs;

export const UserProfilePage: FC = () => {
	const { email } = useParams();
	const BASE_URL = `${process.env.REACT_APP_ENTITY_SEARCH_API}/store-user-profile/${email}`;

	const [user_name, set_user_name] = useState('');

	return (
		<PageLayout
			{...{
				title: 'Usuário',
				key: 'user',
				browser_back: true,
				breadcrumb: () => <UserProfileBreadcrumb {...{user_name}} />,
			}}
		>
			<Loader
				{...{
					request: () => request().get<UserProfileDto>(BASE_URL),
					renderError: () => (
						<p className='text-red-500'>
							Usuário <span className='font-bold'>{email}</span> não cadastrado.
						</p>
					),
					renderChildren: (user_profile: UserProfile) => {
						set_user_name(user_profile.name);
						const birth_date = parseISO(user_profile.birthDate);

						return (
							<Tabs type='card'>
								<TabPane tab='Dados Pessoais' key={user_profile.email}>
									<PageSection {...{ title: 'Informacoes', class_name: 'pb-4' }}>
										<UserPersonalData
											{...{
												picture: user_profile.picture,
												name: user_profile.name,
												email: user_profile.email,
												cpf: user_profile.cpf === '00000000000' ? '' : user_profile.cpf,
												phone:
													user_profile.phone.number !== 99999999
														? `${user_profile.phone.areaCode} ${user_profile.phone.number}`
														: '',
												birthDate:
													user_profile.birthDate !== '1901-10-10'
														? format(birth_date, 'dd/MM/yyyy', { locale: pt })
														: '',
											}}
										/>
									</PageSection>
									<PageSection {...{ title: 'Endereços' }}>
										<UserAddressData
											{...{ work: user_profile.work, address: user_profile.address }}
										/>
									</PageSection>
								</TabPane>

								<TabPane tab='Conteúdos Permitidos' key='contents'>
									<UsersContentsDataTable />
								</TabPane>
							</Tabs>
						);
					},
				}}
			/>
		</PageLayout>
	);
};
