import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { InformativeListPage } from './pages/InformativeListPage';
import { InsertInformativePage } from './pages/InsertInformativePage';
import { UpdateInformativePage } from './pages/UpdateInformativePage';
import { InformativeListTypesPage } from './pages/InformativeListTypesPage';

export const InformativesRoutes: FC = () => (
	<Routes>
		<Route {...{ path: ':type/list/novo', element: <InsertInformativePage /> }} />
		<Route {...{ path: ':type/list/:num_id', element: <UpdateInformativePage /> }} />
		<Route {...{ path: ':type/list/:publish_date', element: <UpdateInformativePage /> }} />
		<Route {...{ path: ':type/list', element: <InformativeListPage /> }} />
		<Route {...{ index: true, path: '/', element: <InformativeListTypesPage /> }} />
	</Routes>
);
