import React from 'react';
import { PublicImage, Quality } from '../../../components/PublicImage';
import { Tag } from '../../../model/Tag';
import { useBookEdition } from '../hooks/useBookEdition';

interface IProps {
	tags?: Tag[];
	id?: string;
	title?: string;
	quality: Quality;
	img_props?: any;
}

export const EditionCover: React.FC<IProps> = ({ tags = [], id = '', title, quality, img_props = {} }) => {
	const { get_num_ids_from_tags, get_num_ids_from_id } = useBookEdition();

	if (tags.length > 0) {
		const [err, num_ids] = get_num_ids_from_tags(tags);

		if (err) {
			return null;
		}

		return (
			<PublicImage
				{...{
					quality,
					img_props: {
						...img_props,
						alt: title,
					},
					src: `books/cover/${num_ids?.[0]}/${num_ids?.[1]}/cover.jpg`,
					alt: title,
				}}
			/>
		);
	} else {
		const [err, num_ids] = get_num_ids_from_id(id);

		if (err) {
			return null;
		}

		return (
			<PublicImage
				{...{
					quality,
					img_props: {
						...img_props,
						alt: title,
					},
					src: `books/cover/${num_ids?.[0]}/${num_ids?.[1]}/cover.jpg`,
					alt: title,
				}}
			/>
		);
	}
};
