import { FC } from 'react';
import { FormButtons } from '../../../../../lib/components/Form/FormButtons';
import { FormContainer } from '../../../../../lib/components/Form/FormContainer';
import { FormState } from '../../../../../lib/hooks/useForm/types';
import { ClientConfigFormValues } from '../../hooks/useClientConfigForm';
import { BaseClientConfigForm } from './BaseClientConfigForm';
import { RestrictedForm } from './RestrictedForm';
import { ContractManagersList } from './UnlimitedForm/ContractManagersList';
import { UnlimitedForm } from './UnlimitedForm/UnlimitedForm';

interface IProps {
	form_state: FormState<ClientConfigFormValues>;
	change_field_value: (field: keyof ClientConfigFormValues, value: any) => void;
	add_into: (field: keyof ClientConfigFormValues) => (value: any) => void;
	remove_from: (field: keyof ClientConfigFormValues) => (value: any) => void;
	loading: boolean;
	on_submit: () => void;
}

export const UpdateClientConfigForm: FC<IProps> = ({
	form_state,
	change_field_value,
	add_into,
	remove_from,
	loading,
	on_submit,
}) => (
	<FormContainer>
		<BaseClientConfigForm
			form_state={form_state}
			change_field_value={change_field_value}
			is_updating
			{...{
				add_domain: add_into('domains'),
				remove_domain: remove_from('domains'),
			}}
		/>

		<div className='my-10'>
			<ContractManagersList
				{...{
					contract_managers: form_state.value.contract_managers,
					on_remove: remove_from('contract_managers'),
					on_add: add_into('contract_managers'),
				}}
			/>
		</div>

		{form_state.value.unlimited ? (
			<UnlimitedForm
				{...{
					form_state,
					change_field_value,
					add_into,
					remove_from,
				}}
			/>
		) : (
			<RestrictedForm
				{...{
					form_state,
					on_change: (max_users, max_simultaneous_users) => {
						change_field_value('max_users', max_users);
						change_field_value('max_simultaneous_users', max_simultaneous_users);
					},
				}}
			/>
		)}

		<br />
		<br />

		<FormButtons
			{...{
				disabled: loading || !form_state.dirty,
				go_back_on_cancel: true,
				on_add_button_click: undefined,
				on_update_button_click: on_submit,
			}}
		/>
	</FormContainer>
);
