import { Menu } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import SubMenu from 'antd/lib/menu/SubMenu';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useForumAuth } from '../../features/auth/hooks/useForumAuth';
import { MENU } from './Menu';
import './style.scss';

export const Sidebar: FC = () => {
	const { session } = useForumAuth();

	const is_allowed = (roles: string[] = []) => {
		if (roles.length === 0) {
			return true;
		}

		return session.roles.some((role: string) => roles.includes(role));
	};

	return (
		<Sider className='shell-sidebar shell-sidebar-content'>
			<div className='px-12 pt-6 mb-4'>
				<img src={'/img/logo.png'} alt='Editora Fórum' className='w-24' />
			</div>
			<Menu mode='inline' key={'menu'} defaultOpenKeys={['plat', 'store', 'contents', 'tags']}>
				{MENU.map((item) => (
					<>
						{is_allowed(item.roles) ? (
							<SubMenu key={item.key} title={item.name}>
								{(item.items || [])
									.filter((item) => is_allowed(item.roles))
									.map((item) => (
										<>
											{item.items ? (
												<SubMenu key={item.key} title={item.name}>
													{item.items.map((i) => (
														<Menu.Item key={i.key}>
															<Link to={i.href || '/'}>{i.name}</Link>
														</Menu.Item>
													))}
												</SubMenu>
											) : (
												<Menu.Item key={item.key}>
													<Link to={item.href || '/'}>{item.name}</Link>
												</Menu.Item>
											)}
										</>
									))}
							</SubMenu>
						) : null}
					</>
				))}
			</Menu>
		</Sider>
	);
};
