// @ts-nocheck
import { defaultTo, equals, filter, find, length, map, pipe, prop, split, when } from 'ramda';
import { ContentType } from '../../../model/ContentType';
import { Result, success, is_ok, get_value, not_found_result } from '../../../model/Result';
import { Tag } from '../../../model/Tag';

export function useBookEdition() {
	return {
		get_id_from_tags,
		get_num_ids_from_tags,
		get_num_ids_from_id,
	};
}

const extract_numbers = pipe(
	split('_'),
	prop(1),
	defaultTo(''),
	split('-'),
	map((num) => +num),
	filter((num) => !!num)
);

const get_id_from_tags = (tags: Tag[]): Result<string> => {
	const tag_id = pipe(
		defaultTo([]),
		map(prop('id')),
		filter((id: string) => id.startsWith(ContentType.Book + '_')),
		find(pipe(extract_numbers, length, equals(2)))
	)(tags);

	if (!tag_id) {
		return not_found_result;
	}

	return success(tag_id);
};

const get_num_ids_from_tags = (tags: Tag[]): Result<[number, number]> =>
	pipe(
		get_id_from_tags,

		when(is_ok, pipe(get_value, extract_numbers, success))
	)(tags);

const get_num_ids_from_id = (id: string) =>
	get_num_ids_from_tags([
		{
			id,
			title: '-',
		},
	]);
