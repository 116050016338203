import { FC, useState } from 'react';
import { SearchableProvider } from '../../../../lib/components/SearchableDataTable/SearchableProvider';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { ContentList } from '../components/ContentList';
import { ContentListInsertModal } from '../components/ContentListInsertModal';

export const ContentListPage: FC = () => {
	const [visible_form_insert, set_visible_form_insert] = useState(false);

	return (
		<PageLayout {...{ title: 'Conteúdos Liberados' }}>
			<SearchableProvider
				{...{
					entity_name: 'content-assignment-list',
					default_query: {
						limit: 20,
						page: 1,
						sort: 'date1',
						sort_dir: 'desc',
						tags: '',
						text: '',
					},
				}}
			>
				<ContentList {...{ set_visible_form_insert: () => set_visible_form_insert(true) }} />
			</SearchableProvider>

			<ContentListInsertModal
				{...{
					visible: visible_form_insert,
					close: () => set_visible_form_insert(false),
				}}
			/>
		</PageLayout>
	);
};
