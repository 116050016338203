import { useForm } from '../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../lib/hooks/useForm/validators';
import { SectionDisplay } from '../../../../../lib/model/Section';

export type SectionFormArgs = {
	title: string;
	display: SectionDisplay;
};

export function useSectionForm() {
	const form = useForm<SectionFormArgs>(
		{
			title: '',
			display: SectionDisplay.CAROUSEL || SectionDisplay.GRID,
		},
		function validate_form(state) {
			return {
				title: form_validators.required_text_field(state.value.title),
				display: form_validators.required_text_field(state.value.display),
			};
		}
	);

	return form;
}
