import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ChapterListPage } from './pages/ChapterListPage';
import { InsertChapterPage } from './pages/InsertChapterPage';
import { UpdateChapterPage } from './pages/UpdateChapterPage';

export const ChapterRoutes: FC = () => (
	<Routes>
		<Route {...{ path: ':chapter_id', element: <UpdateChapterPage /> }} />
		<Route {...{ path: 'novo', element: <InsertChapterPage /> }} />
		<Route {...{ index: true, path: '/', element: <ChapterListPage /> }} />
	</Routes>
);
