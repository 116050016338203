import { SortAscendingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { CSSProperties, FC } from 'react';

interface IProps {
	onClick: () => void;
	disabled?: boolean;
	style?: CSSProperties;
}

export const OrderButton: FC<IProps> = ({ onClick, disabled = false, style }) => (
	<Button {...{ type: 'dashed', onClick, disabled, style }}>
		<div className='flex items-center'>
			<SortAscendingOutlined style={{ marginRight: '0.3rem' }} /> Ordenar
		</div>
	</Button>
);
// "default", "primary", "ghost", "dashed", "link", "text"
