import { useRequest } from '../../../hooks';
import { request } from '../../../utils/request';

const { REACT_APP_CONTENT_GROUP_API } = process.env;
const BASE_URL = `${REACT_APP_CONTENT_GROUP_API}`;

export function useContentGroupApi() {
	const remove = useRemove();
	const create = useCreate();
	const update = useUpdate();
	const content_group = useGetContentGroup();
	const update_from_isbns = useUpdateSpotlightfromISBNs();

	return {
		update_from_isbns,
		content_group,
		create,
		remove,
		update,
	};
}

export const create_new_content_group = () => request().post<ContentGroupDto>(BASE_URL);

function useGetContentGroup() {
	const [result, loading, error, get] = useRequest<ContentGroupDto>((id: string) =>
		request().get(`${process.env.REACT_APP_CONTENT_GROUP_API}/${id}`)
	);

	return {
		do_get: (id: string) => get(id),
		result,
		error,
		loading,
	};
}

function useCreate() {
	const [result, loading, error, do_create] = useRequest<ContentGroupDto>(() => request().post(BASE_URL));

	return {
		do_create,
		result,
		error,
		loading,
	};
}

function useUpdate() {
	const [result, loading, error, update] = useRequest<ContentGroupDto>((id: string, dto: ContentDto[]) =>
		request().put(`${BASE_URL}/${id}`, dto)
	);

	return {
		do_update: (id: string, dto: ContentDto[]) => update(id, dto),
		result,
		error,
		loading,
	};
}

function useUpdateSpotlightfromISBNs() {
	const [result, loading, error, update] = useRequest<ContentGroupDto>((id: string, dto: string[]) =>
		request().put(`${BASE_URL}/${id}/isbns`, dto)
	);

	return {
		do_update: (id: string, dto: string[]) => update(id, dto),
		result,
		error,
		loading,
	};
}

function useRemove() {
	const [result, loading, error, remove] = useRequest<string>((id: string) =>
		request().delete(`${BASE_URL}/${id}`)
	);

	return {
		do_remove: (id: string) => remove(id),
		result,
		loading,
		error,
	};
}

export type ContentDto = {
	id: string;
	title: string;
	authors: string;
};

type ContentGroupDto = {
	id: string;
	contents: ContentDto[];
};
