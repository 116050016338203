import { validate_email } from '../utils/validate_email';
import { MultiLineInput } from './MultilineInput';

interface IProps {
	on_submit: (email: string[]) => void;
}

export const MultiEmailInput: React.FC<IProps> = ({ on_submit }) => (
	<MultiLineInput
		{...{
			on_submit: (emails: string[]) => {
				on_submit(emails.map((email) => email.toLowerCase().trim()));
			},
			validate_fn: (email: string) => {
				if (validate_email(email)) {
					return '';
				}

				return `${email} inválido.`;
			},
		}}
	/>
);
