import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UploaderImage } from '../../../../../lib/components/UploadMultipleResolutionImages/UploaderImage';
import { extract_num_id_from_id } from '../../utils/extract_num_id_from_id';

interface IProps {
	src_img: string;
}

export const UploadImage: FC<IProps> = ({ src_img }) => {
	const [show_img, set_show_img] = useState(true);

	const { id: periodic_id, edition_id } = useParams() as { id: string; edition_id: string };

	const periodicNumId = extract_num_id_from_id(periodic_id);
	const editionNumId = extract_num_id_from_id(edition_id);

	const image_key = `periodics/cover/${periodicNumId}/${periodicNumId}.jpg`;

	return (
		<div className=' flex-col'>
			<div className='block'>
				{show_img && (
					<img
						{...{
							src: `${src_img}?d=${Date.now()}`,
							style: {
								maxHeight: 300,
							},
							alt: `${src_img}`,
						}}
						onError={(e: any) => {
							e.target.onerror = null;
							e.target.src = `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/periodics/cover/default/no-image.png`;
						}}
					/>
				)}
			</div>

			<div className='mt-2'>
				<UploaderImage
					{...{
						upload_api_endpoint: `${process.env.REACT_APP_UPLOAD_IMAGE_API}`,
						image_key,
						on_success: () => {
							set_show_img(false);
							setTimeout(() => {
								try {
									set_show_img(true);
								} catch (error) {}
							}, 500);
						},
					}}
				/>
			</div>
		</div>
	);
};
