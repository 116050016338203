import { FC } from 'react';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { PeriodicsBreadcrumb } from '../components/PeriodicsBreadcrumb';
import { PeriodicForm } from '../components/form/PeriodicForm';

export const InsertPeriodicPage: FC = () => (
	<PageLayout
		{...{ title: 'Inserir Revista', browser_back: true, breadcrumb: () => <PeriodicsBreadcrumb /> }}
	>
		<PeriodicForm />
	</PageLayout>
);
