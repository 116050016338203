import { useForm } from '../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../lib/hooks/useForm/validators';
import { Tag } from '../../../../../lib/model/Tag';

export type FormValues = {
	num_id: number;
	title: string;
	description: string;
	event_date: string;
	video_serie: Tag;
	image?: string;
	url?: string;
	keyword_tags: Tag[];
	area_interest_tags: Tag[];
	author_tags: Tag[];
	theme_tags: Tag[];
	event_tags: Tag[];
};

export function useVideoForm() {
	const form = useForm<FormValues>(
		{
			num_id: 0,
			title: '',
			description: '',
			event_date: '',
			video_serie: { id: '', title: '' },
			image: '',
			url: '',
			keyword_tags: [],
			area_interest_tags: [],
			author_tags: [],
			theme_tags: [{ id: '', title: '' }],
			event_tags: [{ id: '', title: '' }],
		},
		function validate_form(state) {
			return {
        num_id: '',
				title: form_validators.required_text_field(state.value.title),
				description: form_validators.required_text_field(state.value.description),
				event_date: form_validators.required_text_field(state.value.event_date),
				video_serie: '',
				keyword_tags: form_validators.required_list_field(state.value.keyword_tags),
				area_interest_tags: form_validators.required_list_field(state.value.area_interest_tags),
				author_tags: form_validators.required_list_field(state.value.author_tags),
				theme_tags: '',
				event_tags: '',
			};
		}
	);

	return form;
}
