import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../lib/components/Loader';
import { Section } from '../../../../lib/model/Section';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { SectionBreadcrumb } from '../components/Breadcrumb';
import { UpdateSection } from '../components/UpdateSection';
import { SectionDto } from '../dtos/SectionDto';

export const UpdateSectionListPage: FC = () => {
	const { id } = useParams();
	const [section_title, set_section_title] = useState('');

	return (
		<PageLayout
			{...{
				title: 'Atualizar Seçao',
				key: 'content-release',
				browser_back: true,
				breadcrumb: () => <SectionBreadcrumb {...{ section_title }} />,
			}}
		>
			<Loader
				{...{
					request: () => request().get<SectionDto>(`${process.env.REACT_APP_SECTIONS_API}/${id}`),
					renderChildren: (section: Section) => {
						set_section_title(section.title);

						return <UpdateSection {...{ section }} />;
					},
				}}
			/>
		</PageLayout>
	);
};
