import { Content } from 'antd/lib/layout/layout';
import { FC, ReactNode } from 'react';
import { Topbar } from './Topbar';

interface IProps {
	browser_back?: boolean;
	back_href?: string;
	breadcrumb?: () => ReactNode;
	title: string;
	children: ReactNode;
}

export const PageLayout: FC<IProps> = ({ back_href, breadcrumb, title, children, browser_back }) => (
	<div className='flex flex-col h-screen w-full'>
		<Topbar {...{ browser_back, back_href, breadcrumb, title }} />

		<Content className='p-6 flex-1 overflow-auto'>{children}</Content>
	</div>
);
