import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../lib/components/Loader';
import { EditionEntity } from '../../../../../lib/model/Book';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../lib/utils/request';
import { extract_num_id_from_id } from '../../utils/extract_num_id_from_id';
import { EditionsBreadcrumb } from '../components/EditionsBreadcrumb';
import { EditionForm } from '../components/form/EditionForm';
import { EditionDto } from '../dto/EditionDto';

export const UpdateEditionPage: FC = () => {
	const { id: book_id, edition_id } = useParams() as { id: string; edition_id: string };

	const book_num_id = extract_num_id_from_id(book_id);
	const edition_num_id = extract_num_id_from_id(edition_id);

	const BASE_URL = `${process.env.REACT_APP_BOOKS_API}/books/${book_num_id}/editions/${edition_num_id}`;

	return (
		<PageLayout
			{...{
				title: 'Atualizar Edicao',
				browser_back: true,
				breadcrumb: () => <EditionsBreadcrumb {...{ book_id, in_editions: true, edition_id }} />,
			}}
		>
			<Loader
				{...{
					request: () => request().get<EditionDto>(BASE_URL),
					renderChildren: (edition: EditionEntity) => <EditionForm {...{ edition }} />,
				}}
			/>
		</PageLayout>
	);
};
