import { Alert, Input, message, Tabs } from 'antd';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormButtons } from '../../../../../../../lib/components/Form/FormButtons';
import { FormContainer } from '../../../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../../../lib/components/Form/FormField';
import { MultipleTagSelect } from '../../../../../../../lib/components/MultipleTagSelect';
import { UploaderPDF } from '../../../../../../../lib/components/UploaderPDF';
import { useRequestFeedback } from '../../../../../../../lib/hooks';
import { ItemEntity, ItemTypes, SignedUrlTypes } from '../../../../../../../lib/model/Periodic';
import { extract_num_id_from_id } from '../../../../utils/extract_num_id_from_id';
import { InsertItemDto } from '../../dto/InsertItemDto';
import { UpdateItemDto } from '../../dto/UpdateItemDto';
import { useItemsApi } from '../../hooks/useItemsApi';
import { SelectItemType } from '../SelectItemType';
import { TextFromPdfModal } from '../TextFromPdfModal';
import { useItemForm } from './useItemForm';
import { createBrowserHistory } from 'history';
import { FilePdfOutlined } from '@ant-design/icons';
import { UpdateItemContentTextDto } from '../../dto/UpdateItemContentTextDto';
import { UpdateItemText } from '../../dto/UpdateItemText';

const class_name_field = 'w-1/2';

interface IProps {
	item?: ItemEntity;
}

export const ItemForm: FC<IProps> = ({ item }) => {
	const history = createBrowserHistory();
	const { TabPane } = Tabs;

	const {
		id: periodicId,
		editionId,
		itemId,
	} = useParams() as { id: string; editionId: string; itemId: string };
	const periodicNumId = String(extract_num_id_from_id(periodicId)).toUpperCase();
	const editionNumId = String(extract_num_id_from_id(editionId)).toUpperCase();
	const itemNumId = extract_num_id_from_id(itemId);
	const BASE_URL = `${process.env.REACT_APP_PERIODIC_API}/periodics/${periodicNumId}/editions/${editionNumId}/items/${itemNumId}/pdf`;

	const { form_state, show_errors, change_field_value, fill_with_data } = useItemForm();

	const { insert, update, get_last_id, read_pdf, update_text } = useItemsApi();

	const update_pdf_text = async (pdf_text: UpdateItemText[]) => {
		const dto: UpdateItemContentTextDto[] = pdf_text.map((t: UpdateItemText) => ({
			page: t.page,
			content: t.text,
		}));
		await update_text.do_update(dto);
	};

	useEffect(() => {
		const pdf_text = read_pdf.result as UpdateItemText[];
		if (pdf_text?.length) {
			update_pdf_text(pdf_text);
		}
	}, [read_pdf.result]);

	useEffect(() => {
		const keywords = item?.keywords?.length ? item?.keywords.filter((k) => !!k) : [];
		const authors = item?.authors?.length ? item?.authors.filter((k) => !!k) : [];
		const themes = item?.themes?.length ? item?.themes.filter((k) => !!k) : [];
		fill_with_data({
			numId: item?.numId || '',
			title: item?.title || '',
			itemType: item?.itemType || ItemTypes.Other,
			keywords,
			authors,
			themes,
		});
		if (!item) {
			get_last_id.get();
		}
	}, [item]);

	useEffect(() => {
		if (get_last_id.result) {
			const last_num_id = Number(String(get_last_id.result.numId).replace(/ /g, ''));
			if (!form_state.value.numId) {
				const keywords = item?.keywords?.length ? item?.keywords.filter((k) => !!k) : [];
				const authors = item?.authors?.length ? item?.authors.filter((k) => !!k) : [];
				const themes = item?.themes?.length ? item?.themes.filter((k) => !!k) : [];
				fill_with_data({
					numId: last_num_id ? `${last_num_id + 1}` : '',
					title: item?.title || '',
					itemType: item?.itemType || ItemTypes.Other,
					keywords,
					authors,
					themes,
				});
			}
		}
	}, [get_last_id.result]);

	function submit() {
		show_errors();

		const dto: UpdateItemDto | InsertItemDto = {
			...form_state.value,
			numId: String(form_state.value.numId),
		};

		if (!form_state.valid) {
			return;
		}

		return item ? update.do_update(dto) : insert.create(dto);
	}

	useEffect(() => {
		if (update.result || insert.result) {
			message.success('Salvo com sucesso.');
			const itemId = update.result?.numId || insert.result?.numId;
			history.push(`/conteudos/revistas/${periodicNumId}/edicoes/${editionNumId}/itens/${itemId}`);
			window.location.reload();
		}
	}, [update.result, insert.result]);

	useRequestFeedback(update.result, update.error);
	useRequestFeedback(insert.result, insert.error);

	return (
		<Tabs type='card'>
			<TabPane tab='Informações' key='infos'>
				<FormContainer>
					{item ? (
						<>
							<Alert
								message={`É necessário republicar a edição para que as alterações reflitam na plataforma.`}
								type='warning'
							/>
							<br />
						</>
					) : (
						<></>
					)}
					<FormField {...{ label: 'Identificador Numérico', name: 'numId', form_state }}>
						<Input
							value={form_state.value.numId}
							type={'number'}
							onChange={(event) => change_field_value('numId', event.target.value)}
							className={class_name_field}
							disabled={true}
						/>
					</FormField>

					<FormField {...{ label: 'Título', name: 'title', form_state }}>
						<Input
							value={form_state.value.title}
							onChange={(event) => change_field_value('title', event.target.value)}
							className={class_name_field}
						/>
					</FormField>

					<FormField {...{ label: 'Tipo', name: 'itemType', form_state }}>
						<SelectItemType {...{ value: form_state.value.itemType, on_change: change_field_value }} />
					</FormField>

					<h2 className='text-lg mt-10 mb-5'>Taxonomias</h2>

					<div className='bg-gray-50 p-5'>
						<MultipleTagSelect
							{...{
								form_state,
								label: 'Palavras-Chave',
								name: 'keywords',
								on_change: (keywords) => change_field_value('keywords', keywords),
								values: form_state.value.keywords,
								tag_prefix: 'kwrd',
								class_name: class_name_field,
							}}
						/>
					</div>

					<div className='bg-gray-100 bg- p-5'>
						<MultipleTagSelect
							{...{
								form_state,
								required: false,
								label: 'Autores',
								name: 'authors',
								on_change: (authors) => change_field_value('authors', authors),
								values: form_state.value.authors,
								tag_prefix: 'author',
								class_name: class_name_field,
							}}
						/>
					</div>

					<div className='bg-gray-50 p-5'>
						<MultipleTagSelect
							{...{
								form_state,
								required: false,
								label: 'Temas Específicos',
								name: 'themes',
								on_change: (themes) => change_field_value('themes', themes),
								values: form_state.value.themes,
								tag_prefix: 'theme',
								class_name: class_name_field,
							}}
						/>
					</div>
					<FormButtons
						{...{
							disabled: !form_state.valid,
							on_update_button_click: item ? submit : undefined,
							go_back_on_cancel: true,
							on_add_button_click: !item ? submit : undefined,
						}}
					/>
				</FormContainer>
			</TabPane>

			<TabPane tab='PDF' key='pdf'>
				{item ? (
					<>
						<FormField {...{ label: 'PDF', name: 'articleKey', form_state }}>
							<UploaderPDF
								{...{
									on_success: () => {
										read_pdf.get();
										return message.success('Documento salvo com sucesso.');
									},
									upload_api_endpoint: `${BASE_URL}/${SignedUrlTypes.WRITE}`,
								}}
							/>
						</FormField>
						{item.articleKey && (
							<FormField {...{ label: 'Download', name: 'key', required: false, form_state }}>
								<a href={item.articleKey} target='_blank' className='block mt-2 mb-1'>
									<div className='flex items-center'>
										<FilePdfOutlined style={{ marginRight: '0.3rem' }} />{' '}
										{String(item.articleKey).split('/')[6].split('.')[0]}
									</div>
								</a>
							</FormField>
						)}
						<FormField {...{ label: 'Texto do PDF', name: 'txt', form_state }}>
							<TextFromPdfModal />
						</FormField>
					</>
				) : (
					<div className='block'>
						Por favor, salve os dados iniciais antes de realizar o upload de um PDF.
					</div>
				)}
			</TabPane>
		</Tabs>
	);
};
