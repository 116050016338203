import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Radio } from 'antd';
import React from 'react';
import { SortDirection, SortField } from './model/SearchableQueryDto';

interface IProps {
	date_fields?: string[];
	sortable_fields?: string[];
	on_change: (sort_field: SortField, sort_dir: SortDirection) => any;
	sort_field: SortField;
	sort_dir: SortDirection;
}

export const SortFields: React.FC<IProps> = ({
	date_fields = [],
	sortable_fields = [],
	on_change,
	sort_field,
	sort_dir,
}) => {
	return (
		<div className='py-2 flex items-center'>
			<span className=' text-xs  text-gray-500 uppercase'>Ordenado por: </span>
			<div className='ml-4 flex flex-row'>
				<Radio.Group
					{...{
						buttonStyle: 'solid',
						value: sort_field,
						onChange: (e) => on_change(e.target.value, sort_dir),
					}}
				>
					{date_fields.map((f, i) => (
						<Radio.Button
							className='uppercase'
							{...{
								type: 'primary',
								key: `date${i + 1}`,
								value: `date${i + 1}`,
							}}
						>
							{f}
						</Radio.Button>
					))}
					{sortable_fields.map((f, i) => (
						<Radio.Button
							className='uppercase'
							{...{
								type: 'primary',
								key: `sort${i + 1}`,
								value: `sort${i + 1}`,
							}}
						>
							{f}
						</Radio.Button>
					))}
				</Radio.Group>

				<Button
					className='ml-4'
					icon={sort_dir === 'asc' ? <UpOutlined /> : <DownOutlined />}
					{...{
						onClick: () => {
							if (sort_dir === 'asc') {
								return on_change(sort_field, 'desc');
							}

							on_change(sort_field, 'asc');
						},
					}}
				/>
			</div>
		</div>
	);
};
