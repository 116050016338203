import { PlusOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
import axios from 'axios';
import { FC, ReactNode } from 'react';
import { useRequest, useRequestFeedback } from '../../hooks';
import { request } from '../../utils/request';
import './style.scss';

interface IProps {
	upload_api_endpoint: string;
	accept: string;
	upload_success: () => void;
	class_name?: string;
	children?: ReactNode;
}

export const ChapterPdfUploader: FC<IProps> = ({
	upload_api_endpoint,
	accept,
	upload_success,
	class_name,
	children,
}) => {
	const [signed_url, , error_signed, get_signed_url, set_signed_url] = useRequest(() =>
		request().get<string>(upload_api_endpoint)
	);

	useRequestFeedback('', error_signed);

	return (
		<div className={`flex flex-col ${class_name}`}>
			<Upload
				{...{
					method: 'PUT',
					accept,
					multiple: false,
					name: 'file',
					action: signed_url,

					beforeUpload() {
						return get_signed_url();
					},

					customRequest: ({ file, onSuccess, onError, action }: any) => {
						const config = {
							headers: {
								'Content-Type': file.type,
							},
						};
						axios
							.put(action, file, config)
							.then((res) => {
								onSuccess(res.data, file);
							})
							.catch((err: Error) => {
								onError(err);
							});
					},
					onChange(info: any) {
						if (info.file.status === 'done') {
							message.success(`${info.file.name} upload executado com sucesso.`);
							set_signed_url('');

							upload_success();
						} else if (info.file.status === 'error') {
							message.error(`${info.file.name} erro no upload.`);
						}
					},
				}}
			>
				{children || (
					<div className='flex flex-col bg-gray-100 hover:bg-gray-200 border border-dashed border-gray-300 w-48 h-40 items-center'>
						<span className='flex flex-row mt-12 items-center pb-4'>
							<PlusOutlined style={{ color: 'green', fontSize: 17 }} />
						</span>
						Clique ou Arraste
					</div>
				)}
			</Upload>
		</div>
	);
};
