import { Tabs } from 'antd';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../lib/components/Loader';
import { UploadMultipleResolutionImages } from '../../../../lib/components/UploadMultipleResolutionImages/UploadMultipleResolutionImages';
import { ContentGroupDataTable } from '../../../../lib/features/content_group/ContentGroupDataTable';
import { Highlight } from '../../../../lib/model/Highlight';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { HighlightForm } from '../components/HighlightForm';
import { HighlightsBreadcrumb } from '../components/HighlightsBreadcrumb';
import { HighlightDto } from '../dtos/HighlightDto';

const BASE_URL = process.env.REACT_APP_HIGHLIGHTS_API;

const { TabPane } = Tabs;

export const HighlightPage: FC = () => {
	const { id } = useParams();
	const [highlight_title, set_highlight_title] = useState('');

	return (
		<PageLayout
			{...{
				title: 'Destaque',
				breadcrumb: () => <HighlightsBreadcrumb {...{ highlight_title }} />,
				browser_back: true,
			}}
		>
			<Loader
				{...{
					request: () => request().get<HighlightDto>(`${BASE_URL}/${id}`),
					renderChildren: (highlight: Highlight) => {
						set_highlight_title(highlight.name);

						return (
							<Tabs type='card'>
								<TabPane tab='Atualizar Destaque' key='highlight'>
									<HighlightForm {...{ highlight }} />
								</TabPane>

								<TabPane tab='Imagens' key='img'>
									<UploadMultipleResolutionImages
										{...{
											src_img: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/store/highlights/${id}`,
											upload_api_endpoint: `${process.env.REACT_APP_HIGHLIGHTS_API}/upload-url/${id}`,
										}}
									/>
								</TabPane>

								<TabPane tab='Conteúdos' key='contents'>
									<ContentGroupDataTable {...{ id: highlight.content_group_id }} />
								</TabPane>
							</Tabs>
						);
					},
				}}
			/>
		</PageLayout>
	);
};
