import React from 'react';
import { Tag } from '../../model/Tag';
import { SearchQueryDto } from './model/SearchQueryDto';

export interface IEntity {
	id: string;
	type: string;
	path: string;
	title: string;
	subtitle: string;
	description: string;
	publish_date: string;
	tags: Tag[];
	authors: { id: string; title: string }[];
	price: any;
}

interface ISearchContext {
	result: {
		total: number;
		entities: IEntity[];
	};
	loading: boolean;
	error: any;
	reload: () => void;
	query: {
		change: (search_query: SearchQueryDto) => any;
		text: string;
		change_text: (text: string) => any;
		tags: string[];
		change_tags: (tags: string) => any;
		groups: string[];
		change_groups: (groups: string) => any;
		sort_by: string;
		change_sort_by: (dir: 'publish_date' | 'relevance') => any;
		default: boolean;
		clear: () => void;
		page: number;
		change_page: (page_number: number) => any;
	};
}

export const SearchContext = React.createContext({} as ISearchContext);
