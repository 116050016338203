import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	num_id?: string;
	in_informative?: boolean;
	new_informative?: boolean;
}

export const InformativeBreadcrumb: FC<IProps> = ({ num_id, in_informative, new_informative }) => (
	<Breadcrumb>
		{in_informative && (
			<Breadcrumb.Item key='num_id'>
				<Link to={`/conteudos/informativos/`}>Informativos</Link>
			</Breadcrumb.Item>
		)}

		{new_informative && <Breadcrumb.Item key='new_informative'>novo</Breadcrumb.Item>}

		{num_id && (
			<Breadcrumb.Item key='num_id'>
				<Link to={`/conteudos/informativos/${num_id}`}>{num_id}</Link>
			</Breadcrumb.Item>
		)}
	</Breadcrumb>
);
