import { Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import { AddButton } from '../../../../../lib/components/Buttons/AddButton';
import { InputItem } from '../../../../../lib/components/InputItem';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { InsertSearchTagDto } from '../dto/SearchTagDto';
import { useSearchTagsApi } from '../hooks/SearchTagsApi';

interface IProps {
	visible: boolean;
	close: () => void;
	insert_success: () => void;
	prefix: string;
	tag_name_in_portuguese: string;
}

export const SearchTagInsertModal: FC<IProps> = ({
	visible,
	close,
	insert_success,
	prefix,
	tag_name_in_portuguese,
}) => {
	const [tag_title, set_tag_title] = useState('');
	const { insert } = useSearchTagsApi();

	const submit = (dto: InsertSearchTagDto) => {
		if (dto.title) insert.create({ title: dto.title, prefix: dto.prefix });
	};

	useRequestFeedback(insert.result, insert.error);

	useEffect(() => {
		if (!!insert.result) {
			insert_success();
			close();
			set_tag_title('');
		}
	}, [insert.result]);

	return (
		<Modal
			{...{
				visible,
				centered: true,
				closable: true,
				destroyOnClose: true,
				onCancel: () => close(),
				title: `Criar ${tag_name_in_portuguese}`,
				footer: [
					<AddButton
						{...{ onClick: () => submit({ title: tag_title, prefix }), disabled: !tag_title }}
					/>,
				],
			}}
		>
			<InputItem
				{...{
					label: 'Título',
					on_changed: set_tag_title,
					input_item_name: tag_title,
				}}
			/>
		</Modal>
	);
};
