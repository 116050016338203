import Title from 'antd/lib/typography/Title';
import { CSSProperties, FC, ReactNode } from 'react';
interface IProps {
	title: ReactNode;
	children: ReactNode;
	class_name?: string;
	style?: CSSProperties;
}

export const PageSection: FC<IProps> = ({ children, title, class_name, style }) => (
	<section {...{ className: `flex flex-col ${class_name}`, style }}>
		<div className='mb-3'>
			<Title level={4} style={{ marginBottom: 0 }}>
				{title}
			</Title>
		</div>
		<div className={'h-full'}>{children}</div>
	</section>
);
