import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	version_date: string;
}

export const PricesFilesListBreadcrumb: FC<IProps> = ({ version_date }) => (
	<Breadcrumb>
		<Breadcrumb.Item key='home'>
			<Link {...{ to: '/loja/precos' }}>Preços</Link>
		</Breadcrumb.Item>
		<Breadcrumb.Item key='price'>{version_date}</Breadcrumb.Item>
	</Breadcrumb>
);
