import { FC } from 'react';
import { Loader } from '../../../../lib/components/Loader';
import { request } from '../../../../lib/utils/request';

interface IProps {
	version_date: number;
}

export const PriceVersionLog: FC<IProps> = ({ version_date }) => (
	<Loader
		{...{
			request: () =>
				request().get<string>(`${process.env.REACT_APP_PRICING_ADMIN_API}/${version_date}/log`),
			renderChildren: (log: string) => (
				<pre className='border-2 border-gray-200 p-4 bg-gray-800 text-yellow-200 leading-loose text-xs overflow-x-auto'>
					{log}
				</pre>
			),
		}}
	/>
);
