import Modal from 'antd/lib/modal/Modal';
import { FC, useState } from 'react';
import { InsertPriceVersionForm } from './InsertPriceVersionForm';
import { PricesXlsxUploader } from './PricesXlsxUploader';
interface IProps {
	on_success: () => void;
	on_cancel: () => void;
}

export const InsertPriceVersionSteps: FC<IProps> = ({ on_success, on_cancel }) => {
	const [version_date] = useState(Date.now());
	const [current_step, set_current_step] = useState<1 | 2>(1);

	const step1 = <PricesXlsxUploader {...{ version_date, on_success: () => set_current_step(2) }} />;

	const step2 = <InsertPriceVersionForm {...{ version_date, on_success }} />;

	return (
		<Modal
			{...{
				title: current_step === 1 ? 'Enviar Arquivo (.xlsx)' : 'Criar Versão',
				visible: true,
				centered: true,
				width: '23rem',
				onCancel: on_cancel,
				children: <div className='flex flex-col items-center'>{current_step === 1 ? step1 : step2}</div>,
				footer: null,
			}}
		/>
	);
};
