import { format } from 'date-fns';
import { useForm } from '../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../lib/hooks/useForm/validators';

type FormValues = {
	num_id: string;
	publish_date: number;
	pdf?: string;
	html?: string;
	is_published?: any;
};

export function useInformativeForm() {
	const form = useForm<FormValues>(
		{
			num_id: '',
			publish_date: Date.now(),
		},
		function validate_form(state) {
			return {
				num_id: '',
				publish_date: form_validators.required_text_field(String(state.value.publish_date)),
			};
		}
	);

	return form;
}
