import { FC } from 'react';
import { FormButtons } from '../../../../../../lib/components/Form/FormButtons';
import { EditionEntity } from '../../../../../../lib/model/Periodic';

interface IProps {
	edition?: EditionEntity;
	submit: () => void;
	disabled: boolean;
}

export const EditionFormButtons: FC<IProps> = ({ edition, submit, disabled }) => {
	return (
		<FormButtons
			{...{
				disabled,
				go_back_on_cancel: true,
				on_add_button_click: edition ? undefined : () => submit(),
				on_update_button_click: edition ? () => submit() : undefined,
				on_action_button_click: edition ? () => {} : undefined,
				action_button_text: edition ? 'Itens' : undefined,
				action_button_route: edition
					? `/conteudos/revistas/${edition?.periodicNumId}/edicoes/${edition?.numId}/itens`
					: undefined,
			}}
		/>
	);
};
