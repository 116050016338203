import React from 'react';

export const BookSerieIcon = (props: any) => (
	<svg viewBox='0 0 512 512' className='w-4 h-4 fill-current' {...props}>
		<path
			d='M286.118,606.164c15.637,0,28.329-12.55,28.329-28.644V34.5c0-15.837-12.893-28.672-28.329-28.672h-86.272
			c-15.637,0.028-28.329,12.578-28.329,28.672v542.993c0,15.837,12.893,28.644,28.329,28.644h86.272V606.164z M200.045,34.557
			c0-0.229,85.816-0.057,85.816-0.057c-0.257,0-0.2,543.021-0.2,543.021c0,0.229-85.815,0.058-85.815,0.058
			C200.074,577.578,200.045,34.557,200.045,34.557z'
		/>
		<path
			d='M507.689,607.107l83.357-22.326c15.121-4.059,24.098-19.467,19.952-35.018L470.441,25.266
			c-4.088-15.293-19.867-24.355-34.789-20.354l-83.357,22.326c-15.122,4.059-24.098,19.467-19.953,35.018l140.529,524.498
			C477.017,602.048,492.768,611.081,507.689,607.107z M442.827,32.641c-0.229,0.058,140.358,524.555,140.358,524.555
			c0.057,0.229-82.871,22.269-82.871,22.269c0.229-0.058-140.358-524.555-140.358-524.555
			C359.87,54.71,442.827,32.641,442.827,32.641z'
		/>
		<path
			d='M114.602,606.164c15.637,0,28.329-12.578,28.329-28.672V34.5c0-15.837-12.893-28.672-28.329-28.672H28.329
			C12.692,5.856,0,18.405,0,34.5v542.993c0,15.837,12.893,28.644,28.329,28.644h86.273V606.164z M28.529,34.557
			c0-0.229,85.815-0.057,85.815-0.057c-0.257,0-0.2,543.021-0.2,543.021c0,0.229-85.816,0.058-85.816,0.058
			C28.558,577.578,28.529,34.557,28.529,34.557z'
		/>
	</svg>
);
