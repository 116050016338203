import { UploadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import { Uploader } from '../../components/Uploader/Uploader';
import { ImageResolution } from './model/ImageResolution';

interface IProps {
	on_success: () => void;
	resolution?: ImageResolution;
	upload_api_endpoint: string;
	image_key?: string;
}

export const UploaderImage: React.FC<IProps> = ({
	on_success,
	resolution,
	upload_api_endpoint,
	image_key,
}) => {
	return (
		<Uploader
			{...{
				doc_key: image_key,
				accept: '.jpg',
				upload_api_endpoint: resolution
					? `${upload_api_endpoint}/${resolution}.jpg`
					: `${upload_api_endpoint}`,
				upload_success: () => setTimeout(on_success, 3000),
				class_name: 'items-start mb-2 items-center',
			}}
		>
			<Button
				{...{
					type: 'primary',
					title: 'Fazer upload',
					icon: <UploadOutlined />,
				}}
			>
				Fazer upload
			</Button>
		</Uploader>
	);
};
