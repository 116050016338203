import { FC, useEffect, useState } from 'react';
import { CloseButton } from '../../../../../lib/components/Buttons/CloseButton';
import { Tag } from '../../../../../lib/model/Tag';
import { request } from '../../../../../lib/utils/request';
import { SelectedTags } from './SelectedTags';
import { TagTypeWithColor } from './Tags/tag_type';
import { Card, Input, Select, message } from 'antd';
import { AddButton } from '../../../../../lib/components/Buttons/AddButton';

const BASE_URL = process.env.REACT_APP_PERIODIC_API || '';
const Option = Select;

interface IProps {
	selected_contents: Tag[];
	tag_type: TagTypeWithColor;
	on_change: (tags: Tag[]) => void;
	close: () => void;
}

export const PeriodicTagsForm: FC<IProps> = ({ on_change, tag_type, selected_contents, close }) => {
	const [selected_tags, set_selected_tags] = useState(selected_contents);
	const [is_loading, set_is_loading] = useState(false);
	const [periodics_list, set_periodics_list] = useState<any[]>([]);
	const [periodic, set_periodic] = useState('');
	const [start_number, set_start_number] = useState('');
	const [final_number, set_final_number] = useState('');

	function insert_tag_with_color() {
		if (!periodic || !start_number || !final_number) return;
		const periodicNumId = String(periodic).replace('P', '');
		const p = periodics_list.find((v) => v.numId === periodic);
		const tag: Tag = {
			id: `periodic-${periodicNumId}_edition_${start_number}-${final_number}`,
			title: `Revistas: ${p?.acronym || periodicNumId} - De ${start_number} a ${final_number}`,
		};
		set_selected_tags([...selected_tags, tag]);
		message.success('Adicionado com sucesso.');
		set_periodic('');
		set_start_number('');
		set_final_number('');
	}

	function remove_tag(tag: Tag) {
		const new_contents = selected_tags.filter((content) => content !== tag);

		set_selected_tags(new_contents);
	}

	function select_tags() {
		on_change(selected_tags);
		close();
	}

	const get_tag_type_title = (tag: Tag): string => {
		if (tag?.id?.includes('book')) {
			return tag?.id?.includes('legislacao-comentada') ? `${tag.title}` : `Livros: ${tag.title}`;
		}
		if (tag?.id?.includes('video')) {
			return `Vídeos: ${tag.title}`;
		}
		if (tag?.id?.includes('periodic')) {
			let title = tag?.title || '';
			const tagSplited = String(tag?.id).split('-');
			const idSplited = String(tagSplited?.[1] || '').split('_');
			const periodicId = idSplited?.[0] || '';
			const start_number = idSplited?.[2] || '';
			const final_number = tagSplited?.[2] || '';
			const periodicsArray = periodics_list || [];
			if (periodicId && start_number && final_number && periodicsArray?.length) {
				const p = periodicsArray.find((v) => String(v.numId).replace('P', '') === periodicId);
				title = p?.acronym ? `${p?.acronym} - De ${start_number} a ${final_number}` : title;
			}

			return `Revistas: ${title}`;
		}
		return `${tag.title}`;
	};

	async function getPeriodics() {
		set_is_loading(true);
		const result = await request().get(`${BASE_URL}/periodics`);
		set_periodics_list(result as any[]);
		set_is_loading(false);
	}

	useEffect(() => {
		getPeriodics();
	}, []);

	useEffect(() => {
		on_change(selected_tags);
	}, [selected_tags]);

	return (
		<section className='flex flex-col w-full'>
			<div className='flex flex-row justify-between'>
				<Card
					style={{ background: '#fff', marginTop: 1, width: '100%' }}
					bodyStyle={{ width: '100%' }}
					className='flex flex-row rounded border border-gray-100 p-4 bg-gray-50 flex-wrap max-w-lg'
				>
					<div className='grid grid-cols-2'>
						<div className='col-span-2 block mb-3'>
							<label>
								<span className='text-xs mr-1'>Revista</span>
								<span className='font-semibold text-red-500'>*</span>
							</label>
							<Select
								placeholder='Selecione a revista'
								onChange={(option) => set_periodic(option)}
								defaultValue={periodic}
								style={{ width: '100%' }}
							>
								{periodics_list.map((p) => (
									<Option value={p.numId} key={p.numId}>
										{p.acronym} - {p.title}
									</Option>
								))}
							</Select>
						</div>
						<div className='col-span-1 block mb-3'>
							<label>
								<span className='text-xs mr-1'>Número Inicial</span>
								<span className='font-semibold text-red-500'>*</span>
							</label>
							<Input value={start_number} onChange={(event) => set_start_number(event.target.value)} />
						</div>
						<div className='col-span-1 block mb-3 pl-2'>
							<label>
								<span className='text-xs mr-1'>Número Final</span>
								<span className='font-semibold text-red-500'>*</span>
							</label>
							<Input value={final_number} onChange={(event) => set_final_number(event.target.value)} />
						</div>
						<div className='col-span-2 block mb-3'>
							<AddButton onClick={insert_tag_with_color} />
						</div>
					</div>
				</Card>
			</div>

			{selected_tags.length !== 0 && !is_loading && (
				<SelectedTags {...{ items: selected_tags, remove_tag, get_tag_type_title }} />
			)}
			<div className='flex justify-end mt-8'>
				<CloseButton disabled={selected_tags.length === 0} onClick={select_tags} />
			</div>
		</section>
	);
};
