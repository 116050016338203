import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from '../../../../../lib/components/Loader';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { Clipping } from '../../../../../lib/model/Clipping';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../lib/utils/request';
import { ClippingBreadcrumb } from '../../components/ClippingBreadcrumb';
import { ClippingDto } from '../../dtos/ClippingDtos';
import { ClippingForm } from '../components/ClippingForm/ClippingForm';
import { useClippingsApi } from '../hooks/useClippingsApi';

export const UpdateClippingPage: FC = () => {
	const navigate = useNavigate();

	const { source_id, clipping_id } = useParams();
	const { update } = useClippingsApi();

	if (!!update.result) {
		navigate(`/plataforma/fontes-de-informativos/${source_id}/informativos`);
	}

	useRequestFeedback(update.result, update.error);

	return (
		<PageLayout
			{...{
				title: 'Atualizar Informativo',
				breadcrumb: () => <ClippingBreadcrumb source_id={source_id} />,
				browser_back: true,
			}}
		>
			<Loader
				{...{
					request: () =>
						request().get<ClippingDto>(
							`${process.env.REACT_APP_CLIPPING_API}/sources/${source_id}/clippings/${clipping_id}`
						),

					renderChildren: (clipping: Clipping) => (
						<ClippingForm clipping={clipping} on_submit={update.do_update} />
					),
				}}
			/>
		</PageLayout>
	);
};
