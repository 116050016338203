import { Alert, Input, Switch, Tabs } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { FormContainer } from '../../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../../lib/components/Form/FormField';
import { MultipleTagSelect } from '../../../../../../lib/components/MultipleTagSelect';
import { EditionEntity } from '../../../../../../lib/model/Book';
import { extract_num_id_from_id } from '../../../utils/extract_num_id_from_id';
import { useEditionsApi } from '../../hooks/useEditionsApi';
import { UploadImage } from '../UploadImage';
import { EditionFormButtons } from './EditionFormButtons';
import { useEditionForm } from './useEditionForm';
import { message } from 'antd';
import { DateInput } from '../../../../../../lib/components/DateInput';
import TextArea from 'antd/lib/input/TextArea';

const class_name_field = 'w-1/2';

interface IProps {
	edition?: EditionEntity;
}

export const EditionForm: FC<IProps> = ({ edition }) => {
	const history = createBrowserHistory();
	const { TabPane } = Tabs;
	const { id: book_id } = useParams() as { id: string };

	const book_num_id = extract_num_id_from_id(book_id);

	const { form_state, show_errors, change_field_value, fill_with_data } = useEditionForm();
	const [coordinated, set_coordinated] = useState(false);
	const [is_actual, set_is_actual] = useState(false);

	const { insert, update, get_last_id } = useEditionsApi();

	useEffect(() => {
		if (edition?.coordinators.length) {
			set_coordinated(true);
		}
		set_is_actual(!!edition?.is_actual);
	}, [edition]);

	useEffect(() => {
		const edition_data = {
			book_num_id: edition?.book_num_id || '',
			num_id: edition?.num_id || '',
			title: edition?.title || '',
			isbn: edition?.isbn || '',
			description: edition?.description || '',
			number: edition?.number || 0,
			pages: edition?.pages || 0,
			subtitle: edition?.subtitle || '',
			coordinators: edition?.coordinators || [],
			authors: edition?.authors || [],
			publish_date: Number(edition?.publish_date || Date.now()),
		};

		fill_with_data(edition_data);

		if (!edition) {
			get_last_id.get();
		}
	}, [edition]);

	const dto = {
		...form_state.value,
		book_num_id,
		num_id: String(form_state.value.num_id),
		is_actual,
	};

	function submit() {
		show_errors();

		if (!form_state.valid) {
			return;
		}

		return edition ? update.do_update(dto) : insert.create(dto);
	}

	useEffect(() => {
		if (update.result || insert.result) {
			message.success('Salvo com sucesso.');
			const edition_id = update.result?.id || insert.result?.id;
			history.push(`/conteudos/livros/${book_id}/edicoes/${edition_id}`);
			window.location.reload();
		}
	}, [update.result, insert.result]);

	const convert_date_string_to_timestamp = (date: string) => {
		return Date.parse(date) + 90000000;
	};

	useEffect(() => {
		if (get_last_id.result) {
			const last_num_id = Number(String(get_last_id.result.num_id).replace(/E/g, '').replace(/ /g, ''));
			if (!form_state.value.num_id) {
				const data = {
					num_id: last_num_id ? `E${last_num_id + 1}` : '',
					book_num_id: edition?.book_num_id || '',
					title: edition?.title || '',
					isbn: edition?.isbn || '',
					description: edition?.description || '',
					number: edition?.number || 0,
					pages: edition?.pages || 0,
					subtitle: edition?.subtitle || '',
					coordinators: edition?.coordinators || [],
					authors: edition?.authors || [],
					publish_date: Number(edition?.publish_date || Date.now()),
					is_actual: edition?.is_actual || false,
				};

				fill_with_data(data);
			}
		}
	}, [get_last_id.result]);

	return (
		<Tabs type='card'>
			<TabPane tab='Informações' key='infos'>
				<FormContainer>
					{edition ? (
						<>
							<Alert
								message={`É necessário republicar a edição para que as alterações reflitam na plataforma.`}
								type='warning'
							/>
							<br />
						</>
					) : (
						<></>
					)}
					<FormField {...{ label: 'ID', name: 'num_id', form_state }}>
						<Input
							value={form_state.value.num_id}
							type={'text'}
							onChange={(event) => change_field_value('num_id', event.target.value)}
							className={class_name_field}
							disabled={true}
						/>
					</FormField>
					<FormField {...{ label: 'Edição', name: 'number', form_state }}>
						<Input
							defaultValue={1}
							value={form_state.value.number}
							type={'number'}
							onChange={(event) => change_field_value('number', event.target.value)}
							className={class_name_field}
						/>
					</FormField>
					<FormField {...{ label: 'Titulo', name: 'title', form_state }}>
						<Input
							value={form_state.value.title}
							onChange={(event) => change_field_value('title', event.target.value)}
							className={class_name_field}
						/>
					</FormField>
					<FormField {...{ label: 'Subtítulo', name: 'subtitle', form_state, required: false }}>
						<Input
							value={form_state.value.subtitle}
							onChange={(event) => change_field_value('subtitle', event.target.value)}
							className={class_name_field}
						/>
					</FormField>

					<FormField {...{ label: 'ISBN', name: 'isbn', form_state }}>
						<Input
							value={form_state.value.isbn}
							onChange={(event) => change_field_value('isbn', event.target.value)}
							className={class_name_field}
						/>
					</FormField>

					<FormField {...{ label: 'Descrição', name: 'description', form_state }}>
						<TextArea
							value={form_state.value.description}
							onChange={(event) => change_field_value('description', event.target.value)}
							className={class_name_field}
						/>
					</FormField>
					<FormField {...{ label: 'Número de Páginas', name: 'pages', form_state }}>
						<Input
							value={form_state.value.pages}
							type={'number'}
							onChange={(event) => change_field_value('pages', event.target.value)}
							className={class_name_field}
						/>
					</FormField>
					<FormField
						{...{
							label: 'Data de Publicação',
							name: 'publish_date',
							form_state,
						}}
					>
						<DateInput
							{...{
								style: { width: '50%' },
								date: new Date(form_state.value.publish_date),
								on_changed: (date) =>
									change_field_value('publish_date', convert_date_string_to_timestamp(date)),
							}}
						/>
					</FormField>
					<FormField
						{...{ label: 'Obra Coordenada?', name: 'coordinated', form_state, required: false }}
					>
						<Switch
							{...{
								defaultChecked: false,
								onChange: (value) => set_coordinated(value),
								checked: coordinated,
							}}
						/>
					</FormField>

					{coordinated ? (
						<div className={class_name_field}>
							<MultipleTagSelect
								{...{
									form_state,
									label: 'Coordenadores',
									name: 'coordinators',
									on_change: (coordinator) =>
										change_field_value(
											'coordinators',
											coordinator.map((coord) => ({ id: coord.id, title: coord.title }))
										),
									values: form_state.value.coordinators,
									tag_prefix: 'author',
								}}
							/>
						</div>
					) : (
						<div className={class_name_field}>
							<MultipleTagSelect
								{...{
									form_state,
									label: 'Autores',
									name: 'authors',
									on_change: (authors) =>
										change_field_value(
											'authors',
											authors.map((author) => ({ id: author.id, title: author.title }))
										),
									values: form_state.value.authors,
									tag_prefix: 'author',
								}}
							/>
						</div>
					)}

					<FormField {...{ label: 'Edição atual?', name: 'is_actual', form_state, required: false }}>
						<Switch
							{...{
								defaultChecked: false,
								onChange: (value) => set_is_actual(value),
								checked: is_actual,
							}}
						/>
					</FormField>

					<EditionFormButtons {...{ edition, submit, disabled: !form_state.valid }} />
				</FormContainer>
			</TabPane>
			<TabPane tab='Mídia' key='midia'>
				{edition ? (
					<FormField {...{ label: 'Capa', name: 'cover', form_state }}>
						<span className='flex items-start'>
							<UploadImage
								{...{
									src_img: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/books/cover/${book_num_id}/editions/${edition?.num_id}.jpg`,
								}}
							/>
						</span>
					</FormField>
				) : (
					<div className='block'>
						Por favor, salve os dados iniciais antes de realizar o upload da capa.
					</div>
				)}
			</TabPane>
		</Tabs>
	);
};
