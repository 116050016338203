import { message } from 'antd';
import { useEffect } from 'react';
import _ from 'lodash';
import React from 'react';
import './useRequestFeedBack.scss';

export function useRequestFeedback(success: any, error: any) {
	useEffect(() => {
		if (success) {
			return message.success({
				className: 'message-success',
				content: React.createElement('span', {}, 'Operação concluída com sucesso'),
			});
		}

		if (error) {
			console.dir(error);

			const default_msg = 'Ocorreu um erro inesperado.';
			const msg = error?.response?.data || default_msg;

			return message.error({
				className: 'message-error',
				content: React.createElement('span', {}, msg),
			});
		}
	}, [success, error]);
}
