import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../lib/components/Loader';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../lib/utils/request';
import { ClippingBreadcrumb } from '../../components/ClippingBreadcrumb';
import { ClippingFromElasticDto } from '../../dtos/ClippingFromElasticDto';
import { ClippingsDataTable } from '../components/ClippingsDataTable';

export const ClippingsListPage: FC = () => {
	const { source_id } = useParams();

	const [is_removed, set_is_removed] = useState(0);

	return (
		<PageLayout
			{...{
				title: 'Informativos',
				breadcrumb: () => <ClippingBreadcrumb {...{ source_id }} />,
				browser_back: true,
			}}
		>
			<Loader
				{...{
					request: () =>
						request().get<ClippingFromElasticDto[]>(
							`${process.env.REACT_APP_CLIPPING_API}/sources/${source_id}/clippings`
						),

					reload_when_changes: [is_removed],
					renderChildren: (clippings: ClippingFromElasticDto[]) => (
						<ClippingsDataTable
							{...{ clippings, on_remove_success: () => set_is_removed(is_removed + 1) }}
						/>
					),
				}}
			/>
		</PageLayout>
	);
};
