import { useParams } from 'react-router-dom';
import { useRequest } from '../../../../../lib/hooks';
import { request } from '../../../../../lib/utils/request';
import { extract_num_id_from_id } from '../../utils/extract_num_id_from_id';
import { EditionDto } from '../dto/EditionDto';
import { InsertEditionDto } from '../dto/InsertEditionDto';
import { UpdateEditionDto } from '../dto/UpdateEditionDto';

export function useEditionsApi() {
	const { id: book_id, edition_id } = useParams() as { id: string; edition_id: string };

	const book_num_id = extract_num_id_from_id(book_id);
	const edition_num_id = extract_num_id_from_id(edition_id);
	const BASE_URL = `${process.env.REACT_APP_BOOKS_API}/books/${book_num_id}/editions`;

	const insert = useCreateEdition(BASE_URL);
	const remove = useRemoveEdition(BASE_URL);
	const update = useUpdateEdition(BASE_URL, edition_num_id);
	const get_last_id = useGetLastEditionID(BASE_URL);
	const publish = usePublishEdition(BASE_URL);

	return {
		insert,
		remove,
		update,
		get_last_id,
		publish,
	};
}

function useCreateEdition(BASE_URL: string) {
	const [result, loading, error, do_create] = useRequest<EditionDto>((dto: InsertEditionDto) =>
		request().post(BASE_URL, dto)
	);

	return {
		create: (dto: InsertEditionDto) => do_create(dto),
		result,
		error,
		loading,
	};
}

function useUpdateEdition(BASE_URL: string, edition_num_id: string) {
	const [result, loading, error, update] = useRequest<EditionDto>((dto: UpdateEditionDto) =>
		request().put(`${BASE_URL}/${edition_num_id}`, dto)
	);

	return {
		do_update: (dto: UpdateEditionDto) => update(dto),
		result,
		error,
		loading,
	};
}

function useRemoveEdition(BASE_URL: string) {
	const [result, loading, error, remove] = useRequest<string>((edition_num_id: string) =>
		request().delete(`${BASE_URL}/${edition_num_id}`)
	);

	return {
		do_remove: (edition_id: string) => remove(edition_id),
		result,
		loading,
		error,
	};
}

function usePublishEdition(BASE_URL: string) {
	const [result, loading, error, index] = useRequest<string>((edition_num_id: string) =>
		request().put(`${BASE_URL}/${edition_num_id}/publish`, {})
	);

	return {
		do_index: (edition_id: string) => index(edition_id),
		result,
		loading,
		error,
	};
}

function useGetLastEditionID(BASE_URL: string) {
	const [result, loading, error, do_get] = useRequest<{ num_id: string }>(
		() =>
			new Promise((resolve, reject) => {
				request()
					.get(`${BASE_URL}/last-id`)
					.then((res) => setTimeout(() => resolve(res as { num_id: string }), 1500))
					.catch(reject);
			})
	);

	return {
		get: () => do_get(),
		result,
		error,
		loading,
	};
}
