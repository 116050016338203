import { CloseCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { SearchProps } from 'antd/lib/input/Search';
import React from 'react';
import './styles.scss';

const { Search: AntSearch } = Input;

interface IProps {
	onChange: (event: any) => any;
	onSearch: (text: string) => any;
	onClear?: () => any;
	value: string;
	placeholder?: string;
	loading?: boolean;
	other_props?: SearchProps;
	disabled?: boolean;
	size?: SizeType;
}

export const Search = ({
	onSearch,
	onClear,
	value,
	disabled,
	onChange,
	loading = false,
	placeholder = 'Pesquisar',
	size,
	other_props = {},
}: IProps) => {
	return (
		<AntSearch
			{...{
				size,
				disabled,
				loading,
				placeholder,
				onSearch,
				suffix: (
					<CloseCircleOutlined
						onClick={!!onClear ? onClear : () => onSearch('')}
						style={{
							cursor: 'pointer',
							opacity: value ? 1 : 0,
						}}
					/>
				),
				enterButton: true,
				onChange,
				value,
				...other_props,
			}}
		/>
	);
};
