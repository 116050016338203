import { Checkbox, InputNumber } from 'antd';
import { FC, useEffect, useState } from 'react';
import { FormButtons } from '../../../../../../lib/components/Form/FormButtons';
import { FormContainer } from '../../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../../lib/components/Form/FormField';
import { useRequestFeedback } from '../../../../../../lib/hooks';
import { DownloadConfig } from '../../../../../../lib/model/Client';
import { DownloadConfigDto } from '../../../dtos/DownloadConfigDto';
import { useDownloadConfigApi } from '../../../hooks/useDownloadConfigApi';
import { AddUserLimitModal } from './AddUserLimitModal';
import { remove_duplicate_emails } from './remove_duplicate_emails';
import { useDownloadsConfigForm, UserLimit } from './useDownloadsconfigForm';
import { UserLimitsDataTable } from './UserLimitsDataTable';

interface IProps {
	download_config: DownloadConfig;
}

export const DownloadsConfigForm: FC<IProps> = ({ download_config }) => {
	const [user_limit_modal_visible, set_user_limit_modal_visible] = useState(false);

	const { change_field_value, form_state, show_errors, fill_with_data } = useDownloadsConfigForm();

	const { update, error, result } = useDownloadConfigApi();

	useEffect(() => {
		fill_with_data(download_config);
	}, [download_config]);

	function submit() {
		show_errors();

		if (!form_state.valid) {
			return;
		}

		const user_limits_without_duplicates = remove_duplicate_emails<UserLimit>(form_state.value.user_limits);

		const dto: DownloadConfigDto = {
			...form_state.value,
			user_limits: user_limits_without_duplicates,
			client_id: download_config.client_id,
		};

		update(dto);
	}

	useRequestFeedback(result, error);

	return (
		<FormContainer>
			<FormField
				label='Limite de Download Padrao'
				name='download_limit'
				form_state={form_state}
				required={false}
				render_children_inside_label={false}
				class_name='flex flex-row'
			>
				<Checkbox
					checked={form_state.value.is_default_limit}
					onChange={(event) => change_field_value('is_default_limit', event.target.checked)}
					className='mb-3 ml-2'
				/>
			</FormField>

			<FormField
				{...{
					label: 'Limite',
					name: 'limit',
					form_state,
					required: false,
					children: (
						<InputNumber
							{...{
								min: 0,
								disabled: form_state.value.is_default_limit,
								value: form_state.value.limit,
								onChange: (value) => change_field_value('limit', value),
								style: { width: '11rem' },
							}}
						/>
					),
				}}
			/>
			<FormField
				{...{
					label: 'Limite por Usuario',
					name: 'limit_by_user',
					form_state,
					required: false,
					children: (
						<>
							<UserLimitsDataTable
								{...{
									user_limits: form_state.value.user_limits,
									set_user_limits: (value) => change_field_value('user_limits', value),
									set_visible: () => set_user_limit_modal_visible(true),
								}}
							/>

							<AddUserLimitModal
								{...{
									visible: user_limit_modal_visible,
									close: () => set_user_limit_modal_visible(false),
									set_user_limits: (value: any) =>
										change_field_value('user_limits', form_state.value.user_limits.concat(value)),
								}}
							/>
						</>
					),
				}}
			/>
			<FormButtons {...{ go_back_on_cancel: true, on_update_button_click: submit }} />
		</FormContainer>
	);
};
