import { useRequest } from '../../../../lib/hooks';
import { request } from '../../../../lib/utils/request';
import { PriceVersionDto } from '../dtos/PriceVersionDto';

export function useInsert() {
	const [result, loading, error, do_insert] = useRequest<PriceVersionDto>((dto: PriceVersionDto) =>
		request().post(`${process.env.REACT_APP_PRICING_ADMIN_API}`, dto)
	);

	return {
		do_insert,
		result,
		loading,
		error,
	};
}
