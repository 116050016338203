import { useRequest } from '../../../../lib/hooks';
import { request } from '../../../../lib/utils/request';
import { DownloadConfigDto } from '../dtos/DownloadConfigDto';

const BASE_URL = `${process.env.REACT_APP_CONTENT_DOWNLOADS_API}`;

export function useDownloadConfigApi() {
	const [result, loading, error, do_update] = useRequest<string>((dto: DownloadConfigDto) =>
		request().put(BASE_URL, dto)
	);

	return {
		update: (dto: DownloadConfigDto) => do_update(dto),
		result,
		error,
		loading,
	};
}
