import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	list_name: string;
}

export const ContentAssignmentBreadcrumb: FC<IProps> = ({ list_name }) => (
	<Breadcrumb>
		<Breadcrumb.Item key='author'>
			<Link to={'/loja/liberacao-de-conteudo/direito-autoral'}>Listas Liberadas</Link>
		</Breadcrumb.Item>
		<Breadcrumb.Item key='author'>{list_name}</Breadcrumb.Item>
	</Breadcrumb>
);
