import axios, { AxiosRequestConfig } from 'axios';
let headers = {};

export function setRequestToken(token: string) {
	headers = {
		...headers,
		Authorization: 'Bearer ' + token,
	};
}

export function request() {
	const axiosInstance = axios.create({});

	async function doRequest<T>(
		method: 'GET' | 'POST' | 'PUT' | 'DELETE',
		url: string,
		params?: any
	): Promise<T> {
		let config: AxiosRequestConfig = {
			method,
			url,
			headers: {
				...headers,
				// 'x-bid-trace': uuid(),
			},
		};

		if (method === 'GET') {
			config.params = params;
		} else {
			config.data = params;
		}

		const result = await axiosInstance.request<T>(config);
		return (result.data || true) as T;
	}

	return {
		head: <T>(url: string) => axiosInstance.request<T>({ url, method: 'HEAD' }),
		get: <T>(url: string, params?: any) => doRequest<T>('GET', url, params),
		put: <T>(url: string, params?: any) => doRequest<T>('PUT', url, params),
		post: <T>(url: string, params?: any) => doRequest<T>('POST', url, params),
		delete: <T>(url: string, params?: any) => doRequest<T>('DELETE', url, params),
	};
}
