import { Role } from './Role';

export type Session = {
	created_at: number;
	email: string;
	name: string;
	roles: Role[];
};

export const EmptySession = {
	created_at: 99,
	email: '',
	name: '',
	roles: [],
};
