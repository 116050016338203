import { ReactNode } from 'react';
import { IEntity } from '../ContentDataTable/SearchContext';
import { useSearchable } from './hooks/useSearchable';
import { SearchableQueryDto } from './model/SearchableQueryDto';
import { SearchableContext } from './SearchableContext';

interface IProps {
	children: ReactNode;
	default_query: SearchableQueryDto;
	entity_name: string;
}

type ISearch = {
	total: number;
	entities: IEntity[];
};

export const SearchableProvider: React.FC<IProps> = ({ children, default_query, entity_name }) => {
	const search = useSearchable<ISearch>(default_query, entity_name);

	return <SearchableContext.Provider value={search}>{children}</SearchableContext.Provider>;
};
