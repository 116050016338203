import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ItemListPage } from './pages/ItemListPage';
import { InsertItemPage } from './pages/InsertItemPage';
import { UpdateItemPage } from './pages/UpdateItemPage';

export const ItemRoutes: FC = () => (
	<Routes>
		<Route {...{ path: ':itemId', element: <UpdateItemPage /> }} />
		<Route {...{ path: 'novo', element: <InsertItemPage /> }} />
		<Route {...{ index: true, path: '/', element: <ItemListPage /> }} />
	</Routes>
);
