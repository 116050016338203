import { Card } from 'antd';
import React, { ReactNode } from 'react';
import './style.scss';

interface IProps {
	children: ReactNode;
	disabled?: boolean;
}

export const FormContainer: React.FC<IProps> = ({ children, disabled = false, ...styles }) => (
	<Card style={{ background: '#fff', marginTop: 1, ...styles }} className='frm-fieldset '>
		<fieldset disabled={disabled}>{children}</fieldset>
	</Card>
);
