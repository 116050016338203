import { Loader } from '../../../../../lib/components/Loader';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../lib/utils/request';
import { ClippingSourceDto } from '../../dtos/ClippingSourceDtos';
import { ClippingSourceDataTable } from '../components/ClippingSourceDataTable';

export const ClippingSourceListPage = () => (
	<PageLayout title='Fontes de Informativos'>
		<Loader
			{...{
				request: () =>
					request().get<ClippingSourceDto[]>(`${process.env.REACT_APP_CLIPPING_API}/sources`),

				renderChildren: (clipping_sources: ClippingSourceDto[]) => (
					<ClippingSourceDataTable {...{ clipping_sources }} />
				),
			}}
		/>
	</PageLayout>
);
