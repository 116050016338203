import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	video_id?: string;
}

export const VideosBreadcrumb: FC<IProps> = ({ video_id }) => (
	<Breadcrumb>
		<Breadcrumb.Item key='videos'>
			<Link to={'/conteudos/videos'}>Vídeos</Link>
		</Breadcrumb.Item>

		{video_id ? (
			<Breadcrumb.Item key='video_title'>
				<Link to={`/conteudos/videos/${video_id}`}>{video_id}</Link>
			</Breadcrumb.Item>
		) : (
			<Breadcrumb.Item key='new_video'>novo</Breadcrumb.Item>
		)}
	</Breadcrumb>
);
