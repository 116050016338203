import { MinusCircleTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { Form, Input, InputNumber, Space } from 'antd';
import { FC } from 'react';

interface IUserLimitsFormItems {
	key: number;
	index: number;
	remove: (index: number) => void;
	add: () => void;
	fields_length: number;
}

export const UserLimitsFormItems: FC<IUserLimitsFormItems> = ({ add, index, key, remove, fields_length }) => {
	return (
		<Space key={key} style={{ display: 'flex' }} align='baseline'>
			<Form.Item
				name={[index, 'email']}
				rules={[
					{
						type: 'email',
						message: 'E-mail invalido.',
					},
					{
						required: true,
					},
				]}
			>
				<Input
					{...{
						placeholder: 'E-mail',
						style: { width: '17rem' },
					}}
				/>
			</Form.Item>
			<Form.Item name={[index, 'limit']} style={{ position: 'relative', top: '.05rem' }} initialValue={0}>
				<InputNumber
					{...{
						min: 0,
						style: { width: '5rem' },
					}}
				/>
			</Form.Item>
			{fields_length > 1 && (
				<MinusCircleTwoTone {...{ twoToneColor: '#ff0000', onClick: () => remove(index) }} />
			)}

			<PlusCircleTwoTone {...{ color: 'blue', onClick: add }} />
		</Space>
	);
};
