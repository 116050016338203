import { FC } from 'react';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { BooksBreadcrumb } from '../components/BooksBreadcrumb';
import { BookForm } from '../components/form/BookForm';

export const InsertBookPage: FC = () => (
	<PageLayout {...{ title: 'Inserir Livro', browser_back: true, breadcrumb: () => <BooksBreadcrumb /> }}>
		<BookForm />
	</PageLayout>
);
