import { LockTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import React from 'react';
interface IProps {
	api_response: string;
	logout: () => any;
}

export const LoginErrorsMessage: React.FC<IProps> = ({ api_response, logout }) => {
	function get_error_msg_from(api_response: string) {
		if ((api_response + '').match(/não encontrada/)) {
			return 'Sua sessão expirou ou uma nova sessão está ativa em outro dispositivo.';
		}

		return 'Ocorreu um erro inesperado.';
	}

	return (
		<Modal
			{...{
				visible: true,
				closable: false,
				footer: null,
				bodyStyle: {
					height: 250,
				},
			}}
		>
			<div className='flex justify-center items-center flex-col h-full'>
				<LockTwoTone
					{...{
						style: {
							fontSize: 32,
						},
					}}
				/>

				<p className=' mt-3 text-center font-bold '>{get_error_msg_from(api_response)}</p>

				<Button
					{...{
						className: 'w-32 mt-6',
						type: 'primary',
						onClick: () => logout(),
					}}
				>
					Voltar
				</Button>
			</div>
		</Modal>
	);
};
