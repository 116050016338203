import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../lib/components/Loader';
import { EditionEntity } from '../../../../../lib/model/Periodic';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../lib/utils/request';
import { extract_num_id_from_id } from '../../utils/extract_num_id_from_id';
import { EditionsBreadcrumb } from '../components/EditionsBreadcrumb';
import { EditionsDataTable } from '../components/EditionsDataTable';
import { EditionDto } from '../dto/EditionDto';

export const EditionListPage: FC = () => {
	const [remove_counter, set_remove_counter] = useState(0);

	const { id: periodic_id } = useParams();
	const periodicNumId = String(extract_num_id_from_id(`${periodic_id}`)).toUpperCase();
	const BASE_URL = `${process.env.REACT_APP_PERIODIC_API}/periodics/${periodicNumId}/editions`;

	return (
		<PageLayout
			{...{
				title: 'Edições',
				browser_back: true,
				breadcrumb: () => <EditionsBreadcrumb {...{ periodic_id: periodicNumId, in_editions: true }} />,
			}}
		>
			<Loader
				{...{
					reload_when_changes: [remove_counter],
					request: () => request().get<EditionDto[]>(BASE_URL),
					renderChildren: (editions: EditionEntity[]) => {
						return (
							<EditionsDataTable
								{...{
									editions: editions,
									on_remove_success: () => set_remove_counter(remove_counter + 1),
								}}
							/>
						);
					},
				}}
			/>
		</PageLayout>
	);
};
