import React, { useEffect, useState } from 'react';
import { complement, prop, propEq } from 'ramda';
import { FormState } from '../../hooks/useForm/types';
import { Tag } from '../../model/Tag';
import { FormField } from '../Form/FormField';
import { SearchInput } from './SearchInput';
import { SelectedTags } from './SelectedTags';
import { TagList } from './TagList';
import { request } from '../../../lib/utils/request';
import { TagDto } from '../../dtos/TagDto';

interface IMultipleSerieSelectProps {
	name: string;
	label?: string;
	required?: boolean;
	form_state: FormState<any>;
	values: Tag[];
	on_change: (tag_list: Tag[]) => void;
	class_name?: string;
}

export const MultipleSerieSelect = ({
	name,
	label,
	required,
	form_state,
	on_change,
	values,
	class_name,
}: IMultipleSerieSelectProps) => {
	const [loading, set_loading] = useState(false);
	const [book_series, set_book_series] = useState([] as Tag[]);
	const [text, set_text] = useState<string>('');

	const get_series = async () => {
		set_loading(true);
		const BOOK_SERIES_BASE_URL = `${process.env.REACT_APP_CLIENT_CONTRACT_MANAGEMENT_API}/tags/book`;
		const result: Tag[] = await request().get<TagDto[]>(BOOK_SERIES_BASE_URL);
		set_loading(false);
		return result;
	};

	const filter_series = async (text: string) => {
		const result = await get_series();
		if (!text) return set_book_series(result);
		const filtered = result.filter((t: Tag) => String(t.title).includes(text));
		set_book_series(filtered);
	};

	return (
		<FormField
			{...{
				name,
				label,
				required,
				form_state,
				render_children_inside_label: false,
				class_name,
			}}
		>
			<SelectedTags
				{...{
					on_remove_tag: (id: string) => {
						on_change(values.filter(complement(propEq('id', id))));
					},
					tags: values,
				}}
			/>

			<SearchInput
				{...{
					name,
					loading,
					text,
					set_text,
					on_search: (text: string) => filter_series(text),
				}}
			/>

			<TagList
				{...{
					tags: book_series.filter(not_selected_tag(values)),
					on_tag_selected: (tag: Tag) => {
						on_change([...values, tag]);
						set_text('');
					},
				}}
			/>
		</FormField>
	);
};

const not_selected_tag = (selecteds: Tag[]) => {
	const selected_ids = new Set(selecteds.map(prop('id')));
	return (tag: Tag) => !selected_ids.has(tag.id);
};
