import { FC } from 'react';
import { SearchableProvider } from '../../../../lib/components/SearchableDataTable/SearchableProvider';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { default_query } from '../../../../lib/utils/consts';
import { VideosDataTable } from '../components/VideosDataTable';

export const VideoListPage: FC = () => (
	<PageLayout title='Vídeos'>
		<SearchableProvider {...{ entity_name: 'admin-videos-entity', default_query }}>
			<VideosDataTable />
		</SearchableProvider>
	</PageLayout>
);
