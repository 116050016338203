import { Select } from 'antd';
import { FC } from 'react';
import { ItemTypes } from '../../../../../../lib/model/Periodic';

const Option = Select;

interface IProps {
	value: ItemTypes;
	on_change: (field_name: any, value: ItemTypes) => void;
}

export const SelectItemType: FC<IProps> = ({ value, on_change }) => (
	<Select
		placeholder='Selecione o tipo'
		onChange={(itemType) => on_change('itemType', itemType)}
		defaultValue={ItemTypes.Doctrine}
		style={{ width: '50%' }}
		value={value}
	>
		<Option value={''}>Outro</Option>
		<Option value={ItemTypes.Doctrine} key={ItemTypes.Doctrine}>
			Doutrina
		</Option>
		<Option value={ItemTypes.Jurisprudence} key={ItemTypes.Jurisprudence}>
			Jurisprudência
		</Option>
		<Option value={ItemTypes.Jurisprudential_Trends} key={ItemTypes.Jurisprudential_Trends}>
			Tendências Jurisprudenciais
		</Option>
	</Select>
);
