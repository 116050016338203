import { Alert, Input, Switch } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { FormContainer } from '../../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../../lib/components/Form/FormField';
import { EditionEntity, EditionFrequency } from '../../../../../../lib/model/Periodic';
import { extract_num_id_from_id } from '../../../utils/extract_num_id_from_id';
import { useEditionsApi } from '../../hooks/useEditionsApi';
import { EditionFormButtons } from './EditionFormButtons';
import { useEditionForm } from './useEditionForm';
import { message } from 'antd';
import { SelectEditionFrequency } from './SelectEditionFrequency';
import { SelectMonth } from './SelectMonth';
import moment from 'moment';

const class_name_field = 'w-1/2';

interface IProps {
	edition?: EditionEntity;
}

export const EditionForm: FC<IProps> = ({ edition }) => {
	const history = createBrowserHistory();
	const { id: periodic_id } = useParams() as { id: string };

	const periodicNumId = String(extract_num_id_from_id(periodic_id)).toUpperCase();

	const { form_state, show_errors, change_field_value, fill_with_data } = useEditionForm();
	const [currentEdition, setCurrentEdition] = useState(false);

	const { insert, update, get_number, get_last_id } = useEditionsApi();

	useEffect(() => {
		get_number.do_get_number();
	}, []);

	useEffect(() => {
		const startMonth = edition?.startMonth ? moment(edition?.startMonth) : null;
		const endMonth = edition?.endMonth ? moment(edition?.endMonth) : null;
		const edition_data = {
			periodicNumId: edition?.periodicNumId ? String(edition?.periodicNumId).toUpperCase() : '',
			numId: edition?.numId ? String(edition?.numId).toUpperCase() : '',
			number: edition?.number || 0,
			title: edition?.title || '',
			frequency: (edition?.frequency || '') as EditionFrequency,
			startMonth: startMonth ? startMonth.format('MM') : '',
			endMonth: endMonth ? endMonth.format('MM') : '',
			startYear: edition?.startYear || '',
			currentEdition: edition?.currentEdition || false,
		};
		setCurrentEdition(edition?.currentEdition || false);
		fill_with_data(edition_data);
		if (!edition) {
			get_last_id.get();
		}
	}, [edition]);

	useEffect(() => {
		if (get_last_id.result) {
			const last_num_id = Number(String(get_last_id.result.numId).replace(/E/g, '').replace(/ /g, ''));
			if (!form_state.value.numId) {
				const periodic_data = {
					...form_state.value,
					numId: last_num_id ? `E${last_num_id + 1}` : '0',
				};

				fill_with_data(periodic_data);
			}
		}
	}, [get_last_id.result]);

	const dto = {
		...form_state.value,
		periodicNumId,
		numId: String(form_state.value.numId).toUpperCase(),
		currentEdition,
	};

	function submit() {
		show_errors();

		if (!form_state.valid) {
			return;
		}
		const startMonth =
			dto?.startMonth && dto?.startYear ? `${dto?.startYear}-${dto?.startMonth}-01 00:00:00` : null;
		const endMonth =
			dto?.endMonth && dto?.startYear ? `${dto?.startYear}-${dto?.endMonth}-01 00:00:00` : null;
		const newDto = {
			...dto,
			title: dto?.title || `Número ${dto.number}`,
			startMonth: startMonth || dto?.startMonth,
			endMonth: endMonth || dto?.endMonth,
		};
		return edition ? update.do_update(newDto) : insert.create(newDto);
	}

	useEffect(() => {
		if (update.result || insert.result) {
			message.success('Salvo com sucesso.');
			const edition_id = String(update.result?.numId || insert.result?.numId).toUpperCase();
			history.push(`/conteudos/revistas/${periodic_id}/edicoes/${edition_id}`);
			window.location.reload();
		}
	}, [update.result, insert.result]);

	useEffect(() => {
		if (get_number.result) {
			const number = edition?.number ? edition?.number : get_number.result?.number || 1;
			change_field_value('number', number);
			// console.log('edition?.number: ', edition?.number);
			// console.log('get_number.result?.number: ', get_number.result?.number);
			// const number = edition?.number
			// 	? edition?.number
			// 	: (get_number.result?.number ? get_number.result?.number + 1 : 1) || 1;
		}
	}, [get_number.result]);

	return (
		<FormContainer>
			{edition ? (
				<>
					<Alert
						message={`É necessário republicar a edição para que as alterações reflitam na plataforma.`}
						type='warning'
					/>
					<br />
				</>
			) : (
				<></>
			)}
			<FormField {...{ label: 'Identificador Numérico', name: 'numId', form_state }}>
				<Input
					value={form_state.value.numId}
					onChange={(event) => change_field_value('numId', event.target.value)}
					className={class_name_field}
					disabled={true}
				/>
			</FormField>
			<FormField {...{ label: 'Número Sequencial', name: 'number', form_state }}>
				<Input
					defaultValue={1}
					value={form_state.value.number}
					type={'number'}
					onChange={(event) => change_field_value('number', event.target.value)}
					className={class_name_field}
					disabled={true}
				/>
			</FormField>
			<FormField {...{ label: 'Título', name: 'title', form_state, required: false }}>
				<Input
					value={form_state.value.title}
					onChange={(event) => change_field_value('title', event.target.value)}
					className={class_name_field}
					required={false}
				/>
			</FormField>
			<FormField {...{ label: 'Frequência', name: 'frequency', form_state }}>
				<SelectEditionFrequency
					{...{ value: form_state.value.frequency, on_change: change_field_value }}
				/>
			</FormField>
			<FormField {...{ label: 'Mês Inicial', name: 'startMonth', form_state }}>
				<SelectMonth
					{...{ value: form_state.value.startMonth, field: 'startMonth', on_change: change_field_value }}
				/>
			</FormField>
			<FormField {...{ label: 'Mês Final', name: 'endMonth', form_state }}>
				<SelectMonth
					{...{ value: form_state.value.endMonth, field: 'endMonth', on_change: change_field_value }}
				/>
			</FormField>
			<FormField {...{ label: 'Ano de Início', name: 'startYear', form_state }}>
				<Input
					value={form_state.value.startYear}
					onChange={(event) => change_field_value('startYear', event.target.value)}
					className={class_name_field}
					placeholder={'Ex: 2023'}
				/>
			</FormField>

			{/* <FormField {...{ label: 'Edição Atual?', name: 'currentEdition', form_state, required: false }}>
				<Switch
					{...{
						defaultChecked: false,
						onChange: (value) => setCurrentEdition(value),
						checked: currentEdition,
					}}
				/>
			</FormField> */}

			<EditionFormButtons
				{...{
					edition,
					submit,
					// disabled: !form_state.valid
					disabled: false,
				}}
			/>
		</FormContainer>
	);
};
