import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../lib/components/Loader';
import { EditionEntity } from '../../../../../lib/model/Book';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../lib/utils/request';
import { extract_num_id_from_id } from '../../utils/extract_num_id_from_id';
import { EditionsBreadcrumb } from '../components/EditionsBreadcrumb';
import { EditionsDataTable } from '../components/EditionsDataTable';
import { EditionDto } from '../dto/EditionDto';

export const EditionListPage: FC = () => {
	const [remove_counter, set_remove_counter] = useState(0);

	const { id: book_id } = useParams();
	const book_num_id = extract_num_id_from_id(`${book_id}`);
	const BASE_URL = `${process.env.REACT_APP_BOOKS_API}/books/${book_num_id}/editions`;

	return (
		<PageLayout
			{...{
				title: 'Edições',
				browser_back: true,
				breadcrumb: () => <EditionsBreadcrumb {...{ book_id, in_editions: true }} />,
			}}
		>
			<Loader
				{...{
					reload_when_changes: [remove_counter],
					request: () => request().get<EditionDto[]>(BASE_URL),
					renderChildren: (editions: EditionEntity[]) => {
						const row_data_from_entity: any[] = editions.map((edition) => {
							const coordinators_name =
								edition.coordinators?.map((coordinator) => coordinator.title) || [];
							const authors_name = edition.authors?.map((author) => author.title) || [];

							return {
								...edition,
								coordinators: coordinators_name,
								authors: authors_name,
							};
						});

						return (
							<EditionsDataTable
								{...{
									editions: row_data_from_entity,
									on_remove_success: () => set_remove_counter(remove_counter + 1),
								}}
							/>
						);
					},
				}}
			/>
		</PageLayout>
	);
};
