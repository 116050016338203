import { FormState } from './types';

export const create_empty_errors = (obj: any) => {
	let result: any = {};

	Object.keys(obj).forEach((k) => {
		result[k] = '';
	});

	return result;
};

export const create_form_initial_state = <T>(value: T): FormState<T> => ({
	show_errors: false,
	dirty: false,
	dirty_fields: [],
	valid: false,
	errors: create_empty_errors(value),
	value,
});
