import { Alert, Input, message, Tabs } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormButtons } from '../../../../../../../lib/components/Form/FormButtons';
import { FormContainer } from '../../../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../../../lib/components/Form/FormField';
import { MultipleTagSelect } from '../../../../../../../lib/components/MultipleTagSelect';
import { UploaderPDF } from '../../../../../../../lib/components/UploaderPDF';
import { useRequestFeedback } from '../../../../../../../lib/hooks';
import {
	ChapterEntity,
	ChapterSubTypesPosTextual,
	ChapterSubTypesPreTextual,
	ChapterTypes,
	Languages,
	SignedUrlTypes,
} from '../../../../../../../lib/model/Book';
import { extract_num_id_from_id } from '../../../../utils/extract_num_id_from_id';
import { InsertChapterDto } from '../../dto/InsertChapterDto';
import { UpdateChapterDto } from '../../dto/UpdateChapterDto';
import { useChaptersApi } from '../../hooks/useChaptersApi';
import { SelectChapterLanguage } from '../SelectChapterLanguage';
import { SelectChapterType } from '../SelectChapterType';
import { TextFromPdfModal } from '../TextFromPdfModal';
import { useChapterForm } from './useChapterForm';
import { FilePdfOutlined } from '@ant-design/icons';
import { createBrowserHistory } from 'history';
import { SelectChapterSubType } from '../SelectChapterSubType';
import { UpdateChapterContentTextDto } from '../../dto/UpdateChapterContentTextDto';
import { UpdateChapterText } from '../../dto/UpdateChapterText';

const class_name_field = 'w-1/2';

interface IProps {
	chapter?: ChapterEntity;
	on_change_form?: () => void;
}

export const ChapterForm: FC<IProps> = ({ chapter, on_change_form }) => {
	const history = createBrowserHistory();
	const navigate = useNavigate();
	const { TabPane } = Tabs;
	const {
		id: book_id,
		edition_id,
		chapter_id,
	} = useParams() as { id: string; edition_id: string; chapter_id: string };
	const book_num_id = extract_num_id_from_id(book_id);
	const edition_num_id = extract_num_id_from_id(edition_id);
	const chapter_num_id = extract_num_id_from_id(chapter_id);
	const BASE_URL = `${process.env.REACT_APP_BOOKS_API}/books/${book_num_id}/editions/${edition_num_id}/chapters/${chapter_num_id}/pdf`;

	const { form_state, show_errors, change_field_value, fill_with_data } = useChapterForm();
	const [sub_type, set_sub_type] = useState('');

	const { insert, update, get_last_id, read_pdf, update_text } = useChaptersApi();

	const update_pdf_text = async (pdf_text: UpdateChapterText[]) => {
		const dto: UpdateChapterContentTextDto[] = pdf_text.map((t: UpdateChapterText) => ({
			page: t.page,
			content: t.text,
		}));
		await update_text.do_update(dto);
	};

	useEffect(() => {
		const pdf_text = read_pdf.result as UpdateChapterText[];
		if (pdf_text?.length) {
			update_pdf_text(pdf_text);
		}
	}, [read_pdf.result]);

	useEffect(() => {
		let options: any[] = [];
		if (chapter?.chapter_type === ChapterTypes.PRE_TEXTUAL) {
			options = Object.entries(ChapterSubTypesPreTextual).map(([key, value]) => ({ key, value }));
		}
		if (chapter?.chapter_type === ChapterTypes.POS_TEXTUAL) {
			options = Object.entries(ChapterSubTypesPosTextual).map(([key, value]) => ({ key, value }));
		}
		const current_sub_type = options.find((o) => o.value === chapter?.title);
		set_sub_type(current_sub_type || '');
		fill_with_data({
			authors: chapter?.authors || [],
			language: chapter?.language || Languages.PORTUGUESE,
			num_id: chapter?.num_id || '',
			order: chapter?.order || 1,
			title:
				(chapter?.chapter_type === ChapterTypes.PRE_TEXTUAL ||
					chapter?.chapter_type === ChapterTypes.POS_TEXTUAL) &&
				current_sub_type
					? ''
					: chapter?.title || '',
			chapter_type: chapter?.chapter_type || ChapterTypes.CHAPTER,
		});
		if (!chapter) {
			get_last_id.get();
		}
	}, [chapter]);

	function submit() {
		show_errors();
		let title = form_state?.value?.title || '';
		if (form_state.value.chapter_type !== ChapterTypes.CHAPTER && !form_state.value.title) {
			title = sub_type;
		}
		const dto: UpdateChapterDto | InsertChapterDto = {
			...form_state.value,
			num_id: String(form_state.value.num_id),
			title,
		};

		if (!form_state.valid) {
			return;
		}

		return chapter ? update.do_update(dto) : insert.create(dto);
	}

	useEffect(() => {
		if (update.result || insert.result) {
			message.success('Salvo com sucesso.');
			const chapter_id = update.result?.id || insert.result?.id;
			history.push(`/conteudos/livros/${book_id}/edicoes/${edition_id}/capitulos/${chapter_id}`);
			window.location.reload();
		}
	}, [update.result, insert.result]);

	useRequestFeedback(update.result, update.error);
	useRequestFeedback(insert.result, insert.error);

	useEffect(() => {
		if (get_last_id.result) {
			const last_num_id = Number(String(get_last_id.result.num_id).replace(/ /g, ''));
			if (!form_state.value.num_id) {
				fill_with_data({
					num_id: last_num_id ? `${last_num_id + 1}` : '',
					authors: chapter?.authors || [],
					language: chapter?.language || Languages.PORTUGUESE,
					order: chapter?.order || 1,
					title: chapter?.title || '',
					chapter_type: chapter?.chapter_type || ChapterTypes.CHAPTER,
				});
			}
		}
	}, [get_last_id.result]);

	return (
		<Tabs type='card'>
			<TabPane tab='Informações' key='infos'>
				<FormContainer>
					{chapter ? (
						<>
							<Alert
								message={`É necessário republicar a edição para que as alterações reflitam na plataforma.`}
								type='warning'
							/>
							<br />
						</>
					) : (
						<></>
					)}
					<FormField {...{ label: 'ID', name: 'num_id', form_state }}>
						<Input
							value={form_state.value.num_id}
							type={'text'}
							onChange={(event) => change_field_value('num_id', event.target.value)}
							className={class_name_field}
							disabled={true}
						/>
					</FormField>

					<FormField {...{ label: 'Tipo', name: 'type', form_state }}>
						<SelectChapterType
							{...{ value: form_state.value.chapter_type, on_change: change_field_value }}
						/>
					</FormField>

					{form_state.value.chapter_type !== ChapterTypes.CHAPTER ? (
						<FormField {...{ label: 'Subtipo', name: 'sub_type', form_state }}>
							<SelectChapterSubType
								{...{ type: form_state.value.chapter_type, value: sub_type, on_change: set_sub_type }}
							/>
						</FormField>
					) : (
						<></>
					)}

					<FormField
						{...{
							label: 'Título',
							name: 'title',
							form_state,
							required: form_state.value.chapter_type === ChapterTypes.CHAPTER,
						}}
					>
						<Input
							value={form_state.value.title}
							onChange={(event) => change_field_value('title', event.target.value)}
							className={class_name_field}
						/>
					</FormField>

					<FormField {...{ label: 'Ordem de Listagem', name: 'order', form_state }}>
						<Input
							value={form_state.value.order}
							type={'number'}
							onChange={(event) => change_field_value('order', event.target.value)}
							className={class_name_field}
						/>
					</FormField>

					<FormField {...{ label: 'Idioma', name: 'language', form_state }}>
						<SelectChapterLanguage
							{...{ value: form_state.value.language, on_change: change_field_value }}
						/>
					</FormField>

					<div className={class_name_field}>
						<MultipleTagSelect
							{...{
								form_state,
								label: 'Autores',
								name: 'authors',
								required: false,
								on_change: (authors) =>
									change_field_value(
										'authors',
										authors.map((author) => ({ id: author.id, title: author.title }))
									),
								values: form_state.value.authors,
								tag_prefix: 'author',
							}}
						/>
					</div>

					<FormButtons
						{...{
							disabled: !form_state.valid,
							on_update_button_click: chapter ? submit : undefined,
							go_back_on_cancel: true,
							on_add_button_click: !chapter ? submit : undefined,
						}}
					/>
				</FormContainer>
			</TabPane>
			<TabPane tab='PDF' key='pdf'>
				{chapter ? (
					<>
						<FormField {...{ label: 'PDF', name: 'pdf', form_state }}>
							<UploaderPDF
								{...{
									on_success: () => {
										read_pdf.get();
										message.success('Documento salvo com sucesso.');
										if (on_change_form) on_change_form();
									},
									upload_api_endpoint: `${BASE_URL}/${SignedUrlTypes.WRITE}`,
								}}
							/>
						</FormField>
						{chapter.pdf && (
							<FormField {...{ label: 'Download', name: 'key', required: false, form_state }}>
								<a href={chapter.pdf} target='_blank' className='block mt-2 mb-1'>
									<div className='flex items-center'>
										<FilePdfOutlined style={{ marginRight: '0.3rem' }} />{' '}
										{String(chapter.key).split('/')[3]}
									</div>
								</a>
							</FormField>
						)}
						<FormField {...{ label: 'Texto do Capítulo', name: 'txt', required: false, form_state }}>
							<TextFromPdfModal formState={form_state} />
						</FormField>
					</>
				) : (
					<div className='block'>
						Por favor, salve os dados iniciais antes de realizar o upload de um PDF.
					</div>
				)}
			</TabPane>
		</Tabs>
	);
};
