import { useHasRole } from '../hooks/useHasRole';
import { Role } from '../model/Role';

interface IProps {
	children: any;
	roles: Role[];
}

export const ProtectedContent: React.FC<IProps> = ({ children, roles }) => {
	const has_role = useHasRole();

	return roles.some(has_role) ? children : null;
};
