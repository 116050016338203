import { UserOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React, { FC } from 'react';
import { List } from '../../../../../../lib/components/List/List';
import { AddContractManagerInput } from './AddContractManagerInput';

interface IProps {
	contract_managers: string[];
	on_add: (value: any) => void;
	on_remove: (value: any) => void;
}

export const ContractManagersList: FC<IProps> = ({ contract_managers, on_add, on_remove }) => (
	<Card>
		<List
			{...{
				data: contract_managers,
				header: 'Gestores de contrato',
				on_remove_click: on_remove,
				icon: (
					<UserOutlined
						{...{
							className: ' text-blue-500  flex items-center justify-center  py-1 ',
						}}
					/>
				),
			}}
		/>
		<AddContractManagerInput
			{...{
				on_added: on_add,
			}}
		/>
	</Card>
);
