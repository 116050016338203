import TextArea from 'antd/lib/input/TextArea';
import { FC, useState } from 'react';
import { AddButton } from './Buttons/AddButton';

interface IProps {
	on_submit: (value: string[]) => void;
	validate_fn: (text: string) => string;
}

export const MultiLineInput: FC<IProps> = ({ on_submit, validate_fn }) => {
	const [text, set_text] = useState('');
	const [errors, set_errors] = useState<string[]>([]);

	function split_text(text: string) {
		const regex = /,|;|\n/;

		return text.split(regex);
	}

	function get_value_error(value: string, idx: number) {
		const error = validate_fn(value);

		if (error) {
			return `Item ${idx + 1} - ${error}`;
		}

		return '';
	}

	function submit() {
		const value = split_text(text)
			.filter((v) => !!v)
			.map((v) => v.trim());

		const errors = value.map(get_value_error).filter((e) => !!e);

		set_errors(errors);

		if (errors.length > 0) {
			return;
		}

		on_submit(value);
	}

	const has_errors = errors.length > 0;

	return (
		<div className='flex flex-col'>
			<TextArea
				{...{
					style: {
						lineHeight: 30,
					},
					className: has_errors ? 'border-red-500' : '',
					rows: 15,
					allowClear: true,
					onChange: (event) => {
						set_text(event.target.value);
					},
					value: text,
					placeholder: 'Insira os e-mails.',
				}}
			/>

			{has_errors && (
				<ul className='mt-2'>
					{errors.map((error) => (
						<li className='text-red-500  text-sm' key={error}>
							{error}
						</li>
					))}
				</ul>
			)}

			<div className=' w-full flex   items-end justify-end mt-2'>
				<AddButton {...{ disabled: !text, onClick: submit }} />
			</div>
		</div>
	);
};
