import { Modal } from 'antd';
import React, { useEffect } from 'react';
import { FormContainer } from '../../components/Form/FormContainer';
import { useRequestFeedback } from '../../hooks';
import { useContentGroupApi } from './hooks/useContentGroup';
import { MultiISBNInput } from './MultiISBNInput';

interface IProps {
	id: string;
	visible: boolean;
	close: () => void;
	on_insert: (value: any) => void;
}

export const InsertContentForISBNModal: React.FC<IProps> = ({ visible, close, on_insert, id }) => {
	const { update_from_isbns } = useContentGroupApi();

	useEffect(() => {
		if (update_from_isbns.result) {
			on_insert(update_from_isbns?.result.contents);
			close();
		}
	}, [update_from_isbns.result?.contents]);

	useRequestFeedback(update_from_isbns.result, update_from_isbns.error);

	function submit(isbns: string[]) {
		update_from_isbns.do_update(id, isbns);
	}

	return (
		<Modal
			{...{
				title: 'Adicionar Conteúdos por ISBNs',
				visible,
				centered: true,
				width: '41rem',
				closable: true,
				destroyOnClose: true,
				onCancel: close,
				children: (
					<FormContainer
						{...{
							disabled: false,
							border: 'none',
						}}
					>
						<MultiISBNInput
							{...{
								on_submit: submit,
							}}
						/>
					</FormContainer>
				),
				footer: [],
			}}
		/>
	);
};
