import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { ClippingBreadcrumb } from '../../components/ClippingBreadcrumb';
import { ClippingForm } from '../components/ClippingForm/ClippingForm';
import { useClippingsApi } from '../hooks/useClippingsApi';

export const InsertClippingPage: FC = () => {
	const { source_id } = useParams();
	const navigate = useNavigate();

	const { insert } = useClippingsApi();

	useEffect(() => {
		if (!!insert.result) {
			navigate(-1);
		}
	}, [insert.result]);

	useRequestFeedback(insert.result, insert.error);

	return (
		<PageLayout
			{...{
				title: 'Inserir Informativo',
				breadcrumb: () => <ClippingBreadcrumb source_id={source_id} />,
				browser_back: true,
			}}
		>
			<ClippingForm on_submit={insert.create} />
		</PageLayout>
	);
};
