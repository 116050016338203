import { SaveOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { FC } from 'react';

interface IProps {
	onClick: () => void;
	disabled?: boolean;
}

export const IndexButton: FC<IProps> = ({ onClick, disabled = false }) => (
	<Button {...{ type: 'dashed', onClick, disabled }}>
		<div className='flex items-center'>
			<SaveOutlined style={{ marginRight: '0.3rem' }} /> Salvar Conteúdo
		</div>
	</Button>
);
