import { format, parseISO } from 'date-fns';
import { FC } from 'react';
import { PublishButton } from '../../../../lib/components/Buttons/PublishButton';
import { ContentDatatable } from '../../../../lib/components/ContentDataTable/ContentDatatable';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { EditionCover } from '../../../../lib/features/book_edition/components/EditionCover';
import { Tag } from '../../../../lib/model/Tag';
import { PriceVersionDto } from '../dtos/PriceVersionDto';
import { DownloadPriceVersionXlsButton } from './DownloadPriceVersionXlsButton';

interface IProps {
	version: PriceVersionDto;
	loading: boolean;
	set_as_current_version: (version_date: number) => void;
}

export const PreviewPricesDatatable: FC<IProps> = ({ version, loading, set_as_current_version }) => (
	<ContentDatatable
		{...{
			columns: [
				{
					col_def: {
						field: 'cover',
						headerName: '',
						width: 110,
						cellStyle: CellStyle.Centered,
					},
					renderer: (props: { data: { tags: Tag[] } }) => (
						<EditionCover
							{...{
								quality: 'low',
								tags: props.data.tags,
							}}
						/>
					),
				},
				{
					field: 'title',
					headerName: 'Título',
					width: 300,
					resizable: true,
				},
				{
					field: 'edition_number',
					headerName: 'Número da Edição',
					width: 150,
					resizable: true,
				},
				{
					field: 'authors',
					headerName: 'Autor',
					width: 300,
					resizable: true,
				},
				{
					field: 'publish_date',
					headerName: 'Data de Publicação',
					width: 150,
					resizable: true,
				},
				{
					field: 'price_digital',
					headerName: 'Preço Digital',
					width: 150,
					resizable: true,
				},
				{
					field: 'discount_price_digital',
					headerName: 'Desconto - Digital',
					width: 145,
					resizable: true,
				},
				{
					field: 'final_price_digital',
					headerName: 'Preço Final - Digital',
					width: 155,
					resizable: true,
				},
				{
					field: 'price_paper',
					headerName: 'Preço Físico',
					width: 110,
					resizable: true,
				},
				{
					field: 'discount_price_paper',
					headerName: 'Desconto - Físico',
					width: 145,
					resizable: true,
				},
				{
					field: 'final_price_paper',
					headerName: 'Preço Final - Físico',
					width: 150,
					resizable: true,
				},
			],
			row_data_from_entity: (entity) => {
				const publish_date = parseISO(entity.publish_date);

				return {
					...entity,
					authors: entity.authors.map((author) => author.title).join(', '),
					title: entity.title.replaceAll('<em>', '').replaceAll('</em>', ''),
					publish_date: format(publish_date, 'dd/MM/yyyy'),
					edition_number: entity.path.split('/')[1],
				};
			},
			buttons: [
				<div className='flex flex-row items-center'>
					<DownloadPriceVersionXlsButton {...{ version_date: version.date }} />

					{version.is_current ? (
						<div className='w-44 text-green-500 font-semibold border-1 rounded-sm bg-white p-2 text-center ml-4'>
							Lista de Preços Atual
						</div>
					) : (
						<PublishButton
							{...{
								loading,
								text_confirm: 'Deseja tornar essa lista atual?',
								onClick: () => set_as_current_version(version.date),
								text: 'Definir como versão atual',
								style: { marginLeft: '1rem' },
							}}
						/>
					)}
				</div>,
			],
		}}
	/>
);
