import Axios from 'axios';

type CreateRestoreSessionDto = {
	session: {
		created_at: number;
		email: string;
		name: string;
		roles: string[];
	};
	token: string;
};

const BASE_API_URL = process.env.REACT_APP_ADMIN_AUTH_API + '';

export function useAdminAuthApi(auth0_token: string) {
	const get_error_msg = (error: any) => {
		return (error || {})?.response.data;
	};

	return {
		login: async () => {
			try {
				const result = await Axios.post<CreateRestoreSessionDto>(BASE_API_URL, null, {
					headers: {
						authorization: `Bearer ${auth0_token}`,
					},
				});
				return result.data;
			} catch (err) {
				throw get_error_msg(err);
			}
		},
		restore: async (created_at: number) => {
			try {
				const result = await Axios.get<CreateRestoreSessionDto>(`${BASE_API_URL}/${created_at}`, {
					headers: {
						authorization: `Bearer ${auth0_token}`,
					},
				});
				return result.data;
			} catch (err) {
				throw get_error_msg(err);
			}
		},
		logout: async (created_at: number) => {
			try {
				return Axios.delete<CreateRestoreSessionDto>(`${BASE_API_URL}/${created_at}`, {
					headers: {
						authorization: `Bearer ${auth0_token}`,
					},
				});
			} catch (err) {
				console.error(err);
			}
		},
	};
}
