import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../lib/components/Loader';
import { InformativeEntity } from '../../../../lib/model/Informative';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { InformativeDto } from '../dto/InformativeDto';
import { InformativesDataTable } from '../components/InformativesDataTable';
import { InformativeListBreadcrumb } from '../components/InformativeListBreadcrumb';
import moment, { Moment } from 'moment';

export const InformativeListPage: FC = () => {
	const [remove_counter, set_remove_counter] = useState(0);
	const [month, setMonth] = useState(moment());

	const { type: type } = useParams() as { type: string; num_id: string };
	const BASE_URL = `${process.env.REACT_APP_INFORMATIVE_API}/informatives/${type}/list/filter`;

	function dateChanged(date: Moment) {
		setMonth(date);
	}

	return (
		<PageLayout
			{...{
				title: 'Informativos',
				browser_back: true,
				breadcrumb: () => <InformativeListBreadcrumb {...{ in_informatives: true }} />,
			}}
		>
			<Loader
				{...{
					reload_when_changes: [remove_counter, month],
					request: () => request().get<InformativeDto[]>(`${BASE_URL}/${month.format('MM-YYYY')}`),
					renderChildren: (informative: InformativeEntity[]) => {
						return (
							<InformativesDataTable
								informatives={informative || []}
								{...{
									type,
									on_remove_success: () => set_remove_counter(remove_counter + 1),
									dateChanged,
								}}
							/>
						);
					},
				}}
			/>
		</PageLayout>
	);
};
