import { FC } from 'react';
import { Uploader } from '../../../../lib/components/Uploader/Uploader';

interface IProps {
	version_date: number;
	on_success: () => void;
}

export const PricesXlsxUploader: FC<IProps> = ({ version_date, on_success }) => (
	<Uploader
		{...{
			accept: '.xlsx',
			upload_api_endpoint: `${process.env.REACT_APP_PRICING_ADMIN_API}/upload-url/${version_date}`,
			upload_success: () => setTimeout(on_success, 300),
			class_name: 'mb-6',
			document_type: '.xlsx',
		}}
	/>
);
