import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { UpdateButton } from '../../components/Buttons/UpdateButton';
import { CellStyle } from '../../components/DataTable/CellStyle';
import { DataTable } from '../../components/DataTable/DataTable';
import { EditionCover } from '../book_edition/components/EditionCover';
import { ContentInsertModal } from './ContentInsertModal';
import { ContentDto, useContentGroupApi } from './hooks/useContentGroup';
import { InsertContentForISBNModal } from './InsertContentForISBNModal';

interface IProps {
	id: string;
}

export const ContentGroupDataTable: React.FC<IProps> = ({ id }) => {
	const [update_contents_modal_visible, set_update_contents_modal_visible] = useState(false);
	const [add_by_isbn_visible, set_add_by_isbn_visible] = useState(false);

	const { content_group, update } = useContentGroupApi();

	useEffect(() => {
		if (!id) {
			return;
		}

		content_group.do_get(id);
	}, [id]);

	const refresh = () => content_group.do_get(id);

	return (
		<>
			<DataTable
				{...{
					buttons: [
						<Button
							{...{
								type: 'default',
								onClick: () => set_add_by_isbn_visible(true),
							}}
						>
							Inserir por ISBNs
						</Button>,

						<UpdateButton
							{...{
								onClick: () => set_update_contents_modal_visible(true),
							}}
						/>,
					],
					rowData: content_group.result ? content_group.result?.contents : [],
					row_drag: true,
					on_row_drag: (contents: ContentDto[]) => update.do_update(id, contents),
					columns: [
						{
							col_def: {
								field: 'cover',
								headerName: '',
								width: 110,
								cellStyle: CellStyle.Centered,
							},
							renderer: (props: { data: { id: string } }) => (
								<EditionCover
									{...{
										quality: 'low',
										id: props.data.id,
									}}
								/>
							),
						},
						{
							rowDrag: true,
							field: 'title',
							headerName: 'Conteúdo',
							resizable: true,
							cellStyle: {
								display: 'flex ',
								'justify-content': 'left',
								'align-items': 'center ',
							},
							wrapText: true,
							width: 600,
						},
						{
							field: 'authors',
							headerName: 'Autores',
							resizable: true,
							wrapText: true,
							width: 600,
							cellStyle: {
								display: 'flex ',
								'justify-content': 'left',
								'align-items': 'center ',
							},
						},
					],
					row_height: () => 130,
				}}
			/>

			<InsertContentForISBNModal
				{...{
					id,
					visible: add_by_isbn_visible,
					close: () => set_add_by_isbn_visible(false),
					on_insert: refresh,
				}}
			/>

			<ContentInsertModal
				{...{
					id,
					close: () => set_update_contents_modal_visible(false),
					contents: content_group.result?.contents,
					visible: update_contents_modal_visible,
					on_success: refresh,
				}}
			/>
		</>
	);
};
