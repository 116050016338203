import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	book_id?: string;
	edition_id?: string;
	in_chapters?: boolean;
	chapter_id?: string;
	new_chapter?: boolean;
}

export const ChaptersBreadcrumb: FC<IProps> = ({
	book_id,
	edition_id,
	new_chapter,
	chapter_id,
	in_chapters,
}) => (
	<Breadcrumb>
		<Breadcrumb.Item key='books'>
			<Link to={'/conteudos/livros'}>Livros</Link>
		</Breadcrumb.Item>

		<Breadcrumb.Item key='book_title'>
			<Link to={`/conteudos/livros/${book_id}`}>{book_id}</Link>
		</Breadcrumb.Item>

		<Breadcrumb.Item key='edition_id'>
			<Link to={`/conteudos/livros/${book_id}/edicoes`}>Edições</Link>
		</Breadcrumb.Item>

		<Breadcrumb.Item key='edition_id'>
			<Link to={`/conteudos/livros/${book_id}/edicoes/${edition_id}`}>{edition_id}</Link>
		</Breadcrumb.Item>

		{in_chapters && (
			<Breadcrumb.Item key='chapter_id'>
				<Link to={`/conteudos/livros/${book_id}/edicoes/${edition_id}/capitulos`}>Capítulos</Link>
			</Breadcrumb.Item>
		)}

		{new_chapter && <Breadcrumb.Item key='new_chapter'>novo</Breadcrumb.Item>}

		{chapter_id && (
			<Breadcrumb.Item key='edition_id'>
				<Link to={`/conteudos/livros/${book_id}/edition/${edition_id}/capitulos/${chapter_id}`}>
					{chapter_id}
				</Link>
			</Breadcrumb.Item>
		)}
	</Breadcrumb>
);
