import React from 'react';

export const AreaInterestIcon = (props: any) => (
	<svg viewBox='0 0 24 24' className='w-4 h-4 fill-current' {...props}>
		<path d='m12 0c-1.24 0-2.25 1.009-2.25 2.25v.5c0 .414.336.75.75.75h3c.414 0 .75-.336.75-.75v-.5c0-1.241-1.01-2.25-2.25-2.25z' />
		<path d='m20.25 4h-16.5c-.553 0-1-.448-1-1s.447-1 1-1h16.5c.553 0 1 .448 1 1s-.447 1-1 1z' />
		<path d='m5 5v14.038c0 .697.395 1.308 1.029 1.595.235.106.482.158.727.158.414 0 .821-.15 1.149-.439l4.095-3.603 4.094 3.603c.523.46 1.242.567 1.877.281.634-.287 1.029-.898 1.029-1.595v-14.038z' />
		<path d='m12 18.75-2.5 2.2v2.3c0 .41.34.75.75.75h3.5c.41 0 .75-.34.75-.75v-2.3z' />
	</svg>
);
