import { parseISO } from 'date-fns';
import { FC, useEffect } from 'react';
import { DateInput } from '../../../../../../lib/components/DateInput';
import { FormContainer } from '../../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../../lib/components/Form/FormField';
import { Clipping } from '../../../../../../lib/model/Clipping';
import { ClippingFormButtons } from '../../../components/ClippingFormButtons';
import { InputHtmlContent } from '../InputHtmlContent';
import './html_text.scss';
import { ClippingArgs, useClippingForm } from './useClippingForm';

interface IClippingFormProps {
	clipping?: Clipping;
	on_submit: (values: ClippingArgs) => void;
}

export const ClippingForm: FC<IClippingFormProps> = ({ clipping, on_submit }) => {
	const { change_field_value, form_state, show_errors, fill_with_data } = useClippingForm();

	useEffect(() => {
		if (clipping) {
			fill_with_data(clipping);
		}
	}, []);

	function validate_and_submit(args: ClippingArgs) {
		show_errors();

		if (!form_state.valid) {
			return;
		}

		return on_submit(args);
	}

	return (
		<FormContainer>
			<FormField
				{...{
					label: 'Data de Publicacao',
					name: 'publish_date',
					form_state,
					required: true,

					children: (
						<DateInput
							{...{
								disabled: !!clipping,
								key: clipping?.id,
								date: parseISO(form_state.value.publish_date),
								on_changed: (date) => change_field_value('publish_date', date),
							}}
						/>
					),
				}}
			/>

			<FormField
				{...{
					label: 'Texto HTML',
					name: 'text',
					form_state,
					required: true,
					children: (
						<InputHtmlContent
							{...{
								initial_value: clipping ? clipping.text : '',
								on_change: (html_content) => change_field_value('text', html_content),
							}}
						/>
					),
				}}
			/>

			<ClippingFormButtons
				{...{
					disabled: !form_state.value.publish_date || !form_state.valid,
					on_submit: () => validate_and_submit(form_state.value),
					has_clipping: !!clipping,
				}}
			/>
		</FormContainer>
	);
};
