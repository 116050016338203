import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchableQueryDto } from '../model/SearchableQueryDto';
import { useQuery } from './useQuery';
import { useSearchableApi } from './useSearchableApi';

export function useSearchable<T>(default_query: SearchableQueryDto, entity_name: string) {
	const location = useLocation();
	const [pathname] = useState(location.pathname);
	const query = useQuery(default_query);
	const { result, loading, error, search } = useSearchableApi<T>(entity_name);

	const dto: SearchableQueryDto = {
		tags: query.tags ? default_query.tags : '',
		text: query.text || default_query.text || '',
		sort: query.sort || default_query.sort || '',
		sort_dir: query.sort_dir || default_query.sort_dir || 'desc',
		limit: query.limit || default_query.limit || 20,
		page: query.page || default_query?.limit || 1,
	};

	useEffect(() => {
		if (location.pathname !== pathname) {
			return;
		}
		query.clear();
	}, [location.pathname]);

	useEffect(() => {
		if (location.pathname !== pathname || loading) {
			return;
		}

		search(dto);
	}, [location.pathname, dto.text, dto.page, dto.sort_dir, dto.sort, dto.tags]);

	return {
		result,
		error,
		loading,
		query,
		reload: () => search(dto),
	};
}
