import { FC, useState } from 'react';
import { Loader } from '../../../../lib/components/Loader';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { InformativeListBreadcrumb } from '../components/InformativeListBreadcrumb';
import { InformativeTypeEntity } from '../../../../lib/model/InformativeType';
import { InformativesTypeDataTable } from '../components/InformativesTypeDataTable';
import { InformativeListTypeDto } from '../dto/InformativeListTypeDto';

export const InformativeListTypesPage: FC = () => {
	const [remove_counter, set_remove_counter] = useState(0);

	const BASE_URL = `${process.env.REACT_APP_INFORMATIVE_API}/informatives`;

	return (
		<PageLayout
			{...{
				title: 'Informativos',
				browser_back: true,
				breadcrumb: () => <InformativeListBreadcrumb />,
			}}
		>
			<Loader
				{...{
					reload_when_changes: [remove_counter],
					request: () => request().get<InformativeListTypeDto[]>(BASE_URL),
					renderChildren: (informative_types: InformativeTypeEntity[]) => {
						return (
							<InformativesTypeDataTable
								{...{
									informative_types,
									on_remove_success: () => set_remove_counter(remove_counter + 1),
								}}
							/>
						);
					},
				}}
			/>
		</PageLayout>
	);
};
