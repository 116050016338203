import { Tag } from './Tag';

export type BookEntity = {
	id: string;
	num_id: number;
	title: string;
	cdu: string;
	cdd: string;
	book_serie: Tag;
	tags: Tag[];
};

export type EditionEntity = {
	book_num_id: string;
	id: string;
	num_id: string;
	title: string;
	isbn: string;
	description: string;
	tags: Tag[];
	number: number;
	pages: number;
	subtitle: string;
	coordinators: Tag[];
	authors: Tag[];
	publish_date: string;
	is_actual?: boolean;
};

export type ChapterEntity = {
	id: string;
	num_id: string;
	title: string;
	chapter_type: ChapterTypes;
	language: Languages;
	authors: Tag[];
	order: number;
	tags: Tag[];
	pdf?: string;
	key?: string;
};

export enum ChapterTypes {
	PRE_TEXTUAL = 'pre-textual',
	CHAPTER = 'capitulo',
	POS_TEXTUAL = 'pos-textual',
}

export enum ChapterSubTypesPreTextual {
	OUTROS = '',
	FOLHA_DE_ROSTO = 'Folha de Rosto',
	EXPEDIENTE = 'Expediente',
	DEDICATORIA = 'Dedicatória',
	AGRADECIMENTO = 'Agradecimento',
	EPIGRAFE = 'Epígrafe',
	LISTA_DE_ILUSTRACOES = 'Lista de Ilustrações',
	LISTA_DE_TABELAS = 'Lista de Tabelas',
	LISTA_DE_ABREVIATURAS_E_SIGLAS = 'Lista de Abreviaturas e Siglas',
	LISTA_DE_SIMBOLOS = 'Lista de Símbolos',
	SUMARIO = 'Sumário',
	PREFACIO = 'Prefácio',
	APRESENTACAO = 'Apresentação',
}

export enum ChapterSubTypesPosTextual {
	OUTROS = '',
	POSFACIO = 'Pósfacio',
	REFERENCIAS = 'Referências',
	GLOSSARIO = 'Glossário',
	APENDICE = 'Apêndice',
	ANEXO = 'Anexo',
	INDICES = 'Índices',
	SOBRE_OS_AUTORES = 'Sobre os Autores',
}

export enum Languages {
	ENGLISH = 'en',
	PORTUGUESE = 'pt-br',
	SPANISH = 'es',
}

export enum SignedUrlTypes {
	READ = 'read',
	WRITE = 'write',
}
