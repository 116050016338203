import { FC } from 'react';
import { DownloadFromSignedUrlButton } from '../../../../lib/components/Buttons/DownloadFromSignedUrlButton';

interface IProps {
	version_date: number;
}

export const DownloadPriceVersionXlsButton: FC<IProps> = ({ version_date }) => (
	<DownloadFromSignedUrlButton
		{...{ api_url: `${process.env.REACT_APP_PRICING_ADMIN_API}/list/download/${version_date}` }}
	/>
);
