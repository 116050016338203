import { FC, useState } from 'react';
import { SearchableProvider } from '../../../../../lib/components/SearchableDataTable/SearchableProvider';
import { PageLayout } from '../../../../../lib/shell/PageLayout/PageLayout';
import { SearchTagInsertModal } from '../components/SearchTagInsertModal';
import { SearchTagsDatatable } from '../components/SearchTagsDatatable';

export const EventTagListPage: FC = () => {
	const [remove_counter, set_remove_counter] = useState(0);
	const [insert_counter, set_insert_counter] = useState(0);
	const [visible_form_insert, set_visible_form_insert] = useState(false);

	return (
		<PageLayout title='Tag de Evento'>
			<SearchableProvider
				{...{
					key: `tag-list-${insert_counter}-${remove_counter}`,
					entity_name: 'admin-search-tags-entity',
					default_query: {
						limit: 20,
						page: 1,
						sort: 'sort1',
						sort_dir: 'desc',
						tags: 'event',
						text: '',
					},
				}}
			>
				<SearchTagsDatatable
					{...{
						title_of_the_item_to_be_removed: 'tag de evento',
						on_remove_success: () => set_remove_counter(remove_counter + 1),
						visible_insert_modal: () => set_visible_form_insert(true),
					}}
				/>
			</SearchableProvider>
			<SearchTagInsertModal
				{...{
					tag_name_in_portuguese: 'Tag de Evento',
					prefix: 'event',
					visible: visible_form_insert,
					close: () => set_visible_form_insert(false),
					insert_success: () => set_insert_counter(insert_counter + 1),
				}}
			/>
		</PageLayout>
	);
};
