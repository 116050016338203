import { formatRelative } from 'date-fns';
import { pt } from 'date-fns/locale';
import React, { FC } from 'react';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { RemoveButton } from '../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../lib/components/DataTable/ColumnFactory';
import { SearchableDataTable } from '../../../../lib/components/SearchableDataTable/SearchableDataTable';
import { useRequestFeedback } from '../../../../lib/hooks';
import { ContentListDto } from '../dtos/ContentListDto';
import { useContentAssignmentApi } from '../hooks/useContentListApi';

interface IProps {
	set_visible_form_insert: () => void;
}

export const ContentList: FC<IProps> = ({ set_visible_form_insert }) => {
	const { remove } = useContentAssignmentApi();

	useRequestFeedback(remove.result, remove.error);

	return (
		<SearchableDataTable
			{...{
				re_render: !!remove.result,
				sortable_fields: ['Nome'],
				row_height: () => 60,
				searchable_defaults: {
					sort_field: 'date1',
					sort_dir: 'desc',
				},
				date_fields: ['Última atividade'],
				buttons_on_top: [<AddButton {...{ onClick: set_visible_form_insert }} />],

				row_data_from_entity: (result: { list: ContentListDto }) => {
					const yet = formatRelative(result.list.updated_at, Date.now(), {
						locale: pt,
					});

					return {
						id: result.list.id,
						name: result.list.name,
						contents_title: result.list.contents.map((content: { title: string }) => content.title),
						contents: result.list.contents,
						associateds: result.list.associateds.length,
						last_activity: yet,
					};
				},
				columns: [
					ColumnFactory.Link(
						{
							field: 'name',
							headerName: 'Nome',
							resizable: true,
							rowDrag: false,
							width: 350,
						},
						(props: { data: { id: string } }) => `${props.data.id}`
					),

					{
						field: 'associateds',
						headerName: 'Qtd. de Associados',
						resizable: true,
						width: 160,
					},
					{
						field: 'last_activity',
						headerName: 'Última atividade',
						resizable: true,
						rowDrag: false,
						width: 150,
					},
					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							cellStyle: CellStyle.Centered,
							width: 100,
						},
						renderer: (props: { data: ContentListDto }) => (
							<RemoveButton
								{...{
									on_confirm: () => remove.do_remove(props.data.id),
									title: 'Deseja remover essa lista?',
								}}
							/>
						),
					},
				],
			}}
		/>
	);
};
