import { Checkbox, Input, message } from 'antd';
import { FC, useEffect } from 'react';
import { FormButtons } from '../../../../../lib/components/Form/FormButtons';
import { FormContainer } from '../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../lib/components/Form/FormField';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { Banner } from '../../../../../lib/model/Banner';
import { BannerDto } from '../../dtos/BannerDto';
import { useBannersApi } from '../../hooks/useBannersApi';
import { useBannerForm } from './useBannerForm';

interface IProps {
	banner: Banner;
}

export const BannerForm: FC<IProps> = ({ banner }) => {
	const { form_state, show_errors, change_field_value, fill_with_data } = useBannerForm();
	const { update } = useBannersApi();

	const url1 = banner.links[0]?.href;
	const url2 = banner.links[1]?.href;

	useEffect(() => {
		fill_with_data({
			...banner,
			href1: url1 ? url1 : '',
			href2: url2 ? url2 : '',
			open_new_window1: banner.links[0] ? banner.links[0].open_new_window : false,
			open_new_window2: banner.links[1] ? banner.links[1].open_new_window : false,
		});
	}, [banner]);

	useRequestFeedback(update.result, update.error);

	function submit() {
		show_errors();

		if (!form_state.valid) {
			return;
		}

		function validade_link(link: string) {
			if (link) {
				try {
					const url = new URL(link);

					return url.href;
				} catch (error) {
					message.error('URL inválida.');
				}
			}

			return link;
		}

		const dto: BannerDto = {
			id: form_state.value.id,
			name: form_state.value.name,
			links: [
				{
					href: form_state.value.href1 ? validade_link(form_state.value.href1) : '',
					open_new_window: form_state.value.open_new_window1,
				},
				{
					href: form_state.value.href2 ? validade_link(form_state.value.href2) : '',
					open_new_window: form_state.value.open_new_window2,
				},
			],
		};

		update.do_update(dto);
	}

	return (
		<FormContainer>
			<FormField {...{ label: 'Nome', name: 'name', form_state }}>
				<Input
					value={form_state.value.name}
					onChange={(event) => change_field_value('name', event.target.value)}
					className='mb-3 w-1/2'
				/>
			</FormField>

			<FormField {...{ label: 'Link 1', name: 'href1', form_state, required: false }}>
				<Input
					{...{
						value: form_state.value.href1,
						onChange: (event) => change_field_value('href1', event.target.value),
						className: 'mb-3 w-1/2',
					}}
				/>
			</FormField>

			<FormField
				label='Abrir Link em nova janela?'
				name='open_new_window1'
				form_state={form_state}
				required={false}
				render_children_inside_label={false}
				class_name='flex flex-row'
			>
				<Checkbox
					checked={form_state.value.open_new_window1}
					onChange={(event) => change_field_value('open_new_window1', event.target.checked)}
					className='mb-5 ml-1'
				/>
			</FormField>

			<FormField {...{ label: 'Link 2', name: 'href2', form_state, required: false }}>
				<Input
					{...{
						value: form_state.value.href2,
						onChange: (event) => change_field_value('href2', event.target.value),
						className: 'mb-3 w-1/2',
					}}
				/>
			</FormField>

			<FormField
				label='Abrir Link em nova janela?'
				name='open_new_window2'
				form_state={form_state}
				required={false}
				render_children_inside_label={false}
				class_name='flex flex-row'
			>
				<Checkbox
					checked={form_state.value.open_new_window2}
					onChange={(evt) => change_field_value('open_new_window2', evt.target.checked)}
					className='mb-5 ml-1'
				/>
			</FormField>

			<FormButtons {...{ go_back_on_cancel: true, on_update_button_click: submit }} />
		</FormContainer>
	);
};
