import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';
import { Content } from '../../../../model/Content';
import { EditionCover } from '../EditionCover';

interface IProps {
	selected_contents: Content[];
	on_change: (editions: Content[]) => any;
}

export const SelectedEdition: React.FC<IProps> = ({ selected_contents, on_change }) => {
	function remove(edition: Content): Content[] {
		function content_index() {
			return selected_contents.findIndex((content) => edition === content);
		}

		selected_contents.splice(content_index(), 1);

		return [...selected_contents];
	}

	return (
		<>
			{selected_contents.length !== 0 && (
				<ul className='border bg-white px-4 py-4 overflow-y-auto' style={{ height: '21.9rem' }}>
					{selected_contents.map((content) => (
						<li
							{...{
								className: 'flex w-full mb-8 items-start',
								key: content.id,
							}}
						>
							<div className='mr-3 w-12 '>
								<EditionCover
									{...{
										quality: 'low',
										id: content.id,
									}}
								/>
							</div>
							<div className='w-full flex flex-col'>
								<span className='text-sm'>{content.title}</span>

								<span className='text-xs  mt-3 '>Autores: {content.authors}</span>

								<span className='w-full flex justify-end'>
									<DeleteOutlined
										style={{ color: '#ff0000' }}
										onClick={() => on_change(remove(content))}
									/>
								</span>
							</div>
						</li>
					))}
				</ul>
			)}
		</>
	);
};
