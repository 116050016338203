import { Select } from 'antd';
import { FC } from 'react';
import { PublishType } from '../../../../../lib/model/Periodic';

const Option = Select;

interface IProps {
	value: PublishType;
	on_change: (field_name: any, value: PublishType) => void;
}

export const SelectPublishType: FC<IProps> = ({ value, on_change }) => (
	<Select
		placeholder='Selecione o Tipo de Publicação'
		onChange={(publishType) => on_change('publishType', publishType)}
		defaultValue={PublishType.Periodic}
		style={{ width: '50%' }}
		value={value}
	>
		<Option value={PublishType.Periodic} key={PublishType.Periodic}>
			Revista
		</Option>
		<Option value={PublishType.Collection} key={PublishType.Collection}>
			Coleção
		</Option>
	</Select>
);
