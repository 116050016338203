import { GlobalOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { List } from '../../../../../lib/components/List/List';

interface IProps {
	domains: string[];
	on_removed: (d: string) => void;
}

export const DomainsList: FC<IProps> = ({ domains, on_removed }) => {
	return (
		<List
			{...{
				data: domains,
				header: 'Domínios habilitados',
				on_remove_click: on_removed,
				icon: (
					<GlobalOutlined
						{...{
							className: ' text-blue-500  flex items-center justify-center  py-1 ',
						}}
					/>
				),
			}}
		/>
	);
};
