import { ReactNode } from 'react';
import { useSearch } from './hooks/useSearch';
import { SearchQueryDto } from './model/SearchQueryDto';
import { IEntity, SearchContext } from './SearchContext';

interface IProps {
	children: ReactNode;
	default_query: SearchQueryDto;
	version_date: number | 'current';
}

type ISearch = {
	total: number;
	entities: IEntity[];
};

export const SearchProvider: React.FC<IProps> = ({ children, default_query, version_date }) => {
	// TODO - Inserir tipo correto
	const search = useSearch<any>(default_query, version_date);

	return <SearchContext.Provider value={search}>{children}</SearchContext.Provider>;
};