import { FC } from 'react';
import { SearchableProvider } from '../../../../lib/components/SearchableDataTable/SearchableProvider';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { UserProfilesDataTable } from '../components/UserProfilesDataTable';

export const UserProfileListPage: FC = () => (
	<PageLayout {...{ title: 'Perfis de Usuários', key: 'user-profiles' }}>
		<SearchableProvider
			{...{
				entity_name: 'store-user-profile',
				default_query: {
					limit: 20,
					page: 1,
					sort: 'date1',
					sort_dir: 'desc',
					tags: '',
					text: '',
				},
			}}
		>
			<UserProfilesDataTable />
		</SearchableProvider>
	</PageLayout>
);
