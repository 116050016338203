import { FC, useEffect } from 'react';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { usePricesListNav } from '../../hooks/usePricestListNav';
import { useInsert } from '../../hooks/usePriceVersionApi';
import { Form } from './Form';

interface IProps {
	version_date: number;
	on_success: () => void;
}

export const InsertPriceVersionForm: FC<IProps> = ({ version_date, on_success }) => {
	const insert = useInsert();
	const { go_to_preview_page } = usePricesListNav();

	useEffect(() => {
		if (!!insert?.result) {
			go_to_preview_page(version_date);
			on_success();
		}
	}, [insert?.result]);

	useRequestFeedback(insert?.result, insert?.error);

	return <Form {...{ loading: insert.loading, submit: insert.do_insert, version_date }} />;
};
