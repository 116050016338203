import { CheckCircleTwoTone } from '@ant-design/icons';
import { Button } from 'antd';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { ButtonsContainer } from '../../../../lib/components/Buttons/ButtonsContainer';
import { RemoveButton } from '../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../lib/components/DataTable/ColumnFactory';
import { DataTable } from '../../../../lib/components/DataTable/DataTable';
import { Loader } from '../../../../lib/components/Loader';
import { useRequestFeedback } from '../../../../lib/hooks';
import { Client } from '../../../../lib/model/Client';
import { Contract } from '../../../../lib/model/Contract';
import { request } from '../../../../lib/utils/request';
import { ContractDto } from '../client_contracts/dtos/ContractDto';
import { useContractManagementApi } from '../client_contracts/hooks/useContractManagementApi';

const API_URL = process.env.REACT_APP_CLIENT_CONTRACT_MANAGEMENT_API;

interface IProps {
	client: Client;
	show_contracted_contents?: () => void;
}

export const ContractDatatable: FC<IProps> = ({ client, show_contracted_contents }) => {
	const navigate = useNavigate();

	const { remove } = useContractManagementApi();
	useRequestFeedback(remove.removed, remove.error);

	return (
		<Loader
			{...{
				request: () => request().get<ContractDto[]>(`${API_URL}/clients/${client.id}/contracts`),
				reload_when_changes: [!!remove.removed],
				renderChildren: (contracts: Contract[]) => (
					<DataTable
						{...{
							buttons: [
								<ButtonsContainer class_name='flex flex-row'>
									<AddButton onClick={() => navigate('contratos/novo')} />
									{show_contracted_contents && (
										<Button onClick={show_contracted_contents} style={{ marginLeft: '1rem' }}>
											Conteudos Contratados
										</Button>
									)}
								</ButtonsContainer>,
							],
							columns: [
								{
									renderer: (props: { data: { active: boolean } }) =>
										props.data.active ? (
											<CheckCircleTwoTone
												{...{
													twoToneColor: '#52c41a',
												}}
											/>
										) : null,

									col_def: {
										field: 'active',
										headerName: 'Ativo',
										width: 70,
										cellStyle: CellStyle.Centered,
									},
								},
								ColumnFactory.Link(
									{
										field: 'c_number',
										headerName: 'Número do Contrato',
										resizable: true,
										rowDrag: false,
										width: 180,
									},
									(props: { data: { c_number: number } }) => `contratos/${props.data.c_number}`
								),
								{
									field: 'client_id',
									headerName: 'ID do Cliente',
									resizable: true,
									rowDrag: false,
									width: 120,
								},
								{
									field: 'started_at',
									headerName: 'Data de Início',
									resizable: true,
									rowDrag: false,
									width: 125,
								},
								{
									field: 'end_at',
									headerName: 'Data de Término',
									resizable: true,
									rowDrag: false,
									width: 140,
								},
								{
									col_def: {
										field: 'buttons',
										headerName: '',
										width: 100,
										cellStyle: CellStyle.Centered,
										pinned: 'right',
									},
									renderer: (props: { data: { c_number: number; client_id: string } }) => (
										<RemoveButton
											{...{
												title: 'Deseja remover esse contrato?',
												on_confirm: () =>
													remove.do_remove(props.data.client_id, props.data.c_number),
											}}
										/>
									),
								},
							],
							rowData: contracts.map((contract) => ({
								...contract,
								started_at: format(contract.started_at, 'dd/MM/yyyy', { locale: pt }),
								end_at: format(contract.end_at, 'dd/MM/yyyy', { locale: pt }),
							})),
						}}
					/>
				),
			}}
		/>
	);
};
