import { FC } from 'react';
import { UpdateButton } from '../../../../../../lib/components/Buttons/UpdateButton';

interface IProps {
	show_content_list: () => void;
}

export const TagsFormField: FC<IProps> = ({ show_content_list }) => {
	return (
		<section className='flex flex-row items-center pb-4'>
			<Title />
			<UpdateButton {...{ onClick: show_content_list }} />
		</section>
	);
};

const Title: FC = () => <h1 className='text-lg font-medium mr-6'>Conteúdos</h1>;
