import generatePicker from 'antd/lib/date-picker/generatePicker';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import { format } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import { FC } from 'react';

interface IProps {
	date: Date;
	on_changed: (value: string) => void;
	disabled?: boolean;
	style?: any;
}

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export const DateInput: FC<IProps> = ({ date, on_changed, disabled, style }) => (
	<DatePicker
		{...{
			style: style || {},
			disabled,
			format: 'dd/MM/yyyy',
			locale,
			value: date,
			onChange: (date: any) => on_changed(format(date, 'yyyy-MM-dd')),
			allowClear: false,
		}}
	/>
);
