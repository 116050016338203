import React, { FC } from 'react';
import { UpdateButton } from '../../../../lib/components/Buttons/UpdateButton';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { DataTable } from '../../../../lib/components/DataTable/DataTable';
import { EditionCover } from '../../../../lib/features/book_edition/components/EditionCover';
import { Content } from '../../../../lib/model/Content';

interface IProps {
	contents: Content[] | [];
	set_visible_content_modal: () => void;
}

export const ContentDataTable: FC<IProps> = ({ contents, set_visible_content_modal }) => (
	<DataTable
		{...{
			buttons: [<UpdateButton {...{ onClick: set_visible_content_modal }} />],
			rowData: contents,
			columns: [
				{
					col_def: {
						field: 'cover',
						headerName: '',
						width: 110,
						cellStyle: CellStyle.Centered,
					},
					renderer: (props: { data: { id: string } }) => (
						<EditionCover
							{...{
								quality: 'low',
								id: props.data.id,
							}}
						/>
					),
				},
				{
					field: 'title',
					headerName: 'Conteúdo',
					resizable: true,
					cellStyle: CellStyle.Left,
					wrapText: true,
					width: 600,
				},
				{
					field: 'authors',
					headerName: 'Autores',
					resizable: true,
					wrapText: true,
					width: 600,
					cellStyle: CellStyle.Left,
				},
			],
			row_height: () => 130,
		}}
	/>
);
