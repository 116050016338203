import { Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { CancelButton } from '../../components/Buttons/CancelButton';
import { Content } from '../../model/Content';
import { BookEditionSelect } from '../book_edition/components/BookEditionSelect';
import { useContentGroupApi } from './hooks/useContentGroup';

interface IProps {
	id: string;
	visible: boolean;
	close: () => void;
	contents: Content[] | [];
	on_success: (value: any) => void;
}

export const ContentInsertModal: React.FC<IProps> = ({ id, close, visible, contents, on_success }) => {
	const [editions, set_edition_selecteds] = useState<Content[]>(contents);

	const { update } = useContentGroupApi();

	useEffect(() => {
		if (update.result) {
			on_success(editions);
			close();
		}
	}, [update.result]);

	useEffect(() => {
		set_edition_selecteds(contents);
	}, [contents]);

	return (
		<Modal
			{...{
				title: 'Editar Conteúdo',
				visible,
				centered: true,
				width: 1070,
				closable: false,
				children: (
					<BookEditionSelect
						{...{
							value: editions,
							on_change: set_edition_selecteds,
						}}
					/>
				),
				footer: [
					<CancelButton {...{ onClick: close, className: 'mr-2' }} />,
					<Button type={'primary'} onClick={() => update.do_update(id, editions)}>
						Confirmar
					</Button>,
				],
			}}
		/>
	);
};
