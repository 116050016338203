import React, { FC, ReactNode } from 'react';
import { Loader } from '../../../../lib/components/Loader';
import { PriceVersionDto } from '../dtos/PriceVersionDto';

interface IProps {
	version_date: number;
	renderChildren: (version: PriceVersionDto) => ReactNode;
	get_version: (version_date: number) => Promise<PriceVersionDto>;
	result: PriceVersionDto;
}

export const PriceVersionLoader: FC<IProps> = ({ renderChildren, version_date, get_version, result }) => (
	<Loader
		{...{
			request: () => get_version(version_date),
			reload_when_changes: [result],
			renderChildren,
		}}
	/>
);
