import { MultiLineInput } from '../../components/MultilineInput';

interface IProps {
	on_submit: (isbns: string[]) => void;
}

export const MultiISBNInput: React.FC<IProps> = ({ on_submit }) => {
	return (
		<MultiLineInput
			{...{
				on_submit: (isbns: string[]) => {
					on_submit(isbns.map((isbn) => isbn.trim().replaceAll('-', '')));
				},
				validate_fn: (isbn: string) => {
					if (isbn.length < 9) {
						return `${isbn} inválido.`;
					}

					return '';
				},
			}}
		/>
	);
};
