import { FC, ReactNode, useEffect } from 'react';
import { DataTable, IDataTableProps } from '../DataTable/DataTable';
import { Column } from '../DataTable/Types';
import { Pagination } from '../Pagination/Pagination';
import { useSearchableContext } from './hooks/useSearchableContext';
import { SearchableDefaults, SortDirection, SortField } from './model/SearchableQueryDto';
import { ReloadButton } from './ReloadButton';
import { SearchableSearchInput } from './SearchableSearchInput';
import { SortFields } from './SortFields';

interface IProps extends IDataTableProps {
	columns: Column[];
	tags?: string[];
	row_data_from_entity?: (entity: any) => any;
	buttons_on_top?: ReactNode[];
	sortable_fields?: string[];
	date_fields?: string[];
	searchable_defaults?: SearchableDefaults;
	re_render?: boolean;
	show_input_text?: boolean;
	row_height?: (props: any) => any;
}

export const SearchableDataTable: FC<IProps> = ({
	columns,
	buttons_on_top = [],
	tags,
	row_data_from_entity = (e) => e,
	sortable_fields,
	date_fields,
	searchable_defaults,
	re_render,
	show_input_text = true,
	row_height = () => 130,
	...data_table_props
}) => {
	const { result, loading, error, query, reload } = useSearchableContext();

	useEffect(() => {
		if (re_render) {
			reload();
		}
	}, [re_render]);

	return (
		<DataTable
			{...{
				...data_table_props,
				loading,
				columns,
				rowData: (result?.entities || []).map(row_data_from_entity),
				on_refresh_button_click: reload,
				buttons: [...buttons_on_top, <ReloadButton {...{ on_click: reload }} />].filter((el) => !!el),
				row_height,
				element_on_top: (
					<div className='flex flex-col'>
						<div className='max-w-lg'>
							{show_input_text && (
								<SearchableSearchInput
									{...{
										on_search: query?.change_text,
									}}
								/>
							)}

							{(!!sortable_fields || !!date_fields) && (
								<SortFields
									{...{
										on_change: query?.change_sort_dir,
										sort_dir: query?.sort_dir as SortDirection,
										sort_field: query?.sort as SortField,
										sortable_fields,
										date_fields,
									}}
								/>
							)}
						</div>
					</div>
				),
				element_on_bottom: [
					<Pagination
						{...{
							change_page: query?.change_page,
							loading,
							page: query?.page,
							total: result?.total,
						}}
					/>,
				],
			}}
		/>
	);
};
