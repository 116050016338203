import React, { useEffect, useState } from 'react';
import { setRequestToken } from '../../utils/request';
import { LoginErrorsMessage } from './components/LoginErrorsMessage';
import { forum_auth_context } from './forum_auth_context';
import { useAdminAuthApi } from './hooks/useAdminAuthApi';
import { useSessionStore } from './hooks/useSessionStore';
import { EmptySession, Session } from './model/Session';

const AuthContext = forum_auth_context;

interface IProps {
	auth_token: string;
	auth0_logout: () => any;
	children: JSX.Element;
}

export const ForumAuthProvider: React.FC<IProps> = ({ auth_token, auth0_logout, children }) => {
	const [session, set_session] = useState<Session>(EmptySession);
	const session_storage = useSessionStore();
	const [error, set_error] = useState('');
	const api = useAdminAuthApi(auth_token);

	const logout = () => {
		api.logout(session.created_at);
		session_storage.clear();
		auth0_logout();
	};

	useEffect(() => {
		const [ses_not_found_in_localstorage, ses_info] = session_storage.get();

		const req = ses_not_found_in_localstorage ? api.login() : api.restore(ses_info?.created_at || 0);

		req.then((dto) => {
			session_storage.store(dto.session.created_at);
			setRequestToken(dto.token);
			set_session(dto.session as Session);
		}).catch((err) => {
			set_error(err);
		});
	}, [auth_token]);

	if (error) {
		return <LoginErrorsMessage {...{ api_response: error, logout }} />;
	}

	return (
		<AuthContext.Provider
			value={{
				session,
				logout,
			}}
		>
			{session !== EmptySession && children}
		</AuthContext.Provider>
	);
};
