import { useParams } from 'react-router-dom';
import { useRequest } from '../../../../../lib/hooks';
import { request } from '../../../../../lib/utils/request';
import { ClippingDto } from '../../dtos/ClippingDtos';
import { InsertClippingDto } from '../../dtos/InsertClippingDto';
import { UpdateClippingDto } from '../../dtos/UpdateClippingDto';

const BASE_URL = `${process.env.REACT_APP_CLIPPING_API}/sources`;

export function useClippingsApi() {
	const { source_id } = useParams() as { source_id: string };

	const insert = useCreateClipping(source_id);
	const update = useUpdateClipping(source_id);
	const remove = useRemoveClipping(source_id);

	return {
		insert,
		remove,
		update,
	};
}

function useCreateClipping(source_id: string) {
	const [result, loading, error, do_create] = useRequest<ClippingDto>((dto: InsertClippingDto) => {
		return new Promise((resolve, reject) => {
			request()
				.post(`${BASE_URL}/${source_id}/clippings`, dto)
				.then((res) => setTimeout(() => resolve(res as ClippingDto), 1500))
				.catch(reject);
		});
	});

	return {
		create: (dto: InsertClippingDto) => do_create(dto),
		result,
		error,
		loading,
	};
}

function useUpdateClipping(source_id: string) {
	const [result, loading, error, update] = useRequest<ClippingDto>((dto: UpdateClippingDto) => {
		return new Promise((resolve, reject) => {
			request()
				.put(`${BASE_URL}/${source_id}/clippings`, dto)
				.then((res) => setTimeout(() => resolve(res as ClippingDto), 1500))
				.catch(reject);
		});
	});

	return {
		do_update: (dto: UpdateClippingDto) => update(dto),
		result,
		error,
		loading,
	};
}

function useRemoveClipping(source_id: string) {
	const [result, loading, error, remove] = useRequest<string>((id: string) => {
		return new Promise((resolve, reject) => {
			request()
				.delete(`${BASE_URL}/${source_id}/clippings/${id}`)
				.then((res) => setTimeout(() => resolve(res as string), 1500))
				.catch(reject);
		});
	});

	return {
		do_remove: (id: string) => remove(id),
		result,
		loading,
		error,
	};
}
