import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { EditionListPage } from './pages/EditionListPage';
import { InsertEditionPage } from './pages/InsertEditionPage';
import { UpdateEditionPage } from './pages/UpdateEditionPage';

export const EditionRoutes: FC = () => (
	<Routes>
		<Route {...{ path: ':edition_id', element: <UpdateEditionPage /> }} />
		<Route {...{ path: 'novo', element: <InsertEditionPage /> }} />
		<Route {...{ index: true, path: '/', element: <EditionListPage /> }} />
	</Routes>
);
