import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { DataTable } from '../../../../lib/components/DataTable/DataTable';
import { Loader } from '../../../../lib/components/Loader';
import { EditionCover } from '../../../../lib/features/book_edition/components/EditionCover';
import { UserTags } from '../../../../lib/model/UserProfile';
import { request } from '../../../../lib/utils/request';
import { UserTagsDto } from '../dtos/UserTagsDto';

export const UsersContentsDataTable: FC = () => {
	const { email } = useParams();

	return (
		<Loader
			{...{
				request: () =>
					request().get<UserTagsDto[]>(
						`${process.env.REACT_APP_STORE_AUTH_API}/${email}/tags?app_secret=bidforum1998`
					),

				renderChildren: (contents: UserTags[]) => (
					<DataTable
						{...{
							row_height: () => 150,
							rowData: contents,
							columns: [
								{
									col_def: {
										headerName: '',
										field: 'picture',
										width: 120,
										cellStyle: CellStyle.Centered,
									},
									renderer: (props: { data: { id: string } }) => {
										const tags = [{ id: props.data.id, title: '-' }];

										return <EditionCover {...{ quality: 'low', tags }} />;
									},
								},

								{
									field: 'description',
									headerName: 'Descrição',
									width: 500,
									resizable: true,
									cellStyle: {
										'justify-content': 'left',
										'align-items': 'center ',
									},
								},
								{
									field: 'source',
									headerName: 'Aquisição',
									width: 250,
									resizable: true,
									cellStyle: {
										'justify-content': 'left',
										'align-items': 'center ',
									},
								},
							],
						}}
					/>
				),
			}}
		/>
	);
};
