import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { ChapterRoutes } from './editions/chapters/routes';
import { EditionRoutes } from './editions/routes';
import { BookListPage } from './pages/BookListPage';
import { InsertBookPage } from './pages/InsertBookPage';
import { UpdateBookPage } from './pages/UpdateBookPage';

export const BookRoutes: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.Contents] }}>
		<Routes>
			<Route {...{ path: ':id/edicoes/:edition_id/capitulos/*', element: <ChapterRoutes /> }} />
			<Route {...{ path: ':id/edicoes/*', element: <EditionRoutes /> }} />
			<Route {...{ path: 'novo', element: <InsertBookPage /> }} />
			<Route {...{ path: ':id', element: <UpdateBookPage /> }} />
			<Route {...{ index: true, path: '/', element: <BookListPage /> }} />
		</Routes>
	</ProtectedContent>
);
