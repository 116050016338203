import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { EditionRoutes } from './editions/routes';
import { ItemRoutes } from './editions/items/routes';
import { PeriodicListPage } from './pages/PeriodicListPage';
import { InsertPeriodicPage } from './pages/InsertPeriodicPage';
import { UpdatePeriodicPage } from './pages/UpdatePeriodicPage';

export const PeriodicsRoutes: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.Contents] }}>
		<Routes>
			<Route {...{ path: ':id/edicoes/:editionId/itens/*', element: <ItemRoutes /> }} />
			<Route {...{ path: ':id/edicoes/*', element: <EditionRoutes /> }} />
			<Route {...{ path: 'novo', element: <InsertPeriodicPage /> }} />
			<Route {...{ path: ':id', element: <UpdatePeriodicPage /> }} />
			<Route {...{ index: true, path: '/', element: <PeriodicListPage /> }} />
		</Routes>
	</ProtectedContent>
);
