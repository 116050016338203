import axios from 'axios';
import { get_content_price } from '../../../features/content_price/get_content_price';
import { useRequest } from '../../../hooks/useRequest';
import { SearchResultDto } from '../../../model/SearchDto';
import { SearchQueryDto } from '../model/SearchQueryDto';
import { MemoryCache } from '../../../utils/MemoryCache';

const search_url = `${process.env.REACT_APP_STORE_SEARCH_API}`;

export function useSearchApi<T>(with_prices = true) {
	const call_search_api = async (
		dto: SearchQueryDto,
		version_date: number | 'current'
	): Promise<SearchResultDto<T>> => {
		const result = await axios.get(search_url, { params: dto }).then((r) => r.data as SearchResultDto<T>);

		if (!with_prices) {
			return result;
		}

		const contents_with_price = await Promise.all(
			result.entities.map((content) => put_prices_into(content, version_date))
		);

		return {
			total: result.total,
			entities: contents_with_price,
		};
	};

	const [result, loading, error, search_request] = useRequest(with_cache(call_search_api));

	return {
		search: (dto: SearchQueryDto, version_date: number | 'current') => search_request(dto, version_date),
		result,
		error,
		loading,
	};
}

const cache = MemoryCache();

function with_cache<T>(fn: (...args: any[]) => Promise<T>) {
	return async (...args: any[]): Promise<T> => {
		const cache_key = args.map((arg) => JSON.stringify(arg)).join('-');

		const [, r] = cache.get(cache_key);

		if (r) {
			return Promise.resolve(r as T);
		}

		const promise_result = await fn(...args);

		cache.set(cache_key, promise_result);

		return Promise.resolve(promise_result);
	};
}

async function put_prices_into(content: any, version_date: number | 'current') {
	const content_price = await get_content_price(content.id, version_date);

	return {
		...content,
		price_digital: `R$ ${content_price.price.digital.price}`,
		discount_price_digital: `${content_price.price.digital.discount_percent}%`,
		final_price_digital: `R$ ${content_price.price.digital.final_price}`,
		price_paper: `R$ ${content_price.price.paper.price}`,
		discount_price_paper: `${content_price.price.paper.discount_percent}%`,
		final_price_paper: `R$ ${content_price.price.paper.final_price}`,
	};
}
