import { Avatar } from 'antd';
import { FC } from 'react';

interface IProps {
	picture: string;
	name: string;
	email: string;
	cpf: string;
	phone: string;
	birthDate: string;
}

export const UserPersonalData: FC<IProps> = ({ cpf, email, name, phone, picture, birthDate }) => (
	<div className='flex flex-row items-center border border-gray-300 rounded-lg p-5'>
		<Avatar {...{ src: picture, alt: name, size: 150 }} />
		<table className='ml-24'>
			<tr className='align-baseline'>
				<td className='text-gray-600 font-semibold w-32'>Nome:</td>
				<td className='text-base'>{name}</td>
			</tr>
			<tr className='align-baseline'>
				<td className='text-gray-600 font-semibold py-4'>E-mail:</td>
				<td className='text-base'>{email}</td>
			</tr>
			<tr className='align-baseline'>
				<td className='text-gray-600 font-semibold'>CPF:</td>
				<td className='text-base'>{cpf}</td>
			</tr>
			<tr className='align-baseline'>
				<td className='text-gray-600 font-semibold py-4'>Telefone:</td>
				<td className='text-base'>{phone}</td>
			</tr>
			{birthDate !== '1901-10-10' ? (
				<tr className='align-baseline'>
					<td className='text-gray-600 font-semibold'>Nascimento:</td>
					<td className='text-base'>{birthDate}</td>
				</tr>
			) : null}
		</table>
	</div>
);
