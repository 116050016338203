import { useParams } from 'react-router-dom';
import { useRequest } from '../../../../../lib/hooks';
import { request } from '../../../../../lib/utils/request';
import { extract_num_id_from_id } from '../../utils/extract_num_id_from_id';
import { EditionDto } from '../dto/EditionDto';
import { InsertEditionDto } from '../dto/InsertEditionDto';
import { UpdateEditionDto } from '../dto/UpdateEditionDto';

const BASE_URL_API = `${process.env.REACT_APP_PERIODIC_API}/periodics`;

export function useEditionsApi() {
	const { id: periodic_id, edition_id } = useParams() as { id: string; edition_id: string };

	const periodicNumId = String(extract_num_id_from_id(periodic_id)).toUpperCase();
	const editionNumId = String(extract_num_id_from_id(edition_id)).toUpperCase();

	const baseUrl = `${BASE_URL_API}/${periodicNumId}/editions`;
	const insert = useCreateEdition(baseUrl);
	const remove = useRemoveEdition(baseUrl);
	const update = useUpdateEdition(baseUrl, editionNumId);
	const get_number = useGetNextEdition(baseUrl);
	const get_last_id = useGetLastID(baseUrl);
	const publish = usePublishEdition(baseUrl);

	return {
		insert,
		remove,
		update,
		get_number,
		get_last_id,
		publish,
	};
}

function useCreateEdition(BASE_URL: string) {
	const [result, loading, error, do_create] = useRequest<EditionDto>((dto: InsertEditionDto) =>
		request().post(BASE_URL, dto)
	);

	return {
		create: (dto: InsertEditionDto) => do_create(dto),
		result,
		error,
		loading,
	};
}

function useUpdateEdition(BASE_URL: string, editionNumId: string) {
	const [result, loading, error, update] = useRequest<EditionDto>((dto: UpdateEditionDto) =>
		request().put(`${BASE_URL}/${editionNumId}`, dto)
	);

	return {
		do_update: (dto: UpdateEditionDto) => update(dto),
		result,
		error,
		loading,
	};
}

function useRemoveEdition(BASE_URL: string) {
	const [result, loading, error, remove] = useRequest<string>((editionNumId: string) =>
		request().delete(`${BASE_URL}/${editionNumId}`)
	);

	return {
		do_remove: (edition_id: string) => remove(edition_id),
		result,
		loading,
		error,
	};
}

function useGetNextEdition(BASE_URL: string) {
	const [result, loading, error, get_number] = useRequest<{ number: number }>(() =>
		request().get(`${BASE_URL}/number`)
	);

	return {
		do_get_number: () => get_number(),
		result,
		loading,
		error,
	};
}

function usePublishEdition(BASE_URL: string) {
	const [result, loading, error, index] = useRequest<string>((editionNumId: string) =>
		request().put(`${BASE_URL}/${editionNumId}/publish`, {})
	);

	return {
		do_index: (editionId: string) => index(editionId),
		result,
		loading,
		error,
	};
}

function useGetLastID(baseUrl: string) {
	const [result, loading, error, do_get] = useRequest<{ numId: string }>(
		() =>
			new Promise((resolve, reject) => {
				request()
					.get(`${baseUrl}/last-id`)
					.then((res) => setTimeout(() => resolve(res as { numId: string }), 1500))
					.catch(reject);
			})
	);

	return {
		get: () => do_get(),
		result,
		error,
		loading,
	};
}
