import { WifiOutlined } from '@ant-design/icons';
import React, { FC } from 'react';
import { List } from '../../../../../../lib/components/List/List';

interface IProps {
	ips: string[];
	on_removed: (ip: string) => void;
}

export const IpList: FC<IProps> = ({ ips, on_removed }) => {
	return (
		<List
			{...{
				data: ips,
				header: 'IPs',
				on_remove_click: on_removed,
				icon: <WifiOutlined className='text-blue-500 flex items-center justify-center py-1' />,
			}}
		/>
	);
};
