import { Tabs } from 'antd';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { SearchProvider } from '../../../../lib/components/ContentDataTable/SearchProvider';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { PricesFilesListBreadcrumb } from '../components/Breadcrumb';
import { PreviewPricesDatatable } from '../components/PreviewPricesDatatable';
import { PriceVersionLoader } from '../components/PriceVersionLoader';
import { PriceVersionLog } from '../components/PriceVersionLog';
import { usePriceAdminApi } from '../hooks/usePriceAdminApi';

const { TabPane } = Tabs;

export const PreviewPage: FC = () => {
	const { version_date } = useParams() as { version_date: string };
	const price_admin_api = usePriceAdminApi();

	const formatted_date = format(new Date(+version_date), "dd/MM/yyyy' 'HH:mm", { locale: pt });

	return (
		<PageLayout
			{...{
				title: `Lista enviada em: ${formatted_date}`,
				back_href: '/loja/precos',
				breadcrumb: () => <PricesFilesListBreadcrumb {...{version_date}}/>,
			}}
		>
			<Tabs type='card'>
				<TabPane tab='Log' key='log'>
					<PriceVersionLog {...{ version_date: +version_date }} />
				</TabPane>

				<TabPane tab='Precos' key='prices'>
					<PriceVersionLoader
						{...{
							get_version: price_admin_api.get_version,
							result: price_admin_api.result,
							version_date: +version_date,
							renderChildren: (version) => (
								<SearchProvider
									{...{
										version_date: version.is_current ? 'current' : +version_date,
										default_query: {
											groups: '',
											tags: '',
											sort_by: 'publish_date',
											page: 1,
											text: '',
										},
									}}
								>
									<PreviewPricesDatatable
										{...{
											loading: price_admin_api.loading,
											set_as_current_version: price_admin_api.set_as_current_version,
											version,
										}}
									/>
								</SearchProvider>
							),
						}}
					/>
				</TabPane>
			</Tabs>
		</PageLayout>
	);
};
