import { Select } from 'antd';
import { FC } from 'react';
import { SectionDisplay } from '../../../../lib/model/Section';

const { Option } = Select;

interface IProps {
	display: SectionDisplay;
	on_change: (value: SectionDisplay) => void;
}

export const SelectSectionDiplay: FC<IProps> = ({ display, on_change }) => (
	<Select
		{...{
			placeholder: 'Selecione a forma de exibição',
			optionFilterProp: 'children',
			defaultValue: SectionDisplay.CAROUSEL,
			value: display,
			style: { width: '100%' },
			onChange: on_change,
		}}
	>
		<Option value={SectionDisplay.GRID}>Grid</Option>
		<Option value={SectionDisplay.CAROUSEL}>Carrosel</Option>
	</Select>
);
