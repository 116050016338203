import { Button } from 'antd';
import React, { CSSProperties, FC, useEffect } from 'react';
import { useRequest, useRequestFeedback } from '../../hooks';
import { request } from '../../utils/request';

interface IProps {
	api_url: string;
	style?: CSSProperties;
}
export const DownloadFromSignedUrlButton: FC<IProps> = ({ api_url, style }) => {
	const [signed_url, loading, error, get_signed_url] = useRequest(() => request().get<string>(api_url));

	useRequestFeedback('', error);

	useEffect(() => {
		if (signed_url) {
			window.open(signed_url, '_blank');
		}
	}, [signed_url]);

	return <Button {...{ type: 'primary', onClick: get_signed_url, loading, style }}>Download</Button>;
};
