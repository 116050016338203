import { useContext } from 'react';
import { forum_auth_context } from '../forum_auth_context';

export function useForumAuth() {
	const forum_ctx = useContext(forum_auth_context);

	return {
		...forum_ctx,
	};
}
