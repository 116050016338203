import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { BannerDetailsPage } from './pages/BannerDetailsPage';
import { BannersListPage } from './pages/BannersListPage';

export const BannersRoutes: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.StoreAdmin, Role.StoreBanners] }}>
		<Routes>
			<Route path=':id' element={<BannerDetailsPage />} />
			<Route path='/' element={<BannersListPage />} />
		</Routes>
	</ProtectedContent>
);
