import { FC } from 'react';
import { SearchableProvider } from '../../../../lib/components/SearchableDataTable/SearchableProvider';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { default_query } from '../../../../lib/utils/consts';
import { BooksDataTable } from '../components/BooksDataTable';

export const BookListPage: FC = () => (
	<PageLayout title='Livros'>
		<SearchableProvider {...{ entity_name: 'admin-books-entity', default_query }}>
			<BooksDataTable />
		</SearchableProvider>
	</PageLayout>
);
