import { LeftOutlined } from '@ant-design/icons';
import { PageHeader } from 'antd';
import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { LogoutButton } from '../../features/auth/components/LogoutButton';
import { useForumAuth } from '../../features/auth/hooks/useForumAuth';

interface IProps {
	browser_back?: boolean;
	back_href?: string;
	breadcrumb?: () => ReactNode;
	title: string;
}

export const Topbar: FC<IProps> = ({ back_href, breadcrumb, title, browser_back }) => {
	const { session } = useForumAuth();
	const navigate = useNavigate();

	return (
		<PageHeader
			{...{
				className: 'border-b shadow',
				title,
				onBack: () => (browser_back ? navigate(-1) : navigate(String(back_href), { replace: true })),
				backIcon: back_href || browser_back ? <LeftOutlined /> : null,
				extra: [
					<div className='flex flex-row items-center'>
						{session.name}
						<LogoutButton />
					</div>,
				],
				breadcrumbRender: breadcrumb,
			}}
		/>
	);
};
