import { Checkbox, Input, Tabs } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormContainer } from '../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../lib/components/Form/FormField';
import { VideoEntity } from '../../../../../lib/model/Video';
import { Tag } from '../../../../../lib/model/Tag';
import { InsertVideoDto } from '../../dto/InsertVideoDto';
import { UpdateVideoDto } from '../../dto/UpdateVideoDto';
import { useVideosApi } from '../../hooks/useVideosApi';
import { VideoFormButtons } from './VideoFormButtons';
import { useVideoForm } from './useVideoForm';
import { MultipleTagSelect } from '../../../../../lib/components/MultipleTagSelect';
import { VideoSerieSelect } from '../SelectVideoSerie';
import { ImageUploader } from './ImageUploader';
import { VideoUploader } from './VideoUploader';
import { Button } from 'antd';
import { Videopreview } from './VideoPreview';

const class_name_field = 'mb-2 w-1/2';

interface IProps {
	video?: VideoEntity;
}

export const VideoForm: FC<IProps> = ({ video }) => {
	const navigate = useNavigate();
	const { form_state, show_errors, change_field_value, fill_with_data } = useVideoForm();
	const { insert, update } = useVideosApi();
	const { TabPane } = Tabs;
	const [custom_num_id, set_custom_num_id] = useState(false);
	const [image, set_image] = useState('');
	const [url, set_url] = useState('');
	const [url_needs_save, set_url_needs_save] = useState(false);

	let keyword_tags: Tag[] = [];
	let area_interest_tags: Tag[] = [];
	let author_tags: Tag[] = [];
	let theme_tags: Tag[] = [];
	let event_tags: Tag[] = [];

	const submit = () => {
		if (!form_state.valid) return show_errors();

		const dto: UpdateVideoDto | InsertVideoDto = {
			...form_state.value,
			num_id: Number(form_state.value.num_id),
			url,
			image,
			tags: [
				...form_state.value.keyword_tags,
				...form_state.value.area_interest_tags,
				...form_state.value.author_tags,
				...(form_state.value.theme_tags || []),
				...(form_state.value.event_tags || []),
			].map((tag) => ({ id: tag.id, title: tag.title })),
		};

		video ? update.do_update(dto) : insert.create(dto);
	};

	useEffect(() => {
		if (insert.result || update.result) navigate(-1);
		if (video?.image) set_image(video.image);
		if (video?.url) set_url(video.url);

		video?.tags.forEach((tag) => {
			if (tag.id.includes('kwrd')) return keyword_tags.push(tag);
			if (tag.id.includes('area-interest')) return area_interest_tags.push(tag);
			if (tag.id.includes('author')) return author_tags.push(tag);
			if (tag.id.includes('theme')) return theme_tags.push(tag);
			if (tag.id.includes('event')) return event_tags.push(tag);
			return;
		});

		const video_data = {
			num_id: video?.num_id || 0,
			title: video?.title || '',
			description: video?.description || '',
			event_date: video?.event_date || '',
			video_serie: video?.video_serie || { id: '', title: '' },
			image: image || '',
			url: url || '',
			keyword_tags: keyword_tags || [],
			area_interest_tags: area_interest_tags || [],
			author_tags: author_tags || [],
			theme_tags: theme_tags || [],
			event_tags: event_tags || [],
		};

		fill_with_data(video_data);
	}, [video, insert.result, update.result, navigate]);

	const image_endpoint = `${process.env.REACT_APP_VIDEO_API}/videos/${video?.num_id}/image-upload-url/WRITE`;
	const url_endpoint = `${process.env.REACT_APP_VIDEO_API}/videos/${video?.num_id}/video-upload-url/WRITE`;
	const view_api_endpoint = `${process.env.REACT_APP_VIDEO_API}/videos/${video?.num_id}/video-upload-url/READ`;

	const toggleNumID = (ev: any) => {
		set_custom_num_id(ev.target.checked);
		change_field_value('num_id', 0);
	};

	return (
		<>
			<Tabs type='card'>
				<TabPane tab='Informações' key='infos'>
					<FormContainer>
						{!video ? (
							<FormField
								label='Inserir identificador manualmente'
								name='custom_num_id'
								form_state={form_state}
								required={false}
								render_children_inside_label={false}
								class_name='flex flex-row'
							>
								<Checkbox checked={custom_num_id} onChange={toggleNumID} className='mb-3 ml-2' />
							</FormField>
						) : null}
						{custom_num_id || !!video ? (
							<FormField
								{...{
									label: 'Identificador Numerico',
									name: 'num_id',
									form_state,
									class_name: class_name_field,
								}}
							>
								<Input
									{...{
										disabled: !!video,
										value: form_state.value.num_id,
										defaultValue: 0,
										type: 'number',
										onChange: (event) => change_field_value('num_id', event.target.value),
									}}
								/>
							</FormField>
						) : null}

						<FormField
							{...{ label: 'Título', name: 'title', form_state, class_name: class_name_field }}
						>
							<Input
								{...{
									value: form_state.value.title,
									onChange: (event) => change_field_value('title', event.target.value),
								}}
							/>
						</FormField>

						<FormField
							{...{
								label: 'Descrição',
								name: 'description',
								form_state,
								class_name: class_name_field,
							}}
						>
							<Input
								{...{
									value: form_state.value.description,
									onChange: (event) => change_field_value('description', event.target.value),
								}}
							/>
						</FormField>

						<FormField
							{...{
								label: 'Data do Evento',
								name: 'event_date',
								form_state,
								class_name: class_name_field,
							}}
						>
							<Input
								{...{
									type: 'date',
									value: form_state.value.event_date,
									onChange: (event) => change_field_value('event_date', event.target.value),
								}}
							/>
						</FormField>

						<h2 className='text-lg mt-10 mb-5'>Permissionamento</h2>
						<div className='bg-gray-50 p-5'>
							<VideoSerieSelect
								{...{
									label: 'Série de Vídeo',
									name: 'video-serie',
									form_state,
									class_name: class_name_field,
									serie_from_video: video?.video_serie,
									video_serie_state: form_state.value.video_serie,
									on_change: (serie) => change_field_value('video_serie', JSON.parse(serie)),
								}}
							/>
						</div>

						<h2 className='text-lg mt-10 mb-5'>Taxonomias</h2>
						<div className='bg-gray-50 p-5'>
							<MultipleTagSelect
								{...{
									form_state,
									label: 'Palavras-Chave',
									name: 'keyword_tags',
									on_change: (keyword_tags) => change_field_value('keyword_tags', keyword_tags),
									values: form_state.value.keyword_tags,
									tag_prefix: 'kwrd',
									class_name: class_name_field,
								}}
							/>
						</div>

						<div className='bg-gray-100 p-5'>
							<MultipleTagSelect
								{...{
									form_state,
									label: 'Área de Interesse',
									name: 'area_interest_tags',
									on_change: (area_interest_tags) =>
										change_field_value('area_interest_tags', area_interest_tags),
									values: form_state.value.area_interest_tags,
									tag_prefix: 'area-interest',
									class_name: class_name_field,
								}}
							/>
						</div>

						<div className='bg-gray-50 p-5'>
							<MultipleTagSelect
								{...{
									form_state,
									label: 'Autores/Palestrantes',
									name: 'author_tags',
									on_change: (author_tags) => change_field_value('author_tags', author_tags),
									values: form_state.value.author_tags,
									tag_prefix: 'author',
									class_name: class_name_field,
								}}
							/>
						</div>

						<div className='bg-gray-100 bg- p-5'>
							<MultipleTagSelect
								{...{
									form_state,
									required: false,
									label: 'Temas Específicos',
									name: 'theme_tags',
									on_change: (theme_tags) => change_field_value('theme_tags', theme_tags),
									values: form_state.value.theme_tags,
									tag_prefix: 'theme',
									class_name: class_name_field,
								}}
							/>
						</div>

						<div className='bg-gray-50 p-5 mb-5'>
							<MultipleTagSelect
								{...{
									form_state,
									required: false,
									label: 'Evento(s)',
									name: 'event_tags',
									on_change: (event_tags) => change_field_value('event_tags', event_tags),
									values: form_state.value.event_tags,
									tag_prefix: 'event',
									class_name: class_name_field,
								}}
							/>
						</div>
					</FormContainer>
				</TabPane>

				<TabPane tab='Mídia' key='Medias'>
					<FormContainer>
						<h2 className='mt-5 mb-2 font-bold'>Imagem de Capa</h2>
						<div className='bg-gray-100 p-5'>
							{video ? (
								<div>
									{image ? (
										<>
											<img
												{...{
													src: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/${image}`,
													style: { maxHeight: 200 },
													alt: '',
												}}
											/>
											<Button className='mt-2' {...{ danger: true, onClick: () => set_image('') }}>
												Alterar imagem
											</Button>
										</>
									) : (
										<ImageUploader
											{...{
												upload_api_endpoint: image_endpoint,
												accept: 'image/jpg, image/jpeg',
												upload_success: (file) => set_image(file),
											}}
										/>
									)}
								</div>
							) : (
								<div>Para fazer upload de mídia você preecisa antes salvar o conteúdo.</div>
							)}
						</div>
						<h2 className='mt-10 mb-2 font-bold'>Vídeo</h2>
						<div className='bg-gray-100 p-5'>
							{video ? (
								<div>
									{url ? (
										url_needs_save ? (
											<>
												<p className='bg-yellow-200 p-2 mb-2'>
													É necessário salvar o conteúdo para exibir o vídeo.
												</p>
												<img
													{...{
														src: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/videos/thumbs/placeholder.jpg`,
														style: { maxHeight: 200 },
														alt: '',
													}}
												/>
											</>
										) : (
											<>
												<Videopreview {...{ view_api_endpoint }} />
												<Button
													className='mt-2'
													{...{
														danger: true,
														onClick: () => {
															set_url_needs_save(true);
															set_url('');
														},
													}}
												>
													Alterar vídeo
												</Button>
											</>
										)
									) : (
										<VideoUploader
											{...{
												upload_api_endpoint: url_endpoint,
												accept: 'video/mp4',
												upload_success: (file) => {
													set_url(file);
													set_url_needs_save(true);
												},
											}}
										/>
									)}
								</div>
							) : (
								<div>Para fazer upload de mídia você preecisa antes salvar o conteúdo.</div>
							)}
						</div>
					</FormContainer>
				</TabPane>
			</Tabs>
			<VideoFormButtons
				{...{
					video,
					submit,
					disabled: !form_state.valid,
				}}
			/>
		</>
	);
};
