import { useParams } from 'react-router-dom';
import { useRequest } from '../../../../../../lib/hooks';
import { request } from '../../../../../../lib/utils/request';
import { extract_num_id_from_id } from '../../../utils/extract_num_id_from_id';
import { ItemDto } from '../dto/ItemDto';
import { InsertItemDto } from '../dto/InsertItemDto';
import { UpdateItemDto } from '../dto/UpdateItemDto';
import { UpdateItemContentTextDto } from '../dto/UpdateItemContentTextDto';

export function useItemsApi() {
	const { id, editionId, itemId } = useParams() as {
		id: string;
		editionId: string;
		itemId: string;
	};

	const periodicNumId = String(extract_num_id_from_id(id)).toUpperCase();
	const editionNumId = String(extract_num_id_from_id(editionId)).toUpperCase();
	const itemNumId = extract_num_id_from_id(itemId);

	const BASE_URL = `${process.env.REACT_APP_PERIODIC_API}/periodics/${periodicNumId}/editions/${editionNumId}/items`;

	const insert = useCreateItem(BASE_URL);
	const remove = useRemoveItem(BASE_URL);
	const update = useUpdateItem(BASE_URL, itemNumId);
	const get_last_id = useGetLastItemID(BASE_URL);
	const update_text = useUpdateItemText(BASE_URL, itemNumId);
	const read_pdf = useReadPDF(BASE_URL, itemNumId);
	const update_order = useUpdateItemOrder(BASE_URL);

	return {
		insert,
		remove,
		update,
		get_last_id,
		update_text,
		update_order,
		read_pdf,
	};
}

function useCreateItem(BASE_URL: string) {
	const [result, loading, error, do_create] = useRequest<ItemDto>((dto: InsertItemDto) =>
		request().post(BASE_URL, dto)
	);

	return {
		create: (dto: InsertItemDto) => do_create(dto),
		result,
		error,
		loading,
	};
}

function useUpdateItem(BASE_URL: string, itemNumId: string) {
	const [result, loading, error, update] = useRequest<ItemDto>((dto: UpdateItemDto) =>
		request().put(`${BASE_URL}/${itemNumId}`, dto)
	);

	return {
		do_update: (dto: UpdateItemDto) => update(dto),
		result,
		error,
		loading,
	};
}

function useUpdateItemOrder(BASE_URL: string) {
	const [result, loading, error, update] = useRequest<ItemDto>((numId: string, dto: UpdateItemDto) =>
		request().put(`${BASE_URL}/${numId}`, dto)
	);

	return {
		do_update: (numId: string, dto: UpdateItemDto) => update(numId, dto),
		result,
		error,
		loading,
	};
}

function useRemoveItem(BASE_URL: string) {
	const [result, loading, error, remove] = useRequest<string>((num_id: string) =>
		request().delete(`${BASE_URL}/${num_id}`)
	);

	return {
		do_remove: (num_id: string) => remove(num_id),
		result,
		loading,
		error,
	};
}

function useUpdateItemText(BASE_URL: string, itemNumId: string) {
	const [result, loading, error, update] = useRequest<string>((dto: UpdateItemContentTextDto[]) =>
		request().put(`${BASE_URL}/${itemNumId}/update-text`, dto)
	);

	return {
		do_update: (dto: UpdateItemContentTextDto[]) => update(dto),
		result,
		loading,
		error,
	};
}

function useGetLastItemID(BASE_URL: string) {
	const [result, loading, error, do_get] = useRequest<{ numId: string }>(
		() =>
			new Promise((resolve, reject) => {
				request()
					.get(`${BASE_URL}/last-id`)
					.then((res) => setTimeout(() => resolve(res as { numId: string }), 1500))
					.catch(reject);
			})
	);

	return {
		get: () => do_get(),
		result,
		error,
		loading,
	};
}

function useReadPDF(BASE_URL: string, itemNumId: string) {
	const [result, loading, error, do_get] = useRequest<any>(
		() =>
			new Promise((resolve, reject) => {
				request()
					.get(`${BASE_URL}/${itemNumId}/pdf-content-text`)
					.then((res) => setTimeout(() => resolve(res as any), 1500))
					.catch(reject);
			})
	);

	return {
		get: () => do_get(),
		result,
		error,
		loading,
	};
}
