import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { HighlightPage } from './pages/HighlightPage';
import { HighlightsListPage } from './pages/HighlightsListPage';

export const HighlightsRoutes: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.StoreAdmin] }}>
		<Routes>
			<Route {...{ path: ':id', element: <HighlightPage /> }} />
			<Route {...{ path: '/', element: <HighlightsListPage /> }} />
		</Routes>
	</ProtectedContent>
);
