import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../lib/components/Loader';
import { InformativeEntity } from '../../../../lib/model/Informative';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { extract_num_id_from_id } from '../utils/extract_num_id_from_id';
import { InformativeBreadcrumb } from '../components/InformativeBreadcrumb';
import { InformativeForm } from '../components/form/InformativeForm';
import { InformativeDto } from '../dto/InformativeDto';
import { InformativeUpdateForm } from '../components/form/InformativeUpdateForm';

export const UpdateInformativePage: FC = () => {
	const { id, num_id } = useParams() as {
		id: string;
		num_id: string;
	};

	const { type: type } = useParams() as { type: string; num_id: string };

	const BASE_URL = `${process.env.REACT_APP_INFORMATIVE_API}/informatives/${type}/list/${num_id}`;

	const [change_form, set_change_form] = useState(0);

	return (
		<PageLayout
			{...{
				title: 'Atualizar informativo',
				browser_back: true,
				breadcrumb: () => <InformativeBreadcrumb {...{ num_id, in_informatives: true }} />,
			}}
		>
			<Loader
				{...{
					reload_when_changes: [change_form],
					request: () => request().get<InformativeDto>(BASE_URL),
					renderChildren: (informative: InformativeEntity) => (
						<InformativeUpdateForm
							{...{ informative, on_change_form: () => set_change_form(change_form + 1) }}
						/>
					),
				}}
			/>
		</PageLayout>
	);
};
