import { Button, Input } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import React from 'react';
import { FormField } from '../../../lib/components/Form/FormField';
import { useRequest, useRequestFeedback } from '../../../lib/hooks';
import { FormDebugger } from '../../../lib/hooks/useForm/FormDebugger';
import { ValidateForm } from '../../../lib/hooks/useForm/types';
import { useForm } from '../../../lib/hooks/useForm/useForm';

type SignUpDto = {
	name: string;
	allow_marketing: boolean;
	tags: { id: string; title: string }[];
	password: string;
	confirm_password: string;
};

const validate_fn: ValidateForm<SignUpDto> = (state) => {
	const confirm_error =
		state.value.password !== state.value.confirm_password ? 'As senhas nao conferem.' : '';

	return {
		password: '',
		tags: '',
		allow_marketing: '',
		name: !state.value.name ? 'Campo obrigatorio.' : '',
		confirm_password: confirm_error,
	};
};

function useSignUpForm() {
	const form = useForm(
		{
			name: '',
			allow_marketing: false,
			tags: [],
			password: '',
			confirm_password: '',
		},
		validate_fn
	);

	return form;
}

const sleep = () => new Promise((resolve) => setTimeout(() => resolve(true), 500));

const sign_up = (dto: SignUpDto) => sleep();

const FormTestPage: React.FC<{}> = () => {
	const { form_state, change_field_value, fill_with_data, show_errors } = useSignUpForm();

	const [success, loading, error, do_sign_up] = useRequest(sign_up);

	function submit() {
		show_errors();
		if (!form_state.valid || loading) {
			return;
		}

		do_sign_up(form_state.value);
	}

	useRequestFeedback(success, error);

	return (
		<div>
			<fieldset
				style={{
					opacity: loading ? 0.5 : 1,
				}}
			>
				<button
					onClick={() => {
						fill_with_data({
							name: 'celio',
							password: 'senha senha senha ',
							confirm_password: '',
							allow_marketing: false,
							tags: [
								{ id: 'job', title: 'developer' },
								{ id: 'tech', title: 'react ' },
							],
						});
					}}
				>
					Celio
				</button>
				<form onSubmit={(evt) => evt.preventDefault()} className='flex flex-col'>
					<div>
						<label>nome:</label>

						<input
							{...{
								value: form_state.value.name,
								onChange: (evt) => change_field_value('name', evt.target.value),
							}}
							className='border'
						/>
						{form_state.dirty_fields.includes('name') && form_state.errors.name && (
							<span className='text-red-500'>{form_state.errors.name}</span>
						)}
					</div>

					<div>
						<label>senha:</label>
						<input
							{...{
								value: form_state.value.password,
								onChange: (evt) => change_field_value('password', evt.target.value),
							}}
							className='border'
						/>
					</div>

					<div>
						<FormField
							{...{
								label: 'confirmar senha',
								name: 'confirm_password',
								form_state: form_state,
								value: form_state.value.confirm_password,
							}}
						>
							<Input
								{...{
									onChange: (evt) => change_field_value('confirm_password', evt.target.value),
								}}
							/>
						</FormField>
					</div>
					<div>
						<label>tags:</label>
						<input
							{...{
								value: form_state.value.tags.map(({ title, id }) => `${id}_${title}`).join(),
								onChange: (evt) => {
									change_field_value(
										'tags',
										evt.target.value.split(',').map((tag_str) => ({
											id: tag_str.split('_')[0],
											title: tag_str.split('_')[1],
										}))
									);
								},
							}}
							className='border'
						/>
					</div>
					<div>
						<label>deseja receber ofertas?:</label>
						<Checkbox
							{...{
								checked: form_state.value.allow_marketing,
								onChange: (evt) => {
									change_field_value('allow_marketing', evt.target.checked);
								},
							}}
							className='border'
						/>
					</div>

					<Button type='primary' onClick={submit}>
						Inscrever
					</Button>
				</form>
			</fieldset>

			<FormDebugger state={form_state} />
		</div>
	);
};

export default FormTestPage;
