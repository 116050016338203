import { ReactNode } from 'react';
import { SearchableSearchInput } from '../SearchableDataTable/SearchableSearchInput';
import { DataTable } from '../DataTable/DataTable';
import { Column } from '../DataTable/Types';
import { useSearchContext } from './hooks/useSearchContext';
import { Pagination } from '../Pagination/Pagination';
import { IEntity } from './SearchContext';

interface IProps {
	columns: Column[];
	row_data_from_entity?: (entity: IEntity) => any;
	buttons?: ReactNode[];
}

export const ContentDatatable: React.FC<IProps> = ({ columns, row_data_from_entity = (e) => e, buttons }) => {
	const { result, loading, error, query } = useSearchContext();

	return (
		<DataTable
			{...{
				rowData: (result?.entities || []).map(row_data_from_entity),
				loading,
				columns,
				buttons,
				row_height: () => 130,
				element_on_top: (
					<div className='flex flex-col'>
						<div className='max-w-lg'>
							<SearchableSearchInput
								{...{
									on_search: query.change_text,
								}}
							/>
						</div>
					</div>
				),
				element_on_bottom: [
					<Pagination
						{...{
							change_page: query?.change_page,
							loading,
							page: query?.page,
							total: result?.total,
						}}
					/>,
				],
			}}
		/>
	);
};
