import React from 'react';

export const InformativesIcon = (props: any) => (
	<svg viewBox='0 0 335.08 335.079' className='w-3 h-3 fill-current' {...props}>
		<path d='m401.612 208.651h-238.142c-4.144 0-7.502 3.359-7.502 7.502s3.359 7.502 7.502 7.502h238.142c4.144 0 7.502-3.359 7.502-7.502s-3.358-7.502-7.502-7.502z' />
		<path d='m401.612 258.343h-238.142c-4.144 0-7.502 3.359-7.502 7.502s3.359 7.502 7.502 7.502h238.142c4.144 0 7.502-3.359 7.502-7.502s-3.358-7.502-7.502-7.502z' />
		<path d='m401.612 308.035h-238.142c-4.144 0-7.502 3.359-7.502 7.502s3.359 7.502 7.502 7.502h238.142c4.144 0 7.502-3.359 7.502-7.502s-3.358-7.502-7.502-7.502z' />
		<path d='m401.612 357.727h-119.071c-4.144 0-7.502 3.359-7.502 7.502s3.359 7.502 7.502 7.502h119.071c4.144 0 7.502-3.359 7.502-7.502s-3.358-7.502-7.502-7.502z' />
		<path d='m401.612 407.419h-119.071c-4.144 0-7.502 3.359-7.502 7.502s3.359 7.502 7.502 7.502h119.071c4.144 0 7.502-3.359 7.502-7.502s-3.358-7.502-7.502-7.502z' />
		<path d='m452.764 355.032c4.144 0 7.502-3.359 7.502-7.502v-302.237c0-13.621-11.082-24.703-24.702-24.703h-314.748c-5.878-12.173-18.347-20.59-32.745-20.59-20.037 0-36.338 16.301-36.338 36.337l.001 439.067c-.001.086-.001.171-.001.258h.001c0 20.036 16.3 36.337 36.337 36.337h292.84c13.621 0 24.702-11.082 24.702-24.703v-12.468h29.951c13.621 0 24.702-11.082 24.702-24.703v-67.085c0-4.143-3.358-7.502-7.502-7.502s-7.502 3.359-7.502 7.502v67.085c0 5.347-4.35 9.698-9.697 9.698h-311.156v-423.486c0-.249-.014-.495-.019-.743h311.174c5.347 0 9.697 4.351 9.697 9.698v302.238c.001 4.143 3.36 7.502 7.503 7.502zm-386.026-318.695c0-11.763 9.57-21.333 21.333-21.333s21.333 9.57 21.333 21.333v409.979c-12.914-9.388-30.049-9.235-42.666-.053zm323.871 450.96c0 5.347-4.35 9.698-9.697 9.698h-292.841c-11.763 0-21.332-9.57-21.332-21.333 0-24.444 34.713-29.523 41.756-6.169.955 3.168 3.873 5.336 7.183 5.336h274.931z' />
		<path d='m409.115 80.384c0-7.598-6.181-13.779-13.778-13.779h-162.779c-4.144 0-7.502 3.359-7.502 7.502s3.359 7.502 7.502 7.502h161.552v84.027h-223.137v-84.026h26.512c4.144 0 7.502-3.359 7.502-7.502s-3.359-7.502-7.502-7.502h-27.738c-7.598 0-13.779 6.181-13.779 13.779v86.48c0 7.598 6.181 13.778 13.779 13.778h225.59c7.597 0 13.778-6.181 13.778-13.778z' />
		<path d='m169.747 357.727c-7.598 0-13.779 6.181-13.779 13.778v37.14c0 7.598 6.181 13.778 13.779 13.778h72.76c7.598 0 13.779-6.181 13.779-13.778v-37.14c0-7.598-6.181-13.778-13.779-13.778zm71.534 49.692h-70.308v-34.687h70.308z' />
	</svg>
);
