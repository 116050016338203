import { FC } from 'react';
import { SearchableProvider } from '../../../../lib/components/SearchableDataTable/SearchableProvider';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { default_query } from '../../../../lib/utils/consts';
import { PeriodicsDataTable } from '../components/PeriodicsDataTable';

export const PeriodicListPage: FC = () => (
	<PageLayout title='Revistas'>
		<SearchableProvider {...{ entity_name: 'admin-periodics-entity', default_query }}>
			<PeriodicsDataTable />
		</SearchableProvider>
	</PageLayout>
);
