import { Tag } from '../../model/Tag';
import { TagComponent } from './Tag';

interface IProps {
	tags: Tag[];
	on_remove_tag: (id: string) => any;
}

export const SelectedTags: React.FC<IProps> = ({ tags, on_remove_tag }) => {
	return (
		<ul className='list-none p-0 m-0 flex flex-wrap'>
			{tags.map((tag: Tag) => (
				<li key={'tag-' + tag.id} className='my-1 mx-1 bg-blue-400 py-1 px-2 rounded-full text-white '>
					<TagComponent {...{ tag, on_remove_tag }} />
				</li>
			))}
		</ul>
	);
};
