import { useCallback, useReducer } from 'react';
import { useRequestFeedback } from './useRequestFeedBack';

type RequestState<T> = {
	result: T;
	error: any;
	loading: boolean;
};

const Types = {
	error_occured: 'error_occured',
	on_success: 'on_success',
	on_start: 'on_start',
};

const INITIAL_STATE = {
	result: undefined as any,
	error: null,
	loading: false,
};

export function useRequest<T>(
	request: (...args: any) => Promise<T>,
	with_feedback = false,
	initialState = {
		...INITIAL_STATE,
	}
): [T, boolean, any, (...args: any) => any, (result: T) => any] {
	const [state, dispatch] = useReducer(request_reducer, { ...initialState });

	const do_request = useCallback(
		async (...args: any[]) => {
			dispatch(actions.on_start());

			try {
				const data = await request(...args);
				return dispatch(actions.on_success(data));
			} catch (err) {
				return dispatch(actions.error_occured(err));
			}
		},
		[request]
	);

	useRequestFeedback(with_feedback ? state.result : null, with_feedback ? state.error : null);

	return [
		state.result as T,
		state.loading,
		state.error,
		do_request,
		function set_result(result: T) {
			dispatch(actions.on_success(result));
		},
	];
}

function request_reducer<T>(
	state: RequestState<T> = { ...INITIAL_STATE },
	{ type, payload }: { type: string; payload: any }
): RequestState<T> {
	if (type === Types.error_occured) {
		return {
			result: undefined as any,
			loading: false,
			error: payload,
		};
	} else if (type === Types.on_success) {
		return {
			result: payload,
			loading: false,
			error: null,
		};
	} else if (type === Types.on_start) {
		return {
			result: undefined as any,
			loading: true,
			error: null,
		};
	}
	return state;
}

const actions = {
	error_occured: (error: any) => ({
		type: Types.error_occured,
		payload: error,
	}),
	on_success: (result: any) => ({
		type: Types.on_success,
		payload: result,
	}),
	on_start: () => ({
		type: Types.on_start,
		payload: null,
	}),
};
