export type Section = {
	id: string;
	title: string;
	display: SectionDisplay;
	content_group_id: string;
};

export enum SectionDisplay {
	GRID = 'grid',
	CAROUSEL = 'caroulsel',
}
