import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../lib/components/Loader';
import { PeriodicEntity } from '../../../../lib/model/Periodic';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { PeriodicsBreadcrumb } from '../components/PeriodicsBreadcrumb';
import { PeriodicForm } from '../components/form/PeriodicForm';
import { PeriodicDto } from '../dto/PeriodicDto';
import { extract_num_id_from_id } from '../utils/extract_num_id_from_id';

export const UpdatePeriodicPage: FC = () => {
	const { id: periodic_id } = useParams() as { id: string };
	const [refresh_counter, set_refresh_counter] = useState(0);
	const periodicNumId = String(extract_num_id_from_id(periodic_id)).toUpperCase();

	const BASE_URL = `${process.env.REACT_APP_PERIODIC_API}/periodics/${periodicNumId}`;

	return (
		<PageLayout
			{...{
				title: 'Atualizar Revista',
				browser_back: true,
				breadcrumb: () => <PeriodicsBreadcrumb {...{ periodic_id }} />,
			}}
		>
			<Loader
				{...{
					request: () => request().get<PeriodicDto>(BASE_URL),
					renderChildren: (periodic: PeriodicEntity) => (
						<PeriodicForm {...{ periodic, refresh: () => set_refresh_counter(refresh_counter + 1) }} />
					),
					reload_when_changes: [refresh_counter],
				}}
			/>
		</PageLayout>
	);
};
