import { Alert, Empty, Spin } from 'antd';
import React, { ReactNode, useEffect } from 'react';
import { useRequest } from '../hooks';

interface IProps<T> {
	renderChildren: (result: T) => ReactNode;
	renderError?: (err: any) => any;
	request: () => Promise<T>;
	reload_when_changes?: any[];
	render_loading?: () => ReactNode;
}

const result_initial_state = {
	result: undefined,
	error: null,
	loading: true,
};

export function Loader<T>({
	renderChildren,
	request,
	reload_when_changes = [],
	renderError = () => <Alert message='Conteudo nao encontrado.' type='error' showIcon />,
	render_loading,
}: IProps<T>) {
	const [result, loading = true, error, get_result] = useRequest(request, false, result_initial_state);

	useEffect(() => {
		get_result();
	}, reload_when_changes);

	return (() => {
		if (loading) {
			return !!render_loading ? (
				render_loading()
			) : (
				<Spin {...{ size: 'large', style: { position: 'absolute', top: '50%', left: '60%' } }} />
			);
		}

		if (error) {
			return renderError(error);
		}

		return !!result ? renderChildren(result) : <Empty />;
	})();
}
