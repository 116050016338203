import { useForm } from '../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../lib/hooks/useForm/validators';
import { Tag } from '../../../../../lib/model/Tag';

type FormValues = {
	num_id: any;
	title: string;
	cdd: string;
	cdu: string;
	book_serie: Tag;
	alternative_series: Tag[];
	areas_interest: Tag[];
	keywords: Tag[];
};

export function useBookForm() {
	const form = useForm<FormValues>(
		{
			num_id: '',
			alternative_series: [],
			areas_interest: [],
			keywords: [],
			cdu: '',
			cdd: '',
			title: '',
			book_serie: { id: '', title: '' },
		},
		function validate_form(state) {
			return {
				num_id: form_validators.required_text_field(state.value.num_id),
				areas_interest: form_validators.required_list_field(state.value.areas_interest),
				keywords: form_validators.required_list_field(state.value.keywords),
				cdu: form_validators.required_text_field(state.value.cdu),
				cdd: form_validators.required_text_field(state.value.cdd),
				title: form_validators.required_text_field(state.value.title),
				book_serie: '',
				alternative_series: '',
			};
		}
	);

	return form;
}
