import { Modal } from 'antd';
import { FC } from 'react';
import { Client } from '../../../../lib/model/Client';
import { ContractedContents } from '../../contracts/components/ContractedContents';

interface IProps {
	client: Client;
	visible: boolean;
	close: () => void;
}

export const ContractedContentsModal: FC<IProps> = ({ client, close, visible }) => (
	<Modal
		{...{
			title: `Contratos de ${client.name}`,
			centered: true,
			visible,
			width: 800,
			closable: true,
			destroyOnClose: true,
			onCancel: close,
			footer: null,
		}}
	>
		<ContractedContents {...{ client_id: client.id }} />
	</Modal>
);
