import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { BarsOutlined } from '@ant-design/icons';
import './styles.scss';

const DndTrigger: any = SortableHandle(
	({ children, className }: { children: React.ReactNode; className: string }) => (
		<div className={className || ''}>{children}</div>
	)
);

const DndItem: any = SortableElement(
	({ children, className }: { children: React.ReactNode; className: string }) => (
		<div className={className || ''}>{children}</div>
	)
);

const DndList: any = SortableContainer(
	({ children, className }: { children: React.ReactNode; className: string }) => {
		return <div className={className || ''}>{children}</div>;
	}
);

const OrderItems = ({ items, onChange }: any): JSX.Element => {
	const [currentItems, setCurrentItems] = useState<any[]>([]);

	const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }): void => {
		const newItems = arrayMoveImmutable(currentItems, oldIndex, newIndex);
		setCurrentItems(newItems);
	};

	const stringLimit = (value: string) => {
		const maxLength = 100;
		return value.length > maxLength ? value.substring(0, maxLength - 3) + '...' : value;
	};

	useEffect(() => {
		if (onChange) {
			const reverseItems = [...currentItems];
			onChange(reverseItems);
		}
	}, [currentItems]);

	useEffect(() => {
		const newItems = JSON.parse(JSON.stringify(items));
		const orderItems = newItems?.length
			? [...newItems].sort((a, b) => (a.order < b.order ? 1 : a.order > b.order ? -1 : 0))
			: [];
		setCurrentItems(orderItems);
	}, [items]);

	return (
		<DndList
			lockAxis='y'
			lockToContainerEdges={true}
			useDragHandle
			onSortEnd={onSortEnd}
			className='order-items-container'
		>
			{currentItems?.length ? (
				<>
					{currentItems.map((value: any, index: number) => (
						<DndItem key={`item-${index}`} index={index} className='order-items-item'>
							<DndTrigger className='order-items-item-trigger'>
								<BarsOutlined />
							</DndTrigger>
							<div className='order-items-item-content'>{stringLimit(value.title)}</div>
						</DndItem>
					))}
				</>
			) : (
				<span>Não existem itens para ordenar</span>
			)}
		</DndList>
	);
};

export default OrderItems;
