import { format } from 'date-fns';
import { useForm } from '../../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../../lib/hooks/useForm/validators';

export type ClippingArgs = {
	text: string;
	publish_date: string;
};

export function useClippingForm() {
	const form = useForm<ClippingArgs>(
		{
			publish_date: format(Date.now(), 'yyyy-MM-dd 00:00:00'),
			text: '',
		},
		function validate_form(state) {
			return {
				publish_date: form_validators.required_text_field(state.value.publish_date),
				text: form_validators.required_text_field(state.value.text),
			};
		}
	);

	return form;
}
