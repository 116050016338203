import React, { ReactNode } from 'react';
interface IProps {
	htmlFor?: string;
	children?: ReactNode;
	label_text: string;
	show_required?: boolean;
}

export const Label: React.FC<IProps> = ({ htmlFor, children = null, show_required = true, label_text }) => {
	return (
		<label {...{ htmlFor }}>
			<span className='text-xs mr-1'>{label_text}</span>
			{show_required && <span className='font-semibold text-red-500'>*</span>}
			{children}
		</label>
	);
};
