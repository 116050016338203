import { LogoutOutlined } from '@ant-design/icons';
import React from 'react';
import { useForumAuth } from '../hooks/useForumAuth';

export const LogoutButton: React.FC = () => {
	const { logout } = useForumAuth();

	return (
		<button onClick={logout}>
			<LogoutOutlined className='mt-1 ml-2' style={{ color: '#ff0000', margin: 10 }} />
		</button>
	);
};
