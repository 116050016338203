import { useForm } from '../../../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../../../lib/hooks/useForm/validators';
import { ChapterTypes, Languages } from '../../../../../../../lib/model/Book';
import { Tag } from '../../../../../../../lib/model/Tag';

type FormValues = {
	authors: Tag[] | [];
	language: Languages;
	num_id: string;
	order: number;
	title: string;
	chapter_type: ChapterTypes;
};

export function useChapterForm() {
	const form = useForm<FormValues>(
		{
			authors: [],
			language: Languages.PORTUGUESE,
			num_id: '',
			order: 1,
			title: '',
			chapter_type: ChapterTypes.CHAPTER,
		},
		function validate_form(state) {
			return {
				authors: '',
				language: form_validators.required_text_field(state.value.language),
				num_id: form_validators.required_text_field(state.value.num_id),
				order: form_validators.required_number_field(state.value.order),
				chapter_type: form_validators.required_text_field(state.value.chapter_type),
				title:
					state.value.chapter_type !== ChapterTypes.CHAPTER
						? ''
						: form_validators.required_text_field(state.value.title),
			};
		}
	);

	return form;
}
