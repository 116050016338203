import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PageLayout } from '../../../../../../lib/shell/PageLayout/PageLayout';
import { ItemsBreadcrumb } from '../components/ItemsBreadcrumb';
import { ItemForm } from '../components/form/ItemForm';

export const InsertItemPage: FC = () => {
	const { id: periodicId, editionId } = useParams() as { id: string; editionId: string };

	return (
		<PageLayout
			{...{
				title: 'Inserir Item',
				browser_back: true,
				breadcrumb: () => (
					<ItemsBreadcrumb {...{ periodicId, editionId, in_items: true, new_item: true }} />
				),
			}}
		>
			<ItemForm />
		</PageLayout>
	);
};
