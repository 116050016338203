import { Card } from 'antd';
import { FC } from 'react';
import { FormState } from '../../../../../../lib/hooks/useForm/types';
import { ClientConfigFormValues } from '../../../hooks/useClientConfigForm';
import { AddIpInput } from './AddIpInput';
import { AddIpRangeInput } from './AddIpRangeInput';
import { IpList } from './IpList';
import { IpRangeList } from './IpRangeList';
import { SsoForm } from './SsoForm';

interface IProps {
	form_state: FormState<ClientConfigFormValues>;
	add_into: (field_name: keyof ClientConfigFormValues) => (value: any) => void;
	remove_from: (field_name: keyof ClientConfigFormValues) => (value: any) => void;
	change_field_value: (field_name: keyof ClientConfigFormValues, value: any) => void;
}

const marginBottom = '2rem';

export const UnlimitedForm: FC<IProps> = ({ form_state, add_into, remove_from, change_field_value }) => (
	<>
		<Card style={{ marginBottom }}>
			<SsoForm change_field_value={change_field_value} form_state={form_state} />
		</Card>

		<Card style={{ marginBottom }}>
			<IpList ips={form_state.value.ips} on_removed={remove_from('ips')} />
			<AddIpInput on_ip_added={add_into('ips')} />
		</Card>

		<Card>
			<IpRangeList ranges={form_state.value.ip_ranges} on_removed={remove_from('ip_ranges')} />
			<AddIpRangeInput on_added={add_into('ip_ranges')} />
		</Card>
	</>
);
