import { FC } from 'react';
import { FormButtons } from '../../../../lib/components/Form/FormButtons';

interface IProps {
	on_submit: () => void;
	disabled: boolean;
	has_clipping: boolean;
}

export const ClippingFormButtons: FC<IProps> = ({ on_submit, disabled, has_clipping }) => (
	<FormButtons
		{...{
			disabled,
			go_back_on_cancel: true,
			on_add_button_click: has_clipping ? undefined : on_submit,
			on_update_button_click: has_clipping ? on_submit : undefined,
		}}
	/>
);
