export type Result<T> = [any?, T?];

export function success<T>(r: T): Result<T> {
	return [null, r];
}

export function failed(err: string): Result<any> {
	return [err, null];
}

export function is_ok(r: Result<any>) {
	return !r[0] && !!r[1];
}

export const not_found_result = failed('not found');

export const get_value = <T>(result: Result<T>) => result[1];
