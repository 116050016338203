import { GridApi } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Empty, Spin } from 'antd';
import React, { ReactNode, useEffect, useState } from 'react';
import { columns_reducer } from './columns_reducer';
import { Toolbar } from './Toolbar';
import { Column } from './Types';

export interface IDataTableProps {
	columns: Column[];
	buttons?: ReactNode[];
	row_height?: (props: any) => any;
	class_name?: string;
	element_on_top?: ReactNode;
	element_on_bottom?: ReactNode[];
	on_row_drag?: (new_list: any[]) => void;
}

interface IProps extends IDataTableProps {
	rowData: any[];
	loading?: boolean;
}

export const DataTable: React.FC<IProps> = ({
	columns,
	row_height,
	rowData,
	class_name,
	element_on_top = null,
	element_on_bottom = [],
	buttons,
	on_row_drag = () => {},
	loading = false,
}) => {
	const [grid_api, set_grid_api] = useState<GridApi>();

	useEffect(() => {
		if (loading) {
			return grid_api?.showLoadingOverlay();
		}

		grid_api?.hideOverlay();
	}, [loading]);

	const { columnDefs, frameworkComponents } = columns.reduce(columns_reducer, {
		frameworkComponents: {},
		columnDefs: [],
	});

	return (
		<div className='w-full flex flex-col h-full'>
			{buttons && (
				<Toolbar>
					<div className=' w-full '>{element_on_top}</div>
					{buttons.map((button, index) => (
						<div key={`${index}-btn`} {...{ className: ' px-2' }}>
							{button}
						</div>
					))}
				</Toolbar>
			)}

			<div className={`ag-theme-alpine ${class_name}`} style={{ height: '65vh', maxHeight: '71vh' }}>
				{rowData.length === 0 && loading === false ? (
					<div
						style={{
							border: '1px solid #babfc7',
							paddingTop: 200,
							paddingBottom: '34vh',
						}}
					>
						<Empty description={'Lista Vazia.'} style={{ color: 'gray' }} />
					</div>
				) : (
					<AgGridReact
						{...{
							reactUi: true,
							enableCellTextSelection: true,
							ensureDomOrder: true,
							onGridReady: (evt) => {
								set_grid_api(evt.api);
							},
							rowData,
							columnDefs,
							frameworkComponents: {
								...frameworkComponents,
								customLoadingOverlay: CustomLoadingOverlay,
							},
							getRowHeight: row_height,
							loadingOverlayComponent: 'customLoadingOverlay',
							animateRows: true,
							rowDragManaged: true,
							onRowDragEnd: (e) => {
								const new_order = e.node.parent?.childrenAfterSort?.map((item) => item.data);
								on_row_drag(new_order || []);
							},
						}}
					/>
				)}
			</div>

			{element_on_bottom.length > 0 && (
				<Toolbar {...{ top: false }}>
					{element_on_bottom.map((el, idx) => (
						<div {...{ key: `idx-bottom-${idx}`, className: 'w-full' }}>{el}</div>
					))}
				</Toolbar>
			)}
		</div>
	);
};

const CustomLoadingOverlay = () => {
	return (
		<div className='ag-custom-loading-cell' style={{ paddingLeft: '10px', lineHeight: '25px' }}>
			<Spin />
		</div>
	);
};
