import { Button, Input, Modal } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CancelButton } from '../../../../lib/components/Buttons/CancelButton';
import { InsertBannerDto } from '../dtos/InsertBannerDto';
import { useBannersApi } from '../hooks/useBannersApi';

interface IProps {
	visible: boolean;
	close: () => void;
}

export const BannerInsertModal: FC<IProps> = ({ visible, close }) => {
	const [input_banner_name, set_input_banner_name] = useState('');
	const { insert } = useBannersApi();
	const navigate = useNavigate();

	function submit(dto: InsertBannerDto) {
		if (dto.name.length !== 0) {
			insert.do_insert(dto);
		}
	}
	useEffect(() => {
		if (insert.result) {
			navigate(`/banners/${insert.result.id}`);
		}
	}, [insert.result]);

	return (
		<Modal
			{...{
				title: 'Adicionar banner',
				visible,
				centered: true,
				width: 700,
				closable: false,
				children: (
					<div className='flex flex-row mb-4'>
						<label className='mr-2 mt-1 font-semibold'>
							<span className='text-red-500'> * </span> Nome do banner:
						</label>
						<div className='flex flex-col'>
							<Input
								{...{
									autoFocus: true,
									value: input_banner_name,
									onChange: (event) => set_input_banner_name(event.target.value),
									allowClear: true,
									style: { width: '75%' },
								}}
							/>
						</div>
					</div>
				),
				footer: [
					<CancelButton {...{ onClick: close, className: 'mr-2' }} />,
					<Button
						type={'primary'}
						onClick={() => submit({ name: input_banner_name })}
						disabled={input_banner_name.length === 0}
					>
						Criar
					</Button>,
				],
			}}
		/>
	);
};
