import React, { useState } from 'react';
import { Search } from '../Search';
interface IProps {
	name?: string;
	loading: boolean;
	text: string;
	set_text: (text: string) => any,
	on_search: (text: string) => any;
}

export const SearchInput: React.FC<IProps> = ({ on_search, text, set_text, loading, name = '' }) => {
	return (
		<Search
			{...{
				other_props: {
					id: name,
				},
			}}
			loading={loading}
			onSearch={() => {
				on_search(text);
			}}
			onClear={() => {
				on_search('');
				set_text('');
			}}
			onChange={(event) => {
				set_text(event.target.value);
			}}
			value={text}
			placeholder={'Pesquisar'}
		/>
	);
};
