export function remove_duplicate_emails<T>(items: T[]): T[] {
	const list_without_duplicates: any[] = [];

	items.forEach((item: any) => {
		var duplicated =
			list_without_duplicates.findIndex((i: { email: string }) => item.email === i.email) > -1;

		if (!duplicated) {
			list_without_duplicates.push(item);
		}
	});

	return list_without_duplicates;
}
