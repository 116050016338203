import { Tabs } from 'antd';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../lib/components/Loader';
import { UploadMultipleResolutionImages } from '../../../../lib/components/UploadMultipleResolutionImages/UploadMultipleResolutionImages';
import { Banner } from '../../../../lib/model/Banner';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { BannerForm } from '../components/BannerForm';
import { BannersBreadcrumb } from '../components/BannersBreadcrumb';
import { BannerDto } from '../dtos/BannerDto';

const { TabPane } = Tabs;

export const BannerDetailsPage: FC = () => {
	const { id } = useParams();
	const [banner_name, set_banner_name] = useState('');

	return (
		<PageLayout
			{...{
				title: 'Edição de Banner',
				breadcrumb: () => <BannersBreadcrumb {...{ banner_name }} />,
				browser_back: true,
			}}
		>
			<Loader
				{...{
					request: () => request().get<BannerDto>(`${process.env.REACT_APP_BANNERS_API}/${id}`),
					renderChildren: (banner: Banner) => {
						set_banner_name(banner.name);

						return (
							<Tabs type='card'>
								<TabPane tab='Banner' key={banner.id}>
									<BannerForm {...{ banner }} />
								</TabPane>

								<TabPane tab='Imagens' key='imgs'>
									<UploadMultipleResolutionImages
										{...{
											src_img: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/store/banners/${id}`,
											upload_api_endpoint: `${process.env.REACT_APP_BANNERS_API}/upload-url/${id}`,
										}}
									/>
								</TabPane>
							</Tabs>
						);
					},
				}}
			/>
		</PageLayout>
	);
};
