import { useReducer } from 'react';
import { form_actions } from './form_actions';
import { create_form_reducer } from './form_reducer';
import { ValidateForm } from './types';
import { create_form_initial_state } from './utils';

export function useForm<T>(initial_value: T, validate_fn: ValidateForm<T>) {
	const { change_value, initialize, show_errors } = form_actions<T>();
	const form_reducer = create_form_reducer<T>(validate_fn);

	const initial_state = create_form_initial_state(initial_value);

	const [form_state, dispatch] = useReducer(form_reducer, initial_state);

	return {
		form_state,
		change_field_value: (field_name: keyof T, field_value: any) =>
			dispatch(change_value(field_name, field_value)),
		fill_with_data: (dto: T) => dispatch(initialize(dto)),
		show_errors: () => dispatch(show_errors()),
	};
}
