import React, { FC, useState } from 'react';
import { Loader } from '../../../../lib/components/Loader';
import { Section } from '../../../../lib/model/Section';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../lib/utils/request';
import { SectionInsertModal } from '../components/SectionInsertModal';
import { SectionsDataTable } from '../components/SectionsDataTable';
import { SectionDto } from '../dtos/SectionDto';

export const SectionListPage: FC = () => {
	const [visible_insert_modal, set_visible_insert_modal] = useState(false);
	const [remove_count, set_remove_count] = useState(0);

	return (
		<PageLayout {...{ title: 'Seções', key: 'sections-initial-page-store' }}>
			<Loader
				{...{
					reload_when_changes: [remove_count],
					request: () => request().get<SectionDto[]>(`${process.env.REACT_APP_SECTIONS_API}`),
					renderChildren: (sections: Section[]) => (
						<>
							<SectionsDataTable
								{...{
									sections,
									visible_insert_modal: () => set_visible_insert_modal(true),
									on_remove_success: () => set_remove_count(remove_count + 1),
								}}
							/>
							<SectionInsertModal
								{...{ visible: visible_insert_modal, close: () => set_visible_insert_modal(false) }}
							/>
						</>
					),
				}}
			/>
		</PageLayout>
	);
};
