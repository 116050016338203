import { Select } from 'antd';
import { FC } from 'react';
import {
	ChapterTypes,
	ChapterSubTypesPreTextual,
	ChapterSubTypesPosTextual,
} from '../../../../../../lib/model/Book';

const Option = Select;

interface IProps {
	type: ChapterTypes | string;
	value: ChapterSubTypesPreTextual | ChapterSubTypesPosTextual | string;
	on_change: (value: ChapterSubTypesPreTextual | ChapterSubTypesPosTextual | string) => void;
}

export const SelectChapterSubType: FC<IProps> = ({ type, value, on_change }) => {
	let options: any[] = [];
	if (type === ChapterTypes.PRE_TEXTUAL) {
		options = Object.entries(ChapterSubTypesPreTextual).map(([key, value]) => ({ key, value }));
	}
	if (type === ChapterTypes.POS_TEXTUAL) {
		options = Object.entries(ChapterSubTypesPosTextual).map(([key, value]) => ({ key, value }));
	}
	return (
		<Select
			placeholder='Selecione o subtipo'
			onChange={(chapter_sub_type) => on_change(chapter_sub_type)}
			style={{ width: '50%' }}
			value={value}
		>
			{options.map((o) => (
				<Option value={o.value} key={o.key}>
					{o.value || 'Outros'}
				</Option>
			))}
		</Select>
	);
};
