import TextArea from 'antd/lib/input/TextArea';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Checkbox, message, Modal } from 'antd';
import { SeeTextButton } from '../../../../../../lib/components/Buttons/SeeTextButton';
import { Loader } from '../../../../../../lib/components/Loader';
import { request } from '../../../../../../lib/utils/request';
import { extract_num_id_from_id } from '../../../utils/extract_num_id_from_id';
import { UpdateChapterText } from '../dto/UpdateChapterText';
import { UpdateChapterContentTextDto } from '../dto/UpdateChapterContentTextDto';
import { useChaptersApi } from '../hooks/useChaptersApi';
import { IndexButton } from '../../../../../../lib/components/Buttons/IndexButton';

interface IProps {
	formState: any;
}

export const TextFromPdfModal: FC<IProps> = ({ formState }) => {
	const [text_modal, set_text_modal] = useState(false);
	const [read_force, set_read_force] = useState(false);
	const [pdf_text, set_pdf_text] = useState([] as UpdateChapterText[]);
	const { update_text } = useChaptersApi();

	async function submit() {
		const dto: UpdateChapterContentTextDto[] = pdf_text.map((t: UpdateChapterText) => ({
			page: t.page,
			content: t.text,
		}));
		await update_text.do_update(dto);
		set_text_modal(false);
		message.success('Texto salvo com sucesso.');
	}

	return (
		<>
			<SeeTextButton {...{ onClick: () => set_text_modal(true) }} />
			<div className='flex items-center'>
				<Checkbox
					checked={read_force}
					onChange={(evt) => set_read_force(evt.target.checked)}
					className='ml-1'
				>
					Forçar Leitura do PDF
				</Checkbox>
			</div>
			<TextModal
				{...{
					close: () => set_text_modal(false),
					visible: text_modal,
					read_force,
					onClick: submit,
					on_change: set_pdf_text,
				}}
			/>
		</>
	);
};

interface ITextModal {
	visible: boolean;
	read_force: boolean;
	close: () => void;
	onClick: () => void;
	on_change: (pdf_text: any[]) => void;
}

const TextModal: FC<ITextModal> = ({ close, read_force, visible, onClick, on_change }) => {
	const {
		id: book_id,
		edition_id,
		chapter_id,
	} = useParams() as { id: string; edition_id: string; chapter_id: string };

	const book_num_id = extract_num_id_from_id(book_id);
	const edition_num_id = extract_num_id_from_id(edition_id);
	const chapter_num_id = extract_num_id_from_id(chapter_id);
	const BASE_URL = `${
		process.env.REACT_APP_BOOKS_API
	}/books/${book_num_id}/editions/${edition_num_id}/chapters/${chapter_num_id}/pdf-content-text${
		read_force ? '?force_read=true' : ''
	}`;
	const [pdf_text, set_pdf_text] = useState([] as UpdateChapterText[]);

	useEffect(() => {
		on_change(pdf_text);
	}, [pdf_text]);

	return (
		<Modal
			{...{
				title: 'Texto do Capítulo',
				visible,
				centered: true,
				width: 1100,
				closable: true,
				destroyOnClose: true,
				onCancel: close,
				footer: null,
			}}
		>
			<Loader
				{...{
					request: () => request().get<string>(BASE_URL),
					renderChildren: (text_from_pdf: any) => {
						set_pdf_text(text_from_pdf);
						return (
							<div>
								<Alert
									message={`Antes de fechar esta janela, salve o texto corrigido clicando no botão "Salvar Conteúdo" no final da página.`}
									type='warning'
								/>
								<br />
								{text_from_pdf.map((t: UpdateChapterText) => (
									<>
										<label>Página {t.page}</label>
										<TextAreaComponent
											{...{
												text_from_pdf: t.text,
												on_change: (e) => {
													t.text = e;
													set_pdf_text(text_from_pdf);
													return e;
												},
											}}
										/>
									</>
								))}
								<IndexButton {...{ onClick }} />
							</div>
						);
					},
				}}
			/>
		</Modal>
	);
};

interface ITextAreaComponent {
	on_change: (text: string) => void;
	text_from_pdf: string;
}

const TextAreaComponent: FC<ITextAreaComponent> = ({ on_change, text_from_pdf }) => {
	const [text, set_text] = useState(text_from_pdf);

	useEffect(() => {
		on_change(text);
	}, [text]);

	return (
		<TextArea
			{...{
				allowClear: true,
				value: text,
				className: 'mb-8 mt-1',
				rows: 5,
				onChange: (event) => set_text(event.target.value),
			}}
		/>
	);
};
