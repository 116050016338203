import React, { CSSProperties, FC } from 'react';
import { DownloadFromSignedUrlButton } from './DownloadFromSignedUrlButton';

interface IProps {
	url: string;
	style?: CSSProperties;
}
export const DownloadXlsButton: FC<IProps> = ({ url, style }) => (
	<DownloadFromSignedUrlButton {...{ style, api_url: url }} />
);
