import { Alert, Input, message, Switch, Tabs } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormButtons } from '../../../../../lib/components/Form/FormButtons';
import { FormContainer } from '../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../lib/components/Form/FormField';
import { UploaderPDF } from '../../../../../lib/components/UploaderPDF';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { InformativeEntity, SignedUrlTypes } from '../../../../../lib/model/Informative';
import { InformativeInsertDto } from '../../dto/InformativeInsertDto';
import { InformativeUpdateDto } from '../../dto/InformativeUpdateDto';
import { useInformativesApi } from '../../hooks/useInformativesApi';
import { useInformativeForm } from './useInformativeForm';
import { FilePdfOutlined } from '@ant-design/icons';
import { createBrowserHistory } from 'history';
import { DateInput } from '../../../../../lib/components/DateInput';
import moment from 'moment';
import { UpdateInformativeContentTextDto } from '../../dto/UpdateInformativeContentTextDto';
import { UpdateInformativeText } from '../../dto/UpdateInformativeText';
import { TextFromPdfModal } from '../TextFromPdfModal';
import { PublishButton } from '../../../../../lib/components/Buttons/PublishButton';
import { IndexContentTxts } from '../../dto/IndexContentTxts';
import { InformativeContent } from '../../dto/InformativeContent';

const class_name_field = 'w-1/2';

interface IProps {
	informative?: InformativeEntity;
	on_change_form?: () => void;
}

export const InformativeUpdateForm: FC<IProps> = ({ informative, on_change_form }) => {
	const [is_published, setIsPublished] = useState(false);
	const history = createBrowserHistory();
	const navigate = useNavigate();
	const { TabPane } = Tabs;
	const { num_id: num_id, publish_date: publish_date } = useParams() as {
		num_id: string;
		publish_date: string;
	};
	const BASE_URL = `${process.env.REACT_APP_INFORMATIVE_API}/informatives/${num_id}/pdf`;
	const { form_state, show_errors, change_field_value, fill_with_data } = useInformativeForm();

	const {
		insert,
		update,
		get_informative,
		get_informative_by_date,
		read_pdf,
		update_text,
		publish: publish_informative,
	} = useInformativesApi();

	const update_pdf_text = async (pdf_text: UpdateInformativeText[]) => {
		const dto: UpdateInformativeContentTextDto[] = pdf_text.map((t: UpdateInformativeText) => ({
			page: t.page,
			content: t.text,
		}));
		await update_text.do_update(dto);
	};

	useEffect(() => {
		const pdf_text = read_pdf.result as UpdateInformativeText[];
		if (pdf_text?.length) {
			update_pdf_text(pdf_text);
		}
	}, [read_pdf.result]);

	const convert_date_string_to_timestamp = (date: string) => {
		return Date.parse(date) + 90000000;
	};
	useEffect(() => {
		get_informative.get();
	}, []);

	useEffect(() => {
		if (get_informative.result) {
			const result = get_informative.result;
			const informative_data = {
				num_id: result.num_id,
				publish_date: convert_date_string_to_timestamp(result.publish_date),
				is_published: result.is_published,
			};
			setIsPublished(result.is_published || false);
			fill_with_data(informative_data);
		}
	}, [get_informative.result]);

	function submit() {
		show_errors();
		const dto: InformativeUpdateDto | InformativeInsertDto = {
			...form_state.value,
			publish_date: String(form_state.value.num_id),
			num_id: String(form_state.value.num_id),
			type: 'jacoby',
			is_published,
			pdf: 'informative/pdf/' + moment(form_state.value.publish_date).format('YYYY-MM-DD') + '.pdf',
			filter_month_year: moment(form_state.value.publish_date).format('MM-YYYY'),
		};

		if (!form_state.valid) {
			return;
		}

		return informative ? update.do_update(dto) : insert.create(dto);
	}

	useEffect(() => {
		if (update.result || insert.result) {
			message.success('Salvo com sucesso.');
			const num_id = update.result?.num_id || insert.result?.num_id;
			publish_informative.do_index(num_id);
		}
	}, [update.result, insert.result]);

	useEffect(() => {
		if (publish_informative.result) {
			history.push(`/conteudos/informativos/jacoby/list/${num_id}`);
			window.location.reload();
		}
	}, [publish_informative.result]);

	useRequestFeedback(update.result, update.error);
	useRequestFeedback(insert.result, insert.error);

	return (
		<Tabs type='card'>
			<TabPane tab='Informações' key='infos'>
				<FormContainer>
					{informative ? (
						<>
							<Alert
								message={`É necessário republicar a edição para que as alterações reflitam na plataforma.`}
								type='warning'
							/>
							<br />
						</>
					) : (
						<></>
					)}

					{informative ? (
						<>
							<FormField
								{...{
									label: 'Data de Publicacao',
									name: 'publish_date',
									form_state,
									required: true,
									children: (
										<DateInput
											{...{
												disabled: true,
												key: informative?.num_id,
												date: new Date(form_state.value.publish_date),
												on_changed: (date) => {
													change_field_value('publish_date', date);
												},
											}}
										/>
									),
								}}
							/>
						</>
					) : (
						<></>
					)}

					{informative ? (
						<>
							<FormField {...{ label: 'ID', name: 'num_id', form_state }}>
								<Input
									value={informative.num_id}
									type={'text'}
									onChange={(event) => change_field_value('num_id', event.target.value)}
									className={class_name_field}
									disabled={true}
								/>
							</FormField>
							<FormField {...{ label: 'PDF', name: 'pdf', form_state }}>
								<UploaderPDF
									{...{
										on_success: () => {
											message.success('Documento salvo com sucesso.');
											if (on_change_form) on_change_form();
										},
										upload_api_endpoint: `${BASE_URL}/${SignedUrlTypes.WRITE}`,
									}}
								/>
							</FormField>
							{informative.pdf && (
								<FormField {...{ label: 'Download', name: 'key', required: false, form_state }}>
									<a href={informative.pdf} target='_blank' className='block mt-2 mb-1'>
										<div className='flex items-center'>
											<FilePdfOutlined style={{ marginRight: '0.3rem' }} />{' '}
											{String(informative.pdf).split('/')[3]}
										</div>
									</a>
								</FormField>
							)}
							<FormField {...{ label: 'Texto do PDF', name: 'txt', form_state }}>
								<TextFromPdfModal />
							</FormField>
							<FormField
								{...{
									label: 'Publicar na plataforma?',
									name: 'is_published',
									form_state,
									required: false,
								}}
							>
								<Switch
									{...{
										onChange: (value) => {
											setIsPublished(value);
										},
										checked: is_published,
									}}
								/>
							</FormField>
						</>
					) : (
						<>
							<FormField {...{ label: 'PDF', name: 'pdf', form_state }}>
								<UploaderPDF
									{...{
										on_success: () => {
											read_pdf.get();
											message.success('Documento salvo com sucesso.');
											if (on_change_form) on_change_form();
										},
										upload_api_endpoint: `${BASE_URL}/${SignedUrlTypes.WRITE}`,
									}}
								/>
							</FormField>
							<FormField {...{ label: 'Texto do PDF', name: 'txt', form_state }}>
								<TextFromPdfModal />
							</FormField>
						</>
					)}

					<FormButtons
						{...{
							disabled: !form_state.valid,
							on_update_button_click: informative ? submit : undefined,
							go_back_on_cancel: true,
							on_add_button_click: !informative ? submit : undefined,
						}}
					/>
				</FormContainer>
			</TabPane>
		</Tabs>
	);
};
