import { useParams } from 'react-router-dom';
import { useRequest } from '../../../../lib/hooks/useRequest';
import { request } from '../../../../lib/utils/request';
import { BannerDto } from '../dtos/BannerDto';
import { InsertBannerDto } from '../dtos/InsertBannerDto';

const BASE_URL = `${process.env.REACT_APP_BANNERS_API}`;

export function useBannersApi() {
	const { id } = useParams() as { id: string };

	const insert = useInsertBanner();
	const update = useUpdateBanner(id);
	const remove = useDeleteBanner();
	const publish = usePublishBanners();
	const update_list = useUpdateBannerList();

	return {
		remove,
		insert,
		update,
		publish,
		update_list,
	};
}

function useDeleteBanner() {
	const [result, loading, error, remove_banner] = useRequest<string>((id) =>
		request().delete(`${BASE_URL}/${id}`)
	);

	return {
		remove: (id: string) => remove_banner(id),
		result,
		loading,
		error,
	};
}
function useInsertBanner() {
	const [result, loading, error, insert] = useRequest<BannerDto>((dto) => request().post(BASE_URL, dto));

	return {
		do_insert: (dto: InsertBannerDto) => insert(dto),
		result,
		loading,
		error,
	};
}
function useUpdateBannerList() {
	const [result, loading, error, update] = useRequest<BannerDto[]>((dto) => request().put(BASE_URL, dto));

	return {
		do_update: (dto: BannerDto[]) => update(dto),
		result,
		loading,
		error,
	};
}
function useUpdateBanner(banner_id: string) {
	const [result, loading, error, update] = useRequest<BannerDto>((dto) =>
		request().put(`${BASE_URL}/${banner_id}`, dto)
	);

	return {
		do_update: (dto: BannerDto) => update(dto),
		result,
		loading,
		error,
	};
}
function usePublishBanners() {
	const [result, loading, error, publish] = useRequest<string>(() =>
		request().post(`${BASE_URL}/publish_banners`)
	);

	return {
		do_publish: () => publish(),
		result,
		loading,
		error,
	};
}
