import { FC } from 'react';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
interface IProps {
	date: Moment;
	on_changed: (value: Moment) => void;
	disabled?: boolean;
	style?: any;
}

export const DateInputFormMonthYear: FC<IProps> = ({ date, on_changed }) => (
	<DatePicker
		value={date}
		onChange={(date: any) => on_changed(moment(date))}
		format='MM-yyyy'
		picker='month'
		allowClear={false}
	/>
);
