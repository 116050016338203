import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../../../../lib/components/Loader';
import { ItemEntity } from '../../../../../../lib/model/Periodic';
import { PageLayout } from '../../../../../../lib/shell/PageLayout/PageLayout';
import { request } from '../../../../../../lib/utils/request';
import { extract_num_id_from_id } from '../../../utils/extract_num_id_from_id';
import { ItemsBreadcrumb } from '../components/ItemsBreadcrumb';
import { ItemsDataTable } from '../components/ItemsDataTable';
import { ItemDto } from '../dto/ItemDto';

export const ItemListPage: FC = () => {
	const [remove_counter, set_remove_counter] = useState(0);
	const [order_counter, set_order_counter] = useState(0);

	const { id: periodic_id, editionId } = useParams() as { id: string; editionId: string };
	const periodicNumId = String(extract_num_id_from_id(periodic_id)).toUpperCase();
	const editionNumId = String(extract_num_id_from_id(editionId)).toUpperCase();
	const BASE_URL = `${process.env.REACT_APP_PERIODIC_API}/periodics/${periodicNumId}/editions/${editionNumId}/items`;

	return (
		<PageLayout
			{...{
				title: 'Itens',
				browser_back: true,
				breadcrumb: () => (
					<ItemsBreadcrumb
						{...{
							periodicId: periodicNumId,
							in_editions: true,
							editionId: editionNumId,
							in_items: true,
						}}
					/>
				),
			}}
		>
			<Loader
				{...{
					reload_when_changes: [remove_counter, order_counter],
					request: () => request().get<ItemDto[]>(BASE_URL),
					renderChildren: (items: ItemEntity[]) => {
						const row_data_from_entity: any[] = items.map((item) => {
							return {
								...item,
							};
						});

						return (
							<ItemsDataTable
								{...{
									items: row_data_from_entity,
									on_remove_success: () => set_remove_counter(remove_counter + 1),
									on_order_success: () => set_order_counter(order_counter + 1),
								}}
							/>
						);
					},
				}}
			/>
		</PageLayout>
	);
};
