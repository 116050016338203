import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	book_id?: string;
	in_editions?: boolean;
	edition_id?: string;
	new_edition?: boolean;
}

export const EditionsBreadcrumb: FC<IProps> = ({ book_id, edition_id, in_editions, new_edition }) => (
	<Breadcrumb>
		<Breadcrumb.Item key='books'>
			<Link to={'/conteudos/livros'}>Livros</Link>
		</Breadcrumb.Item>

		<Breadcrumb.Item key='book_title'>
			<Link to={`/conteudos/livros/${book_id}`}>{book_id}</Link>
		</Breadcrumb.Item>

		{in_editions && (
			<Breadcrumb.Item key='edition_id'>
				<Link to={`/conteudos/livros/${book_id}/edicoes`}>Edições</Link>
			</Breadcrumb.Item>
		)}

		{new_edition && <Breadcrumb.Item key='new_edition'>novo</Breadcrumb.Item>}

		{edition_id && (
			<Breadcrumb.Item key='edition_id'>
				<Link to={`/conteudos/livros/${book_id}/edicoes/${edition_id}`}>{edition_id}</Link>
			</Breadcrumb.Item>
		)}
	</Breadcrumb>
);
