import { FC } from 'react';
import { AddInput } from '../../../../../../lib/components/AddInput';
import { ListFooter } from '../../../../../../lib/components/List/ListFooter';
import { useClientConfigForm } from '../../../hooks/useClientConfigForm';

interface IProps {
	on_added: (email: string) => void;
}

export const AddContractManagerInput: FC<IProps> = ({ on_added }) => {
	const { validators } = useClientConfigForm();

	return (
		<ListFooter>
			<AddInput
				{...{
					on_added,
					validate: validators.email,
				}}
			/>
		</ListFooter>
	);
};
