import React, { FC } from 'react';
import { AddInput } from '../../../../../lib/components/AddInput';
import { ListFooter } from '../../../../../lib/components/List/ListFooter';
import { useClientConfigForm } from '../../hooks/useClientConfigForm';

interface IProps {
	on_added: (d: string) => void;
}

export const AddDomainInput: FC<IProps> = ({ on_added }) => {
	const { validators } = useClientConfigForm();

	return (
		<ListFooter>
			<AddInput
				{...{
					on_added,
					validate: validators.domain,
				}}
			/>
		</ListFooter>
	);
};
