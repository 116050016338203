import { FC } from 'react';
import { FormButtons } from '../../../../../lib/components/Form/FormButtons';
import { VideoEntity } from '../../../../../lib/model/Video';

interface IProps {
	video?: VideoEntity;
	submit: () => void;
	disabled?: boolean;
}

export const VideoFormButtons: FC<IProps> = ({ video, submit, disabled }) => {
	return (
		<FormButtons
			{...{
				disabled,
				go_back_on_cancel: true,
				on_add_button_click: video ? undefined : () => submit(),
				on_update_button_click: video ? () => submit() : undefined,
			}}
		/>
	);
};
