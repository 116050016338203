import React, { FC, useEffect } from 'react';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { RemoveButton } from '../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../lib/components/DataTable/ColumnFactory';
import { DataTable } from '../../../../lib/components/DataTable/DataTable';
import { AssociatedsDto } from '../dtos/AssociatedsDto';
import { useContentAssignmentApi } from '../hooks/useContentListApi';

interface IProps {
	disabled: boolean;
	associateds: string[];
	on_success: (value: string) => void;
	set_visible_email_form: () => void;
}

export const AssociationDataTable: FC<IProps> = ({
	associateds,
	on_success,
	disabled,
	set_visible_email_form,
}) => {
	const { remove_associated } = useContentAssignmentApi();

	useEffect(() => {
		if (remove_associated.result) {
			on_success(remove_associated.result);
		}
	}, [remove_associated.result]);

	return (
		<DataTable
			{...{
				row_height: () => 45,
				rowData: row_data_from_entity(associateds),
				buttons: [<AddButton {...{ disabled, onClick: set_visible_email_form }} />],
				columns: [
					ColumnFactory.Link(
						{
							field: 'email',
							headerName: 'E-mail',
							resizable: true,
							wrapText: true,
							width: 450,
						},
						(props: { data: { email: string } }) => `/loja/usuarios/${props.data.email}`
					),

					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							cellStyle: CellStyle.Centered,
							width: 80,
						},
						renderer: (props: { data: { email: string } }) => (
							<RemoveButton
								{...{
									on_confirm: () => remove_associated.do_remove_associated(props.data.email),
									title: 'Deseja remover essa associação?',
								}}
							/>
						),
					},
				],
				row_drag: true,
			}}
		/>
	);
};

function row_data_from_entity(emails: AssociatedsDto) {
	return emails.map((email) => ({ email }));
}
