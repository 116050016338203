import { Alert, Input, message, Tabs } from 'antd';
import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormContainer } from '../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../lib/components/Form/FormField';
import { PeriodicEntity, PublishType } from '../../../../../lib/model/Periodic';
import { Tag } from '../../../../../lib/model/Tag';
import { InsertPeriodicDto } from '../../dto/InsertPeriodicDto';
import { UpdatePeriodicDto } from '../../dto/UpdatePeriodicDto';
import { usePeriodicsApi } from '../../hooks/usePeriodicsApi';
import { PeriodicFormButtons } from './PeriodicFormButtons';
import { usePeriodicForm } from './usePeriodicForm';
import { SelectPublishType } from './SelectPublishType';
import { MultipleTagSelect } from '../../../../../lib/components/MultipleTagSelect';
import { UploaderPDF } from '../../../../../lib/components/UploaderPDF';
import { extract_num_id_from_id } from '../../utils/extract_num_id_from_id';
import { createBrowserHistory } from 'history';
import { FilePdfOutlined } from '@ant-design/icons';
import { UploadImage } from '../../editions/components/UploadImage';

const class_name_field = 'mb-2 w-1/2';

interface IProps {
	periodic?: PeriodicEntity;
	refresh?: () => void;
}

export const PeriodicForm: FC<IProps> = ({ periodic, refresh }) => {
	const history = createBrowserHistory();
	const { form_state, show_errors, change_field_value, fill_with_data } = usePeriodicForm();
	const { insert, update, get_last_id } = usePeriodicsApi();
	const { TabPane } = Tabs;

	let keyword_tags: Tag[] = [];
	let area_interest_tags: Tag[] = [];
	let author_tags: Tag[] = [];
	let theme_tags: Tag[] = [];
	const { id: periodic_id } = useParams() as { id: string };
	const periodic_num_id = String(extract_num_id_from_id(periodic_id)).toLocaleUpperCase();

	const submit = () => {
		show_errors();

		if (!form_state.valid) return;

		const dto: UpdatePeriodicDto | InsertPeriodicDto = {
			...form_state.value,
			numId: String(form_state.value.numId).toUpperCase(),
			tags: [
				...form_state.value.areasInterest,
				...form_state.value.keywords,
				...form_state.value.authors,
				...form_state.value.themes,
			].map((tag) => ({
				id: tag.id,
				title: tag.title,
			})),
		};

		periodic ? update.do_update(dto) : insert.create(dto);
	};

	function uploadFinished() {
		message.success('Documento salvo com sucesso.');
		if (refresh) refresh();
	}

	useEffect(() => {
		periodic?.tags.forEach((tag) => {
			if (tag.id.includes('kwrd')) return keyword_tags.push(tag);
			if (tag.id.includes('area-interest')) return area_interest_tags.push(tag);
			if (tag.id.includes('author')) return author_tags.push(tag);
			if (tag.id.includes('theme')) return theme_tags.push(tag);
			return;
		});

		const periodic_data = {
			numId: periodic?.numId ? String(periodic?.numId).toUpperCase() : '',
			publishType: (periodic?.publishType || '') as PublishType,
			acronym: periodic?.acronym || '',
			title: periodic?.title || '',
			description: periodic?.description || '',
			cdu: periodic?.cdu || '',
			cdd: periodic?.cdd || '',
			issn: periodic?.issn || '',
			issnImpresso: periodic?.issnImpresso || '',
			qualisSeal: periodic?.qualisSeal || '',
			areasInterest: area_interest_tags || [],
			keywords: keyword_tags || [],
			authors: author_tags || [],
			themes: theme_tags || [],
		};

		fill_with_data(periodic_data);
		if (!periodic) {
			get_last_id.get();
		}
	}, [periodic]);

	useEffect(() => {
		if (get_last_id.result) {
			const last_num_id = Number(String(get_last_id.result.numId).replace(/P/g, '').replace(/ /g, ''));
			if (!form_state.value.numId) {
				const periodic_data = {
					numId: last_num_id ? `P${last_num_id + 1}` : '0',
					publishType: (periodic?.publishType || '') as PublishType,
					acronym: periodic?.acronym || '',
					title: periodic?.title || '',
					description: periodic?.description || '',
					cdu: periodic?.cdu || '',
					cdd: periodic?.cdd || '',
					issn: periodic?.issn || '',
					issnImpresso: periodic?.issnImpresso || '',
					qualisSeal: periodic?.qualisSeal || '',
					areasInterest: area_interest_tags || [],
					keywords: keyword_tags || [],
					authors: author_tags || [],
					themes: theme_tags || [],
				};

				fill_with_data(periodic_data);
			}
		}
	}, [get_last_id.result]);

	useEffect(() => {
		if (update.result || insert.result) {
			message.success('Salvo com sucesso.');
			const num_id = String(
				insert?.result?.numId || update?.result?.numId || periodic_num_id
			).toUpperCase();
			history.push(`/conteudos/revistas/${num_id}`);
			window.location.reload();
		}
	}, [update.result, insert.result]);

	return (
		<>
			<Tabs type='card'>
				<TabPane tab='Informações' key='infos'>
					<FormContainer>
						{periodic ? (
							<>
								<Alert
									message={`É necessário republicar todas as edições para que as alterações na revista reflitam na plataforma.`}
									type='warning'
								/>
								<br />
							</>
						) : (
							<></>
						)}
						<FormField
							{...{
								label: 'Identificador Numérico',
								name: 'numId',
								placeholder: 'Ex: P000',
								form_state,
								class_name: class_name_field,
							}}
						>
							<Input
								{...{
									disabled: true,
									value: form_state.value.numId,
									type: 'text',
									onChange: (event) => change_field_value('numId', event.target.value),
								}}
							/>
						</FormField>

						<FormField {...{ label: 'Tipo de Publicação', name: 'publishType', form_state }}>
							<SelectPublishType
								{...{ value: form_state.value.publishType, on_change: change_field_value }}
							/>
						</FormField>

						<FormField
							{...{ label: 'Sigla', name: 'acronym', form_state, class_name: class_name_field }}
						>
							<Input
								{...{
									value: form_state.value.acronym,
									onChange: (event) => change_field_value('acronym', event.target.value),
								}}
							/>
						</FormField>

						<FormField
							{...{ label: 'Título', name: 'title', form_state, class_name: class_name_field }}
						>
							<Input
								{...{
									value: form_state.value.title,
									onChange: (event) => change_field_value('title', event.target.value),
								}}
							/>
						</FormField>

						<FormField
							{...{
								label: 'Descrição',
								name: 'description',
								form_state,
								class_name: class_name_field,
							}}
						>
							<Input
								{...{
									value: form_state.value.description,
									onChange: (event) => change_field_value('description', event.target.value),
								}}
							/>
						</FormField>

						<FormField {...{ label: 'CDD', name: 'cdd', form_state, class_name: class_name_field }}>
							<Input
								{...{
									value: form_state.value.cdd,
									onChange: (event) => change_field_value('cdd', event.target.value),
								}}
							/>
						</FormField>

						<FormField {...{ label: 'CDU', name: 'cdu', form_state, class_name: class_name_field }}>
							<Input
								{...{
									value: form_state.value.cdu,
									onChange: (event) => change_field_value('cdu', event.target.value),
								}}
							/>
						</FormField>

						<FormField
							{...{
								label: 'ISSN Digital',
								name: 'issn',
								form_state,
								class_name: class_name_field,
								required: false,
							}}
						>
							<Input
								{...{
									value: form_state.value.issn,
									onChange: (event) => change_field_value('issn', event.target.value),
									required: false,
								}}
							/>
						</FormField>

						<FormField
							{...{
								label: 'ISSN Impresso',
								name: 'issnImpresso',
								form_state,
								class_name: class_name_field,
								required: false,
							}}
						>
							<Input
								{...{
									value: form_state.value.issnImpresso,
									onChange: (event) => change_field_value('issnImpresso', event.target.value),
									required: false,
								}}
							/>
						</FormField>

						{/* <FormField
							{...{
								label: 'Selo Qualis',
								name: 'qualisSeal',
								form_state,
								class_name: class_name_field,
							}}
						>
							<Input
								{...{
									value: form_state.value.qualisSeal,
									onChange: (event) => change_field_value('qualisSeal', event.target.value),
								}}
							/>
						</FormField> */}

						<h2 className='text-lg mt-10 mb-5'>Taxonomias</h2>

						<div className='bg-gray-50 p-5'>
							<MultipleTagSelect
								{...{
									form_state,
									label: 'Areas de Interesse',
									name: 'areasInterest',
									on_change: (areasInterest) => change_field_value('areasInterest', areasInterest),
									values: form_state.value.areasInterest,
									tag_prefix: 'area-interest',
									class_name: class_name_field,
								}}
							/>
						</div>
						<div className='bg-gray-100 p-5'>
							<MultipleTagSelect
								{...{
									form_state,
									label: 'Palavras-Chave',
									name: 'keywords',
									on_change: (keywords) => change_field_value('keywords', keywords),
									values: form_state.value.keywords,
									tag_prefix: 'kwrd',
									class_name: class_name_field,
								}}
							/>
						</div>

						<div className='bg-gray-50 p-5'>
							<MultipleTagSelect
								{...{
									form_state,
									label: 'Autores',
									name: 'authors',
									on_change: (authors) => change_field_value('authors', authors),
									values: form_state.value.authors,
									tag_prefix: 'author',
									class_name: class_name_field,
								}}
							/>
						</div>

						{/* TODO comentado até a implementação de trilhas.
						<div className='bg-gray-100 bg- p-5'>
							<MultipleTagSelect
								{...{
									form_state,
									required: false,
									label: 'Temas Específicos',
									name: 'themes',
									on_change: (themes) => change_field_value('themes', themes),
									values: form_state.value.themes,
									tag_prefix: 'theme',
									class_name: class_name_field,
								}}
							/>
						</div> */}
					</FormContainer>
				</TabPane>

				<TabPane tab='Mídia' key='Medias'>
					{periodic ? (
						<>
							<div>
								<FormField
									{...{
										label: 'Expediente',
										name: 'expedient',
										form_state,
										class_name: 'flex flex-col block',
									}}
								>
									<UploaderPDF
										{...{
											on_success: () => uploadFinished(),
											upload_api_endpoint: `${process.env.REACT_APP_PERIODIC_API}/periodics/${periodic.numId}/expedient-pdf/WRITE`,
										}}
									/>
								</FormField>
								{periodic.expedientKey && (
									<FormField
										{...{
											label: 'Arquivo',
											name: 'expedientKey',
											required: false,
											form_state,
										}}
									>
										<a href={periodic.expedientKey} target='_blank' className='block mt-1 mb-1'>
											<div className='flex items-center'>
												<FilePdfOutlined style={{ marginRight: '0.3rem' }} /> Download Expediente
											</div>
										</a>
									</FormField>
								)}
							</div>
							<div className='mt-1'>
								<FormField
									{...{
										label: 'Normas de Publicação',
										name: 'publication',
										form_state,
										class_name: 'flex flex-col block',
									}}
								>
									<UploaderPDF
										{...{
											on_success: () => uploadFinished(),
											upload_api_endpoint: `${process.env.REACT_APP_PERIODIC_API}/periodics/${periodic.numId}/publication-pdf/WRITE`,
										}}
									/>
								</FormField>
								{periodic.publicationStandardKey && (
									<FormField
										{...{
											label: 'Arquivo',
											name: 'publicationStandardKey',
											required: false,
											form_state,
										}}
									>
										<a
											href={periodic.publicationStandardKey}
											target='_blank'
											className='block mt-1 mb-1'
										>
											<div className='flex items-center'>
												<FilePdfOutlined style={{ marginRight: '0.3rem' }} /> Download Normas
											</div>
										</a>
									</FormField>
								)}
							</div>
							<div className='mt-1'>
								<FormField {...{ label: 'Capa', name: 'cover', form_state }}>
									<span className='flex items-start'>
										<UploadImage
											{...{
												src_img: `${
													process.env.REACT_APP_PUBLIC_BUCKET_URL
												}/periodics/cover/${String(periodic?.numId).toUpperCase()}/${String(
													periodic?.numId
												).toUpperCase()}.jpg`,
											}}
										/>
									</span>
								</FormField>
							</div>
						</>
					) : (
						<div className='block'>Salve a revista para enviar a capa e as mídias.</div>
					)}
				</TabPane>
			</Tabs>
			<PeriodicFormButtons
				{...{
					periodic,
					submit,
					disabled: false,
					// disabled: !form_state.valid,
				}}
			/>
		</>
	);
};
