import { DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import React, { CSSProperties, FC } from 'react';

interface IProps {
	title?: string;
	on_confirm: () => void;
	disabled?: boolean;
	text?: string;
	style?: CSSProperties;
}

export const RemoveButton: FC<IProps> = ({
	title = 'Deseja continuar?',
	text,
	on_confirm,
	disabled = false,
	style,
}) => (
	<Popconfirm {...{ placement: 'top', title, onConfirm: on_confirm, okText: 'Sim', cancelText: 'Não' }}>
		<Button {...{ disabled, type: 'primary', danger: true, style }}>
			{text ? text : <DeleteOutlined />}
		</Button>
	</Popconfirm>
);
