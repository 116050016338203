import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

export const InformativeListBreadcrumb: FC<any> = () => (
	<Breadcrumb>
		<Breadcrumb.Item>
			<Link to={`/conteudos/informativos/`}>Informativos</Link>
		</Breadcrumb.Item>
	</Breadcrumb>
);
