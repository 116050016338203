import { FC } from 'react';
import { AddInput } from '../../../../../../lib/components/AddInput';
import { ListFooter } from '../../../../../../lib/components/List/ListFooter';
import { useClientConfigForm } from '../../../hooks/useClientConfigForm';

interface IProps {
	on_ip_added: (ip: string) => void;
}

export const AddIpInput: FC<IProps> = ({ on_ip_added }) => {
	const { validators } = useClientConfigForm();

	return (
		<ListFooter>
			<AddInput
				{...{
					on_added: on_ip_added,
					validate: validators.valid_ip,
					format_value: (ip) => ip.replace(/ /g, '.').replace(/[^0-9.]/g, ''),
				}}
			/>
		</ListFooter>
	);
};
