import { GlobalOutlined } from '@ant-design/icons';
import { Divider, Input } from 'antd';
import Title from 'antd/lib/typography/Title';
import { FC } from 'react';
import { FormField } from '../../../../../../lib/components/Form/FormField';
import { FormState } from '../../../../../../lib/hooks/useForm/types';
import { ClientConfigFormValues } from '../../../hooks/useClientConfigForm';
import { SsoTokenKeyInput } from './SsoTokenKeyInput';

interface IProps {
	form_state: FormState<ClientConfigFormValues>;
	change_field_value: (field: keyof ClientConfigFormValues, value: any) => void;
}

export const SsoForm: FC<IProps> = ({ form_state, change_field_value }) => {
	return (
		<>
			<Title level={5} className='uppercase'>
				Token SSO
			</Title>
			<Divider />
			<FormField
				{...{
					label: 'Url de acesso',
					name: 'sso_token_issuer',
					form_state,
					required: false,
					children: (
						<div className='flex   items-center  bg-gray-50 p-2'>
							<GlobalOutlined {...{}} />
							<p className='ml-2'>
								https://www.forumconhecimento.com.br/sso?c=<strong>{form_state.value.sso_id}</strong>
								&t=
								<strong>TOKEN</strong>
							</p>
						</div>
					),
				}}
			/>
			<FormField
				{...{
					label: 'Token issuer',
					name: 'sso_token_issuer',
					form_state,
					required: false,
					children: (
						<Input
							{...{
								value: form_state.value.sso_token_issuer,
								onChange: (event) => change_field_value('sso_token_issuer', event.target.value),
							}}
						/>
					),
				}}
			/>
			<FormField
				{...{
					label: 'Url de Login',
					name: 'sso_login_url',
					form_state,
					required: false,
					children: (
						<Input
							{...{
								value: form_state.value.sso_login_url,
								onChange: (event) => change_field_value('sso_login_url', event.target.value),
								placeholder: 'https://www.sistema.com.br/login',
							}}
						/>
					),
				}}
			/>

			<SsoTokenKeyInput client_id={form_state.value.id} />
		</>
	);
};
