export type PermissionGroup = {
	id?: string;
	title: string;
	ruleType: string;
};

export const ruleOptions = [
	{
		key: 'ALL_SERIES_BY_ACTIVE_CONTRACT',
		title: 'Todas as séries do mesmo grupo -> Contrato Ativo',
	},
	{
		key: 'BOOLEAN_BY_ACTIVE_CONTRACT',
		title: 'Produto Específico -> Contrato Ativo',
	},
];
