import { useForm } from '../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../lib/hooks/useForm/validators';

type FormValues = {
	id: string;
	name: string;
	href1: string;
	href2: string;
	open_new_window1: boolean;
	open_new_window2: boolean;
};

export function useBannerForm() {
	const form = useForm<FormValues>(
		{
			id: '',
			name: '',
			href1: '',
			href2: '',
			open_new_window1: false,
			open_new_window2: false,
		},
		function validate_form(state) {
			return {
				id: '',
				name: form_validators.required_text_field(state.value.name),
				href1: validade_link(state.value.href1),
				href2: validade_link(state.value.href2),
				open_new_window1: '',
				open_new_window2: '',
			};
		}
	);

	return form;
}

function validade_link(link: string) {
	if (link) {
		if (link.startsWith('https://') || link.startsWith('http://')) {
			return '';
		}
		return 'Link deve iniciar com http:// ou https: //. ex: https://digital.editoraforum.com.br';
	}

	return '';
}
