import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { VideoListPage } from './pages/VideoListPage';
import { InsertVideoPage } from './pages/InsertVideoPage';
import { UpdateVideoPage } from './pages/UpdateVideoPage';

export const VideosRoutes: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.Contents] }}>
		<Routes>
			<Route {...{ path: 'novo', element: <InsertVideoPage /> }} />
			<Route {...{ path: ':id', element: <UpdateVideoPage /> }} />
			<Route {...{ index: true, path: '/', element: <VideoListPage /> }} />
		</Routes>
	</ProtectedContent>
);
