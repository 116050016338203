import { useForm } from '../../../../../lib/hooks/useForm/useForm';
import { form_validators } from '../../../../../lib/hooks/useForm/validators';

export function usePriceVersionForm() {
	const form = useForm(
		{
			comments: '',
		},
		function validate_form(state) {
			return {
				comments: form_validators.required_text_field(state.value.comments),
			};
		}
	);

	return form;
}
