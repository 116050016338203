import { InsertPermissionDto, PermissionDto } from '../../../../lib/dtos/PermissionDto';
import { useRequest } from '../../../../lib/hooks';
import { request } from '../../../../lib/utils/request';

const BASE_URL = `${process.env.REACT_APP_PERMISSIONS_GROUPS_API}/permissions`;

export function usePermissionsApi() {
	const insert = useCreatePermission();
	const remove = useRemovePermission();

	return {
		insert,
		remove,
	};
}

function useCreatePermission() {
	const [result, loading, error, do_create] = useRequest<PermissionDto>((dto: InsertPermissionDto) =>
		request().post(`${BASE_URL}`, dto)
	);

	return {
		create: (dto: InsertPermissionDto) => do_create(dto),
		result,
		error,
		loading,
	};
}

function useRemovePermission() {
	const [result, loading, error, remove] = useRequest<string>((id: string) =>
		request().delete(`${BASE_URL}/${id}`)
	);

	return {
		do_remove: (id: string) => remove(id),
		result,
		loading,
		error,
	};
}
