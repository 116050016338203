import { FC, useEffect } from 'react';
import { useRequest, useRequestFeedback } from '../../../../../lib/hooks';
import { request } from '../../../../../lib/utils/request';

interface IProps {
	view_api_endpoint: string,
}

export const Videopreview: FC<IProps> = ({ view_api_endpoint }) => {
	const [signed_url, , error_signed, get_signed_url] = useRequest(() =>
		request().get<string>(view_api_endpoint).then((res: any) => res.url)
	);

	useRequestFeedback('', error_signed);

    useEffect(() => {
        if (!signed_url) get_signed_url();
    }, [signed_url]);

    const logVideoError = (err: any) => {
        console.log('Video error: ', err);
    }

	return (
        <>
            {signed_url ? (<video controls controlsList="nodownload" style={{ maxHeight: 200 }} onError={logVideoError}>
                <source src={signed_url} type="video/mp4" />
            </video>) : (
                <>
                    <p className='bg-yellow-200 p-2 mb-2 block'>Carregando o vídeo...</p>
                    <img {...{
                        src: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/videos/thumbs/placeholder.jpg`,
                        style: { maxHeight: 200 }, alt: ''
                    }} />
                </>
            )}
        </>
    );
};
