import { useQueryParams } from '../../../hooks';
import { useChangeQueryParams } from '../../../hooks/useChangeQueryParams';
import { SearchQueryDto } from '../model/SearchQueryDto';

export function useQuery(default_query: SearchQueryDto) {
	const { change } = useChangeQueryParams<Partial<SearchQueryDto>>();

	const params = useQueryParams() as Partial<SearchQueryDto>;

	return {
		change: (search_query: SearchQueryDto) => change(search_query),
		text: (params.text || '').trim(),
		change_text: (text: string) => change({ text }),
		tags: (params.tags || '').split(','),
		change_tags: (tags: string) => change({ tags }),
		groups: (params.groups || '').split(','),
		change_groups: (groups: string) => change({ groups }),
		sort_by: params.sort_by || 'publish_date',
		change_sort_by: (sort_by: 'publish_date' | 'relevance') => change({ sort_by }),
		page: +(params.page || 1),
		change_page: (page: number) => change({ page }),
		clear: () => change(default_query),
		default:
			params.text === default_query.text &&
			params.groups === default_query.groups &&
			+(params.page || 1) === default_query.page &&
			params.tags === default_query.tags &&
			params.sort_by === default_query.sort_by,
	};
}
