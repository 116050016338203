import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	section_title: string;
}

export const SectionBreadcrumb: FC<IProps> = ({ section_title }) => (
	<Breadcrumb>
		<Breadcrumb.Item key='home'>
			<Link to={'/loja/secoes'}>Seções</Link>
		</Breadcrumb.Item>
		<Breadcrumb.Item key='section'>{section_title}</Breadcrumb.Item>
	</Breadcrumb>
);
