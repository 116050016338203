import React, { useState } from 'react';
import { complement, prop, propEq } from 'ramda';
import { FormState } from '../../hooks/useForm/types';
import { Tag } from '../../model/Tag';
import { FormField } from '../Form/FormField';
import { useSearchableApi } from '../SearchableDataTable/hooks/useSearchableApi';
import { SearchInput } from './SearchInput';
import { SelectedTags } from './SelectedTags';
import { TagList } from './TagList';

type TagPrefix = 'area-interest' | 'author' | 'event' | 'kwrd' | 'theme';

interface IMultipleTagSelectProps {
	name: string;
	label?: string;
	required?: boolean;
	form_state: FormState<any>;
	values: Tag[];
	on_change: (tag_list: Tag[]) => void;
	tag_prefix?: TagPrefix;
	class_name?: string;
}

export const MultipleTagSelect = ({
	name,
	label,
	required,
	form_state,
	on_change,
	values,
	tag_prefix,
	class_name,
}: IMultipleTagSelectProps) => {
	const { loading, result, search } = useSearchableApi<Tag>('admin-search-tags-entity');
	const search_result = result?.entities || [];
	const [text, set_text] = useState<string>('');

	return (
		<FormField
			{...{
				name,
				label,
				required,
				form_state,
				render_children_inside_label: false,
				class_name,
			}}
		>
			<SelectedTags
				{...{
					on_remove_tag: (id: string) => {
						on_change(values.filter(complement(propEq('id', id))));
					},
					tags: values,
				}}
			/>

			<SearchInput
				{...{
					name,
					loading,
					text,
					set_text,
					on_search: (text: string) => search({ text, tags: tag_prefix }),
				}}
			/>

			<TagList
				{...{
					tags: search_result.filter(not_selected_tag(values)),
					on_tag_selected: (tag: Tag) => {
						on_change([...values, tag]);
						set_text('');
					},
				}}
			/>
		</FormField>
	);
};

const not_selected_tag = (selecteds: Tag[]) => {
	const selected_ids = new Set(selecteds.map(prop('id')));
	return (tag: Tag) => !selected_ids.has(tag.id);
};
