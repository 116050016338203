import { Button } from 'antd';
import { CSSProperties, FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
	route: string;
	name: string;
	style?: CSSProperties;
	type?: 'link' | 'text' | 'default' | 'primary' | 'ghost' | 'dashed';
	open_new_tab?: boolean;
}

export const LinkButton: FC<IProps> = ({ route, name, style, type = 'default', open_new_tab }) => {
	const navigate = useNavigate();

	return (
		<Button
			{...{ style, type, onClick: () => (open_new_tab ? window.open(route, '_blank') : navigate(route)) }}
		>
			{name}
		</Button>
	);
};
