import { Modal } from 'antd';
import React, { FC, useEffect } from 'react';
import { MultiEmailInput } from '../../../../lib/components/MultiEmailInput';
import { useRequestFeedback } from '../../../../lib/hooks';
import { useContentAssignmentApi } from '../hooks/useContentListApi';

interface IProps {
	visible: boolean;
	close_modal: () => void;
	on_insert: (value: string[]) => void;
}

export const AssociationInsertModal: FC<IProps> = ({ visible, close_modal, on_insert }) => {
	const { insert_associateds_list } = useContentAssignmentApi();

	useEffect(() => {
		close_modal();
		on_insert(insert_associateds_list.result);
	}, [insert_associateds_list.result]);

	useRequestFeedback(insert_associateds_list.result, insert_associateds_list.error);

	function submit(emails: string[]) {
		insert_associateds_list.do_insert_associateds(emails);
	}

	return (
		<Modal
			{...{
				title: 'Adicionar Associado(s)',
				visible,
				centered: true,
				width: '41rem',
				closable: true,
				destroyOnClose: true,
				onCancel: close_modal,
				footer: null,
			}}
		>
			<MultiEmailInput {...{ on_submit: submit }} />
		</Modal>
	);
};
