import { Breadcrumb } from 'antd';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
	user_name: string;
}

export const UserProfileBreadcrumb: FC<IProps> = ({ user_name }) => (
	<Breadcrumb>
		<Breadcrumb.Item key='home'>
			<Link to={'/loja/usuarios'}>Usuários</Link>
		</Breadcrumb.Item>
		<Breadcrumb.Item key='user'>{user_name}</Breadcrumb.Item>
	</Breadcrumb>
);
