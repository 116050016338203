import qs from 'qs';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from './useQueryParams';

export function useChangeQueryParams<T>() {
	const navigate = useNavigate();
	const query_params: any = useQueryParams();

	return {
		change: (params: T) => {
			navigate(
				`?${qs.stringify({
					...(query_params || {}),
					...params,
				})}`
			);
		},
	};
}
