import { FC } from 'react';
import { SearchableProvider } from '../../../../lib/components/SearchableDataTable/SearchableProvider';
import { PageLayout } from '../../../../lib/shell/PageLayout/PageLayout';
import { default_query } from '../../../../lib/utils/consts';
import { ContractsBreadcrumb } from '../../clients/client_contracts/components/ContractsBreadcrumb';
import { ContractsDatatable } from '../components/ContractsDatatable';

export const ContractsListPage: FC = () => {
	const entity_name = 'plat-client-contract-management-contracts';

	return (
		<PageLayout {...{ title: 'Contratos', breadcrumb: () => <ContractsBreadcrumb /> }}>
			<SearchableProvider {...{ entity_name, default_query }}>
				<ContractsDatatable />
			</SearchableProvider>
		</PageLayout>
	);
};
