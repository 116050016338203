import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { BannersRoutes } from './banners/routes';
import { ContentAssignmentRoutes } from './content_assignment/routes';
import { HighlightsRoutes } from './highlights/routes';
import { PricesRoute } from './prices/routes';
import { SectionsRoutes } from './sections/routes';
import { UserProfilesRoutes } from './user_profiles/routes';

export const StoreRoutes: FC = () => (
	<Routes>
		<Route path='precos/*' element={<PricesRoute />} />
		<Route path='secoes/*' element={<SectionsRoutes />} />
		<Route path='banners/*' element={<BannersRoutes />} />
		<Route path='destaques/*' element={<HighlightsRoutes />} />
		<Route path='usuarios/*' element={<UserProfilesRoutes />} />
		<Route path='liberacao-de-conteudo/direito-autoral/*' element={<ContentAssignmentRoutes />} />
	</Routes>
);
