import { Alert, Input, Tabs, message } from 'antd';
import { FC, useEffect } from 'react';
import { FormContainer } from '../../../../../lib/components/Form/FormContainer';
import { FormField } from '../../../../../lib/components/Form/FormField';
import { MultipleTagSelect } from '../../../../../lib/components/MultipleTagSelect';
import { BookEntity } from '../../../../../lib/model/Book';
import { Tag } from '../../../../../lib/model/Tag';
import { InsertBookDto } from '../../dto/InsertBookDto';
import { UpdateBookDto } from '../../dto/UpdateBookDto';
import { useBooksApi } from '../../hooks/useBooksApi';
import { BookSerieSelect } from '../SelectBookSerie';
import { BookFormButtons } from './BookFormButtons';
import { useBookForm } from './useBookForm';
import { createBrowserHistory } from 'history';
import { useParams } from 'react-router-dom';
import { extract_num_id_from_id } from '../../utils/extract_num_id_from_id';
import { MultipleSerieSelect } from '../../../../../lib/components/MultipleSerieSelect';

const class_name_field = 'mb-2 w-1/2';

interface IProps {
	book?: BookEntity;
}

export const BookForm: FC<IProps> = ({ book }) => {
	const history = createBrowserHistory();
	const { TabPane } = Tabs;
	const { form_state, show_errors, change_field_value, fill_with_data } = useBookForm();
	const { insert, update, get_last_id } = useBooksApi();

	let alternative_series: Tag[] = [];
	let area_interest_tags: Tag[] = [];
	let keyword_tags: Tag[] = [];
	const { id: book_id } = useParams() as { id: string };
	const book_num_id = extract_num_id_from_id(book_id);

	useEffect(() => {
		book?.tags.forEach((tag) => {
			if (tag.id.includes('area-interest')) {
				return area_interest_tags.push(tag);
			}

			if (tag.id.includes('kwrd')) {
				return keyword_tags.push(tag);
			}

			if (tag.id.includes('book-serie')) {
				return alternative_series.push(tag);
			}

			return;
		});

		const book_data = {
			num_id: book?.num_id || '',
			title: book?.title || '',
			cdd: book?.cdd || '',
			cdu: book?.cdu || '',
			book_serie: book?.book_serie || { id: '', title: '' },
			alternative_series: alternative_series || [],
			areas_interest: area_interest_tags || [],
			keywords: keyword_tags || [],
		};

		fill_with_data(book_data);

		if (!book) {
			get_last_id.get();
		}
	}, [book]);

	function submit() {
		show_errors();

		if (!form_state.valid) {
			return;
		}

		const dto: UpdateBookDto | InsertBookDto = {
			...form_state.value,
			num_id: String(form_state.value.num_id),
			tags: [
				...form_state.value.areas_interest,
				...form_state.value.keywords,
				...form_state.value.alternative_series,
			].map((tag) => ({
				id: tag.id,
				title: tag.title,
			})),
		};

		book ? update.do_update(dto) : insert.create(dto);
	}

	useEffect(() => {
		if (update.result || insert.result) {
			message.success('Salvo com sucesso.');
			const num_id = insert?.result?.id || update?.result?.id || book_num_id;
			history.push(`/conteudos/livros/${num_id}`);
			window.location.reload();
		}
	}, [update.result, insert.result]);

	useEffect(() => {
		if (get_last_id.result) {
			const last_num_id = Number(String(get_last_id.result.num_id).replace(/L/g, '').replace(/ /g, ''));
			if (!form_state.value.num_id) {
				const book_data = {
					num_id: last_num_id ? `L${last_num_id + 1}` : '0',
					title: book?.title || '',
					cdd: book?.cdd || '',
					cdu: book?.cdu || '',
					book_serie: book?.book_serie || { id: '', title: '' },
					alternative_series: alternative_series || [],
					areas_interest: area_interest_tags || [],
					keywords: keyword_tags || [],
				};

				fill_with_data(book_data);
			}
		}
	}, [get_last_id.result]);

	return (
		<Tabs type='card'>
			<TabPane tab='Informações' key='infos'>
				<FormContainer>
					{book ? (
						<>
							<Alert
								message={`É necessário republicar todas as edições para que as alterações em livro reflitam na plataforma.`}
								type='warning'
							/>
							<br />
						</>
					) : (
						<></>
					)}

					<FormField
						{...{
							label: 'Identificador',
							name: 'num_id',
							placeholder: 'Ex: L0000',
							form_state,
							class_name: class_name_field,
						}}
					>
						<Input
							{...{
								disabled: true,
								value: form_state.value.num_id,
								type: 'text',
								onChange: (event) => change_field_value('num_id', event.target.value),
							}}
						/>
					</FormField>
					<FormField {...{ label: 'Título', name: 'title', form_state, class_name: class_name_field }}>
						<Input
							{...{
								value: form_state.value.title,
								onChange: (event) => change_field_value('title', event.target.value),
							}}
						/>
					</FormField>

					<FormField {...{ label: 'CDD', name: 'cdd', form_state, class_name: class_name_field }}>
						<Input
							{...{
								value: form_state.value.cdd,
								onChange: (event) => change_field_value('cdd', event.target.value),
							}}
						/>
					</FormField>

					<FormField {...{ label: 'CDU', name: 'cdu', form_state, class_name: class_name_field }}>
						<Input
							{...{
								value: form_state.value.cdu,
								onChange: (event) => change_field_value('cdu', event.target.value),
							}}
						/>
					</FormField>

					<BookSerieSelect
						{...{
							label: 'Série',
							name: 'book-serie',
							form_state,
							class_name: class_name_field,
							serie_from_book: book?.book_serie,
							book_serie_state: form_state.value.book_serie,
							on_change: (serie) => change_field_value('book_serie', JSON.parse(serie)),
						}}
					/>

					<MultipleSerieSelect
						{...{
							form_state,
							label: 'Séries Alternativas',
							name: 'alternative_series',
							on_change: (alternative_series) =>
								change_field_value('alternative_series', alternative_series),
							values: form_state.value.alternative_series,
							class_name: class_name_field,
							required: false,
						}}
					/>

					<MultipleTagSelect
						{...{
							form_state,
							label: 'Áreas de Interesse',
							name: 'areas_interest',
							on_change: (areas_interest) => change_field_value('areas_interest', areas_interest),
							values: form_state.value.areas_interest,
							tag_prefix: 'area-interest',
							class_name: class_name_field,
						}}
					/>

					<MultipleTagSelect
						{...{
							form_state,
							label: 'Palavras-Chave',
							name: 'keywords',
							on_change: (keywords) => change_field_value('keywords', keywords),
							values: form_state.value.keywords,
							tag_prefix: 'kwrd',
							class_name: class_name_field,
						}}
					/>

					<BookFormButtons
						{...{
							book,
							submit,
							disabled: !form_state.valid,
						}}
					/>
				</FormContainer>
			</TabPane>
		</Tabs>
	);
};
