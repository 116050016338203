import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../Buttons/AddButton';
import { UpdateButton } from '../Buttons/UpdateButton';
import { ActionButton } from '../Buttons/ActionButton';

interface IProps {
	go_back_on_cancel?: boolean;
	on_add_button_click?: () => void;
	on_update_button_click?: () => void;
	on_action_button_click?: () => void;
	action_button_text?: string;
	action_button_route?: string;
	disabled?: boolean;
}

export const FormButtons: React.FC<IProps> = ({
	go_back_on_cancel = true,
	on_add_button_click,
	on_update_button_click,
	on_action_button_click,
	action_button_text,
	action_button_route,
	disabled = false,
}) => {
	const navigate = useNavigate();

	return (
		<div
			className='fixed bg-white border-t border-gray-200 py-3 z-20 w-full bottom-0 animate-fadeInUp '
			{...{
				style: {
					left: 200,
					maxWidth: `calc(100vw  -  200px) `,
				},
			}}
		>
			<div className='flex  items-center justify-center'>
				{go_back_on_cancel && (
					<Button
						{...{
							danger: true,
							type: 'link',
							className: 'absolute left-0',
							onClick: () => {
								if (go_back_on_cancel) {
									return navigate(-1);
								}
							},
						}}
					>
						CANCELAR
					</Button>
				)}

				{on_add_button_click && (
					<AddButton
						{...{
							disabled,
							onClick: on_add_button_click,
						}}
					/>
				)}

				{on_update_button_click && (
					<UpdateButton
						{...{
							disabled,
							onClick: on_update_button_click,
						}}
					/>
				)}

				{on_action_button_click && (
					<ActionButton
						{...{
							text: action_button_text || '',
							route: action_button_route || '',
							disabled: false,
							onClick: on_action_button_click,
						}}
					/>
				)}
			</div>
		</div>
	);
};
