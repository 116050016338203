import { Input } from 'antd';
import { FC } from 'react';

interface IProps {
	input_item_name: string;
	label: string;
	class_name?: string;
	on_changed: (value: string) => void;
	disabled?: boolean;
}

export const InputItem: FC<IProps> = ({ input_item_name, on_changed, label, class_name, disabled }) => (
	<div className={class_name}>
		<label className='mr-4 font-semibold'>
			<span className='text-red-500'> * </span>
			{label}:
		</label>
		<div className='w-5/6'>
			<Input
				{...{
					autoFocus: true,
					value: input_item_name,
					onChange: (event) => on_changed(event.target.value),
					allowClear: true,
					disabled: !!disabled,
				}}
			/>
		</div>
	</div>
);
