import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { PreviewPage } from './pages/PreviewPage';
import { PricesFilesListPage } from './pages/PricesFileListPage';

export const PricesRoute: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.StoreAdmin, Role.StorePricing] }}>
		<Routes>
			<Route path=':version_date/visualizar' element={<PreviewPage />} />
			<Route path='/' element={<PricesFilesListPage />} />
		</Routes>
	</ProtectedContent>
);
