import { Tag } from './Tag';

export type PeriodicEntity = {
	id: string;
	numId: string;
	publishType: string;
	acronym: string;
	title: string;
	description: string;
	cdu: string;
	cdd: string;
	issn: string;
	issnImpresso?: string;
	qualisSeal: string;
	tags: Tag[];
	expedientKey?: string;
	publicationStandardKey?: string;
};

export enum SignedUrlTypes {
	READ = 'READ',
	WRITE = 'WRITE',
}

export enum PublishType {
	Periodic = 'periodic',
	Collection = 'collection',
}

export type EditionEntity = {
	id?: string;
	numId: string;
	periodicNumId: string;
	number: number;
	title: string;
	frequency: EditionFrequency;
	startMonth: string;
	endMonth: string;
	startYear: string;
	currentEdition: boolean;
};

export enum EditionFrequency {
	Mensal = 'Mensal',
	Bimestral = 'Bimestral',
	Trimestral = 'Trimestral',
	Quadrimestral = 'Quadrimestral',
	Semestral = 'Semestral',
	Anual = 'Anual',
	Irregular = 'Irregular',
}

export enum ItemTypes {
	Other = '',
	Doctrine = 'doctrine',
	Jurisprudence = 'jurisprudence',
	Jurisprudential_Trends = 'jurisprudential-trends',
}

export type ItemEntity = {
	id?: string;
	numId: string;
	editionNumId: string;
	title: string;
	itemType: ItemTypes;
	keywords: Tag[];
	authors: Tag[];
	themes: Tag[];
	order?: number;
	articleKey?: string;
};
