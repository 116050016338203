import React, { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ProtectedContent } from '../../../lib/features/auth/components/ProtectedContent';
import { Role } from '../../../lib/features/auth/model/Role';
import { ContentListPage } from './pages/ContentListPage';
import { ContentPage } from './pages/ContentPage';

export const ContentAssignmentRoutes: FC = () => (
	<ProtectedContent {...{ roles: [Role.Admin, Role.StoreAdmin] }}>
		<Routes>
			<Route path=':list_id' element={<ContentPage />} />
			<Route path='/' element={<ContentListPage />} />
		</Routes>
	</ProtectedContent>
);
