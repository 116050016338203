import { FC } from 'react';
import { FormButtons } from '../../../../../lib/components/Form/FormButtons';
import { BookEntity } from '../../../../../lib/model/Book';

interface IProps {
	book?: BookEntity;
	submit: () => void;
	disabled?: boolean;
}

export const BookFormButtons: FC<IProps> = ({ book, submit, disabled }) => {
	return (
		<FormButtons
			{...{
				disabled,
				go_back_on_cancel: true,
				on_add_button_click: book ? undefined : () => submit(),
				on_update_button_click: book ? () => submit() : undefined,
				on_action_button_click: book ? () => {} : undefined,
				action_button_text: book ? 'Edições' : undefined,
				action_button_route: book ? `/conteudos/livros/${book?.id}/edicoes` : undefined,
			}}
		/>
	);
};
