import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { Content } from 'antd/lib/layout/layout';
import { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Auth0Wrapper } from './lib/features/auth/Auth0Wrapper';
import { ForumAuthProvider } from './lib/features/auth/ForumAuthProvider';
import { Sidebar } from './lib/shell/Sidebar';
import { ContentsRoutes } from './pages/contents/routes';
import { PlatformRoutes } from './pages/platform/routes';
import { StoreRoutes } from './pages/store/routes';
import FormTestPage from './pages/tests/forms/FormTestPage';
import './tailwind.output.css';

export const SIDEBAR_WIDTH = 250;

const App: FC = () => (
	<Auth0Wrapper
		{...{
			render_children: ({ token, logout }: any) => (
				<ForumAuthProvider {...{ auth_token: token, auth0_logout: logout }}>
					<BrowserRouter>
						<div id="webview" className='w-full flex'>
							<Sidebar />

							<Content>
								<Routes>
									<Route path='plataforma/*' element={<PlatformRoutes />} />
									<Route path='loja/*' element={<StoreRoutes />} />
									<Route path='conteudos/*' element={<ContentsRoutes />} />
									<Route path='tests/form' element={<FormTestPage />} />
								</Routes>
							</Content>
						</div>
					</BrowserRouter>
				</ForumAuthProvider>
			),
		}}
	/>
);

export default App;
