import { useForm } from '../../../../../../lib/hooks/useForm/useForm';

export type UserLimit = { email: string; limit: number };

export type DownloadsConfigArgs = {
	is_default_limit: boolean;
	limit: number;
	user_limits: UserLimit[] | [];
};

export function useDownloadsConfigForm() {
	const form = useForm<DownloadsConfigArgs>(
		{
			limit: 0,
			is_default_limit: false,
			user_limits: [{ email: '', limit: 0 }],
		},
		function validate_form(state) {
			return {
				limit: '',
				is_default_limit: '',
				user_limits: '',
			};
		}
	);

	return form;
}
