import createAuth0Client from '@auth0/auth0-spa-js';
import { Button, Spin } from 'antd';
import { createBrowserHistory } from 'history';
import { useEffect, useState } from 'react';
import { useSessionStore } from './hooks/useSessionStore';
const {
	REACT_APP_AUTH0_DOMAIN,
	REACT_APP_AUTH0_ISSUER,
	REACT_APP_AUTH0_CLIENT_ID,
	REACT_APP_AUTH0_AUDIENCE,
} = process.env;

const history = createBrowserHistory();

export const Auth0Wrapper = ({ render_children }) => {
	const session_storage = useSessionStore();
	const [token, set_token] = useState('');
	const [auth0Client, setAuth0] = useState();
	const [loading, setLoading] = useState(true);
	const [authError, setAuthError] = useState();
	// const history = useHistory();

	const onRedirectCallback = (appState) =>
		history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);

	useEffect(() => {
		const initAuth0 = async () => {
			const auth0FromHook = await createAuth0Client({
				domain: REACT_APP_AUTH0_DOMAIN,
				issuer: REACT_APP_AUTH0_ISSUER,
				client_id: REACT_APP_AUTH0_CLIENT_ID,
				audience: REACT_APP_AUTH0_AUDIENCE,
				redirect_uri: window.location.origin,
				scope: `openid name profile picture`,
				onRedirectCallback,
			});

			setAuth0(auth0FromHook);

			if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
				const { appState } = await auth0FromHook.handleRedirectCallback();
				onRedirectCallback(appState);
			}

			const isAuthenticated = await auth0FromHook.isAuthenticated();

			if (isAuthenticated) {
				const token = await auth0FromHook.getTokenSilently();
				set_token(token);
			} else {
				session_storage.clear();
				auth0FromHook.loginWithRedirect();
			}

			setLoading(false);
		};

		initAuth0().catch((err) => {
			session_storage.clear();
			setAuthError(err);
			console.error(err);
		});
	}, []);

	function logout(...p) {
		auth0Client.logout({
			...p,
			returnTo: window.origin + '',
		});
	}

	return (
		<>
			{(() => {
				if (loading) {
					return (
						<Spin
							{...{
								size: 'large',
								style: { position: 'absolute', top: '50%', left: '50%' },
							}}
						/>
					);
				}

				if (authError) {
					return (
						<Button {...{ onClick: logout }}>Ocorreu um erro na autenticação. Tentar Novamente.</Button>
					);
				}

				return (
					token &&
					render_children({
						token,
						logout,
					})
				);
			})()}
		</>
	);
};
