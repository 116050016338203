import { FC } from 'react';
import { Address, Work } from '../../../../lib/model/UserProfile';

interface IProps {
	work: Work;
	address: Address;
}

export const UserAddressData: FC<IProps> = ({ work, address }) => (
	<div className='flex flex-row w-full'>
		{address.street === 'empty' && work.name === 'empty' && (
			<p className='text-lg text-gray-400'> Nenhum Endereço Cadastrado.</p>
		)}
		{address.street !== 'empty' && (
			<div className='flex flex-col border py-6 px-8 border-gray-300 rounded-lg mr-16'>
				<Title {...{ text: 'Pessoal' }} />
				<Adress {...{ address }} />
			</div>
		)}

		{work.name !== 'empty' && <WorkInf {...{ work }} />}
	</div>
);

interface IAdress {
	address: Address;
}

const Adress: FC<IAdress> = ({ address }) => (
	<table>
		<tr className='align-middle'>
			<td className=' text-gray-600 font-semibold w-40'>Rua:</td>
			<td className='text-base'>{address.street}</td>
		</tr>
		<tr className='align-baseline'>
			<td className='text-gray-600 font-semibold py-4'>Número:</td>
			<td className='text-base'>{address.number}</td>
		</tr>
		<tr className='align-baseline'>
			<td className='text-gray-600 font-semibold'>Distrito:</td>
			<td className='text-base'>{address.district}</td>
		</tr>
		<tr className='align-baseline'>
			<td className='text-gray-600 font-semibold py-4'>Complemento:</td>
			<td className='text-base'>{address.complement}</td>
		</tr>
		<tr className='align-baseline'>
			<td className='text-gray-600 font-semibold'>Cidade:</td>
			<td className='text-base'>{address.city}</td>
		</tr>
		<tr className='align-baseline'>
			<td className='text-gray-600 font-semibold py-4'>Estado:</td>
			<td className='text-base'>{address.state}</td>
		</tr>
		<tr className='align-baseline'>
			<td className='text-gray-600 font-semibold pb-4'>País:</td>
			<td className='text-base'>{address.country}</td>
		</tr>
		<tr className='align-baseline'>
			<td className='text-gray-600 font-semibold'>Código Postal:</td>
			<td className='text-base'>{address.postalCode}</td>
		</tr>
	</table>
);

interface IWorkInf {
	work: Work;
}

const WorkInf: FC<IWorkInf> = ({ work }) => (
	<div className='flex flex-col border py-6 px-8 border-gray-300 rounded-lg'>
		<Title {...{ text: 'Empresa' }} />
		<table>
			<tr className='align-baseline'>
				<td className=' text-gray-600 font-semibold w-40'>Nome:</td>
				<td className='text-base'>{work.name}</td>
			</tr>
			<tr className='align-baseline'>
				<td className=' text-gray-600 font-semibold py-4'>CNPJ:</td>
				<td className='text-base'>{work.cnpj}</td>
			</tr>
			<tr className='align-baseline'>
				<td className=' text-gray-600 font-semibold pb-4'>Telefone:</td>
				<td className='text-base'>
					{work.phone.areaCode} {work.phone.number}
				</td>
			</tr>
		</table>
		{<Adress {...{ address: work.address }} />}
	</div>
);

interface ITitle {
	text: string;
}

const Title: FC<ITitle> = ({ text }) => (
	<div className='w-full border-b-2 mb-5 pb-1 text-gray-700 text-base'> {text} </div>
);
