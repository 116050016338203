import { Select } from 'antd';
import { FC } from 'react';
import { Languages } from '../../../../../../lib/model/Book';

const Option = Select;

interface IProps {
	value: Languages;
	on_change: (field_name: any, value: Languages) => void;
}

export const SelectChapterLanguage: FC<IProps> = ({ value, on_change }) => (
	<Select
		placeholder='Selecione o idioma'
		onChange={(language) => on_change('language', language)}
		defaultValue={Languages.PORTUGUESE}
		style={{ width: '50%' }}
		value={value}
	>
		<Option value={Languages.PORTUGUESE} key={Languages.PORTUGUESE}>
			Português
		</Option>
		<Option value={Languages.ENGLISH} key={Languages.ENGLISH}>
			Inglês
		</Option>
		<Option value={Languages.SPANISH} key={Languages.SPANISH}>
			Espanhol
		</Option>
	</Select>
);
