import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { ButtonsContainer } from '../../../../lib/components/Buttons/ButtonsContainer';
import { LinkButton } from '../../../../lib/components/Buttons/LinkButton';
import { RemoveButton } from '../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../lib/components/DataTable/CellStyle';
import { ColumnFactory } from '../../../../lib/components/DataTable/ColumnFactory';
import { SearchableDataTable } from '../../../../lib/components/SearchableDataTable/SearchableDataTable';
import { useRequestFeedback } from '../../../../lib/hooks';
import { VideoEntity } from '../../../../lib/model/Video';
import { useVideosApi } from '../hooks/useVideosApi';

export const VideosDataTable: FC = () => {
	const { remove } = useVideosApi();

	const navigate = useNavigate();
	const renderImage = (img : string) => img.length ? img : 'videos/thumbs/placeholder.jpg';

	useRequestFeedback(remove.result, remove.error);

	return (
		<SearchableDataTable
			{...{
				row_data_from_entity: (video: VideoEntity) => ({ ...video }),
				sortable_fields: ['#', 'Título', 'Data'],
				searchable_defaults: { sort_field: 'sort1', sort_dir: 'desc' },
				buttons_on_top: [<AddButton onClick={() => navigate('novo')} />],
				columns: [
					{
						field: 'num_id',
						headerName: '#',
					},
					{
						col_def: {
							field: 'num_id',
							headerName: '',
						},
						renderer: (props: { data: { num_id: string; image: string; title: string } }) => (
							<img
								{...{
									style: { width: 149, height: 84 },
									src: `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/${renderImage(props.data.image)}`,
									alt: `${props.data.title}`,
								}}
							/>
						),
					},
					ColumnFactory.Link(
						{
							field: 'title',
							headerName: 'Título',
						},
						(props: { data: { num_id: string } }) => props.data.num_id
					),
					{
						field: 'event_date',
						headerName: 'Data',
					},
					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							cellStyle: CellStyle.Centered,
							width: 180,
						},
						renderer: (props: { data: VideoEntity }) => (
							<ButtonsContainer class_name='flex center'>
								<LinkButton
									{...{
										name: 'Editar',
										type: 'ghost',
										route: `${props.data.num_id}`,
									}}
								/>
								<RemoveButton
									{...{
										on_confirm: () => remove.do_remove(String(props.data.num_id)),
										title: `Deseja remover o vídeo ${props.data.title}?`,
									}}
								/>
							</ButtonsContainer>
						),
					},
				],
			}}
		/>
	);
};
