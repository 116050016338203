import { SearchableQueryDto } from '../components/SearchableDataTable/model/SearchableQueryDto';

export const default_query: SearchableQueryDto = {
	limit: 20,
	page: 1,
	sort: 'sort1',
	sort_dir: 'desc',
	tags: '',
	text: '',
};
