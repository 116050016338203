import { useParams } from 'react-router-dom';
import { create_new_content_group } from '../../../../lib/features/content_group/hooks/useContentGroup';
import { useRequest } from '../../../../lib/hooks';
import { request } from '../../../../lib/utils/request';
import { HighlightDto } from '../dtos/HighlightDto';
import { InsertHighlightDto } from '../dtos/InsertHighlightDto';
import { UpdateHighlightDto } from '../dtos/UpdateHighlightDto';

const BASE_URL = `${process.env.REACT_APP_HIGHLIGHTS_API}`;

export function useHighlightsApi() {
	const { id } = useParams() as { id: string };

	const remove = useRemoveHighlight();
	const insert = useCreateHighlight();
	const update = useUpdateHighlight(id);
	const publish = usePublishHighlights();

	return {
		publish,
		insert,
		remove,
		update,
	};
}

function useCreateHighlight() {
	const [result, loading, error, do_create] = useRequest<HighlightDto>((dto: InsertHighlightDto) =>
		create_new_content_group().then((content_group) =>
			request().post(BASE_URL, { name: dto.name, content_group_id: content_group.id })
		)
	);

	return {
		create: (dto: InsertHighlightDto) => do_create(dto),
		result,
		error,
		loading,
	};
}

function useUpdateHighlight(id: string) {
	const [result, loading, error, update] = useRequest<HighlightDto>((dto: UpdateHighlightDto) =>
		request().put(`${BASE_URL}/${id}`, dto)
	);

	return {
		do_update: (dto: UpdateHighlightDto) => update(dto),
		result,
		error,
		loading,
	};
}

function useRemoveHighlight() {
	const [result, loading, error, remove] = useRequest<string>((id: string) =>
		request().delete(`${BASE_URL}/${id}`)
	);

	return {
		do_remove: (id: string) => remove(id),
		result,
		loading,
		error,
	};
}

function usePublishHighlights() {
	const [result, loading, error, do_publish] = useRequest<string>(() =>
		request().post(`${BASE_URL}/publish`)
	);
	return {
		do_publish,
		result,
		loading,
		error,
	};
}
