import { useEffect, useState } from 'react';
import { Search } from '../../../../components/Search';
import { useSearchableApi } from '../../../../components/SearchableDataTable/hooks/useSearchableApi';
import { Content } from '../../../../model/Content';
import { EditionSelectionList } from './EditionSelectionList';
import { SelectedEdition } from './SelectedEdition';
import { Book } from './types';

interface IProps {
	value: Content[];
	on_change: (selecteds: Content[]) => void;
}

export const BookEditionSelect: React.FC<IProps> = ({ value, on_change }) => {
	const { result, loading, error, search } = useSearchableApi<Book>('books');
	const [text, set_text] = useState('');

	useEffect(() => {
		search({ text: '' });
	}, []);

	return (
		<div className='max-w-5xl flex w-screen '>
			<div className='flex flex-col w-2/3 '>
				<Search
					{...{
						loading,
						size: 'large',
						placeholder: 'Pesquisar Livros...',
						onSearch: (t: string) => {
							if (t === '') {
								set_text('');
							}

							search({ text: t });
						},
						onChange: (event) => {
							set_text(event.target.value);
						},
						value: text,
					}}
				/>

				<section className='max-w-5xl flex flex-row w-screen mt-2'>
					<EditionSelectionList
						{...{
							value,
							books: result?.entities || [],
							on_change,
						}}
					/>
					{value && (
						<div className='max-w-xs ml-3'>
							<SelectedEdition {...{ selected_contents: value, on_change }} />
						</div>
					)}
				</section>
			</div>
		</div>
	);
};
