import { FC, useState } from 'react';
import { AddButton } from '../../../../../lib/components/Buttons/AddButton';
// import { RemoveButton } from '../../../../../lib/components/Buttons/RemoveButton';
import { CellStyle } from '../../../../../lib/components/DataTable/CellStyle';
import { DataTable } from '../../../../../lib/components/DataTable/DataTable';
import { useRequestFeedback } from '../../../../../lib/hooks';
import { Tag } from '../../../../../lib/model/Tag';
import { useTagsManagementApi } from '../../hooks/ContractTagsApi';
import { UpdateButton } from '../../../../../lib/components/Buttons/UpdateButton';

interface IProps {
	book_series: Tag[];
	on_remove_success: () => void;
	visible_insert_modal: () => void;
	set_current_update_id?: (data: Tag | undefined) => void;
}

export const BookSeriesDatatable: FC<IProps> = ({
	book_series,
	on_remove_success,
	visible_insert_modal,
	set_current_update_id,
}) => {
	const { remove } = useTagsManagementApi('book');

	if (remove.result) {
		on_remove_success();
	}

	useRequestFeedback(remove.result, remove.error);

	return (
		<DataTable
			{...{
				rowData: book_series,
				buttons: [
					<AddButton
						onClick={() => {
							if (set_current_update_id) set_current_update_id(undefined);
							visible_insert_modal();
						}}
					/>,
				],
				columns: [
					{
						field: 'title',
						headerName: 'Título',
						resizable: true,
						rowDrag: false,
						width: 500,
					},

					{
						col_def: {
							field: 'buttons',
							headerName: '',
							pinned: 'right',
							cellStyle: CellStyle.Centered,
							width: 100,
						},
						renderer: (props: { data: Tag }) => (
							<UpdateButton
								{...{
									onClick: () => {
										if (props?.data && set_current_update_id) {
											set_current_update_id(props?.data);
										}
										visible_insert_modal();
									},
								}}
							/>
							// <RemoveButton
							// 	{...{
							// 		on_confirm: () => remove.do_remove(props.data.id),
							// 		title: `Deseja remover a ${props.data.title} de livro?`,
							// 	}}
							// />
						),
					},
				],
			}}
		/>
	);
};
