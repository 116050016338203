import { Modal } from 'antd';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../../../../lib/components/Buttons/AddButton';
import { ButtonsContainer } from '../../../../lib/components/Buttons/ButtonsContainer';
import { CancelButton } from '../../../../lib/components/Buttons/CancelButton';
import { SectionDisplay } from '../../../../lib/model/Section';
import { useSectionApi } from '../hooks/useSectionPage';
import { SectionForm } from './Section_Form/SectionForm';
import { SectionFormArgs } from './Section_Form/useSectionForm';

interface IProps {
	visible: boolean;
	close: () => void;
}

export const SectionInsertModal: FC<IProps> = ({ visible, close }) => {
	const navigate = useNavigate();
	const [section, set_section] = useState<SectionFormArgs>({
		title: '',
		display: SectionDisplay.CAROUSEL,
	});

	const { insert } = useSectionApi();

	function validate_and_submit(dto: SectionFormArgs) {
		return insert.do_insert({ title: dto.title, display: dto.display });
	}

	if (insert.result) {
		navigate(`/secoes/${insert.result.id}`);
	}

	return (
		<Modal
			{...{
				title: 'Criar Seção',
				visible,
				centered: true,
				width: 500,
				closable: false,
				destroyOnClose: true,
				footer: [
					<ButtonsContainer class_name={'flex flex-row justify-end'}>
						<CancelButton {...{ onClick: close }} />
						<AddButton onClick={() => validate_and_submit(section)} />
					</ButtonsContainer>,
				],
			}}
		>
			<SectionForm {...{ initial_values: section, on_change: set_section }} />
		</Modal>
	);
};
